import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '@app/_services';
import { AppServices } from '@app/common/appServices';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {

  public dealsForm: any;
  public submitted: boolean;
  sampleDropdownList = [
    'Lorem',
    'Vestib',
    'Nulla',
    'Suspen',
    'Suspe',
    'Pellen',
  ];
  displayName: string;
  cmpId: number;
  cmpName: string;
  dealId: string;
  companyData: any;
  ownerDropdown = [
    'Owner 1',
    'Owner 2',
    'Owner 3',
    'Owner 4',
  ];
  designationDropdown = [
    'Designation 1',
    'Designation 2',
    'Designation 3',
    'Designation 4',
  ];
  fundingRoundDropdown = [
    'First round',
    'Second round',
    'Third round',
    'Fourth round',
  ];
  dealTypeDropdown = [
    'Pre-revenue',
    'Early',
    'Growth',
    'Majority',
    'Buyout',
    'Pre-IPO',
    'PIPE',
  ];
  investmentTypeDropdown = [
    'Equity',
    'CCPS',
    'Non Convertible debt',
    'Convertible debt',
    'Others',
  ];
  stagesOfInvestmentDropdown = [
    'Qualified Lead',
    'Internal Evaluation',
    'DEM',
    'Term Sheet',
    'External DD',
    'ICM',
    'Documentation',
    'Closed',
  ]
  leadTypeDropdown = [
    'Inbound',
    'Outbound',
    'Referred',
  ]
  statusDropdown = [
    'Active',
    'Hold',
    'Declined',
  ]
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    private appservices: AppServices,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // this.prepareEmptyForm();
    this.cmpId = +(localStorage.getItem('selectedCompanyId'));
    this.cmpName = localStorage.getItem('selectedCompanyName');
    let user = localStorage.getItem('currentUser');
    this.displayName = user && JSON.parse(user).userName;
    this.route.params.subscribe(params => {
      this.dealId = params['dealId'];
      this.getDealDetails(params['dealId']);
    })
  }

  getCompanyDetails() {
    this.dashboardService.getCompanyById(this.cmpId).subscribe((res: any) => {
      if (res.intStatus == 200) {
        let d = res.response.data[0];
        return d.dealTeam;
      }
      return null;
    })
  }

  getDealDetails(dealId) {
    if (dealId != 'new') {
      this.dashboardService.getDealById(this.cmpId, dealId).subscribe((res: any) => {
        if (res.intStatus == 200) {
          let d = res.response.data[0];
          this.prepareForm(d);
          // this.dealsForm.setValue({
          //   name: "",
          //   fundingRoundByCapital: d.fundingRound,
          //   dateOfDeal: {
          //     date,
          //     month,
          //     year,
          //   },
          //   dealType: d.dealType,
          //   dealTeam: d.dealTeam,
          //   investmentType: d.investmentType,
          //   existingInvestors: d.existingInvestors,
          //   totalRoundSize: d.totalRoundSize,
          //   investmentByStakeboatCapital: d.investmentSize,
          //   stageOfInvestment: d.stageOfInvestment,
          //   coInvestors: d.coInvestors,
          //   leadInvestor: d.leadInvestor,
          //   businessModel: d.businessModel,
          //   leadType: d.leadType,
          //   investmentDocuments: d.investmentRelatedDoc,
          //   status: d.strStatus,
          // });
        }
      });
    } else {
      this.prepareForm();
    }
  }

  deleteFile(controlName) {
    let control = this.dealsForm.get(`${controlName}.file`);
    console.log(control);
    control.patchValue('');
  }

  prepareForm(d?) {
    let isNew = !d;
    let [year = '', month = '', date = ''] = d ? d.dateofDeal.split('-') : [];
    year = +year;
    month = +month;
    date = +date;
    let dealTeam;
    this.dashboardService.getCompanyById(this.cmpId).subscribe((res: any) => {
      if (res.intStatus == 200) {
        let d = res.response.data[0];
        this.companyData = d;
        dealTeam = d.dealTeam;
      }
      this.dealsForm = this.formBuilder.group({
        // name: [isNew ? '' : d.name, Validators.required],
        fundingRoundByCapital: [isNew ? '' : d.fundingRound, Validators.required],
        dateOfDeal: this.formBuilder.group({
          date: [isNew ? '' : date, Validators.required],
          month: [isNew ? '' : month, Validators.required],
          year: [isNew ? '' : year, Validators.required],
        }),
        dealType: [isNew ? '' : d.dealType, Validators.required],
        dealTeam: this.formBuilder.array(
          (!dealTeam) ? [this.createDealTeam()] :
            dealTeam.map(dt => this.createDealTeam(dt))
        ),
        investmentType: isNew ? 
          this.formBuilder.group({value: ['', Validators.required], customValue: ['']}) : 
          this.formBuilder.group({
            value: [this.investmentTypeDropdown.includes(d.investmentType) ? d.investmentType : 'Others', Validators.required],
            customValue: [this.investmentTypeDropdown.includes(d.investmentType) ? '' : d.investmentType]
          }),
        existingInvestors: this.formBuilder.array(
          isNew ? [this.createExistingInvestors()] :
            d.existingInvestors.map(ei => this.createExistingInvestors(ei))),
        totalRoundSize: [isNew ? '' : d.totalRoundSize, Validators.required],
        investmentByStakeboatCapital: [isNew ? '' : d.investmentSize, Validators.required],
        stageOfInvestment: [isNew ? '' : d.stageOfInvestment, Validators.required],
        coInvestors: this.formBuilder.array(
          isNew ? [this.createCoInvestors()] :
            d.coInvestors.map(ci => this.createCoInvestors(ci))
        ),
        leadInvestor: [isNew ? 'n' : d.leadInvestor, Validators.required],
        businessModel: [isNew ? '' : d.businessModel, Validators.required],
        leadType: [isNew ? '' : d.leadType, Validators.required],
        investmentDocuments: this.formBuilder.array(
          isNew ? [this.createInvestmentDocuments()] :
            d.investmentRelatedDoc.map(ic => this.createInvestmentDocuments(ic))
        ),
        status: [isNew ? '' : d.strStatus, Validators.required],
      });
    })

  }

  onSubmit() {
    this.submitted = true;
    if (this.dealsForm.invalid) {
      return;
    }
    this.saveDeal();
  }

  saveDeal() {
    let d = this.dealsForm.value;
    let date = d.dateOfDeal;
    date.date = date.date && date.date < 10 ? '0' + date.date : date.date;
    date.month = date.month && date.month < 10 ? '0' + date.month : date.month;
    d.investmentType = d.investmentType.value == 'Others' ? d.investmentType.customValue : d.investmentType.value;
    let postData = {
      intCmpId: this.cmpId,
      strDateofDeal: `${date.year}-${date.month}-${date.date}`,
      dealTeam: d.dealTeam,
      strFundingRound: d.fundingRoundByCapital,
      strDealType: d.dealType,
      strInvestmentType: d.investmentType,
      strInvestmentTypeOther: null, // check
      existingInvestors: d.existingInvestors,
      strTotalRoundSize: d.totalRoundSize,
      strInvestmentSize: d.investmentByStakeboatCapital,
      strStageOfInvestment: d.stageOfInvestment,
      strCoInvestors: d.coInvestors,
      strLeadInvestor: d.leadInvestor,
      strBusinessModel: d.businessModel,
      strBusinessModelOther: null, // check
      strLeadType: d.leadType,
      strLeadTypeOther: null, // check
      investmetRelatedDoc: d.investmentDocuments,
      strStatus: d.status,
    }
    this.dashboardService.addDeal(postData, this.dealId).subscribe((res: any) => {
      if (res.intStatus == 200) {
        this.showSuccess(res.strMessage);
        this.router.navigate(['addbusiness']);
      } else {
        this.showError(res && res.strMessage || 'Some error occured');
      }
      console.log("kbt ~ file: company.component.ts ~ line 125 ~ CompanyComponent ~ this.dashboardService.addCompany ~ res", res);
    })
  }

  fileUploadHandler(event, i) {
    let file = event.target.files[0];
    let origFileName = event.target.files[0].name;
    let formArray = this.dealsForm.get(`investmentDocuments`);
    let formGroup = formArray.controls[i];
    let titleFormControl = formGroup && formGroup.get('documentTitle');
    if (!titleFormControl.value) {
      event.target.value = '';
      return;
    }
    let fileName = titleFormControl.value;
    const formData = new FormData();
    formData.append('strFileInput', file, origFileName);
    formData.append('strFileName', fileName);
    formData.append('strUploadFolder', 'deals');
    this.appservices.uploadFiles(formData, (res, err) => {
      event.target.value = '';
      console.log(res.response);
      let fileUrl = res.response.path;
      let fileFormControl = formGroup && formGroup.get('file');
      fileFormControl && fileFormControl.setValue(fileUrl);
    });
  }

  createInvestmentDocuments(data?) {
    return this.formBuilder.group({
      documentTitle: [data ? data.documentTitle : ''],
      file: [data ? data.file : ''],
    })
  }

  createDealTeam(d?) {
    return this.formBuilder.group({
      name: [d ? d.name : '', Validators.required],
      designation: [d ? d.designation : '', Validators.required]
    });
  }

  createExistingInvestors(data?) {
    return this.formBuilder.group({
      name: [data ? data.name : '', Validators.required],
      shareholding: [data ? data.shareholding : '', Validators.required]
    });
  }

  createCoInvestors(data?) {
    return this.formBuilder.group({
      name: [data ? data.name : '', Validators.required],
    })
  }

  addDealTeam() {
    if (this.dealTeam.length < 4)
      this.dealTeam.push(this.createDealTeam());
    else
      this.showError('Only 4 deals can be created');
  }

  addExistingInvestors() {
    this.existingInvestors.push(this.createExistingInvestors());
  }

  addCoInvestors() {
    this.coInvestors.push(this.createCoInvestors());
  }

  addInvestmentDocuments() {
    this.investmentDocuments.push(this.createInvestmentDocuments());
  }

  deleteField(arrayName, i) {
    let ctrl = this.dealsForm.get(arrayName) as FormArray;
    ctrl.removeAt(i);
  }

  showError(err) {
    this.snackBar.open(err, '', {
      duration: 2000,
      panelClass: ['alert', 'show', 'alert-danger'],
    });
  }

  showSuccess(msg) {
    this.snackBar.open(msg, '', {
      duration: 2000,
      panelClass: ['alert', 'show', 'alert-success'],
    });
  }

  errorCheck(controlName, isGroup?) {
    let elem = this.dealsForm.get(controlName);
    if (controlName == 'sector ') {
      console.log('elem.invalid', elem.invalid);
    }
    return elem.invalid && (isGroup ? this.submitted : (this.submitted || elem.touched || elem.dirty));
  }

  errorShow(controlName, isGroup?) {
    let elem = this.dealsForm.get(controlName);
    if (controlName == 'sector ') {
      console.log('elem.invalid', elem.invalid);
    }
    if (elem.invalid && (isGroup ? this.submitted : (this.submitted || elem.touched || elem.dirty))) {
      if (elem.errors && elem.errors.required)
        return 'This field is required';
      else
        return 'Please provide valid input';
    }
  }

  public get investmentType() {
    return this.dealsForm.get('investmentType');
  }

  public get dealTeam() {
    return this.dealsForm.get('dealTeam');
  }
  public get existingInvestors() {
    return this.dealsForm.get('existingInvestors');
  }
  public get coInvestors() {
    return this.dealsForm.get('coInvestors');
  }
  public get investmentDocuments() {
    return this.dealsForm.get('investmentDocuments');
  }
  public get dateOfDeal() {
    return this.dealsForm.get('dateOfDeal');
  }

}
