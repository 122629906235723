import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';
import { SharedModule } from '@app/components/shared.module';

import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import { DomService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CompanyFinancialComponent } from './company-financial/company-financial.component';

import { CompanyComponent } from './pages/company/company.component'
import { InputWrapperComponent } from './components/inputwrapper';
import { DealsComponent } from './pages/deals/deals.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { AddBusinessComponent } from './pages/add-business/add-business.component'
import { DateSelectorComponent } from './components/dateselector';
import { MaterialModule } from './components/material/material.module';
import { CompanyEntryComponent } from './companyentry/companyentry.component';
import { PromoterComponent } from './promoter/promoter.component';
import { UserentryComponent } from './userentry/userentry.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";

export const customCurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    allowZero: true,
    decimal: ".",
    precision: 2,
    prefix: "$ ",
    suffix: "",
    thousands: ",",
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.NATURAL
};
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        FormsModule,
        InlineSVGModule,
        SharedModule,
        NgbModule,
        ToastrModule.forRoot(),
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        CompanyFinancialComponent,
        LoginComponent,
        CompanyComponent,
        InputWrapperComponent,
        DateSelectorComponent,
        CompanyEntryComponent,
        DealsComponent,
        DashboardComponent,
        AddBusinessComponent,
        PromoterComponent,
        UserentryComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DomService

        // provider used to create fake backend
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }