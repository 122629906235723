import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  displayName: string;
  @Input() companyName: any;
  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    let user = localStorage.getItem('currentUser');
    this.displayName = user && JSON.parse(user).userName
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
