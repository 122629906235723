`<div class="container">
	<div class="row">
		<div class="col-md-12">
			<div class="brand-header">
			    <img src="assets/zastra-logo-color.svg" height="50px" alt="stakeboat-logo" />
			    <div class="d-flex align-items-center">
			        <div class="name mr-4">{{displayName}}</div>
			        <button style="padding: 4px 12px;" class="btn btn-outline-primary center-btn-icon" (click)="logout()">
			            <mat-icon class="center-mat-icon">power_settings_new</mat-icon>
			            Logout
			        </button>
			    </div>
			</div>
		</div>
	</div>
</div>
<div class="header-img-wrapper">
	<div class="container">
		<div class="header-img-desc">
			<h3 class="header-img-txt">Company: <span class="bolder-text">{{compName}}</span></h3>
			<p class="header-img-txt-desc">Date of Deal: <span class="bolder-text">{{deaDt}}</span></p>
		</div>
		<div class="header-output">
			<div class="row">
				<div class="col-md-4">
					<div class="form-group">
						
			        </div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						
			        </div>
				</div>
				<div class="col-md-4">
					
				</div>
			</div>
		</div>
	</div>	
</div>
<div class="container">

	<!-- <div class="row">
		<div class="col-md-12">
			<div class="site-logo">
				<img src="assets/zastra-logo.png" alt="stakeboat-logo" />
			</div>
			<div class="user-data">
				<span class="user_det">Super Admin</span>
				<a href="#"><span class="logout-btn">Logout</span></a>
			</div>
		</div>
	</div> -->
</div>
<!-- <div class="header-img-wrapper">
	<div class="container">
		<div class="header-img-desc">
			<h3 class="header-img-txt">Company: <span class="bolder-text">Gillete</span></h3>
			<p class="header-img-txt-desc">Date of Deal: <span class="bolder-text">11 Jan 2020</span></p>
		</div>
	</div>	
</div> -->



<div class="container pad-50 pad-bot-50">
	<div class="row">
		<div class="col-md-12">
			<div class="kbt-breadcrumb">
				<div class="kbt-breadcrumb-item" [class.selected]="page_1 == 'active'" (click)="goToPage(1);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Financial Information</span>
					</div>
				</div>

				<div class="kbt-breadcrumb-item" [class.selected]="page_2 == 'active'" (click)="goToPage(2);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Business</span>
					</div>
				</div>

				<div class="kbt-breadcrumb-item" [class.selected]="page_3 == 'active'" (click)="goToPage(3);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Investment</span>
					</div>
				</div>

				<div class="kbt-breadcrumb-item" [class.selected]="page_4 == 'active'" (click)="goToPage(4);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Promoter</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--begin::Form-->
<form class="form" #angForm="ngForm">
	<div *ngIf="page_1">
		<div class="container pad-50 pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Financial Information</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<input type="text" class="form-control" [(ngModel)]="financial_data_year" name="fin_data_year" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_1}}</label>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_3}}</label>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_4}}</label>
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Consolidated Net Revenue</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="con_net_rev_y1" name="con_net_rev_y1" (ngModelChange)="onRevChange($event, 'y1');calcPBT($event, 'y1')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="con_net_rev_y2" name="con_net_rev_y2" (ngModelChange)="onRevChange($event, 'y2');calcPBT($event, 'y2')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="con_net_rev_y3" name="con_net_rev_y3" (ngModelChange)="onRevChange($event, 'y3');calcPBT($event, 'y3')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="con_net_rev_y4" name="con_net_rev_y4" (ngModelChange)="onRevChange($event, 'y4');calcPBT($event, 'y4')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="con_net_rev_y5" name="con_net_rev_y5" (ngModelChange)="onRevChange($event, 'y5');calcPBT($event, 'y5')" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">EBITDA</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_y1" name="ebitda_y1" (ngModelChange)="onRevChange($event, 'y1'); calcPBT($event, 'y1')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_y2" name="ebitda_y2"  (ngModelChange)="onRevChange($event, 'y2'); calcPBT($event, 'y2')"/>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_y3" name="ebitda_y3"  (ngModelChange)="onRevChange($event, 'y3'); calcPBT($event, 'y3')"/>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_y4" name="ebitda_y4"  (ngModelChange)="onRevChange($event, 'y4'); calcPBT($event, 'y4')"/>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_y5" name="ebitda_y5" (ngModelChange)="onRevChange($event, 'y5'); calcPBT($event, 'y5')" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">EBITDA % to revenue</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="ebitda_rev_y1" name="ebitda_rev_y1" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="ebitda_rev_y2" name="ebitda_rev_y2" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="ebitda_rev_y3" name="ebitda_rev_y3" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="ebitda_rev_y4" name="ebitda_rev_y4" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="ebitda_rev_y5" name="ebitda_rev_y5" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Interest</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="int_y1" name="int_y1" (ngModelChange)="calcPBT($event, 'y1')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="int_y2" name="int_y2" (ngModelChange)="calcPBT($event, 'y2')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="int_y3" name="int_y3" (ngModelChange)="calcPBT($event, 'y3')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="int_y4" name="int_y4" (ngModelChange)="calcPBT($event, 'y4')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="int_y5" name="int_y5" (ngModelChange)="calcPBT($event, 'y5')" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Depreciation / Amortization</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="dep_amo_y1" name="dep_amo_y1" (ngModelChange)="calcPBT($event, 'y1')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="dep_amo_y2" name="dep_amo_y2" (ngModelChange)="calcPBT($event, 'y2')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="dep_amo_y3" name="dep_amo_y3" (ngModelChange)="calcPBT($event, 'y3')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="dep_amo_y4" name="dep_amo_y4" (ngModelChange)="calcPBT($event, 'y4')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="dep_amo_y5" name="dep_amo_y5" (ngModelChange)="calcPBT($event, 'y5')" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Other income</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="other_inc_y1" name="other_inc_y1" (ngModelChange)="calcPBT($event, 'y1')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="other_inc_y2" name="other_inc_y2" (ngModelChange)="calcPBT($event, 'y2')"/>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="other_inc_y3" name="other_inc_y3" (ngModelChange)="calcPBT($event, 'y3')"/>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="other_inc_y4" name="other_inc_y4" (ngModelChange)="calcPBT($event, 'y4')"/>
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="other_inc_y5" name="other_inc_y5" (ngModelChange)="calcPBT($event, 'y5')"/>
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Profit before tax (PBT)</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pro_bef_tax_y1<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pro_bef_tax_y1" name="pro_bef_tax_y1" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pro_bef_tax_y2<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pro_bef_tax_y2" name="pro_bef_tax_y2" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pro_bef_tax_y3<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pro_bef_tax_y3" name="pro_bef_tax_y3" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pro_bef_tax_y4<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pro_bef_tax_y4" name="pro_bef_tax_y4" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pro_bef_tax_y5<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pro_bef_tax_y5" name="pro_bef_tax_y5" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">PBT % to revenue</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pbt_rev_y1" name="pbt_rev_y1" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pbt_rev_y2" name="pbt_rev_y2" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pbt_rev_y3" name="pbt_rev_y3" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pbt_rev_y4" name="pbt_rev_y4" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pbt_rev_y5" name="pbt_rev_y5" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Taxes</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="taxes_y1" name="taxes_y1" (ngModelChange)="calcPAT($event, 'y1')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="taxes_y2" name="taxes_y2" (ngModelChange)="calcPAT($event, 'y2')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="taxes_y3" name="taxes_y3" (ngModelChange)="calcPAT($event, 'y3')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="taxes_y4" name="taxes_y4" (ngModelChange)="calcPAT($event, 'y4')" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="taxes_y5" name="taxes_y5" (ngModelChange)="calcPAT($event, 'y5')" />
							    </div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Profit after tax (PAT)</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask  [ngClass]="{'negValue': pat_y1<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pat_y1" name="pat_y1" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pat_y2<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pat_y2" name="pat_y2" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pat_y3<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pat_y3" name="pat_y3" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pat_y4<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pat_y4" name="pat_y4" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [ngClass]="{'negValue': pat_y5<0}" [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pat_y5" name="pat_y5" />
							    </div>
							</div>
						</div>
					</div>


					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">PAT % to revenue</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pat_rev_y1" name="pat_rev_y1" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pat_rev_y2" name="pat_rev_y2" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pat_rev_y3" name="pat_rev_y3" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pat_rev_y4" name="pat_rev_y4" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pat_rev_y5" name="pat_rev_y5" />
							    </div>
							</div>
						</div>
					</div>


				</div>
			</div>
		</div>

		<!-- financial info year to date -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Financial information Year to Date</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Period ended</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
							    <select class="form-control form-control-solid" [(ngModel)]="info_month" name="info_month">
							    	<option>Month</option>
							    	<option val="01">Jan</option>
							    	<option val="02">Feb</option>
							    	<option val="03">Mar</option>
							    	<option val="04">Apr</option>
							    	<option val="05">May</option>
							    	<option val="06">Jun</option>
							    	<option val="07">Jul</option>
							    	<option val="08">Aug</option>
							    	<option val="09">Sep</option>
							    	<option val="10">Oct</option>
							    	<option val="11">Nov</option>
							    	<option val="12">Dec</option>						    
							    </select>
						   	</div>
						   	
						</div>
						<div class="col-md-2">
							<div class="form-group">
							    <select class="form-control form-control-solid" [(ngModel)]="info_year" name="info_year">
							    	<option>Year</option>
							    	<option val="2015">2015</option>
							    	<option val="2015">2016</option>
							    	<option val="2015">2017</option>
							    	<option val="2015">2018</option>
							    	<option val="2015">2019</option>
							    	<option val="2015">2020</option>
							    	<option val="2015">2021</option>
							    	<option val="2015">2022</option>
							    	<option val="2015">2023</option>
							    	<option val="2015">2024</option>
							    	<option val="2015">2025</option>					    
							    </select>
						   	</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
							    <select class="form-control form-control-solid" [(ngModel)]="no_of_months" name="no_of_months">
							    	<option val="1">1</option>
							    	<option val="2">2</option>	
							    	<option val="3">3</option>	
							    	<option val="4">4</option>	
							    	<option val="5">5</option>	
							    	<option val="6">6</option>	
							    	<option val="7">7</option>	
							    	<option val="8">8</option>	
							    	<option val="9">9</option>	
							    	<option val="10">10</option>	
							    	<option val="11">11</option>	
							    	<option val="12">12</option>							    
							    </select>
						   	</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">

							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Consolidated Net Revenue</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">EBITDA</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">EBITDA % to revenue</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Interest</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Depreciation / Amortization</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Other income</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Profit before tax (PBT)</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">PBT % to revenue</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Taxes</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Profit after tax (PAT)</label>
							</div>
							<div class="form-group row bg-white">
							    <label class="col-lg-12 col-form-label tbl-col-txt">PAT % to revenue</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="con_net_rev_ytd" name="con_net_rev_ytd" (ngModelChange)="onRevChange($event, 'y6');calcPBT($event, 'y6')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_ytd" name="ebitda_ytd" (ngModelChange)="onRevChange($event, 'y6');calcPBT($event, 'y6')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="ebitda_rev_ytd" name="ebitda_rev_ytd" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="int_ytd" name="int_ytd" (ngModelChange)="calcPBT($event, 'y6')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="dep_amo_ytd" name="dep_amo_ytd" (ngModelChange)="calcPBT($event, 'y6')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="other_inc_ytd" name="other_inc_ytd" (ngModelChange)="calcPBT($event, 'y6')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pro_bef_tax_ytd" name="pro_bef_tax_ytd" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pbt_rev_ytd" name="pbt_rev_ytd" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="taxes_ytd" name="taxes_ytd" (ngModelChange)="calcPAT($event, 'y6')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="pat_ytd" name="pat_ytd" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input disabled currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="pat_rev_ytd" name="pat_rev_ytd" />
							    </div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- end of financial info year to date -->

		<!-- upload files -->
		<!-- <div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Upload Files</h3>
					<div class="card-toolbar">
						
					</div>
				</div> 
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow1">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper  *ngIf="fileUplo1 === false">
								<app-fileupload version="1" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >= 1">
								<span class="file-list">
									FileURL : <a target="_blank" href="{{uploadedFiles[0].fileURL}}" download="File.pdf">{{uploadedFiles[0].fileURL}}</a>  
									<span (click)="deletefiles(0)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow2">
								<button class="form-control btn btn-primary" (click)="addUploadControl(1)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>

				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow2">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo2 === false">
								<app-fileupload version="2" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >= 2">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[1].fileURL}}" download="File.pdf">{{uploadedFiles[1].fileURL}}</a>
									<span (click)="deletefiles(1)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
								
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow3">
								<button class="form-control btn btn-primary" (click)="addUploadControl(2)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow3">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo3 === false">
								<app-fileupload version="3" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >= 3">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[2].fileURL}}" download="File.pdf">{{uploadedFiles[2].fileURL}}</a>
									<span (click)="deletefiles(2)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow4">
								<button class="form-control btn btn-primary" (click)="addUploadControl(3)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow4">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo4 === false">
								<app-fileupload version="4" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=4">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[3].fileURL}}" download="File.pdf">{{uploadedFiles[3].fileURL}}</a>
									<span (click)="deletefiles(3)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow5">
								<button class="form-control btn btn-primary" (click)="addUploadControl(4)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow5">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo5 === false">
								<app-fileupload version="5" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=5">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[4].fileURL}}" download="File.pdf">{{uploadedFiles[4].fileURL}}</a>
									<span (click)="deletefiles(4)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow6">
								<button class="form-control btn btn-primary" (click)="addUploadControl(5)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow6">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo6 === false">
								<app-fileupload version="6" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=6">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[5].fileURL}}" download="File.pdf">{{uploadedFiles[5].fileURL}}</a>
									<span (click)="deletefiles(5)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow7">
								<button class="form-control btn btn-primary" (click)="addUploadControl(6)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow7">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo7 === false">
								<app-fileupload version="7" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=7">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[6].fileURL}}" download="File.pdf">{{uploadedFiles[6].fileURL}}</a>
									<span (click)="deletefiles(6)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow8">
								<button class="form-control btn btn-primary" (click)="addUploadControl(7)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow8">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo8 === false">
								<app-fileupload version="8" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=8">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[7].fileURL}}" download="File.pdf">{{uploadedFiles[7].fileURL}}</a>
									<span (click)="deletefiles(7)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow9">
								<button class="form-control btn btn-primary" (click)="addUploadControl(8)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow9">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo9 === false">
								<app-fileupload version="9" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=9">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[8].fileURL}}" download="File.pdf">{{uploadedFiles[8].fileURL}}</a>
									<span (click)="deletefiles(8)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow10">
								<button class="form-control btn btn-primary" (click)="addUploadControl(9)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow10">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo10 === false">
								<app-fileupload version="10" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=10">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[9].fileURL}}" download="File.pdf">{{uploadedFiles[9].fileURL}}</a>
									<span (click)="deletefiles(9)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>						
					</div>
				</div>

			</div>
		</div> -->
		
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Upload Files</h3>
					<div class="card-toolbar">
						
					</div>
				</div> 
				
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow1">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper  *ngIf="fileUplo1 === false">
								<app-fileupload version="1" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >= 1">
								<span class="file-list">
									FileURL : <a target="_blank" href="{{uploadedFiles[0].fileURL}}" download="File.pdf">{{uploadedFiles[0].fileURL}}</a>  
									<span (click)="deletefiles(0)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow2">
								<button class="form-control btn btn-primary fileShow1" (click)="addUploadControl(1)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>

				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow2">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo2 === false">
								<app-fileupload version="2" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >= 2">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[1].fileURL}}" download="File.pdf">{{uploadedFiles[1].fileURL}}</a>
									<span (click)="deletefiles(1)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
								
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow3">
								<button class="form-control btn btn-primary fileShow2" (click)="addUploadControl(2)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow3">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo3 === false">
								<app-fileupload version="3" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >= 3">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[2].fileURL}}" download="File.pdf">{{uploadedFiles[2].fileURL}}</a>
									<span (click)="deletefiles(2)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow4">
								<button class="form-control btn btn-primary fileShow3" (click)="addUploadControl(3)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow4">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo4 === false">
								<app-fileupload version="4" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=4">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[3].fileURL}}" download="File.pdf">{{uploadedFiles[3].fileURL}}</a>
									<span (click)="deletefiles(3)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow5">
								<button class="form-control btn btn-primary fileShow4" (click)="addUploadControl(4)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow5">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo5 === false">
								<app-fileupload version="5" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=5">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[4].fileURL}}" download="File.pdf">{{uploadedFiles[4].fileURL}}</a>
									<span (click)="deletefiles(4)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow6">
								<button class="form-control btn btn-primary fileShow5" (click)="addUploadControl(5)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow6">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo6 === false">
								<app-fileupload version="6" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=6">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[5].fileURL}}" download="File.pdf">{{uploadedFiles[5].fileURL}}</a>
									<span (click)="deletefiles(5)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow7">
								<button class="form-control btn btn-primary fileShow6" (click)="addUploadControl(6)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow7">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo7 === false">
								<app-fileupload version="7" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=7">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[6].fileURL}}" download="File.pdf">{{uploadedFiles[6].fileURL}}</a>
									<span (click)="deletefiles(6)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow8">
								<button class="form-control btn btn-primary" (click)="addUploadControl(7)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow8">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo8 === false">
								<app-fileupload version="8" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=8">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[7].fileURL}}" download="File.pdf">{{uploadedFiles[7].fileURL}}</a>
									<span (click)="deletefiles(7)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow9">
								<button class="form-control btn btn-primary" (click)="addUploadControl(8)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow9">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo9 === false">
								<app-fileupload version="9" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=9">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[8].fileURL}}" download="File.pdf">{{uploadedFiles[8].fileURL}}</a>
									<span (click)="deletefiles(8)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!fileShow10">
								<button class="form-control btn btn-primary" (click)="addUploadControl(9)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="fileShow10">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="fileUplo10 === false">
								<app-fileupload version="10" companyId="companyId" dealId="dealId" (onSelectValue)='selectValue($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles.length >=10">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles[9].fileURL}}" download="File.pdf">{{uploadedFiles[9].fileURL}}</a>
									<span (click)="deletefiles(9)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>						
					</div>
				</div>




			</div>
		</div>
		<!-- upload files -->

		<!-- inventory -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-8">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Inventory</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							   <label class="col-lg-12 col-form-label tbl-col-txt flt-right">Days</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input type="text" class="form-control" [(ngModel)]="inventory" name="inventory" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- inventory -->

		<!-- receivables -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-8">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Receivables</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							   <label class="col-lg-12 col-form-label tbl-col-txt flt-right">Days</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input type="text" class="form-control" [(ngModel)]="receivables" name="receivables" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- receivables -->

		<!-- interest coverage ratio -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Interest Coverage ratio</label>
							</div>
						</div>
						
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input disabled currencyMask [options]="inputRatioConfig" type="text" class="form-control" [(ngModel)]="int_cov_ratio" name="int_cov_ratio" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- interest coverage ratio -->

		<!-- free cash flow from operations -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Free cash flow from operations</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group row">
							    <div class="tbl-hdr">
							    	<input type="text" class="form-control" [(ngModel)]="financial_data_year" name="fin_data_year" />
							    </div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" style="float: right;">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Period ended Month</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt"> </label>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Free Cash Flows</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="free_cash_flow_y1" name="free_cash_flow_y1" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="free_cash_flow_y2" name="free_cash_flow_y2" />
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{no_of_months}} Months</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="free_cash_flow_months" name="free_cash_flow_months" />
							    </div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- free cash flow from operations -->

		<!-- revenue CAGR -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Revenue CAGR (Compounded Annual Growth rate) % over 2 years</label>
							</div>
						</div>
						
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="revenue_cgar" name="revenue_cgar" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- revenue CAGR -->

		<!-- debt equity ratio -->
		<div class="debt-equity-ratio container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Debt Equity ratio</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
								<div class="col-lg-12 tbl-hdr">
									<input type="text" class="form-control" [(ngModel)]="debt_equity_ratio" name="debt_equity_ratio" />
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<div class="col-lg-12 tbl-hdr">
									<label class="col-lg-12 col-form-label">{{fin_dt_yr_1}}</label>
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<div class="col-lg-12 tbl-hdr">
									<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<div class="col-lg-12 tbl-hdr">
									<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<div class="col-lg-12 tbl-hdr">
									<label class="col-lg-12 col-form-label">{{fin_dt_yr_3}}</label>
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<div class="col-lg-12 tbl-hdr">
									<label class="col-lg-12 col-form-label">{{fin_dt_yr_4}}</label>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2 form-group">
							<label class="col-lg-12 col-form-label tbl-col-txt">Total Debt = Long term + Short term</label>
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="total_debt_y1" name="total_debt_y1" 
								(ngModelChange)="calcDER($event, 'y1')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="total_debt_y2" name="total_debt_y2" 
								(ngModelChange)="calcDER($event, 'y2')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="total_debt_y3" name="total_debt_y3" 
								(ngModelChange)="calcDER($event, 'y3')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="total_debt_y4" name="total_debt_y4" 
								(ngModelChange)="calcDER($event, 'y4')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="total_debt_y5" name="total_debt_y5" 
								(ngModelChange)="calcDER($event, 'y5')" />
						</div>
					</div>
					<div class="row">
						<div class="col-md-2 form-group">
							<label class="col-lg-12 col-form-label tbl-col-txt">Equity</label>
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="equity_y1" name="equity_y1" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="equity_y2" name="equity_y2" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="equity_y3" name="equity_y3" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="equity_y4" name="equity_y4" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="equity_y5" name="equity_y5" />
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-2 form-group">
							<label class="col-lg-12 col-form-label tbl-col-txt">Net worth</label>
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="net_worth_y1" name="net_worth_y1"
								(ngModelChange)="calcDER($event, 'y1')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="net_worth_y2" name="net_worth_y2"
								(ngModelChange)="calcDER($event, 'y2')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="net_worth_y3" name="net_worth_y3"
								(ngModelChange)="calcDER($event, 'y3')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="net_worth_y4" name="net_worth_y4"
								(ngModelChange)="calcDER($event, 'y4')" />
						</div>
						<div class="col-md-2">
							<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="net_worth_y5" name="net_worth_y5"
								(ngModelChange)="calcDER($event, 'y5')" />
						</div>
					</div>
					<div class="row">
						<div class="col-md-2 form-group">
							<label class="col-lg-12 col-form-label tbl-col-txt">DE ratio</label>
						</div>
						<div class="col-md-2">
							<input disabled currencyMask [options]="inputRatioConfig" type="text" class="form-control" [(ngModel)]="de_ratio_y1" name="de_ratio_y1" />
						</div>
						<div class="col-md-2">
							<input disabled currencyMask [options]="inputRatioConfig" type="text" class="form-control" [(ngModel)]="de_ratio_y2" name="de_ratio_y2" />
						</div>
						<div class="col-md-2">
							<input disabled currencyMask [options]="inputRatioConfig" type="text" class="form-control" [(ngModel)]="de_ratio_y3" name="de_ratio_y3" />
						</div>
						<div class="col-md-2">
							<input disabled currencyMask [options]="inputRatioConfig" type="text" class="form-control" [(ngModel)]="de_ratio_y4" name="de_ratio_y4" />
						</div>
						<div class="col-md-2">
							<input disabled currencyMask [options]="inputRatioConfig" type="text" class="form-control" [(ngModel)]="de_ratio_y5" name="de_ratio_y5" />
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- debt equity ratio -->

		<!-- debt leverage structured -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Is debt leverage structured in the transaction</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="debt_lever" value="true" [(ngModel)]="debt_leverage"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="debt_lever" value="false" [(ngModel)]="debt_leverage"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- debt leverage structured -->	

		<div class="container pad-bot-50">
			<div class="form_footer_wrapper">
				<button class="btn btn-primary btn-pad-20" (click)="addFinancialInfo()">Save</button>
				<button class="btn btn-primary"  (click)="goTodashboard();">Cancel</button>
			</div>
		</div>
	</div>

	<div *ngIf="page_2">
		
		<!-- Raw Material Costs -->
		<div class="container pad-50 pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Total Raw Material Costs</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<input type="text" class="form-control" [(ngModel)]="financial_data_year" name="fin_data_year" />
							    </div>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Raw Material Costs</label>
							</div>						
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_1}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="raw_materials_y1" name="raw_materials_y1" />
							    </div>
							</div>							
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="raw_materials_y2" name="raw_materials_y2" />
							    </div>
							</div>							
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="raw_materials_y3" name="raw_materials_y3" />
							    </div>
							</div>							
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_3}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="raw_materials_y4" name="raw_materials_y4" />
							    </div>
							</div>							
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_4}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="raw_materials_y5" name="raw_materials_y5" />
							    </div>
							</div>							
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Raw Material Costs -->

		<!-- Top 5 Customers -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Top 5 customers of the Company</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-5">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Customer Name</label>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer1_name" name="top_customer1_name" (ngModelChange)="changeCusName($event, 1)" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer1_name_per" name="top_customer1_name_per" /> 
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer2_name" name="top_customer2_name" (ngModelChange)="changeCusName($event, 2)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer2_name_per" name="top_customer2_name_per" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer3_name" name="top_customer3_name" (ngModelChange)="changeCusName($event, 3)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer3_name_per" name="top_customer3_name_per" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer4_name" name="top_customer4_name" (ngModelChange)="changeCusName($event, 4)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer4_name_per" name="top_customer4_name_per" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer5_name" name="top_customer5_name" (ngModelChange)="changeCusName($event, 5)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_customer5_name_per" name="top_customer5_name_per" />
							    </div>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Total</label>
							</div>	
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Customer Concentration in %</label>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Customer Stickiness in %</label>
							</div>					
						</div>
						
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer1_y1" name="top_customer1_y1"  (ngModelChange)="onCusRevChange($event, 1, 'y1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer1_rev_y1" name="top_customer1_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer2_y1" name="top_customer2_y1"  (ngModelChange)="onCusRevChange($event, 2, 'y1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer2_rev_y1" name="top_customer2_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer3_y1" name="top_customer3_y1" (ngModelChange)="onCusRevChange($event, 3, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer3_rev_y1" name="top_customer3_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer4_y1" name="top_customer4_y1" (ngModelChange)="onCusRevChange($event, 4, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer4_rev_y1" name="top_customer4_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer5_y1" name="top_customer5_y1"  (ngModelChange)="onCusRevChange($event, 5, 'y1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer5_rev_y1" name="top_customer5_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer_total_y1" name="top_customer_total_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer_conc_y1" name="top_customer_conc_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer_stick_y1" name="top_customer_stick_y1" disabled />
							    </div>
							</div>						
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer1_y2" name="top_customer1_y2"    (ngModelChange)="onCusRevChange($event, 1, 'y2')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer1_rev_y2" name="top_customer1_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer2_y2" name="top_customer2_y2"  (ngModelChange)="onCusRevChange($event, 2, 'y2')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer2_rev_y2" name="top_customer2_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer3_y2" name="top_customer3_y2" (ngModelChange)="onCusRevChange($event, 3, 'y2')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer3_rev_y2" name="top_customer3_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer4_y2" name="top_customer4_y2"  (ngModelChange)="onCusRevChange($event, 4, 'y2')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer4_rev_y2" name="top_customer4_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer5_y2" name="top_customer5_y2" (ngModelChange)="onCusRevChange($event, 5, 'y2')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer5_rev_y2" name="top_customer5_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer_total_y2" name="top_customer_total_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>

						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{no_of_months}} Month</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer1_m1" name="top_customer1_m1"  (ngModelChange)="onCusRevChange($event, 1, 'm1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer1_rev_m1" name="top_customer1_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer2_m1" name="top_customer2_m1"  (ngModelChange)="onCusRevChange($event, 2, 'm1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer2_rev_m1" name="top_customer2_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer3_m1" name="top_customer3_m1"  (ngModelChange)="onCusRevChange($event, 3, 'm1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer3_rev_m1" name="top_customer3_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer4_m1" name="top_customer4_m1"  (ngModelChange)="onCusRevChange($event, 4, 'm1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer4_rev_m1" name="top_customer4_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_customer5_m1" name="top_customer5_m1"  (ngModelChange)="onCusRevChange($event, 5, 'm1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_customer5_rev_m1" name="top_customer5_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>						
						</div>
						
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Top 5 Customers -->

		<!-- Top 5 Suppliers -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Top 5 Suppliers of the Company</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Supplier Name</label>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier1_name" name="top_supplier1_name" (ngModelChange)="changeSupName($event, 1)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier1_name_per" name="top_supplier1_name_per" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier2_name" name="top_supplier2_name"  (ngModelChange)="changeSupName($event, 2)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier2_name_per" name="top_supplier2_name_per" /> 
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier3_name" name="top_supplier3_name" (ngModelChange)="changeSupName($event, 3)" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier3_name_per" name="top_supplier3_name_per" /> 
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier4_name" name="top_supplier4_name"  (ngModelChange)="changeSupName($event, 4)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier4_name_per" name="top_supplier4_name_per" /> 
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier5_name" name="top_supplier5_name"  (ngModelChange)="changeSupName($event, 5)"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="top_supplier5_name_per" name="top_supplier5_name_per" />
							    </div>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Total</label>
							</div>	
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Supplier Concentration in %</label>
							</div>				
						</div>
						
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier1_y1" name="top_supplier1_y1"  (ngModelChange)="onSupRevChange($event, 1, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier1_rev_y1" name="top_supplier1_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier2_y1" name="top_supplier2_y1" (ngModelChange)="onSupRevChange($event, 2, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier2_rev_y1" name="top_supplier2_rev_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier3_y1" name="top_supplier3_y1" (ngModelChange)="onSupRevChange($event, 3, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier3_rev_y1" name="top_supplier3_rev_y1" disabled />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier4_y1" name="top_supplier4_y1" (ngModelChange)="onSupRevChange($event, 4, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier4_rev_y1" name="top_supplier4_rev_y1" disabled />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier5_y1" name="top_supplier5_y1"  (ngModelChange)="onSupRevChange($event, 5, 'y1')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier5_rev_y1" name="top_supplier5_rev_y1" disabled />
							    </div>
							</div>
							
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier_total_y1" name="top_supplier_total_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier_conc_y1" name="top_supplier_conc_y1" disabled />
							    </div>
							</div>					
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier1_y2" name="top_supplier1_y2"  (ngModelChange)="onSupRevChange($event, 1, 'y2')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier1_rev_y2" name="top_supplier1_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier2_y2" name="top_supplier2_y2" (ngModelChange)="onSupRevChange($event, 2, 'y2')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier2_rev_y2" name="top_supplier2_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier3_y2" name="top_supplier3_y2" (ngModelChange)="onSupRevChange($event, 3, 'y2')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier3_rev_y2" name="top_supplier3_rev_y2" disabled />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier4_y2" name="top_supplier4_y2" (ngModelChange)="onSupRevChange($event, 4, 'y2')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier4_rev_y2" name="top_supplier4_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier5_y2" name="top_supplier5_y2"  (ngModelChange)="onSupRevChange($event, 5, 'y2')"/>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier5_rev_y2" name="top_supplier5_rev_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier_total_y2" name="top_supplier_total_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_3}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier1_m1" name="top_supplier1_m1" (ngModelChange)="onSupRevChange($event, 1, 'm1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier1_rev_m1" name="top_supplier1_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier2_m1" name="top_supplier2_m1" (ngModelChange)="onSupRevChange($event, 2, 'm1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier2_rev_m1" name="top_supplier2_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier3_m1" name="top_supplier3_m1" (ngModelChange)="onSupRevChange($event, 3, 'm1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier3_rev_m1" name="top_supplier3_rev_m1" disabled />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier4_m1" name="top_supplier4_m1" (ngModelChange)="onSupRevChange($event, 4, 'm1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier4_rev_m1" name="top_supplier4_rev_m1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="top_supplier5_m1" name="top_supplier5_m1" (ngModelChange)="onSupRevChange($event, 5, 'm1')" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="top_supplier5_rev_m1" name="top_supplier5_rev_m1" disabled />
							    </div>
							</div>

							
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" style="visibility: hidden;">
							    </div>
							</div>					
						</div>
						
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Top 5 Suppliers -->

		<!-- upload files -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Upload Files</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show1">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo1 === false"> 
								<app-fileupload version="11" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >= 1">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[0].fileURL}}" download="File.pdf">{{uploadedFiles1[0].fileURL}}</a>
									<span (click)="deletefiles1(0)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show2">
								<button class="form-control btn btn-primary" (click)="addUploadControl(11)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>

				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show2">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo2 === false">
								<app-fileupload version="12" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >= 2">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[1].fileURL}}" download="File.pdf">{{uploadedFiles1[1].fileURL}}</a>
									<span (click)="deletefiles1(1)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show3">
								<button class="form-control btn btn-primary" (click)="addUploadControl(12)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show3">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo3 === false">
								<app-fileupload version="13" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >= 3">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[2].fileURL}}" download="File.pdf">{{uploadedFiles1[2].fileURL}}</a>
									<span (click)="deletefiles1(2)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show4">
								<button class="form-control btn btn-primary" (click)="addUploadControl(13)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show4">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo4 === false">
								<app-fileupload version="14" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=4">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[3].fileURL}}" download="File.pdf">{{uploadedFiles1[3].fileURL}}</a>
									<span (click)="deletefiles1(3)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show5">
								<button class="form-control btn btn-primary" (click)="addUploadControl(14)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show5">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo5 === false">
								<app-fileupload version="15" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=5">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[4].fileURL}}" download="File.pdf">{{uploadedFiles1[4].fileURL}}</a>
									<span (click)="deletefiles1(4)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show6">
								<button class="form-control btn btn-primary" (click)="addUploadControl(15)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show6">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo6 === false">
								<app-fileupload version="16" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=6">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[5].fileURL}}" download="File.pdf">{{uploadedFiles1[5].fileURL}}</a>
									<span (click)="deletefiles1(5)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show7">
								<button class="form-control btn btn-primary" (click)="addUploadControl(16)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show7">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo7 === false">
								<app-fileupload version="17" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=7">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[6].fileURL}}" download="File.pdf">{{uploadedFiles1[6].fileURL}}</a>
									<span (click)="deletefiles1(6)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show8">
								<button class="form-control btn btn-primary" (click)="addUploadControl(17)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show8">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo8 === false">
								<app-fileupload version="18" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=8">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[7].fileURL}}" download="File.pdf">{{uploadedFiles1[7].fileURL}}</a>
									<span (click)="deletefiles1(7)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show9">
								<button class="form-control btn btn-primary" (click)="addUploadControl(18)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show9">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo9 === false">
								<app-fileupload version="19" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=9">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[8].fileURL}}" download="File.pdf">{{uploadedFiles1[8].fileURL}}</a>
									<span (click)="deletefiles1(8)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row" *ngIf="!file2Show10">
								<button class="form-control btn btn-primary" (click)="addUploadControl(19)"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-white uploadFinanicalFiles" *ngIf="file2Show10">
					<div class="row">
						<div class="col-md-10">
							<div class="fileupload-wrapper" #filewrapper *ngIf="file2Uplo10 === false">
								<app-fileupload version="20" (onSelectValue)='selectValue2($event)'></app-fileupload>
							</div>
							<div class="filelist-wrapper" *ngIf="uploadedFiles1.length >=10">
								<span class="file-list">FileURL : <a target="_blank" href="{{uploadedFiles1[9].fileURL}}" download="File.pdf">{{uploadedFiles1[9].fileURL}}</a>
									<span (click)="deletefiles1(9)"><i class="icon-2x text-light-50 flaticon-delete"></i></span>
								</span>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</div>
		<!-- End of upload files -->

		<!-- Potential risk of suppliers -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Potential risk of suppliers increasing the prices</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="potential_supplier_risk" value="true" [(ngModel)]="potential_supplier_risk"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="potential_supplier_risk" value="false" [(ngModel)]="potential_supplier_risk"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Potential risk of suppliers -->

		<!-- alternate suppliers -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Are there alternate suppliers available:</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="alternate_suppliers" value="true" [(ngModel)]="alternate_suppliers"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="alternate_suppliers" value="false" [(ngModel)]="alternate_suppliers"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of alternate suppliers -->

		<!-- pricing industry competitive -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Is the pricing in the industry very competitive and can it impact the business?
									Herfindhal (HHL )Index - HHI of less than 1,500 to be a competitive marketplace, an
									HHI of 1,500 to 2,500 to be a moderately concentrated marketplace, and an HHI of
									2,500 or greater to be a highly concentrated marketplace
								</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="competitive_pricing_industry" name="competitive_pricing_industry" />
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of pricing industry competitive -->

		<!-- Risk of disruption / technology  -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Risk of disruption / technology 
								</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="risk_of_disruption" value="true" [(ngModel)]="risk_of_disruption"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="risk_of_disruption" value="false" [(ngModel)]="risk_of_disruption"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Risk of disruption / technology  -->

		<!-- Net currency risk  -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Net currency risk - (Total value of raw material imports less value of raw materials on
								export revenue, if any) divided by the total value of raw materials cost of the
								Company)
								</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <input type="text" class="form-control" [(ngModel)]="net_currency_risk" name="net_currency_risk" disabled />
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Net currency risk  -->

		<!-- Potential regulatory risk  -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Potential regulatory risk
								</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="potential_regulatory_risk" value="true" [(ngModel)]="potential_regulatory_risk"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="potential_regulatory_risk" value="false" [(ngModel)]="potential_regulatory_risk"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Potential regulatory risk  -->

		<!-- Market size – INR Million  -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Market size – INR Million
								</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="market_size" name="market_size" />
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Market size – INR Million -->

		<!-- References with experts -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">References with experts</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Name of the expert</label>
							</div>	
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_name1" name="expert_ref_name1" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_name2" name="expert_ref_name2" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_name3" name="expert_ref_name3" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_name4" name="expert_ref_name4" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_name5" name="expert_ref_name5" />
							    </div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">Company / Background</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_company1" name="expert_ref_company1" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_company2" name="expert_ref_company2" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_company3" name="expert_ref_company3" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_company4" name="expert_ref_company4" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_company5" name="expert_ref_company5" />
							    </div>
							</div>						
						</div>
						<div class="col-md-3">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">Why he/she is relevant</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_relevance1" name="expert_ref_relevance1" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_relevance2" name="expert_ref_relevance2" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_relevance3" name="expert_ref_relevance3" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_relevance4" name="expert_ref_relevance4" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_relevance5" name="expert_ref_relevance5" />
							    </div>
							</div>					
						</div>
						<div class="col-md-3">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">Feedback</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_feedback1" name="expert_ref_feedback1" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_feedback2" name="expert_ref_feedback2" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_feedback3" name="expert_ref_feedback3" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_feedback4" name="expert_ref_feedback4" />
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input type="text" class="form-control" [(ngModel)]="expert_ref_feedback5" name="expert_ref_feedback5" />
							    </div>
							</div>						
						</div>
						
					</div>				
				</div>
			</div>
		</div>
		<!-- End of References with experts -->

		<!-- Page 2 Footer -->
		<div class="container pad-bot-50">
			<div class="form_footer_wrapper">
				<button class="btn btn-primary btn-pad-20" (click)="addBusinessInfo()">Save</button>
				<button class="btn btn-primary"  (click)="goTodashboard();">Cancel</button>
			</div>
		</div>
		<!-- Page 2 Footer -->
	</div>

	<div *ngIf="page_3">
		<!-- Business model -->
		<div class="container pad-50 pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Business model</h3>
					<div class="card-toolbar">
						<div class="form-group">
						    <div class="radio-inline">								       
						        <label class="radio">
						            <input type="radio" id="radios1" name="debt_lever" value="true" [(ngModel)]="business_model"/>
						            <span></span>
						            General
						        </label>
						        <label class="radio">
						            <input type="radio" id="radios2" name="debt_lever" value="false" [(ngModel)]="business_model"/>
						            <span></span>
						            Subscription
						        </label>
						    </div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="row">						
						<div class="col-md-12">
							<div class="form-group mb-1">
							    <textarea class="form-control" name="business_model_desc" id="exampleTextarea" [(ngModel)]="business_model_desc" rows="3"></textarea>
							</div>						
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Business model -->

		<!-- Enterprise Valuation -->
		<div class="container pad-50 pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-header">
					<h3 class="card-title">Enterprise Valuation</h3>
					<div class="card-toolbar">
						
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12 tbl-hdr">
							    	<input type="text" class="form-control" [(ngModel)]="enterprise_val_year" name="enterprise_val_year" />
							    </div>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Enterprise Valuation </label>
							</div>
							<div class="form-group row">
								<label class="col-lg-12 col-form-label tbl-col-txt" *ngIf="business_model == 'true'">EBITDA</label>
							    <label class="col-lg-12 col-form-label tbl-col-txt" *ngIf="business_model == 'false'">Revenue</label>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Enterprise Valuation / EBITDA Multiple</label>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Debt  = Long term + short term</label>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Cash</label>
							</div>
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Equity Valuation</label>
							</div>							
						</div>
						<div class="col-md-2">
							<div class="form-group row crow">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_1}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enterprise_val_y1" name="enterprise_val_y1"  (ngModelChange)="onEVChange($event, 'y1')" />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enter_ebitda_y1" name="enter_ebitda_y1" disabled/>
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;" >
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_mult_y1" name="ebitda_mult_y1" />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 35px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_debt_y1" name="ebitda_debt_y1" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_cash_y1" name="ebitda_cash_y1" (ngModelChange)="onEVMChange($event, 'y1')" />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_equity_y1" name="ebitda_equity_y1" disabled />
							    </div>
							</div>
							
						</div>
						<div class="col-md-2">
							<div class="form-group row crow">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_2}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enterprise_val_y2" name="enterprise_val_y2"  (ngModelChange)="onEVChange($event, 'y2')" />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enter_ebitda_y2" name="enter_ebitda_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_mult_y2" name="ebitda_mult_y2" />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 35px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_debt_y2" name="ebitda_debt_y2" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_cash_y2" name="ebitda_cash_y2" (ngModelChange)="onEVMChange($event, 'y2')"  />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_equity_y2" name="ebitda_equity_y2" disabled />
							    </div>
							</div>
							
						</div>
						<div class="col-md-2">
							<div class="form-group row crow">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_0}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enterprise_val_y3" name="enterprise_val_y3" (ngModelChange)="onEVChange($event, 'y3')" />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enter_ebitda_y3" name="enter_ebitda_y3" disabled/>
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_mult_y3" name="ebitda_mult_y3"/>
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 35px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_debt_y3" name="ebitda_debt_y3" disabled/>
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_cash_y3" name="ebitda_cash_y3"  (ngModelChange)="onEVMChange($event, 'y3')" />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_equity_y3" name="ebitda_equity_y3" disabled />
							    </div>
							</div>
							
						</div>
						<div class="col-md-2">
							<div class="form-group row crow">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_3}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enterprise_val_y4" name="enterprise_val_y4" (ngModelChange)="onEVChange($event, 'y4')" />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enter_ebitda_y4" name="enter_ebitda_y4" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_mult_y4" name="ebitda_mult_y4"/>
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 35px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_debt_y4" name="ebitda_debt_y4" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_cash_y4" name="ebitda_cash_y4" (ngModelChange)="onEVMChange($event, 'y4')"  />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_equity_y4" name="ebitda_equity_y4" disabled/>
							    </div>
							</div>
							
						</div>
						<div class="col-md-2">
							<div class="form-group row crow">
							    <div class="col-lg-12 tbl-hdr">
							    	<label class="col-lg-12 col-form-label">{{fin_dt_yr_4}}</label>
							    </div>
							</div>
							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enterprise_val_y5" name="enterprise_val_y5" (ngModelChange)="onEVChange($event, 'y5')"  />
							    </div>
							</div>

							<div class="form-group row bg-white">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="enter_ebitda_y5" name="enter_ebitda_y5" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_mult_y5" name="ebitda_mult_y5" />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 35px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_debt_y5" name="ebitda_debt_y5" disabled />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_cash_y5" name="ebitda_cash_y5" (ngModelChange)="onEVMChange($event, 'y5')"  />
							    </div>
							</div>
							<div class="form-group row bg-white" style="padding-top: 20px;">
							    <div class="col-lg-12">
							    	<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="ebitda_equity_y5" name="ebitda_equity_y5" disabled/>
							    </div>
							</div>
							
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- End of Enterprise Valuation -->

		<!-- Stakeboat Capital investment (INR Million) -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Stakeboat Capital investment (INR Million)</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="capital_investment" name="capital_investment" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Stakeboat Capital investment (INR Million) -->

		<!-- Stakeboat Capital Equity holding -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Stakeboat Capital Equity holding</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="capital_equity" name="capital_equity" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Stakeboat Capital Equity holding -->

		<!-- Internal Rate of Return -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Internal Rate of Return</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input currencyMask [options]="inputPercentageConfig" type="text" class="form-control" [(ngModel)]="irr_val" name="irr_val" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Internal Rate of Return -->

		<!-- Exit Month / Year -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Exit Month / Year</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
							    <select class="form-control form-control-solid" [(ngModel)]="exit_month" name="exit_month">
							    	<option>Month</option>
							    	<option val="01">Jan</option>
							    	<option val="02">Feb</option>
							    	<option val="03">Mar</option>
							    	<option val="04">Apr</option>
							    	<option val="05">May</option>
							    	<option val="06">Jun</option>
							    	<option val="07">Jul</option>
							    	<option val="08">Aug</option>
							    	<option val="09">Sep</option>
							    	<option val="10">Oct</option>
							    	<option val="11">Nov</option>
							    	<option val="12">Dec</option>						    
							    </select>
						   	</div>
						   	
						</div>
						<div class="col-md-2">
							<div class="form-group">
							    <select class="form-control form-control-solid" [(ngModel)]="exit_year" name="exit_year">
							    	<option>Year</option>
							    	<option val="2015">2015</option>
							    	<option val="2015">2016</option>
							    	<option val="2015">2017</option>
							    	<option val="2015">2018</option>
							    	<option val="2015">2019</option>
							    	<option val="2015">2020</option>
							    	<option val="2015">2021</option>
							    	<option val="2015">2022</option>
							    	<option val="2015">2023</option>
							    	<option val="2015">2024</option>
							    	<option val="2015">2025</option>					    
							    </select>
						   	</div>
						</div>
						<div class="col-md-1">
							<div class="form-group row">
							    
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Exit Month / Year -->

		<!-- Cash on Cash multiple -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Cash on Cash multiple</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<input currencyMask [options]="inputCurrencyConfig" type="text" class="form-control" [(ngModel)]="cash_on_cash" name="cash_on_cash" />
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Cash on Cash multiple -->

		<!-- Drag Rights -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Drag Rights</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="drag_rights" value="true" [(ngModel)]="drag_rights"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="drag_rights" value="false" [(ngModel)]="drag_rights"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Drag Rights -->

		<!-- Liquidation preference -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Liquidation preference</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="liquid_pref" value="true" [(ngModel)]="liquid_pref"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="liquid_pref" value="false" [(ngModel)]="liquid_pref"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Liquidation preference -->

		<!-- Unique rights negotiated -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Unique rights negotiated</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="unique_rights" value="true" [(ngModel)]="unique_rights"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="unique_rights" value="false" [(ngModel)]="unique_rights"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Unique rights negotiated -->

		<!-- Are there International strategic buyers -->
		<div class="container pad-bot-50">
			<div class="card card-custom example example-compact">		
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-10">
							<div class="form-group row">
							    <label class="col-lg-12 col-form-label tbl-col-txt">Are there International strategic buyers</label>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group row">
							    <div class="col-lg-12">
									<!-- <input type="text" class="form-control" [(ngModel)]="debt_leverage" name="debt_leverage" /> -->
									<div class="form-group">
									    <div class="radio-inline">								       
									        <label class="radio">
									            <input type="radio" id="radios1" name="strategic_buyers" value="true" [(ngModel)]="strategic_buyers"/>
									            <span></span>
									            Yes
									        </label>
									        <label class="radio">
									            <input type="radio" id="radios2" name="strategic_buyers" value="false" [(ngModel)]="strategic_buyers"/>
									            <span></span>
									            No
									        </label>
									    </div>
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
		<!-- Are there International strategic buyers -->

		<!-- Page 3 Footer -->
		<div class="container pad-bot-50">
			<div class="form_footer_wrapper">
				<button class="btn btn-primary btn-pad-20" (click)="addInvestmentInfo()">Save</button>
				<button class="btn btn-primary"  (click)="goTodashboard();">Cancel</button>
			</div>
		</div>
		<!-- Page 3 Footer -->
	</div>

	<div *ngIf="page_4">
		<div class="container pad-50 pad-bot-50">
			<div class="card card-custom example example-compact">
				<div class="card-body bg-white">
					<div class="row">
						<div class="col-md-12">
							<h2>1. Promoter</h2>
						</div>
					</div>
					<div class="row" id="prom-1">
						<div class="col-md-5">
							<div class="form-group">
						    <select class="custom-select form-control" name="promoId1" (ngModelChange)="changePromoter($event, 1)" [(ngModel)]="promoId1" >
						     	<option  >Promoter Name</option>
							    <option *ngFor="let deal of dealTeam" [value]="deal.id">{{deal.name}}</option>
						    </select>
						   </div>
						</div>
						<div class="col-md-1">
							<div class="promoter-lbl-btn" style="margin-top: 13px;" *ngIf="promoterArr.length>0">
								<span> </span><span class="pro_score">{{promoterArr[0].promo_score}}</span>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<button class="btn btn-primary" (click)="goToPromoter(0)">Calculate Score</button>								
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<button class="form-control btn btn-primary" (click)="addPromoComp(1)" *ngIf="!proShow2 && dealTeam.length > 1"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
						<div class="col-md-1">
							<div class="form-group">
								<!-- <i class="icon-2x text-light-50 flaticon-delete" (click)="remPromoComp(1)"></i> -->
							</div>
						</div>
					</div>
					<div class="row" id="prom-2" *ngIf="proShow2">
						<div class="col-md-5">
							<div class="form-group">
						    <select class="custom-select form-control" name="promoId2" (ngModelChange)="changePromoter($event, 2)" [(ngModel)]="promoId2" >
						     	<option  >Promoter Name</option>
							    <option *ngFor="let deal of dealTeam" [value]="deal.id">{{deal.name}}</option>
						    </select>
						   </div>
						</div>
						<div class="col-md-1">
							<div class="form-group">
								<div class="promoter-lbl-btn" style="margin-top: -20px;" *ngIf="promoterArr.length>1">
									<span> </span><span class="pro_score">{{promoterArr[1].promo_score}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-2">

							<div class="form-group">
								<button class="btn btn-primary" (click)="goToPromoter(1)">Calculate Score</button>
								
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<button class="form-control btn btn-primary" (click)="addPromoComp(2)" *ngIf="!proShow3 && dealTeam.length > 2"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div>
						</div>
						<div class="col-md-1">
							<div class="form-group">
								<i class="icon-2x text-light-50 flaticon-delete" (click)="remPromoComp(2)"></i>
							</div>
						</div>
					</div>
					<div class="row" id="prom-3" *ngIf="proShow3">
						<div class="col-md-5">
							<div class="form-group">
						    <select class="custom-select form-control" name="promoId3" (ngModelChange)="changePromoter($event, 3)" [(ngModel)]="promoId3" >
						     	<option  >Promoter Name</option>
							    <option *ngFor="let deal of dealTeam" [value]="deal.id">{{deal.name}}</option>
						    </select>
						   </div>
						</div>
						<div class="col-md-1">
							<div class="form-group">
								<div class="promoter-lbl-btn" style="margin-top: -20px;" *ngIf="promoterArr.length>2">
									<span> </span><span class="pro_score">{{promoterArr[2].promo_score}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<button class="btn btn-primary" (click)="goToPromoter(2)">Calculate Score</button>
								
							</div>
						</div>
						<div class="col-md-2">
							<!-- <div class="form-group">
								<button class="form-control btn btn-primary"> <i class="flaticon-plus icon-lg"></i> Add</button>
							</div> -->
						</div>
						<div class="col-md-1">
							<div class="form-group">
								<i class="icon-2x text-light-50 flaticon-delete" (click)="remPromoComp(3)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Page 4 Footer -->
		<div class="container pad-bot-50">
			<div class="form_footer_wrapper">
				<button class="btn btn-primary btn-pad-20" (click)="goToUser()">Save</button>
				<button class="btn btn-primary" (click)="goTodashboard();">Cancel</button>
			</div>
		</div>
		<!-- Page 4 Footer -->
	</div>

</form>

<!--end::Form-->
<div class="footer">
	<div class="container">
		<div class="footer_text">2020© All rights reserved</div>
		<div class="footer_logo_wrapper">
			<span class="footer_powered_by">POWERED BY</span>
			<span class="footer_img"><img src="assets/media/footer-logo.png" alt="StakeBoat"></span>
		</div>
	</div>
</div>

