<ng-container *ngIf="controlContainer" [formGroup]="controlContainer.control">
    <select class="input-field form-control date mr-2" formControlName="date">
        <option disabled selected hidden value="">Date</option>
        <option *ngFor="let d of dateList" [value]="d">{{d}}</option>
    </select>
    <select class="input-field form-control date mr-2" formControlName="month">
        <option disabled selected hidden value="">Month</option>
        <option *ngFor="let m of monthList; let i = index" [value]="i+1">{{m}}</option>
    </select>
    <select class="input-field form-control date" formControlName="year">
        <option disabled selected hidden value="">Year</option>
        <option *ngFor="let y of yearList" [value]="y">{{y}}</option>
    </select>
</ng-container>