export interface IPromoterInfo 
{
    qualification:number;
    age_in_years:number;
    relevant_experience:number;
    exit_capability:number;
    exit_agreement:number;
    prof_deal_breaker:number;
    confidence: number;
    prom_shareholding:  number;
    risk_appetite: number;
    ability_to_scale: number;
    ability_to_work: number;
    attitude_towards:number;
    reference_competitors: number;
    reference_bankers: number;
    cibil_score: number;
    reference_past_employees: number;
    attitude_governance: number;
}

export class PromoterInfo implements IPromoterInfo
{
    qualification:number;
    age_in_years:number;
    relevant_experience:number;
    exit_capability:number;
    exit_agreement:number;
    prof_deal_breaker:number;
    confidence: number;
    prom_shareholding:  number;
    risk_appetite: number;
    ability_to_scale: number;
    ability_to_work: number;
    attitude_towards:number;
    reference_competitors: number;
    reference_bankers: number;
    cibil_score: number;
    reference_past_employees: number;
    attitude_governance: number;

    constructor() {
        this.qualification = 0;
        this.age_in_years = 0;
        this.relevant_experience = 0;
        this.exit_capability = 0;
        this.exit_agreement = 0;
        this.prof_deal_breaker = 0;
        this.confidence = 0;
        this.prom_shareholding = 0;
        this.risk_appetite = 0;
        this.ability_to_scale = 0;
        this.ability_to_work = 0;
        this.attitude_towards = 0;
        this.reference_competitors = 0;
        this.reference_bankers = 0;
        this.cibil_score = 0;
        this.reference_past_employees = 0;
        this.attitude_governance = 0;
    }
}