import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { DashboardService } from '@app/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { compileDirectiveFromMetadata } from '@angular/compiler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  displayedColumns = ['companyName', 'dateofDeal', 'sector', 'geoRegion', 'strStatus', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  dataLength: number = 0;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 15];
  pageIndex: number = 0;
  isLoading: boolean;
  private unsubscribe: Subscription[] = [];
  sortBy: string; //name_asc, date_desc, etc..
  filterByType: string;
  filterByValue: string;
  sectorLabel: any;
  sortByOptions: any =
    {
      name_asc: 'Company Name - Asc',
      name_desc: 'Company Name - Desc',
      dod_asc: 'Date of Deal - Asc',
      dod_desc: 'Date of Deal - Desc',
    };
  filterByOptions: any =
    {
      filterCompanyName: 'Company Name',
      filterDod: 'Date of Deal',
      filterSector: 'Sector',
      filterRegion: 'Region',
      filterStatus: 'Status',
    }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayName: string;

  constructor(private dashboardService: DashboardService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('currentUser');
    this.displayName = user && JSON.parse(user).userName
    this.dataSource.paginator = this.paginator;
    this.getSector();
    this.getAllCompanies();
  }

  getSector() {
    this.dashboardService.getSector().subscribe((res: any) => {
      if(res.intStatus == 200) {
        let data = res.response.data;
        this.sectorLabel = data.reduce((a, v) => {
          a[v.id] = v.text;
          return a;
        }, {});
      }else {
        this.showError(res.strMessage || 'Failed to fetch Sector values')
      }
    })
  }

  resetPagination() {
    this.pageIndex = 0;
  }

  getAllCompanies(resetPagination?) {
    resetPagination && this.resetPagination();
    let params: any = {
      pageNo: this.pageIndex + 1,
      pageSize: this.pageSize,
    }
    if (this.sortBy) {
      let [sortBy, sortByOrder] = this.sortBy.split('_');
      params.sortBy = sortBy;
      params.sortByOrder = sortByOrder;
    }
    if (this.filterByType && this.filterByValue) {
      params[this.filterByType] = this.filterByValue;
    }
    this.isLoading = true;
    this.dashboardService.getAllCompanies(params).subscribe(res => {
      this.isLoading = false;
      console.log("kbt ~ file: dashboard.component.ts ~ line 22 ~ DashboardComponent ~ this.dashboardService.getAllCompanies ~ res", res);
      if (res.intStatus == 200) {
        this.dataLength = res.response.data.total;
        let pageSize = this.pageSize;
        // this.pageSize = pageSize <= this.dataLength ?
        //   pageSize : this.dataLength;
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.paginator && this.dataSource.paginator.firstPage();
        this.dataSource.data = res.response.data.datas;
      }

    }, error => {
      this.showError(error);
    })
  }

  showError(err) {
    this.snackBar.open(err, '', {
      duration: 2000,
      panelClass: 'alert show alert-success',
    });
  }

  showSuccess(msg) {
    this.snackBar.open(msg, '', {
      duration: 2000,
      panelClass: 'alert show alert-danger',
    });
  }

  onRowMenuClick(option, element) {
    localStorage.setItem('selectedCompanyId', element.intCmpId);
    localStorage.setItem('selectedDealId', element.intDealId);
    this.router.navigate(['company', element.intCmpId]);
  }

  onPaginatorChange(e: PageEvent) {
    console.log("kbt ~ file: dashboard.component.ts ~ line 44 ~ DashboardComponent ~ onPaginatorChange ~ e", e);
    this.dataLength = e.length;
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllCompanies();
  }

  onSortChange(sort) {
    this.sortBy = sort;
    this.resetPagination();
    this.getAllCompanies();
  }

  onFilterChange(source, filter) {
    if (source == 'type') {
      this.filterByType = filter;
    } else if (source == 'value') {
      this.filterByValue = filter;
    } else {
      this.filterByType = '';
      this.filterByValue = '';
    }
  }

  onFilterRemove() {
    this.onFilterChange('type', '');
    this.resetPagination();
    this.getAllCompanies();
  }

  addBusiness() {
    this.router.navigate(['addbusiness']);
    console.log("kbt ~ file: dashboard.component.ts ~ line 61 ~ DashboardComponent ~ addBusiness ~ '/dashboard/addbusiness'", '/dashboard/addbusiness');
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  logout() {
      //this.authenticationService.logout();
      this.router.navigate(['/login']);
  }

}
