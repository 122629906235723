import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';

@Component({ templateUrl: 'login.component.html', styleUrls: ['login.component.scss'] })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    successMsg = '';
    rememberMe: boolean = false;
    isSignUp: boolean;
    forgotRequestEmail: string;
    @ViewChild('forgotRequestEmailRef') forgotRequestEmailRef;
    @ViewChild('forgotPasswordDialog') forgotPasswordDialog;
    forgotPasswordDialogRef: any;
    fpSubmitted: boolean;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/dashboard']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            name: [''],
            email: ['', Validators.compose([
                Validators.required,
                Validators.email,
            ])],
            password: ['', Validators.required],
            confirmPassword: [''],
            remember: [false]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.error = '';
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        // Password match for Sign Up Form
        if (this.isSignUp && (this.f.password.value != this.f.confirmPassword.value)) {
            this.error = "Password doesn't match";
            return;
        }
        let data = this.loginForm.getRawValue();

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                res => {
                    if (res && res.intStatus == 200)
                        this.router.navigate(['dashboard']);
                    else {
                        this.showError(res && res.strMessage);
                    }
                    // this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.showError(error);
                });
    }

    forgotPassword() {
        this.forgotPasswordDialogRef = this.dialog.open(this.forgotPasswordDialog, {
            width: '400px',
            maxWidth: '90%',
        });

    }

    triggerForgotPassword(formValid) {
        if (!formValid) return;
        this.authenticationService.forgotPassword(this.forgotRequestEmail).subscribe(res => {
            this.forgotPasswordDialogRef.close();
            this.forgotRequestEmail = '';
            if (res.intStatus == 200) {
                this.error = '';
                this.successMsg = res.strMessage;
            } else {
                this.showError(res && res.strMessage || 'Forgot password request failed');
            }
        });
    }

    showError(err) {
        this.successMsg = '';
        this.error = err
        this.loading = false;

    }

    // Add/Remove validators of Signup related controls when toggling between SignIn & SignUp.
    toggleForm() {
        this.isSignUp = !this.isSignUp;
        let signUpControls = ['name', 'confirmPassword'];
        if (!this.isSignUp) {
            signUpControls.forEach(c => {
                this.f[c].clearValidators(); this.f[c].updateValueAndValidity()
            });
        } else {
            signUpControls.forEach(c => this.f[c].setValidators(Validators.required));
        }
    }
}
