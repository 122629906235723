<div class="brand-header">
    <img src="assets/zastra-logo-color.svg" height="50px" alt="stakeboat-logo" />
    <div class="d-flex align-items-center">
        <div class="name mr-4">{{displayName}}</div>
        <button style="padding: 4px 12px;" class="btn btn-outline-primary center-btn-icon" (click)="logout()">
            <mat-icon class="center-mat-icon">power_settings_new</mat-icon>
            Logout
        </button>
    </div>
</div>
<div class="banner">
    <div *ngIf="!companyName" class="title font-size-h2 font-size-h1-xs">Your Business Valuation Tool</div>
    <div *ngIf="companyName" class="title">
        <span>Company: </span><span class="font-weight-bold">{{companyName}}</span>
    </div>
</div>