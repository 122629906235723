<div class="container">
	<div class="row">
		<div class="col-md-12">
			<div class="brand-header">
			    <img src="assets/zastra-logo-color.svg" height="50px" alt="stakeboat-logo" />
			    <div class="d-flex align-items-center">
			        <div class="name mr-4">{{displayName}}</div>
			        <button style="padding: 4px 12px;" class="btn btn-outline-primary center-btn-icon" (click)="logout()">
			            <mat-icon class="center-mat-icon">power_settings_new</mat-icon>
			            Logout
			        </button>
			    </div>
			</div>
		</div>
	</div>
</div>
<div class="header-img-wrapper">
	<div class="container">
		<div class="header-img-desc">
			<h3 class="header-img-txt">Company: <span class="bolder-text">{{compName}}</span></h3>
			<p class="header-img-txt-desc">Date of Deal: <span class="bolder-text">{{deaDt}}</span></p>
		</div>
		<div class="header-output">
			<div class="row">
				<div class="col-md-4">
					<div class="form-group">
						
			        </div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						
			        </div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<div class="score_desc">
							<span class="score_txt">{{promoterScore}}</span><br />
							<span class="score_sub">Net Score</span>
						</div>
			        </div>
				</div>
			</div>
		</div>
	</div>	
</div>
<form class="form" #promoterForm="ngForm">
	
	
	<div class="container pad-50 pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">1.<span class="tbl-pro-txt">  Qualification</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <select class="form-control" id="exampleSelect1" [(ngModel)]="promoData.qualification" name="qualification" (ngModelChange)="onValueChange(1)">
				    	<option>Select</option>
				    	<option value="1">Below Average</option>
				    	<option value="2">Average</option>
				    	<option value="3">Good</option>
				    </select>
			    </div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">

						<span>Score </span><span class="pro_score">{{qual_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>
	
	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">2.<span class="tbl-pro-txt">  Age in Years</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.age_in_years" name="ageinyears" (ngModelChange)="onValueChange(2)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{age_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">3.<span class="tbl-pro-txt">  Relevant experience</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <input type="text" class="form-control" [(ngModel)]="promoData.relevant_experience" name="relexperience" (ngModelChange)="onValueChange(3)" />
		   		</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{exp_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>
	
	

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">4.<span class="tbl-pro-txt">  Exit Capability</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <select class="form-control" id="exampleSelect1" [(ngModel)]="promoData.exit_capability" name="exitcapability" (ngModelChange)="onValueChange(4)">
				    	<option>Select</option>
				    	<option value="1">Yes</option>
				    	<option value="2">No</option>
				    </select>
		   		</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{exit_cap}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">15</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">5.<span class="tbl-pro-txt">  Exit agreement</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <select class="form-control" id="exampleSelect1" [(ngModel)]="promoData.exit_agreement" name="exitagreement" (ngModelChange)="onValueChange(5)">
				    	<option>Select</option>
				    	<option value="1">No, hesitant</option>
				    	<option value="2">Not decided but open to discussion</option>
				    	<option value="3">Yes, exit driven</option>
				    </select>
		   		</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{exit_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">20</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">6.<span class="tbl-pro-txt">  Professional - deal breaker for family business</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <select class="form-control" id="exampleSelect1" [(ngModel)]="promoData.prof_deal_breaker" name="professional" (ngModelChange)="onValueChange(6)">
				    	<option>Select</option>
				    	<option value="1">Family business</option>
				    	<option value="2">1st generation - first time founder</option>
				    	<option value="3">Founded more than 1 start-up</option>
				    </select>
		   		</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{prof_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">20</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">7.<span class="tbl-pro-txt">  Confidence in the company</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="number" class="form-control" [(ngModel)]="promoData.confidence" name="confidence" (ngModelChange)="onValueChange(7)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{conf_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">8.<span class="tbl-pro-txt">  Promoters shareholding in the company</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.prom_shareholding" name="promotershare" (ngModelChange)="onValueChange(8)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{promo_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">20</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">9.<span class="tbl-pro-txt">  Risk appetite - think more</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.risk_appetite" name="risk_appetite" (ngModelChange)="onValueChange(13)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{risk_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">10.<span class="tbl-pro-txt">  Ability to scale</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.ability_to_scale" name="abilityscale" (ngModelChange)="onValueChange(9)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{scale_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">11.<span class="tbl-pro-txt">  Ability to work with professionals</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.ability_to_work" name="abilitywork" (ngModelChange)="onValueChange(10)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{work_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">12.<span class="tbl-pro-txt">  Atttude towards Company perks</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.attitude_towards" name="attitude" (ngModelChange)="onValueChange(11)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{attit_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">13.<span class="tbl-pro-txt">  Reference checks - competitors, known contacts etc</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.reference_competitors" name="ref_check_comp" (ngModelChange)="onValueChange(14)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{check_comp_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">14.<span class="tbl-pro-txt">  Reference checks with Bankers</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.reference_bankers" name="ref_check_bankers" (ngModelChange)="onValueChange(15)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{check_bank_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>		
	</div>

	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">15.<span class="tbl-pro-txt">  CIBIL score</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.cibil_score" name="cibil" (ngModelChange)="onValueChange(12)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{cibil_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">16.<span class="tbl-pro-txt"> Reference checks from past employees </span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.reference_past_employees" name="ref_check_past" (ngModelChange)="onValueChange(16)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{check_past_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">5</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="container pad-bot-50">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group row">
				    <label class="col-lg-12 col-form-label ">
				    	<span class="tbl-pro-num">17.<span class="tbl-pro-txt">  Attitude towards governance</span></span> 
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group row">
				    <div class="col-lg-12">
						<input type="text" class="form-control" [(ngModel)]="promoData.attitude_governance" name="attitude_governance" (ngModelChange)="onValueChange(17)" />
					</div>
				</div>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-1">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Score </span><span class="pro_score">{{att_gov_score}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row">
					<div class="promoter-lbl-btn">
						<span>Max Score </span><span class="pro_score">10</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="container pad-50 pad-bot-50">
		<div class="form_footer_wrapper">
			<button class="btn btn-primary btn-pad-20" (click)="updateCompanyEntry()">Done</button>
			<button class="btn btn-primary btn-pad-20">Edit</button>
			<button class="btn btn-primary">Cancel</button>
		</div>
	</div>


	<div class="container pad-50 pad-bot-50">
		<div class="row">
			<div class="col-md-8">
				<div class="form-group row">
					
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row promo_score">
					<div class="promo_wrapper">
						<div class="promoter-lbl-score">
							<span>200</span><br /><span class="pro_score_txt">Category Total</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group row promo_score">
					<div class="promo_wrapper">
						<div class="promoter-lbl-score">
							<span>{{promoterScore}}</span><br /><span class="pro_score_txt">Promoter Score</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</form>