import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '@app/_services';

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.scss']
})
export class AddBusinessComponent implements OnInit {

  company: any;
  deal: any;
  data: any;
  companyList: string[] = [];
  dealList: any[] = [];
  showAddCompany: boolean;
  showAddDeal: boolean;
  displayName: string;
  constructor(private dashboardService: DashboardService, private router: Router) { }

  ngOnInit(): void {
    let user = localStorage.getItem('currentUser');
    this.displayName = user && JSON.parse(user).userName
    this.getAllCompanyDeal();
  }

  getAllCompanyDeal() {
    this.dashboardService.getAllCompany().subscribe(res => {
      if (res.intStatus == 200) {
        this.companyList = res.response.data;
      }
    })
  }

  getCompanyDeal(cId) {
    this.dashboardService.getCompanyDeal(cId).subscribe(res => {
      if(res.intStatus == 200) {
        this.dealList = res.response.data;
      }
    })
  }

  onChangeCompany(company) {
    this.company = company;
    this.deal = null;
    localStorage.setItem('selectedCompany', this.company.cmpName);
    this.showAddCompany = false;
    this.showAddDeal = false;
    this.getCompanyDeal(company.cmpId);
  }

  onChangeDeal(deal) {
    this.showAddDeal = false;
    this.deal = deal;
    localStorage.setItem('dealDate', this.deal.dateofDeal);
    console.log(this.deal);
  }

  addCompany() {
    this.router.navigate(['company', 'new']);
  }

  addDeal() {
    localStorage.setItem('selectedCompanyId', this.company.cmpId);
    localStorage.setItem('selectedCompanyName', this.company.cmpName);
    this.router.navigate(['deals', 'new']);
  }

  goToCompany(){

    this.router.navigate( ['/companyentry', "1", this.company.cmpId, this.deal.dealId]);
  }

}
