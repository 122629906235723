import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    getAllCompanies(params: any) {
         let { 
            pageNo = '', 
            pageSize = '', 
            sortBy = '', 
            sortByOrder = '',
            filterCompanyName='', 
            filterDod='', 
            filterSector='', 
            filterRegion='', 
            filterStatus=''
        } = params;
         
        // let data = {
        //     strEmail,
        //     strPassword,
        // }
        let url = `${environment.apiUrl}/company?intPageNo=${pageNo}&intLimit=${pageSize}&sortBy=${sortBy}&sortByOrder=${sortByOrder}&filterCompanyName=${filterCompanyName}&filterDod=${filterDod}&filterSector=${filterSector}&filterRegion=${filterRegion}&filterStatus=${filterStatus}`
        console.log("kbt ~ file: dashboard.service.ts ~ line 28 ~ DashboardService ~ getAllCompanies ~ url", url);
        return this.http.get<any>(url)
            .pipe(map(res => {
                return res;
            }));
    }

    getAllCompanyDeal() {
        let url = `${environment.apiUrl}/user/getAllCompanyDeal`;
        return this.http.get<any>(url);
    }

    getAllCompany() {
        let url = `${environment.apiUrl}/user/getAllCompany`;
        return this.http.get<any>(url);
    }

    getCompanyDeal(cmpId) {
        let url = `${environment.apiUrl}/user/getCompanyDeal/${cmpId}`;
        return this.http.get<any>(url);
    }

    getCompanyById(cmpId) {
        let url = `${environment.apiUrl}/company/${cmpId}`;
        return this.http.get(url);
    }

    getDealById(cmpId, dealId) {
        let url = `${environment.apiUrl}/deal/${cmpId}/${dealId}`;
        return this.http.get(url);
    }

    addCompany(data, cmpId) {
        let url = `${environment.apiUrl}/company` + (cmpId == 'new' ? '' : `/${cmpId}`);
        return cmpId == 'new' ? this.http.post(url, data) : this.http.put(url, data);
    }

    addDeal(data, dealId) {
        let url = `${environment.apiUrl}/deal` + (dealId == 'new' ? '' : `/${dealId}`);
        return dealId == 'new' ? this.http.post(url, data) : this.http.put(url, data);
    }
    
    getSector() {
        let url = `${environment.apiUrl}/user/sector`;
        return this.http.get(url);
    }
    
    getSubSector(sectorId) {
        let url = `${environment.apiUrl}/user/subSector/${sectorId}`;
        return this.http.get(url);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}