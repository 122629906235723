import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

function getMonth(i) {
    let objDate = new Date();
    objDate.setDate(1);
    objDate.setMonth(i - 1);
    let locale = "en-us";
    let month = objDate.toLocaleString(locale, { month: "short" });
    return month;
}

@Component({
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
    // providers: [
    //     {
    //       provide: NG_VALUE_ACCESSOR,
    //       useExisting: forwardRef(() => DateSelectorComponent),
    //       multi: true
    //     }
    //   ]
})
export class DateSelectorComponent implements OnInit {
    monthList = Array(12).fill(0).map((v, i) => getMonth(i + 1));
    yearList = Array(200).fill(0).map((v, i) => new Date().getFullYear() - i);
  //   public onTouched: () => void = () => {};

  // writeValue(val: any): void {
  //   console.log("kbt: DateSelectorComponent -> writeValue -> val", val);
  //   val && this.form.setValue(val, { emitEvent: false });
  // }
  // registerOnChange(fn: any): void {
  //   console.log("on change");
  //   this.form.valueChanges.subscribe(fn);
  // }
  // registerOnTouched(fn: any): void {
  //   console.log("on blur");
  //   this.onTouched = fn;
  // }
  // setDisabledState?(isDisabled: boolean): void {
  //   isDisabled ? this.form.disable() : this.form.enable();
  // }

    constructor(
        public controlContainer: ControlContainer, public formBuilder: FormBuilder
    ) {
      console.log("kbt: DateSelectorComponent -> controlContainer", controlContainer);
    }

    ngOnInit() {
        // this.form = this.formBuilder.group({
        //     date: [''],
        //     month: [''],
        //     year: [''],
        // })
    }

    get form() {
      return this.controlContainer.control;
    }

    public get dateList() {
        let {month, year} = this.form.value
        if (month && year) {
            const dayCount = this.getDaysInMonth(month, year);
            return Array(dayCount).fill(0).map((v, i) => i + 1)
        } else {
            return Array(31).fill(0).map((v, i) => i + 1);
        }
    }

    public getDaysInMonth(month: number, year: number) {
        return 32 - new Date(year, month - 1, 32).getDate();
    }
}