<div class="card mt-4" *ngIf="!isAdmin">
    <h4 class="card-header">Final Framework</h4>
    <div class="card-body">
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
        <!-- <ul *ngIf="!isAdmin && users">
            <li *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</li>
        </ul> -->

        <div class="row" *ngIf="!isAdmin">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
						  <li class="nav-item">
						    <a class="nav-link active" id="gen-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general"
						      aria-selected="true">General</a>
						  </li>
						  <li class="nav-item">
						    <a class="nav-link" id="sub-tab" data-toggle="tab" href="#subscribe" role="tab" aria-controls="subscribe"
						      aria-selected="false">Subscription</a>
						  </li>
						</ul>
						<div class="tab-content" id="myTabContent">
		  					<div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="gen-tab">
								<div class="row">
									<div class="col-md-4">
										<div class="form-group">
								          <label class="center-block">Max Score : 
								            <input type="text" class="form-control" name="maxScoree" [(ngModel)]="maxScoree" >
								          </label>
								        </div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
								          <label class="center-block">Total Score : 
								            <input type="text" class="form-control" name="totScore" [(ngModel)]="totScore"  >
								          </label>
								        </div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
								          <label class="center-block">Net Score : 
								            <input type="text" class="form-control" name="netScore" [(ngModel)]="netScore" >
								          </label>
								        </div>
									</div>
								</div>

								<div class="forms">
									<form  novalidate>
										
										<div class="row" *ngFor="let rev of genData;">
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-12">
														<h1>{{rev.rev_model}}</h1>												
													</div>
												</div>
												
												<div class="cate_head" *ngFor="let cate of rev.rev_data;let j = index;trackBy:catTrackBy">
													<h4>{{cate.cat_name}}</h4>
													<div class="row" *ngFor="let para of cate.cat_data;let i = index;trackBy:paraTrackBy;">
														<div class="col-md-12">
															<div class="row">
																<div class="col-md-12">
																	<h6>{{cate.cat_id}}.{{para.par_id}}.{{para.par_name}}</h6>
																</div>
															</div>
															<div class="row">
																<div class="col-md-4">
																	
																	<div class="form-group" *ngIf="cate.cat_id != 4">
																		<div *ngIf="para.rule_type == 'rule_1' || para.rule_type == 'rule_2' || para.rule_type == 'rule_6'">
																          <label class="center-block">Input Value:
																            <input type="text" class="form-control" name="netRevenue{{i +  cumulativeLength(j)}}" [(ngModel)]="firstFields[i +  cumulativeLength(j)]" (ngModelChange)="onPerChange(j, i + cumulativeLength(j), $event, para.rules, para.par_id)">
																          </label>											   	
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_3' && para.par_id!=7">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netRevenue{{i +  cumulativeLength(j)}}" [(ngModel)]="firstFields[i +  cumulativeLength(j)]" (ngModelChange)="onPerChange(j, i + cumulativeLength(j), $event, para.rules, para.par_id)">
																            	<option value="Yes">Yes</option>
																            	<option value="No">No</option>
																            </select>
																           </label>										   	
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_3' && para.par_id==7">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netRevenue{{i +  cumulativeLength(j)}}" [(ngModel)]="firstFields[i +  cumulativeLength(j)]"  (ngModelChange)="onPerChange(j, i + cumulativeLength(j), $event, para.rules, para.par_id)">
																            	<option value="Yes">Yes</option>
																            	<option value="No">No</option>
																            </select>
																           </label>
																           <label class="center-block"  *ngIf="firstFields[i +  cumulativeLength(j)] == 'Yes'" style="padding-left: 10px;">LP IRR(%):
																            <input type="text" name="lpIRR" class="form-control"  [(ngModel)]="lpIRR" (ngModelChange)="onPerChange(j, i + cumulativeLength(j), $event, para.rules, para.par_id)">
																           </label>
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_7'">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netRevenue{{i +  cumulativeLength(j)}}" [(ngModel)]="firstFields[i +  cumulativeLength(j)]" (ngModelChange)="onPerChange(j, i + cumulativeLength(j), $event, para.rules, para.par_id)">
																            	<option value="500">Less than {{para.rules[0]['option_1']}}00</option>
																            	<option value="2000">{{para.rules[0]['option_1']}}00 to {{para.rules[1]['option_2']}}00 </option>
																            	<option value="5600">Greater than {{para.rules[2]['option_1']}}00 </option>
																            </select>
																           </label>										   	
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_4'">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netRevenue{{i +  cumulativeLength(j)}}" [(ngModel)]="firstFields[i +  cumulativeLength(j)]" (ngModelChange)="onPerChange(j, i + cumulativeLength(j), $event, para.rules, para.par_id)">
																            	<option value="High">High</option>
																            	<option value="Low">Low</option>
																            </select>
																           </label>										   	
															      		</div>
															        </div>
															        <div class="form-group" *ngIf="cate.cat_id == 4">
															          <label class="center-block">Input Value:
															            <select class="form-control" name="netRevenue{{i +  cumulativeLength(j)}}" [(ngModel)]="firstFields[i +  cumulativeLength(j)]" (ngModelChange)="onPerChange(j, i +  cumulativeLength(j), $event, para.rules, para.par_id)">
															            	<option value="Healthcare">Healthcare</option>
															            	<option value="Business to Business Technology">Business to Business Technology</option>
															            	<option value="Manufacturing">Manufacturing</option>
															            	<option value="Financial Services">Financial Services</option>
															            	<option value=" Business to Business Services"> Business to Business Services</option>
															            	<option value="Logistics">Logistics</option>
															            	<option value="FMCG">FMCG</option>
															            	<option value="Pharma">Pharma</option>
															            	<option value="Infastructure">Infastructure</option>
															            	<option value="Consumer Internet">Consumer Internet</option>
															            	<option value="Real Estate">Real Estate</option>
															            	<option value="Trading">Trading</option>
															            	<option value="Food Retail">Food Retail</option>
															            	<option value="Restaurants">Restaurants</option>
															            </select>
															          </label>											           
															        </div>
																</div>
																<div class="col-md-4">
																	<label class="center-block">Max Score:
																		<div class="input-group mb-3">
																			  <input type="text" class="form-control" name="revenueMax{{i +  cumulativeLength(j)}}" [(ngModel)]="thirdFields[i +  cumulativeLength(j)]" disabled="disabled">
																		</div>
																	</label>
																</div>
																<div class="col-md-4">
																	<label class="center-block">Score:
																		<div class="input-group mb-3">
																		  <input type="text" class="form-control" name="revenuePer{{i +  cumulativeLength(j)}}" [(ngModel)]="secondFields[i + cumulativeLength(j)]">
																		  
																		</div>
																	</label>
																</div>
																
															</div>
														</div>

													</div>
													<div class="row">
														<div class="col-md-4">

														</div>
														<div class="col-md-4">
															<label class="center-block">Category Total:
																<div class="input-group mb-3">
																	  <input type="text" class="form-control" name="netscore{{j}}" [(ngModel)]="netScoreFields[j]" disabled="disabled">
																</div>
															</label>
														</div>
														<div class="col-md-4">
															<label class="center-block">Category Net:
																<div class="input-group mb-3">
																	  <input type="text" class="form-control" name="totscore{{j}}" [(ngModel)]="totScoreFields[j]" disabled="disabled">
																</div>
															</label>
														</div>
														
													</div>
												</div>
											</div>
										</div>
										
										
								        
								  	</form>
								  	<!-- <p>Form value: {{ angForm.value | json }}</p>
									<p>Form status: {{ angForm.status | json }}</p> -->
								</div>
							</div>
							<div class="tab-pane fade show active" id="subscribe" role="tabpanel" aria-labelledby="sub-tab">
								<div class="row">
									<div class="col-md-4">
										<div class="form-group">
								          <label class="center-block">Max Score : 
								            <input type="text" class="form-control" name="maxSubScoree" [(ngModel)]="maxSubScoree" >
								          </label>
								        </div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
								          <label class="center-block">Total Score : 
								            <input type="text" class="form-control" name="totSubScore" [(ngModel)]="totSubScore"  >
								          </label>
								        </div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
								          <label class="center-block">Net Score : 
								            <input type="text" class="form-control" name="netSubScore" [(ngModel)]="netSubScore" >
								          </label>
								        </div>
									</div>
								</div>

								<div class="forms">
									<form  novalidate>
										
										<div class="row" *ngFor="let rev of subData;">
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-12">
														<h1>{{rev.rev_model}}</h1>												
													</div>
												</div>
												
												<div class="cate_head" *ngFor="let cate of rev.rev_data;let j = index;trackBy:catTrackBy">
													<h4>{{cate.cat_name}}</h4>
													<div class="row" *ngFor="let para of cate.cat_data;let i = index;trackBy:paraTrackBy;">
														<div class="col-md-12">
															<div class="row">
																<div class="col-md-12">
																	<h6>{{cate.cat_id}}.{{para.par_id}}.{{para.par_name}}</h6>
																</div>
															</div>
															<div class="row">
																<div class="col-md-4">
																	
																	<div class="form-group" *ngIf="cate.cat_id != 4">
																		<div *ngIf="para.rule_type == 'rule_1' || para.rule_type == 'rule_2' || para.rule_type == 'rule_6'">
																          <label class="center-block">Input Value:
																            <input type="text" class="form-control" name="netSubRevenue{{i +  cumulativeSubLength(j)}}" [(ngModel)]="firstSubFields[i +  cumulativeSubLength(j)]" (ngModelChange)="onPerSubChange(j, i + cumulativeSubLength(j), $event, para.rules, para.par_id)">
																          </label>											   	
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_3' && para.par_id!=7">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netSubRevenue{{i +  cumulativeSubLength(j)}}" [(ngModel)]="firstSubFields[i +  cumulativeSubLength(j)]" (ngModelChange)="onPerSubChange(j, i + cumulativeSubLength(j), $event, para.rules, para.par_id)">
																            	<option value="Yes">Yes</option>
																            	<option value="No">No</option>
																            </select>
																           </label>										   	
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_3' && para.par_id==7">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netSubRevenue{{i +  cumulativeSubLength(j)}}" [(ngModel)]="firstSubFields[i +  cumulativeSubLength(j)]"  (ngModelChange)="onPerSubChange(j, i + cumulativeSubLength(j), $event, para.rules, para.par_id)">
																            	<option value="Yes">Yes</option>
																            	<option value="No">No</option>
																            </select>
																           </label>
																           <label class="center-block"  *ngIf="firstSubFields[i +  cumulativeSubLength(j)] == 'Yes'" style="padding-left: 10px;">LP IRR(%):
																            <input type="text" name="sublpIRR" class="form-control"  [(ngModel)]="sublpIRR" (ngModelChange)="onPerSubChange(j, i + cumulativeSubLength(j), $event, para.rules, para.par_id)">
																           </label>
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_7'">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netSubRevenue{{i +  cumulativeSubLength(j)}}" [(ngModel)]="firstSubFields[i +  cumulativeSubLength(j)]" (ngModelChange)="onPerSubChange(j, i + cumulativeSubLength(j), $event, para.rules, para.par_id)">
																            	<option value="500">Less than {{para.rules[0]['option_1']}}00</option>
																            	<option value="2000">{{para.rules[0]['option_1']}}00 to {{para.rules[1]['option_2']}}00 </option>
																            	<option value="5600">Greater than {{para.rules[2]['option_1']}}00 </option>
																            </select>
																           </label>										   	
															      		</div>
															      		<div *ngIf="para.rule_type == 'rule_4'">
																          <label class="center-block">Input Value:
																            <select class="form-control" name="netSubRevenue{{i +  cumulativeSubLength(j)}}" [(ngModel)]="firstSubFields[i +  cumulativeSubLength(j)]" (ngModelChange)="onPerSubChange(j, i + cumulativeSubLength(j), $event, para.rules, para.par_id)">
																            	<option value="High">High</option>
																            	<option value="Low">Low</option>
																            </select>
																           </label>										   	
															      		</div>
															        </div>
															        <div class="form-group" *ngIf="cate.cat_id == 4">
															          <label class="center-block">Input Value:
															            <select class="form-control" name="netSubRevenue{{i +  cumulativeSubLength(j)}}" [(ngModel)]="firstSubFields[i +  cumulativeSubLength(j)]" (ngModelChange)="onPerSubChange(j, i +  cumulativeSubLength(j), $event, para.rules, para.par_id)">
															            	<option value="Healthcare">Healthcare</option>
															            	<option value="Business to Business Technology">Business to Business Technology</option>
															            	<option value="Manufacturing">Manufacturing</option>
															            	<option value="Financial Services">Financial Services</option>
															            	<option value=" Business to Business Services"> Business to Business Services</option>
															            	<option value="Logistics">Logistics</option>
															            	<option value="FMCG">FMCG</option>
															            	<option value="Pharma">Pharma</option>
															            	<option value="Infastructure">Infastructure</option>
															            	<option value="Consumer Internet">Consumer Internet</option>
															            	<option value="Real Estate">Real Estate</option>
															            	<option value="Trading">Trading</option>
															            	<option value="Food Retail">Food Retail</option>
															            	<option value="Restaurants">Restaurants</option>
															            </select>
															          </label>											           
															        </div>
																</div>
																<div class="col-md-4">
																	<label class="center-block">Max Score:
																		<div class="input-group mb-3">
																			  <input type="text" class="form-control" name="revenueSubMax{{i +  cumulativeSubLength(j)}}" [(ngModel)]="thirdSubFields[i +  cumulativeSubLength(j)]" disabled="disabled">
																		</div>
																	</label>
																</div>
																<div class="col-md-4">
																	<label class="center-block">Score:
																		<div class="input-group mb-3">
																		  <input type="text" class="form-control" name="revenueSubPer{{i +  cumulativeSubLength(j)}}" [(ngModel)]="secondSubFields[i + cumulativeSubLength(j)]">
																		  
																		</div>
																	</label>
																</div>
																
															</div>
														</div>

													</div>
													<div class="row">
														<div class="col-md-4">

														</div>
														<div class="col-md-4">
															<label class="center-block">Category Net:
																<div class="input-group mb-3">
																	  <input type="text" class="form-control" name="netSubScoreFields{{j}}" [(ngModel)]="netSubScoreFields[j]" disabled="disabled">
																</div>
															</label>
														</div>
														<div class="col-md-4">
															<label class="center-block">Category Total:
																<div class="input-group mb-3">
																	  <input type="text" class="form-control" name="totSubScoreFields{{j}}" [(ngModel)]="totSubScoreFields[j]" disabled="disabled">
																</div>
															</label>
														</div>
														
													</div>
												</div>
											</div>
										</div>
										
										
								        
								  	</form>
								  	<!-- <p>Form value: {{ angForm.value | json }}</p>
									<p>Form status: {{ angForm.status | json }}</p> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</div>


<div class="card mt-4" *ngIf="isAdmin">
    <h4 class="card-header">Final Framework</h4>
    <div class="card-body">
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>

		<div class="row" *ngIf="isAdmin">
	      <div class="col-md-12">
	        <div class="row">
	          <div class="col-md-12">
	            
	            <form #angForm="ngForm" (ngSubmit)="addData()">
	            	<ul class="nav nav-tabs" id="myTab" role="tablist">
					  <li class="nav-item">
					    <a class="nav-link active" id="fin-tab" data-toggle="tab" href="#finance" role="tab" aria-controls="finance"
					      aria-selected="true">FINANCIAL INFORMATION</a>
					  </li>
					  <li class="nav-item">
					    <a class="nav-link" id="invest-tab" data-toggle="tab" href="#investment" role="tab" aria-controls="investment"
					      aria-selected="false">INVESTMENT</a>
					  </li>
					  <li class="nav-item">
					    <a class="nav-link" id="bus-tab" data-toggle="tab" href="#business" role="tab" aria-controls="business"
					      aria-selected="false">BUSINESS</a>
					  </li>
					  <li class="nav-item">
					    <a class="nav-link" id="ind-tab" data-toggle="tab" href="#industry" role="tab" aria-controls="industry"
					      aria-selected="false">INDUSTRY</a>
					  </li>
					  <li class="nav-item">
					    <a class="nav-link" id="pro-tab" data-toggle="tab" href="#promoter" role="tab" aria-controls="promoter"
					      aria-selected="false">PROMOTER</a>
					  </li>
					</ul>
					<div class="tab-content" id="myTabContent">
	  					<div class="tab-pane fade show active" id="finance" role="tabpanel" aria-labelledby="fin-tab">
			            	
			              	<!-- Financial Institution -->
				            <div class="param-field" style="padding: 20px;border-bottom: 2px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Net revenue (past financial year)</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                			<div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_1_max_score" [(ngModel)]="fin_1_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_1_option_1" [(ngModel)]="fin_1_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_1_option_2" [(ngModel)]="fin_1_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_1_option_3" [(ngModel)]="fin_1_option_3">
							                      </label>
							                    </div>
							                  </div>
				                		</div>
				                	</div>
				                	<div class="col-md-6">
				                		<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                			<div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_1_sub_max_score" [(ngModel)]="fin_1_sub_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_1_sub_option_1" [(ngModel)]="fin_1_sub_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_1_sub_option_2" [(ngModel)]="fin_1_sub_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_1_sub_option_3" [(ngModel)]="fin_1_sub_option_3">
							                      </label>
							                    </div>
							                  </div>
				                		</div>
				                	</div>
				                  
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_1_par_1_gen_opt_val" [(ngModel)]="fin_1_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_1_par_2_gen_opt_val" [(ngModel)]="fin_1_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_1_par_3_gen_opt_val" [(ngModel)]="fin_1_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_1_par_1_opt_bus_val" [(ngModel)]="fin_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_1_par_2_opt_bus_val" [(ngModel)]="fin_1_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_1_par_3_opt_bus_val" [(ngModel)]="fin_1_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 1 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 2px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">EBITDA margin past financial year (%)</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_2_max_score" [(ngModel)]="fin_2_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_2_option_1" [(ngModel)]="fin_2_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_2_option_2" [(ngModel)]="fin_2_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_2_option_3" [(ngModel)]="fin_2_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_2_sub_max_score" [(ngModel)]="fin_2_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_2_sub_option_1" [(ngModel)]="fin_2_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_2_sub_option_2" [(ngModel)]="fin_2_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_2_sub_option_3" [(ngModel)]="fin_2_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_2_par_1_gen_opt_val" [(ngModel)]="fin_2_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_2_par_2_gen_opt_val" [(ngModel)]="fin_2_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_2_par_3_gen_opt_val" [(ngModel)]="fin_2_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_2_par_1_opt_bus_val" [(ngModel)]="fin_2_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_2_par_2_opt_bus_val" [(ngModel)]="fin_2_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_2_par_3_opt_bus_val" [(ngModel)]="fin_2_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 2 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">PAT margin (past financial year)</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_3_max_score" [(ngModel)]="fin_3_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_3_option_1" [(ngModel)]="fin_3_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_3_option_2" [(ngModel)]="fin_3_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_3_option_3" [(ngModel)]="fin_3_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_3_sub_max_score" [(ngModel)]="fin_3_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_3_sub_option_1" [(ngModel)]="fin_3_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_3_sub_option_2" [(ngModel)]="fin_3_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_3_sub_option_3" [(ngModel)]="fin_3_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_3_par_1_gen_opt_val" [(ngModel)]="fin_3_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_3_par_2_gen_opt_val" [(ngModel)]="fin_3_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_3_par_3_gen_opt_val" [(ngModel)]="fin_3_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_3_par_1_opt_bus_val" [(ngModel)]="fin_3_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_3_par_2_opt_bus_val" [(ngModel)]="fin_3_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_3_par_3_opt_bus_val" [(ngModel)]="fin_3_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 3 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Inventory days - based on past financial year</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_4_max_score" [(ngModel)]="fin_4_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_4_option_1" [(ngModel)]="fin_4_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_4_option_2" [(ngModel)]="fin_4_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_4_option_3" [(ngModel)]="fin_4_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_4_sub_max_score" [(ngModel)]="fin_4_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_4_sub_option_1" [(ngModel)]="fin_4_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_4_sub_option_2" [(ngModel)]="fin_4_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_4_sub_option_3" [(ngModel)]="fin_4_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_4_par_1_gen_opt_val" [(ngModel)]="fin_4_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_4_par_2_gen_opt_val" [(ngModel)]="fin_4_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_4_par_3_gen_opt_val" [(ngModel)]="fin_4_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_4_par_1_opt_bus_val" [(ngModel)]="fin_4_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_4_par_2_opt_bus_val" [(ngModel)]="fin_4_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_4_par_3_opt_bus_val" [(ngModel)]="fin_4_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 4 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Receivables days - based on past financial year</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_5_max_score" [(ngModel)]="fin_5_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_5_option_1" [(ngModel)]="fin_5_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_5_option_2" [(ngModel)]="fin_5_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_5_option_3" [(ngModel)]="fin_5_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_5_sub_max_score" [(ngModel)]="fin_5_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_5_sub_option_1" [(ngModel)]="fin_5_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_5_sub_option_2" [(ngModel)]="fin_5_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_5_sub_option_3" [(ngModel)]="fin_5_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_5_par_1_gen_opt_val" [(ngModel)]="fin_5_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_5_par_2_gen_opt_val" [(ngModel)]="fin_5_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_5_par_3_gen_opt_val" [(ngModel)]="fin_5_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_5_par_1_opt_bus_val" [(ngModel)]="fin_5_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_5_par_2_opt_bus_val" [(ngModel)]="fin_5_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_5_par_3_opt_bus_val" [(ngModel)]="fin_5_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 5 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Interest coverage ratio (EBIT/Interest Expenses)</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_6_max_score" [(ngModel)]="fin_6_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_6_option_1" [(ngModel)]="fin_6_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_6_option_2" [(ngModel)]="fin_6_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_6_option_3" [(ngModel)]="fin_6_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_6_sub_max_score" [(ngModel)]="fin_6_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_6_sub_option_1" [(ngModel)]="fin_6_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_6_sub_option_2" [(ngModel)]="fin_6_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_6_sub_option_3" [(ngModel)]="fin_6_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_6_par_1_gen_opt_val" [(ngModel)]="fin_6_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_6_par_2_gen_opt_val" [(ngModel)]="fin_6_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_6_par_3_gen_opt_val" [(ngModel)]="fin_6_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_6_par_1_opt_bus_val" [(ngModel)]="fin_6_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_6_par_2_opt_bus_val" [(ngModel)]="fin_6_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_6_par_3_opt_bus_val" [(ngModel)]="fin_6_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 6 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Free Cash Flow from Operations</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_7_max_score" [(ngModel)]="fin_7_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_7_option_1" [(ngModel)]="fin_7_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_7_option_2" [(ngModel)]="fin_7_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_7_option_3" [(ngModel)]="fin_7_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_7_sub_max_score" [(ngModel)]="fin_7_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_7_sub_option_1" [(ngModel)]="fin_7_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_7_sub_option_2" [(ngModel)]="fin_7_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_7_sub_option_3" [(ngModel)]="fin_7_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_7_par_1_gen_opt_val" [(ngModel)]="fin_7_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_7_par_2_gen_opt_val" [(ngModel)]="fin_7_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_7_par_3_gen_opt_val" [(ngModel)]="fin_7_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_7_par_1_opt_bus_val" [(ngModel)]="fin_7_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_7_par_2_opt_bus_val" [(ngModel)]="fin_7_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_7_par_3_opt_bus_val" [(ngModel)]="fin_7_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 7 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Revenue CAGR (past 2 years)</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_8_max_score" [(ngModel)]="fin_8_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_8_option_1" [(ngModel)]="fin_8_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_8_option_2" [(ngModel)]="fin_8_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_8_option_3" [(ngModel)]="fin_8_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_8_sub_max_score" [(ngModel)]="fin_8_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_8_sub_option_1" [(ngModel)]="fin_8_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_8_sub_option_2" [(ngModel)]="fin_8_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_8_sub_option_3" [(ngModel)]="fin_8_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_8_par_1_gen_opt_val" [(ngModel)]="fin_8_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_8_par_2_gen_opt_val" [(ngModel)]="fin_8_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_8_par_3_gen_opt_val" [(ngModel)]="fin_8_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_8_par_1_opt_bus_val" [(ngModel)]="fin_8_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_8_par_2_opt_bus_val" [(ngModel)]="fin_8_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_8_par_3_opt_bus_val" [(ngModel)]="fin_8_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 8 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Debt / Equity ratio</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_9_max_score" [(ngModel)]="fin_9_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_9_option_1" [(ngModel)]="fin_9_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_9_option_2" [(ngModel)]="fin_9_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_9_option_3" [(ngModel)]="fin_9_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_9_sub_max_score" [(ngModel)]="fin_9_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_9_sub_option_1" [(ngModel)]="fin_9_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_9_sub_option_2" [(ngModel)]="fin_9_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_9_sub_option_3" [(ngModel)]="fin_9_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_9_par_1_gen_opt_val" [(ngModel)]="fin_9_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_9_par_2_gen_opt_val" [(ngModel)]="fin_9_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_9_par_3_gen_opt_val" [(ngModel)]="fin_9_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_9_par_1_opt_bus_val" [(ngModel)]="fin_9_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_9_par_2_opt_bus_val" [(ngModel)]="fin_9_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_9_par_3_opt_bus_val" [(ngModel)]="fin_9_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 9 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Is debt leverage structured in the transaction ?</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="fin_10_max_score" [(ngModel)]="fin_10_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="fin_10_option_1" [(ngModel)]="fin_10_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="fin_10_option_2" [(ngModel)]="fin_10_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="fin_10_option_3" [(ngModel)]="fin_10_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="fin_10_sub_max_score" [(ngModel)]="fin_9_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="fin_10_sub_option_1" [(ngModel)]="fin_9_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="fin_10_sub_option_2" [(ngModel)]="fin_9_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="fin_10_sub_option_3" [(ngModel)]="fin_9_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_10_par_1_gen_opt_val" [(ngModel)]="fin_10_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_10_par_2_gen_opt_val" [(ngModel)]="fin_10_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_10_par_3_gen_opt_val" [(ngModel)]="fin_10_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="fin_10_par_1_opt_bus_val" [(ngModel)]="fin_10_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="fin_10_par_2_opt_bus_val" [(ngModel)]="fin_10_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="fin_10_par_3_opt_bus_val" [(ngModel)]="fin_10_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				            <!-- cat 10 -->
				            <!-- Financial Institution -->
				        </div>
				        <div class="tab-pane fade show" id="investment" role="tabpanel" aria-labelledby="invest-tab">
				            

				            <!-- Investment -->
				            <div class="param-field" style="padding: 20px;border-bottom: 2px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Valuation multiple ((Enterprise Value / EBITDA) for General) / ((Enterprise Value /Revenue) for Subscription)  - past financial year
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="inv_1_max_score" [(ngModel)]="inv_1_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="inv_1_option_1" [(ngModel)]="inv_1_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="inv_1_option_2" [(ngModel)]="inv_1_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="inv_1_option_3" [(ngModel)]="inv_1_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>
							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_1_sub_max_score" [(ngModel)]="inv_1_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_1_sub_option_1" [(ngModel)]="inv_1_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_1_sub_option_2" [(ngModel)]="inv_1_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_1_sub_option_3" [(ngModel)]="inv_1_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_1_par_1_gen_opt_val" [(ngModel)]="inv_1_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_1_par_2_gen_opt_val" [(ngModel)]="inv_1_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_1_par_3_gen_opt_val" [(ngModel)]="inv_1_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_1_par_1_opt_bus_val" [(ngModel)]="inv_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_1_par_2_opt_bus_val" [(ngModel)]="inv_1_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_1_par_3_opt_bus_val" [(ngModel)]="inv_1_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 1 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 2px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Valuation multiple ((Enterprise Value / EBITDA) for General) / ((Enterprise Value /Revenue) for Subscription)  - projected financial year
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="inv_2_max_score" [(ngModel)]="inv_2_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="inv_2_option_1" [(ngModel)]="inv_2_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="inv_2_option_2" [(ngModel)]="inv_2_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="inv_2_option_3" [(ngModel)]="inv_2_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_2_sub_max_score" [(ngModel)]="inv_2_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_2_sub_option_1" [(ngModel)]="inv_2_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_2_sub_option_2" [(ngModel)]="inv_2_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_2_sub_option_3" [(ngModel)]="inv_2_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_2_par_1_gen_opt_val" [(ngModel)]="inv_2_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_2_par_2_gen_opt_val" [(ngModel)]="inv_2_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_2_par_3_gen_opt_val" [(ngModel)]="inv_2_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_2_par_1_opt_bus_val" [(ngModel)]="inv_2_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_2_par_2_opt_bus_val" [(ngModel)]="inv_2_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_2_par_3_opt_bus_val" [(ngModel)]="inv_2_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 2 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Stakeboat Capital holding (%)
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="inv_3_max_score" [(ngModel)]="inv_3_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="inv_3_option_1" [(ngModel)]="inv_3_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="inv_3_option_2" [(ngModel)]="inv_3_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="inv_3_option_3" [(ngModel)]="inv_3_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_3_sub_max_score" [(ngModel)]="inv_3_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_3_sub_option_1" [(ngModel)]="inv_3_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_3_sub_option_2" [(ngModel)]="inv_3_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_3_sub_option_3" [(ngModel)]="inv_3_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_3_par_1_gen_opt_val" [(ngModel)]="inv_3_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_3_par_2_gen_opt_val" [(ngModel)]="inv_3_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_3_par_3_gen_opt_val" [(ngModel)]="inv_3_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_3_par_1_opt_bus_val" [(ngModel)]="inv_3_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_3_par_2_opt_bus_val" [(ngModel)]="inv_3_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_3_par_3_opt_bus_val" [(ngModel)]="inv_3_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 3 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">Internal Rate of Return (IRR) (%)
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="inv_4_max_score" [(ngModel)]="inv_4_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="inv_4_option_1" [(ngModel)]="inv_4_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="inv_4_option_2" [(ngModel)]="inv_4_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="inv_4_option_3" [(ngModel)]="inv_4_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_4_sub_max_score" [(ngModel)]="inv_4_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_4_sub_option_1" [(ngModel)]="inv_4_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_4_sub_option_2" [(ngModel)]="inv_4_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_4_sub_option_3" [(ngModel)]="inv_4_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_4_par_1_gen_opt_val" [(ngModel)]="inv_4_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_4_par_2_gen_opt_val" [(ngModel)]="inv_4_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_4_par_3_gen_opt_val" [(ngModel)]="inv_4_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_4_par_1_opt_bus_val" [(ngModel)]="inv_4_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_4_par_2_opt_bus_val" [(ngModel)]="inv_4_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_4_par_3_opt_bus_val" [(ngModel)]="inv_4_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 4 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Cash on Cash (CoC) multiple (times)
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="inv_5_max_score" [(ngModel)]="inv_5_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="inv_5_option_1" [(ngModel)]="inv_5_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="inv_5_option_2" [(ngModel)]="inv_5_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="inv_5_option_3" [(ngModel)]="inv_5_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_5_sub_max_score" [(ngModel)]="inv_5_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_5_sub_option_1" [(ngModel)]="inv_5_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_5_sub_option_2" [(ngModel)]="inv_5_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_5_sub_option_3" [(ngModel)]="inv_5_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_5_par_1_gen_opt_val" [(ngModel)]="inv_5_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_5_par_2_gen_opt_val" [(ngModel)]="inv_5_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_5_par_3_gen_opt_val" [(ngModel)]="inv_5_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_5_par_1_opt_bus_val" [(ngModel)]="inv_5_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_5_par_2_opt_bus_val" [(ngModel)]="inv_5_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_5_par_3_opt_bus_val" [(ngModel)]="inv_5_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 5 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Exit rights
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Max Score:
							                        <input type="text" class="form-control" name="inv_6_max_score" [(ngModel)]="inv_6_max_score">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 1:
							                        <input type="text" class="form-control" name="inv_6_option_1" [(ngModel)]="inv_6_option_1">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 2:
							                        <input type="text" class="form-control" name="inv_6_option_2" [(ngModel)]="inv_6_option_2">
							                      </label>
							                    </div>
							                  </div>
							                  <div class="col-md-3">
							                    <div class="form-group">
							                      <label class="center-block">Option 3:
							                        <input type="text" class="form-control" name="inv_6_option_3" [(ngModel)]="inv_6_option_3">
							                      </label>
							                    </div>
							                  </div>
							            </div>
							        </div>

							        <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_6_sub_max_score" [(ngModel)]="inv_6_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_6_sub_option_1" [(ngModel)]="inv_6_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_6_sub_option_2" [(ngModel)]="inv_6_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_6_sub_option_3" [(ngModel)]="inv_6_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_6_par_1_gen_opt_val" [(ngModel)]="inv_6_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_6_par_2_gen_opt_val" [(ngModel)]="inv_6_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_6_par_3_gen_opt_val" [(ngModel)]="inv_6_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_6_par_1_opt_bus_val" [(ngModel)]="inv_6_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_6_par_2_opt_bus_val" [(ngModel)]="inv_6_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_6_par_3_opt_bus_val" [(ngModel)]="inv_6_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 6 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Liquidation Preference
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="inv_7_max_score" [(ngModel)]="inv_7_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="inv_7_option_1" [(ngModel)]="inv_7_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="inv_7_option_2" [(ngModel)]="inv_7_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="inv_7_option_3" [(ngModel)]="inv_7_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_7_sub_max_score" [(ngModel)]="inv_7_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_7_sub_option_1" [(ngModel)]="inv_7_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_7_sub_option_2" [(ngModel)]="inv_7_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_7_sub_option_3" [(ngModel)]="inv_7_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_7_par_1_gen_opt_val" [(ngModel)]="inv_7_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_7_par_2_gen_opt_val" [(ngModel)]="inv_7_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_7_par_3_gen_opt_val" [(ngModel)]="inv_7_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_7_par_1_opt_bus_val" [(ngModel)]="inv_7_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_7_par_2_opt_bus_val" [(ngModel)]="inv_7_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_7_par_3_opt_bus_val" [(ngModel)]="inv_7_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 7 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Unique Rights negotiated
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="inv_8_max_score" [(ngModel)]="inv_8_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="inv_8_option_1" [(ngModel)]="inv_8_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="inv_8_option_2" [(ngModel)]="inv_8_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="inv_8_option_3" [(ngModel)]="inv_8_option_3">
						                      </label>
						                    </div>
						                  </div>
						              	</div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_8_sub_max_score" [(ngModel)]="inv_8_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_8_sub_option_1" [(ngModel)]="inv_8_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_8_sub_option_2" [(ngModel)]="inv_8_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_8_sub_option_3" [(ngModel)]="inv_8_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_8_par_1_gen_opt_val" [(ngModel)]="inv_8_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_8_par_2_gen_opt_val" [(ngModel)]="inv_8_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_8_par_3_gen_opt_val" [(ngModel)]="inv_8_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_8_par_1_opt_bus_val" [(ngModel)]="inv_8_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_8_par_2_opt_bus_val" [(ngModel)]="inv_8_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_8_par_3_opt_bus_val" [(ngModel)]="inv_8_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 8 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Are there international Strategic buyers ?  List 3 International Companies in the same space / List 2 transactions happened in the same space
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="inv_9_max_score" [(ngModel)]="inv_9_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="inv_9_option_1" [(ngModel)]="inv_9_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="inv_9_option_2" [(ngModel)]="inv_9_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="inv_9_option_3" [(ngModel)]="inv_9_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="inv_9_sub_max_score" [(ngModel)]="inv_9_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="inv_9_sub_option_1" [(ngModel)]="inv_9_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="inv_9_sub_option_2" [(ngModel)]="inv_9_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="inv_9_sub_option_3" [(ngModel)]="inv_9_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_9_par_1_gen_opt_val" [(ngModel)]="inv_9_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_9_par_2_gen_opt_val" [(ngModel)]="inv_9_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_9_par_3_gen_opt_val" [(ngModel)]="inv_9_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="inv_9_par_1_opt_bus_val" [(ngModel)]="inv_9_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="inv_9_par_2_opt_bus_val" [(ngModel)]="inv_9_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="inv_9_par_3_opt_bus_val" [(ngModel)]="inv_9_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 9 -->
				        </div>
				        <div class="tab-pane fade show" id="business" role="tabpanel" aria-labelledby="bus-tab">
				            

				            <!-- Business -->
				            <div class="param-field" style="padding: 20px;border-bottom: 2px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Customer Stickiness
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_1_max_score" [(ngModel)]="bus_1_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_1_option_1" [(ngModel)]="bus_1_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_1_option_2" [(ngModel)]="bus_1_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_1_option_3" [(ngModel)]="bus_1_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_1_sub_max_score" [(ngModel)]="bus_1_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_1_sub_option_1" [(ngModel)]="bus_1_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_1_sub_option_2" [(ngModel)]="bus_1_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_1_sub_option_3" [(ngModel)]="bus_1_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_1_par_1_gen_opt_val" [(ngModel)]="bus_1_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_1_par_2_gen_opt_val" [(ngModel)]="bus_1_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_1_par_3_gen_opt_val" [(ngModel)]="bus_1_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_1_par_1_opt_bus_val" [(ngModel)]="bus_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_1_par_2_opt_bus_val" [(ngModel)]="bus_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_1_par_3_opt_bus_val" [(ngModel)]="bus_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 1 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 2px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Customer concentration - Market share of top 2 customers
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_2_max_score" [(ngModel)]="bus_2_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_2_option_1" [(ngModel)]="bus_2_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_2_option_2" [(ngModel)]="bus_2_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_2_option_3" [(ngModel)]="bus_2_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_2_sub_max_score" [(ngModel)]="bus_2_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_2_sub_option_1" [(ngModel)]="bus_2_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_2_sub_option_2" [(ngModel)]="bus_2_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_2_sub_option_3" [(ngModel)]="bus_2_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_2_par_1_gen_opt_val" [(ngModel)]="bus_2_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_2_par_2_gen_opt_val" [(ngModel)]="bus_2_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_2_par_3_gen_opt_val" [(ngModel)]="bus_2_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_2_par_1_opt_bus_val" [(ngModel)]="bus_2_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_2_par_2_opt_bus_val" [(ngModel)]="bus_2_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_2_par_3_opt_bus_val" [(ngModel)]="bus_2_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 2 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									% of revenue contributed by govt. customers (both directly and indirectly)
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_3_max_score" [(ngModel)]="bus_3_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_3_option_1" [(ngModel)]="bus_3_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_3_option_2" [(ngModel)]="bus_3_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_3_option_3" [(ngModel)]="bus_3_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_3_sub_max_score" [(ngModel)]="bus_3_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_3_sub_option_1" [(ngModel)]="bus_3_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_3_sub_option_2" [(ngModel)]="bus_3_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_3_sub_option_3" [(ngModel)]="bus_3_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_3_par_1_gen_opt_val" [(ngModel)]="bus_3_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_3_par_2_gen_opt_val" [(ngModel)]="bus_3_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_3_par_3_gen_opt_val" [(ngModel)]="bus_3_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_3_par_1_opt_bus_val" [(ngModel)]="bus_3_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_3_par_2_opt_bus_val" [(ngModel)]="bus_3_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_3_par_3_opt_bus_val" [(ngModel)]="bus_3_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 3 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Supplier concentration (of the top 3 suppliers)
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_4_max_score" [(ngModel)]="bus_4_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_4_option_1" [(ngModel)]="bus_4_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_4_option_2" [(ngModel)]="bus_4_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_4_option_3" [(ngModel)]="bus_4_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_4_sub_max_score" [(ngModel)]="bus_4_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_4_sub_option_1" [(ngModel)]="bus_4_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_4_sub_option_2" [(ngModel)]="bus_4_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_4_sub_option_3" [(ngModel)]="bus_4_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_4_par_1_gen_opt_val" [(ngModel)]="bus_4_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_4_par_2_gen_opt_val" [(ngModel)]="bus_4_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_4_par_3_gen_opt_val" [(ngModel)]="bus_4_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_4_par_1_opt_bus_val" [(ngModel)]="bus_4_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_4_par_2_opt_bus_val" [(ngModel)]="bus_4_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_4_par_3_opt_bus_val" [(ngModel)]="bus_4_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 4 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Potential risk of suppliers increasing the prices?
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_5_max_score" [(ngModel)]="bus_5_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_5_option_1" [(ngModel)]="bus_5_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_5_option_2" [(ngModel)]="bus_5_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_5_option_3" [(ngModel)]="bus_5_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_5_sub_max_score" [(ngModel)]="bus_5_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_5_sub_option_1" [(ngModel)]="bus_5_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_5_sub_option_2" [(ngModel)]="bus_5_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_5_sub_option_3" [(ngModel)]="bus_5_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_5_par_1_gen_opt_val" [(ngModel)]="bus_5_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_5_par_2_gen_opt_val" [(ngModel)]="bus_5_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_5_par_3_gen_opt_val" [(ngModel)]="bus_5_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_5_par_1_opt_bus_val" [(ngModel)]="bus_5_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_5_par_2_opt_bus_val" [(ngModel)]="bus_5_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_5_par_3_opt_bus_val" [(ngModel)]="bus_5_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>

				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Is the business driven by operations or external factors like location, tax benefits, govt. grants, discounted pricing, subsidy? (Make sure that the impact is high if not then its can be relaxed)
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_6_max_score" [(ngModel)]="bus_6_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_6_option_1" [(ngModel)]="bus_6_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_6_option_2" [(ngModel)]="bus_6_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_6_option_3" [(ngModel)]="bus_6_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_6_sub_max_score" [(ngModel)]="bus_6_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_6_sub_option_1" [(ngModel)]="bus_6_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_6_sub_option_2" [(ngModel)]="bus_6_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_6_sub_option_3" [(ngModel)]="bus_6_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_6_par_1_gen_opt_val" [(ngModel)]="bus_6_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_6_par_2_gen_opt_val" [(ngModel)]="bus_6_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_6_par_3_gen_opt_val" [(ngModel)]="bus_6_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_6_par_1_opt_bus_val" [(ngModel)]="bus_6_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_6_par_2_opt_bus_val" [(ngModel)]="bus_6_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_6_par_3_opt_bus_val" [(ngModel)]="bus_6_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 7 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Is the pricing in the industry very competitive and can it impact the business? Herfindhal (HHL )Index - use the values and link it to pricing point</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_7_max_score" [(ngModel)]="bus_7_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_7_option_1" [(ngModel)]="bus_7_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_7_option_2" [(ngModel)]="bus_7_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_7_option_3" [(ngModel)]="bus_7_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_7_sub_max_score" [(ngModel)]="bus_7_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_7_sub_option_1" [(ngModel)]="bus_7_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_7_sub_option_2" [(ngModel)]="bus_7_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_7_sub_option_3" [(ngModel)]="bus_7_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_7_par_1_gen_opt_val" [(ngModel)]="bus_7_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_7_par_2_gen_opt_val" [(ngModel)]="bus_7_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_7_par_3_gen_opt_val" [(ngModel)]="bus_7_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_7_par_1_opt_bus_val" [(ngModel)]="bus_7_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_7_par_2_opt_bus_val" [(ngModel)]="bus_7_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_7_par_3_opt_bus_val" [(ngModel)]="bus_7_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 8 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Risk of disruption / technology
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_8_max_score" [(ngModel)]="bus_8_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_8_option_1" [(ngModel)]="bus_8_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_8_option_2" [(ngModel)]="bus_8_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_8_option_3" [(ngModel)]="bus_8_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_8_sub_max_score" [(ngModel)]="bus_8_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_8_sub_option_1" [(ngModel)]="bus_8_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_8_sub_option_2" [(ngModel)]="bus_8_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_8_sub_option_3" [(ngModel)]="bus_8_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_8_par_1_gen_opt_val" [(ngModel)]="bus_8_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_8_par_2_gen_opt_val" [(ngModel)]="bus_8_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_8_par_3_gen_opt_val" [(ngModel)]="bus_8_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_8_par_1_opt_bus_val" [(ngModel)]="bus_8_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_8_par_2_opt_bus_val" [(ngModel)]="bus_8_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_8_par_3_opt_bus_val" [(ngModel)]="bus_8_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				              <!-- cat 9 -->
				            <div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Net currency risk
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_9_max_score" [(ngModel)]="bus_9_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_9_option_1" [(ngModel)]="bus_9_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_9_option_2" [(ngModel)]="bus_9_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_9_option_3" [(ngModel)]="bus_9_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_9_sub_max_score" [(ngModel)]="bus_9_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_9_sub_option_1" [(ngModel)]="bus_9_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_9_sub_option_2" [(ngModel)]="bus_9_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_9_sub_option_3" [(ngModel)]="bus_9_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_9_par_1_gen_opt_val" [(ngModel)]="bus_9_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_9_par_2_gen_opt_val" [(ngModel)]="bus_9_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_9_par_3_gen_opt_val" [(ngModel)]="bus_9_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_9_par_1_opt_bus_val" [(ngModel)]="bus_9_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_9_par_2_opt_bus_val" [(ngModel)]="bus_9_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_9_par_3_opt_bus_val" [(ngModel)]="bus_9_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				            <!-- cat 10 -->
							<div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Potential regulatory risk 
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_10_max_score" [(ngModel)]="bus_10_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_10_option_1" [(ngModel)]="bus_10_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_10_option_2" [(ngModel)]="bus_10_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_10_option_3" [(ngModel)]="bus_10_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_10_sub_max_score" [(ngModel)]="bus_10_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_10_sub_option_1" [(ngModel)]="bus_10_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_10_sub_option_2" [(ngModel)]="bus_10_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_10_sub_option_3" [(ngModel)]="bus_10_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_10_par_1_gen_opt_val" [(ngModel)]="bus_10_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_10_par_2_gen_opt_val" [(ngModel)]="bus_10_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_10_par_3_gen_opt_val" [(ngModel)]="bus_10_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_10_par_1_opt_bus_val" [(ngModel)]="bus_10_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_10_par_2_opt_bus_val" [(ngModel)]="bus_10_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_10_par_3_opt_bus_val" [(ngModel)]="bus_10_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				            <!-- cat 11 -->
							<div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Market Size
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="bus_11_max_score" [(ngModel)]="bus_11_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="bus_11_option_1" [(ngModel)]="bus_11_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="bus_11_option_2" [(ngModel)]="bus_11_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="bus_11_option_3" [(ngModel)]="bus_11_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="bus_11_sub_max_score" [(ngModel)]="bus_11_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="bus_11_sub_option_1" [(ngModel)]="bus_11_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="bus_11_sub_option_2" [(ngModel)]="bus_11_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="bus_11_sub_option_3" [(ngModel)]="bus_11_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_11_par_1_gen_opt_val" [(ngModel)]="bus_11_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_11_par_2_gen_opt_val" [(ngModel)]="bus_11_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_11_par_3_gen_opt_val" [(ngModel)]="bus_11_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="bus_11_par_1_opt_bus_val" [(ngModel)]="bus_11_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="bus_11_par_2_opt_bus_val" [(ngModel)]="bus_11_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="bus_11_par_3_opt_bus_val" [(ngModel)]="bus_11_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				            <!-- cat 12 -->

				            <!-- Business -->
		            	</div>

		            	<div class="tab-pane fade show" id="industry" role="tabpanel" aria-labelledby="ind-tab">
		            		<!-- Industry -->
		            		<div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Sector
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="ind_1_max_score" [(ngModel)]="ind_1_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="ind_1_option_1" [(ngModel)]="ind_1_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="ind_1_option_2" [(ngModel)]="ind_1_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="ind_1_option_3" [(ngModel)]="ind_1_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="ind_1_sub_max_score" [(ngModel)]="ind_1_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="ind_1_sub_option_1" [(ngModel)]="ind_1_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="ind_1_sub_option_2" [(ngModel)]="ind_1_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="ind_1_sub_option_3" [(ngModel)]="ind_1_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="ind_1_par_1_gen_opt_val" [(ngModel)]="ind_1_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="ind_1_par_2_gen_opt_val" [(ngModel)]="ind_1_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="ind_1_par_3_gen_opt_val" [(ngModel)]="ind_1_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="ind_1_par_1_opt_bus_val" [(ngModel)]="ind_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="ind_1_par_2_opt_bus_val" [(ngModel)]="ind_1_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="ind_1_par_3_opt_bus_val" [(ngModel)]="ind_1_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				            <!-- cat 1 -->
		            		<!-- Industry -->
		            	</div>

		            	<div class="tab-pane fade show" id="promoter" role="tabpanel" aria-labelledby="pro-tab">
		            		<!-- Promoter -->
		            		<div class="param-field" style="padding: 20px;border-bottom: 0px solid #CFCFCF;">
				                <div class="row">
				                  <div class="col-md-12">
				                    <h5 style="background: #8cb3f7;padding: 7px 20px 7px 5px;margin-left: -10px; border-radius: 3px;border: 1px solid #699aef;">
									Management team
									</h5>
				                  </div>
				                </div>
				                <div class="row">
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For General Business</h6>
				                  </div>
				                  <div class="col-md-6">
				                    <h6 style="margin-right:10px">For Subscription Business</h6>
				                  </div>
				                </div>
				                <div class="row">
				                	<div class="col-md-6">
				                		<div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Max Score:
						                        <input type="text" class="form-control" name="pro_1_max_score" [(ngModel)]="pro_1_max_score">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 1:
						                        <input type="text" class="form-control" name="pro_1_option_1" [(ngModel)]="pro_1_option_1">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 2:
						                        <input type="text" class="form-control" name="pro_1_option_2" [(ngModel)]="pro_1_option_2">
						                      </label>
						                    </div>
						                  </div>
						                  <div class="col-md-3">
						                    <div class="form-group">
						                      <label class="center-block">Option 3:
						                        <input type="text" class="form-control" name="pro_1_option_3" [(ngModel)]="pro_1_option_3">
						                      </label>
						                    </div>
						                  </div>
						                </div>
						            </div>

						            <div class="col-md-6">
										<div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
											<div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Max Score:
													<input type="text" class="form-control" name="pro_1_sub_max_score" [(ngModel)]="pro_1_sub_max_score">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 1:
													<input type="text" class="form-control" name="pro_1_sub_option_1" [(ngModel)]="pro_1_sub_option_1">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 2:
													<input type="text" class="form-control" name="pro_1_sub_option_2" [(ngModel)]="pro_1_sub_option_2">
												  </label>
												</div>
											  </div>
											  <div class="col-md-3">
												<div class="form-group">
												  <label class="center-block">Option 3:
													<input type="text" class="form-control" name="pro_1_sub_option_3" [(ngModel)]="pro_1_sub_option_3">
												  </label>
												</div>
											  </div>
										</div>
									</div>
				                </div>
				                
				                <div class="row">
				                  <div class="col-md-6">
				                    <div class="row" style="margin-right: 10px;background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="pro_1_par_1_gen_opt_val" [(ngModel)]="pro_1_par_1_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="pro_1_par_2_gen_opt_val" [(ngModel)]="pro_1_par_2_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="pro_1_par_3_gen_opt_val" [(ngModel)]="pro_1_par_3_gen_opt_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                  <div class="col-md-6">
				                    <div class="row" style="background: #ededed;padding: 5px 0px 0 0px;border-radius: 5px;border: 1px solid #b6b4b4;">
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 1 Output:
				                            <input type="text" class="form-control" name="pro_1_par_1_opt_bus_val" [(ngModel)]="pro_1_par_1_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 2 Output:
				                            <input type="text" class="form-control" name="pro_1_par_2_opt_bus_val" [(ngModel)]="pro_1_par_2_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                      <div class="col-md-4">
				                        <div class="form-group">
				                          <label class="center-block">Rule 3 Output:
				                            <input type="text" class="form-control" name="pro_1_par_3_opt_bus_val" [(ngModel)]="pro_1_par_3_opt_bus_val">
				                          </label>
				                        </div>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				            </div>
				            <!-- cat 1 -->
		            		<!-- Promoter -->
		            	</div>


		            </div>
		            <!-- Investment Institution -->
		            <div class="row">
		                <div class="col-md-6"></div>
		                <div class="col-md-3" style="float:right">
		                </div>
		                <div class="col-md-3">
		                  <input type="reset" class="btn btn-warning" value="Clear">
		                  &nbsp;
		                  <input type="Submit" class="btn btn-primary" value="Update">
		                </div>
		            </div>
	            </form>
	          </div>
	        </div>
	      </div>
	    </div>

    </div>
</div>
