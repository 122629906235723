import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(strEmail: string, strPassword: string) {
        let data = {
            strEmail,
            strPassword,
        }
        return this.http.post<any>(`${environment.apiUrl}/user/login`, data)
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                if (user.intStatus == 200) {
                    user.response.data.authdata = window.btoa(strEmail + ':' + strPassword);
                    localStorage.setItem('currentUser', JSON.stringify(user.response.data));
                    localStorage.setItem('token', user.response.data.sessionToken);
                    this.currentUserSubject.next(user.response.data);
                }
                return user;
            }));
    }

    forgotPassword(strEmail) {
        return this.http.post<any>(`${environment.apiUrl}/user/forgotPassword`, { strEmail });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        this.currentUserSubject.next(null);
    }
}