<div class="company-page-container">
    <app-header></app-header>
    <form *ngIf="companyForm" class="form" [formGroup]="companyForm" (ngSubmit)="onSubmit()">
        <div class="input-container">
            <app-input serial="1" label="Name of the Company">
                <input class="input-field form-control" type="text" formControlName="name" />
            </app-input>
            <span *ngIf="errorCheck('name')" class="error">
                {{errorShow('name')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="2" label="Date of Entry">
                <div class="">
                    <app-date-selector class="ml-4" formGroupName="dateOfEntry"></app-date-selector>
                </div>
            </app-input>
            <!-- <span *ngIf="errorCheck('dateOfEntry')" class="error">
                {{errorShow('dateOfEntry')}}
            </span> -->
        </div>
        <div class="input-container">
            <app-input serial="3" label="Deal Team">
                <div class="d-flex align-items-center mb-2" formArrayName="dealTeam"
                    *ngFor="let item of dealTeam.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <select class="input-field form-control medium mr-2" formControlName="name">
                            <option disabled selected hidden value="">Owner</option>
                            <option *ngFor="let o of ownerDropdown" [value]="o">{{o}}</option>
                        </select>
                        <select class="input-field form-control medium ml-0 mr-2" formControlName="designation">
                            <option disabled selected hidden value="">Designation</option>
                            <option *ngFor="let o of designationDropdown" [value]="o">{{o}}</option>
                        </select>
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer" (click)="deleteField('dealTeam',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addDealTeam()">
                            <span class="add-icon">+</span> Add</button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('dealTeam', true)" class="error">
                {{errorShow('dealTeam', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="4" class="d-flex align-items-center">
                <div class="radio-container">
                    <label class="radio-field"><input type="radio" formControlName="type" value="llp"
                            name="type">LLP</label>
                    <label class="radio-field"><input type="radio" formControlName="type" value="private"
                            name="type">Private Limited</label>
                    <label class="radio-field"><input type="radio" formControlName="type" value="public"
                            name="type">Public Limited</label>
                </div>
            </app-input>
            <span *ngIf="errorCheck('type')" class="error">
                {{errorShow('type')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="5" label="Company Incorporation Number">
                <input class="input-field form-control"  type="text" formControlName="incorporationNumber" />
            </app-input>
            <span *ngIf="errorCheck('incorporationNumber')" class="error">
                {{errorShow('incorporationNumber')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="6" label="MSME" formGroupName="msme">
                <div class="file-upload-input">
                    <label class="radio-field"><input type="radio" formControlName="isMsme" value="y"
                            name="isMsme">Yes</label>
                    <label class="radio-field"><input type="radio" formControlName="isMsme" value="n"
                            name="isMsme">No</label>
                    <div class="fileupload">
                        <input [disabled]="companyForm.get('msme.isMsme').value == 'n'" id="msmefile" type='file' (change)="fileUploadHandler($event, 'msme')"/>
                        <label class="custom-label" for="msmefile">Choose file</label>
                        <span for="msmefile" class="action-btn browse-btn">Browse</span>
                    </div>
                </div>
                <span *ngIf="msme.value.file" class="uploaded-file-link">
                    <a [href]="msme.value.file" target="_blank" rel="noopener noreferrer">
                        {{msme.value.file.split("/").pop()}}
                    </a>
                    <mat-icon  class="center-mat-icon cursor-pointer" (click)="deleteFile('msme')">
                        close
                    </mat-icon>
                </span>
            </app-input>
            <span
                *ngIf="submitted && companyForm.get('msme.isMsme').value == 'y' && !companyForm.get('msme.file').value"
                class="error">
                Please upload the file {{companyForm.controls.msme.file}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="7" label="Startup" formGroupName="startup">
                <div class="file-upload-input">
                    <label class="radio-field"><input type="radio" formControlName="isStartup" value="y"
                            name="isStartup">Yes</label>
                    <label class="radio-field"><input type="radio" formControlName="isStartup" value="n"
                            name="isStartup">No</label>
                    <div class="fileupload">
                        <input [disabled]="companyForm.get('startup.isStartup').value == 'n'" id="startupfile" type='file' (change)="fileUploadHandler($event, 'startup')"/>
                        <span for="startupfile" class="action-btn browse-btn">Browse</span>
                    </div>
                </div>
                <span *ngIf="startup.value.file" class="uploaded-file-link">
                    <a [href]="startup.value.file" target="_blank" rel="noopener noreferrer">
                        {{startup.value.file.split("/").pop()}}
                    </a>
                    <mat-icon class="center-mat-icon cursor-pointer" (click)="deleteFile('startup')">
                        close
                    </mat-icon>
                </span>
            </app-input>
            <span
                *ngIf="submitted && companyForm.get('startup.isStartup').value == 'y' && !companyForm.get('startup.file').value"
                class="error">
                Please upload the file
            </span>
        </div>
        <div class="input-container">
            <app-input serial="8" label="Names of the Founders">
                <div class="d-flex align-items-center mb-2" formArrayName="nameOfFounders"
                    *ngFor="let item of nameOfFounders.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <input class="input-field form-control medium mr-2" type="text" formControlName="name"
                            placeholder="Promoter {{i+1}}" />
                        <input class="input-field form-control medium ml-0 mr-2" type="text"
                            formControlName="designation" placeholder="Designation" />
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer" (click)="deleteField('nameOfFounders',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addNameOfFounder()">
                            <span class="add-icon">+</span> Add</button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('nameOfFounders', true)" class="error">
                {{errorShow('nameOfFounders', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="9" label="Key Management Team">
                <div class="d-flex align-items-center mb-2" formArrayName="keyManagementTeam"
                    *ngFor="let item of keyManagementTeam.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <!-- <select class="input-field form-control medium mr-2" formControlName="owner">
                            <option disabled selected hidden value="">Owner</option>
                            <option *ngFor="let o of ownerDropdown" [value]="o">{{o}}</option>
                        </select>
                        <select class="input-field form-control medium ml-0 mr-2" formControlName="designation">
                            <option disabled selected hidden value="">Designation</option>
                            <option *ngFor="let o of designationDropdown" [value]="o">{{o}}</option>
                        </select> -->
                        <input class="input-field form-control medium mr-2" type="text" formControlName="owner"
                            placeholder="Owner" />
                        <input class="input-field form-control medium ml-0 mr-2" type="text"
                            formControlName="designation" placeholder="Designation" />
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer" (click)="deleteField('keyManagementTeam',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addKeyManagementTeam()">
                            <span class="add-icon">+</span> Add</button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('keyManagementTeam', true)" class="error">
                {{errorShow('keyManagementTeam', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="10" label="Board of Directors">
                <div class="d-flex align-items-center mb-2" formArrayName="boardOfDirectors"
                    *ngFor="let item of boardOfDirectors.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <input class="input-field form-control short mr-2" type="text" formControlName="name"
                            placeholder="Name" />
                        <input class="input-field form-control short ml-0 mr-2" type="text" formControlName="promotor"
                            placeholder="Promoter" />
                        <input class="input-field form-control short ml-0 mr-2" type="text" formControlName="dinNumber"
                            placeholder="DIN number" />
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer" (click)="deleteField('boardOfDirectors',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addBoardOfDirector()">
                            <span class="add-icon">+</span> Add</button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('boardOfDirectors', true)" class="error">
                {{errorShow('boardOfDirectors', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="11" label="Geography" formGroupName="geography">
                <div class="d-flex">
                    <select class="input-field form-control short" formControlName="region" (change)="makeStateEmpty()">
                        <option disabled selected hidden value="">Select Region</option>
                        <option *ngFor="let d of geoData | keyvalue" [value]="d.key">{{d.key}}</option>
                    </select>
                    <select class="input-field form-control short" formControlName="state">
                        <option disabled selected hidden value="">Select State</option>
                        <option *ngFor="let d of geoData[geography.value.region]" [value]="d">{{d}}</option>
                    </select>
                </div>
            </app-input>
            <span *ngIf="errorCheck('geography', true)" class="error">
                {{errorShow('geography', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="12" label="Sector">
                <select class="input-field form-control" formControlName="sector" (change)="makeSubSectorEmpty(); getSubSector()">
                    <option disabled selected hidden value="">Sector</option>
                    <option *ngFor="let d of sectorDropdown" [value]="d.id">{{d.text}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('sector')" class="error">
                {{errorShow('sector')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="13" label="Subsector">
                <select class="input-field form-control" formControlName="subSector">
                    <option disabled selected hidden value="">Sub Sector</option>
                    <option *ngFor="let d of subSectorDropdown" [value]="d.id">{{d.text}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('subSector')" class="error">
                {{errorShow('subSector')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="14" label="Business Description">
                <textarea class="input-field form-control textarea" formControlName="businessDescription"></textarea>
            </app-input>
            <span *ngIf="errorCheck('businessDescription')" class="error">
                {{errorShow('businessDescription')}}
            </span>
        </div>
        <span class="alert show alert-danger" *ngIf="submitted && (companyForm.invalid || fileUploadErr)">
            Resolve the above errors and Submit
        </span>
        <div class="action-area">
            <button class="btn btn-primary mr-2" type="submit">Submit</button>
            <button [routerLink]="['/dashboard']" class="btn btn-outline-primary" type="button">Cancel</button>
        </div>
    </form>
</div>