<div class="company-page-container">
    <app-header [companyName]="cmpName"></app-header>
    <form class="form" *ngIf="dealsForm" [formGroup]="dealsForm" (ngSubmit)="onSubmit()">
        <div class="input-container">
            <app-input serial="1" label="Date of Deal">
                <div class="ml-4">
                    <app-date-selector formGroupName="dateOfDeal"></app-date-selector>
                </div>
            </app-input>
        </div>
        <div class="input-container">
            <app-input serial="2" label="Deal Team">
                <div class="d-flex align-items-center mb-2" formArrayName="dealTeam"
                    *ngFor="let item of dealTeam.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <input class="input-field form-control medium mr-2" type="text" formControlName="name"
                            placeholder="Name" disabled />
                        <input class="input-field form-control medium ml-0 mr-2" type="text" formControlName="designation"
                            placeholder="Designation" disabled />
                        <!-- <select class="input-field form-control medium mr-2" formControlName="name">
                            <option disabled selected hidden value="">Owner</option>
                            <option *ngFor="let o of ownerDropdown" [value]="o">{{o}}</option>
                        </select>
                        <select class="input-field form-control medium ml-0 mr-2" formControlName="designation">
                            <option disabled selected hidden value="">Designation</option>
                            <option *ngFor="let o of designationDropdown" [value]="o">{{o}}</option>
                        </select>
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer"
                            (click)="deleteField('dealTeam',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addDealTeam()">
                            <span class="add-icon">+</span> Add</button> -->
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('dealTeam', true)" class="error">
                {{errorShow('dealTeam', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="3" label="Funding round by Stakeboat Capital">
                <select class="input-field form-control" formControlName="fundingRoundByCapital">
                    <option disabled selected hidden value="">First round</option>
                    <option *ngFor="let d of fundingRoundDropdown" [value]="d">{{d}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('fundingRoundByCapital')" class="error">
                {{errorShow('fundingRoundByCapital')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="4" label="Deal type">
                <select class="input-field form-control" formControlName="dealType">
                    <option *ngFor="let o of dealTypeDropdown" [value]="o">{{o}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('dealType')" class="error">
                {{errorShow('dealType')}}
            </span>
        </div>
        <!-- <div class="input-container">
            <app-input serial="1" label="Name of the Company">
                <input class="input-field form-control" type="text" formControlName="name" />
            </app-input>
        </div>
        <div class="input-container">
            <app-input serial="2" label="Name of the Owners" formGroupName="nameOfOwners">
                <div class="d-flex align-items-center mb-2">
                    <input class="input-field form-control medium" type="text" placeholder="Owner 1" formControlName="owner1" />
                    <input class="input-field form-control medium" type="text" placeholder="Owner 2" formControlName="owner2" />
                </div>
            </app-input>
        </div> -->
        <div class="input-container">
            <app-input serial="5" label="Investment type">
                <ng-container formGroupName="investmentType">
                    <select class="input-field form-control" formControlName="value">
                        <option *ngFor="let o of investmentTypeDropdown" [value]="o">{{o}}</option>
                    </select>
                    <input *ngIf="investmentType.value.value == 'Others'"
                        placeholder="Enter Investment Type"
                        class="others-input input-field form-control" type="text" 
                        formControlName="customValue" />
                </ng-container>
            </app-input>
            <span *ngIf="errorCheck('investmentType')" class="error">
                {{errorShow('investmentType')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="6" label="Existing Investors">
                <div class="d-flex align-items-center mb-2" formArrayName="existingInvestors"
                    *ngFor="let item of existingInvestors.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <input class="input-field form-control medium mr-2" type="text" formControlName="name"
                            placeholder="Name" />
                        <input class="input-field form-control medium ml-0 mr-2" type="text"
                            formControlName="shareholding" placeholder="Current shareholding %" />
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer"
                            (click)="deleteField('existingInvestors',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addExistingInvestors()">
                            <span class="add-icon">+</span> Add</button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('existingInvestors', true)" class="error">
                {{errorShow('existingInvestors', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="7" label="Total round size">
                <input class="input-field form-control" type="text" formControlName="totalRoundSize" />
            </app-input>
            <span *ngIf="errorCheck('totalRoundSize')" class="error">
                {{errorShow('totalRoundSize')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="8" label="Investment By Stakeboat Capital">
                <input class="input-field form-control" type="text" formControlName="investmentByStakeboatCapital" />
            </app-input>
            <span *ngIf="errorCheck('investmentByStakeboatCapital')" class="error">
                {{errorShow('investmentByStakeboatCapital')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="9" label="Stage of investment">
                <select class="input-field form-control" formControlName="stageOfInvestment">
                    <option *ngFor="let o of stagesOfInvestmentDropdown" [value]="o">{{o}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('stageOfInvestment')" class="error">
                {{errorShow('stageOfInvestment')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="6" label="Co Investors">
                <div class="d-flex align-items-center mb-2" formArrayName="coInvestors"
                    *ngFor="let item of coInvestors.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <input class="input-field form-control medium mr-2" type="text" formControlName="name"
                            placeholder="Name" />
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer"
                            (click)="deleteField('coInvestors',i)">
                            delete_outline
                        </mat-icon>
                        <button *ngIf="last" class="action-btn" type="button" (click)="addCoInvestors()">
                            <span class="add-icon">+</span> Add
                        </button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('coInvestors', true)" class="error">
                {{errorShow('coInvestors', true)}}
            </span>
        </div>
        <!-- <div class="input-container">
            <app-input serial="10" label="Co-investors">
                <input class="input-field form-control" type="text" formControlName="coInvestors" />
            </app-input>
        </div> -->
        <div class="input-container">
            <app-input serial="11" label="Lead Investor">
                <div class="d-flex">
                    <label class="radio-field"><input type="radio" formControlName="leadInvestor" value="y"
                            name="leadInvestor">Yes</label>
                    <label class="radio-field"><input type="radio" formControlName="leadInvestor" value="n"
                            name="leadInvestor">No</label>
                    <!-- <div class="fileupload">
                        <input id="leadInvestor" type='file' />
                        <span for="leadInvestor" class="action-btn browse-btn">Browse</span>
                    </div> -->
                </div>
            </app-input>
            <span *ngIf="errorCheck('leadInvestor')" class="error">
                {{errorShow('leadInvestor')}}
            </span>
            <!-- <span *ngIf="submitted && dealsForm.get('leadInvestor.isLeadInvestor').value && !dealsForm.get('leadInvestor.file').value" class="error">
                Please upload the file
            </span> -->
        </div>
        <div class="input-container">
            <app-input serial="12" label="Business model" class="">
                <label class="radio-field"><input type="radio" formControlName="businessModel" value="subscription"
                        name="businessModel">Subscription</label>
                <label class="radio-field"><input type="radio" formControlName="businessModel" value="general"
                        name="businessModel">General</label>
            </app-input>
            <p class="mt-2">Subscription means > 80% of the current revenue comes out of recurring subscriptions</p>
        </div>
        <div class="input-container">
            <app-input serial="13" label="Lead type">
                <select class="input-field form-control" formControlName="leadType">
                    <option *ngFor="let o of leadTypeDropdown" [value]="o">{{o}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('leadType')" class="error">
                {{errorShow('leadType')}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="14" label="Investment related documents">
                <div class="investmentDocuments d-flex align-items-center" formArrayName="investmentDocuments"
                    *ngFor="let item of investmentDocuments.controls; let i = index; let first = first; let last = last;">
                    <ng-container [formGroupName]="i">
                        <input class="input-field form-control medium mr-2" type="text" formControlName="documentTitle"
                            placeholder="Document Title"
                            />
                        <div class="fileupload">
                            <input id="doc-{{i}}" type='file' (change)="fileUploadHandler($event, i)"
                            [disabled]="!item.value.documentTitle || item.value.file"
                            />
                            <span for="doc-{{i}}" class="action-btn browse-btn">Browse</span>
                        </div>
                        <mat-icon *ngIf="!(first && last)" class="center-mat-icon cursor-pointer"
                            (click)="deleteField('investmentDocuments',i)">
                            delete_outline
                        </mat-icon>
                        <span *ngIf="item.value.file" class="uploaded-file-link">
                            <a [href]="item.value.file" target="_blank" rel="noopener noreferrer">
                                {{item.value.file.split("/").pop()}}
                            </a>
                            
                            <span style="position: absolute; padding-bottom: 3px; padding-left: 5px;">                        
                            <mat-icon class="center-mat-icon cursor-pointer" (click)="deleteFile('item')">
                                close
                            </mat-icon>                            
                            </span>
                        </span>
                        
                        <button *ngIf="last" class="action-btn  ml-2" type="button" (click)="addInvestmentDocuments()">
                            <span class="add-icon">+</span> Add</button>
                    </ng-container>
                </div>
            </app-input>
            <span *ngIf="errorCheck('investmentDocuments', true)" class="error">
                {{errorShow('investmentDocuments', true)}}
            </span>
        </div>
        <div class="input-container">
            <app-input serial="15" label="Status">
                <select class="input-field form-control" formControlName="status">
                    <option *ngFor="let o of statusDropdown" [value]="o">{{o}}</option>
                </select>
            </app-input>
            <span *ngIf="errorCheck('status')" class="error">
                {{errorShow('status')}}
            </span>
        </div>
        <div class="action-area">
            <button class="btn btn-primary mr-2" type="submit">Submit</button>
            <button [routerLink]="['/dashboard']" class="btn btn-outline-primary" type="button">Cancel</button>
        </div>
    </form>
</div>