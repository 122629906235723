export interface IFinancialInfo 
{
    financial_data_year:string;
    fin_dt_yr_0:number;
    fin_dt_yr_1:number;
    fin_dt_yr_2:number;
    fin_dt_yr_3:number;
    fin_dt_yr_4:number;
}

export class FinancialInfo implements IFinancialInfo
{
    financial_data_year:string;
    fin_dt_yr_0:number;
    fin_dt_yr_1:number;
    fin_dt_yr_2:number;
    fin_dt_yr_3:number;
    fin_dt_yr_4:number;

    constructor(dealDate: string) {
        this.financial_data_year = "FY Mar " + dealDate;
        let finyr = parseInt(dealDate);
        this.fin_dt_yr_0 = finyr;
        this.fin_dt_yr_1 = finyr - 2;
        this.fin_dt_yr_2 = finyr - 1;
        this.fin_dt_yr_3 = finyr + 1;
        this.fin_dt_yr_4 = finyr + 2;
    }
}