import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AddBusinessComponent } from './pages/add-business/add-business.component';
import { CompanyComponent } from './pages/company';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DealsComponent } from './pages/deals';
import { AuthGuard } from './_helpers';
import { CompanyFinancialComponent } from './company-financial/company-financial.component';
import { CompanyEntryComponent } from './companyentry/companyentry.component';
import { PromoterComponent } from './promoter/promoter.component';
import { UserentryComponent } from './userentry/userentry.component'
const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'company-financial', component: CompanyFinancialComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'addbusiness', component: AddBusinessComponent, canActivate: [AuthGuard] },
    { path: 'company/:cmpId', component: CompanyComponent, canActivate: [AuthGuard] },
    { path: 'deals/:dealId', component: DealsComponent, canActivate: [AuthGuard] },
    { path: 'companyentry/:id/:cmpId/:dealId', component: CompanyEntryComponent, canActivate: [AuthGuard] },
    { path: 'promoter/:cmpId/:dealId/:promId', component: PromoterComponent, canActivate: [AuthGuard] },
    { path: 'userentry/:id/:cmpId/:dealId', component: UserentryComponent, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });