import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-input',
    templateUrl: './input-wrapper.component.html',
    styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent implements OnInit {
    @Input() label: string;
    @Input() serial: number;

    constructor() { }

    ngOnInit() {
    }
}