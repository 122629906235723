<form  #fileuploadForm="ngForm">
<div class="row">
	<div class="col-md-6">
		<div class="form-group row">
		    <div class="col-lg-12">
				<input type="text" class="form-control" [(ngModel)]="fileName" name="fileName" placeholder="File Name"/>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="form-group row">
		    <div class="col-lg-12">
				<input class="form-control" type="file" [(ngModel)]="file" name="file" id="file" (change)="fileUploadHandler($event)" required>
			</div>
		</div>
	</div>
	
</div>
</form>