import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { StateManagement } from "./stateManager";
import { Observable } from "rxjs";
import { HttpServices } from "./httpServices";
import { ɵangular_packages_platform_browser_dynamic_platform_browser_dynamic_a } from "@angular/platform-browser-dynamic";
import { environment } from '@environments/environment';

@Injectable({ providedIn: "root" })
export class AppServices {


    constructor(private httpServices: HttpServices) {

    }

    // serviceUrls:
    url_execute = `${environment.apiUrl}` + "/execute";
    url_executeTest = `${environment.apiUrl}` + "/executeTest";

    url_execute_serverScan = `${environment.apiUrl}` + "/executeServerScan";
    url_execute_repoScan = `${environment.apiUrl}` + "/executeRepoScan";

    url_userPolling = `${environment.apiUrl}` + "/userPolling";

    url_execute_recenthistory = `${environment.apiUrl}` + "/usersslhistory";
    url_execute_gethistoryall = `${environment.apiUrl}` + "/getAllHistory";

    url_execute_detail = `${environment.apiUrl}` + "/userssldetail";
    url_getHtml = `${environment.apiUrl}` + "/getHtml/";

   
    url_execute_recent_records = `${environment.apiUrl}` + "/getRepoHistory";
    url_getRepoReports = `${environment.apiUrl}` + "/getresutdetails";

    url_postFiles = `${environment.apiUrl}` + "/fileUpload";

    url_uploadCompany = `${environment.apiUrl}` + "/user/business";
    url_getCompanyEntry = `${environment.apiUrl}` + "/user/business";

    url_getCompany = `${environment.apiUrl}` + "/company";

    uploadFiles(
        payload,
        cb: Function
    ){
        const body = "";
        console.log(payload);
        console.log(this.url_postFiles);
        this.httpServices.getHttpFilePost(this.url_postFiles, payload).subscribe(resp => {
            console.log(resp);
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };


    uploadCompanyEntry(
        payload,
        cb: Function
    ){
        this.httpServices.getHttpPost_Token(this.url_uploadCompany, payload).subscribe(resp => {
            console.log(resp);
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };

    getCompany(cmpId:number, cb: Function){
        this.httpServices.getHttpGetData(this.url_getCompany + `/${cmpId}`).subscribe(resp => {
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    }

    getCompanyEntry(cmpId:number, dealId:number, cb: Function){
        this.httpServices.getHttpGetData(this.url_getCompanyEntry + `/${cmpId}/${dealId}` + '/1').subscribe(resp => {
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    }

    getUserEntry(cmpId:number, dealId:number, cb: Function){
        this.httpServices.getHttpGetData(this.url_getCompanyEntry + `/${cmpId}/${dealId}` + '/2').subscribe(resp => {
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    }
    
    /*
    execute(
        payload,
        cb: Function
    ) {
        this.httpServices.getHttpPost(this.url_execute, payload).subscribe(resp => {
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };

    executeserverScan(
        payload,
        cb: Function
    ) {
        this.httpServices.getHttpPost(this.url_execute_serverScan, payload).subscribe(resp => {
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };

    executerecenthistory(
        payload,
        cb: Function
    ) {
        this.httpServices.getHttpPost(this.url_execute_recenthistory, payload).subscribe(resp => {
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };

    getContent(url,
        cb: Function
    ) {
        this.httpServices.getHttpGetContent(url).subscribe(resp => {
            console.log(resp);
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };


    getHtmlFileForServerScan(id,
        cb: Function
    ) {
        this.httpServices.getHttpGetContent(this.url_getHtml + id).subscribe(resp => {
            console.log(resp);
            if (resp.status == this.httpServices.status_success) {
                if (cb) {
                    cb(resp.body, null);
                }
            }
        });
    };

    */

}