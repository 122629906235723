import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef,  Output,  EventEmitter } from '@angular/core';
import { AppServices } from '../../common/appServices';
@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {
	fileName: string;
	file: string;
	@Input('version') version;
    @Input('companyId') companyId;
   	@Input('dealId') dealId;
	@Output() onSelectValue = new EventEmitter<{version: string, fileURL:string}>();
  constructor(
  	private appservices: AppServices
  ) { }

  ngOnInit() {
  }

  fileUploadHandler(event) {
		const formData = new FormData();
		formData.append('strFileInput', event.target.files[0], event.target.files[0].name);
    	formData.append('strFileName', this.fileName);
      	formData.append('strUploadFolder', this.fileName);	
		  this.appservices.uploadFiles(formData, (res, err) => {			
			console.log(res.response);
			this.onSelectValue.emit({version: this.version, fileURL: res.response.path});
			this.fileName = '';
			this.file = '';
		});
	}


}
