import { Component, ViewChild, OnInit, CUSTOM_ELEMENTS_SCHEMA, PipeTransform, Pipe, ElementRef } from '@angular/core';
import { AppServices } from '../common/appServices';
import { Injectable, Renderer2, RendererFactory2} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DomService } from '../_services/dom.service';
import { FileuploadComponent } from '../components/fileupload/fileupload.component';
import { CurrencyMaskInputMode } from "ngx-currency";

import { IFinancialInfo, FinancialInfo } from './models/financial-info';
import { IPromoterInfo, PromoterInfo } from '../companyentry/models/promoter-info';

import { AuthenticationService } from '@app/_services';
@Component({
  selector: 'app-companyentry',
  templateUrl: './companyentry.component.html',
  styleUrls: ['./companyentry.component.scss']
})
export class CompanyEntryComponent implements OnInit {
 
 currencyType: any = [{'id': 1, 'value' : '$'}, {'id': 2, 'value' : '₹'}]
 inputPercentageConfig = {prefix: ' ', suffix: ' %', thousands: '', decimal: '.', inputMode: CurrencyMaskInputMode.NATURAL}
 inputRatioConfig = {prefix: '', suffix: '', thousands: '', decimal: '.', inputMode: CurrencyMaskInputMode.NATURAL}
 inputCurrencyConfig = {prefix: ' ', allowNegative: true, suffix: '', thousands: ',', decimal: '.', inputMode: CurrencyMaskInputMode.NATURAL};
 inputCurrencyConfigWODecimal = {prefix: ' ', suffix: '', thousands: ',', inputMode: CurrencyMaskInputMode.NATURAL};
//  inputPercentageConfig = {suffix: ' %', thousands: '', decimal: '.'}
 private notification: string

 page_1: string = "active";
 page_2: string = "";
 page_3: string = "";
 page_4: string = "";
 fileversion: number = 1;

 financial_data_year:string="";
 fin_dt_yr_0:number;
 fin_dt_yr_1:number;
 fin_dt_yr_2:number;
 fin_dt_yr_3:number;
 fin_dt_yr_4:number;

 financialInfo: IFinancialInfo;

 con_net_rev_y1:number;
 ebitda_y1:number;
 ebitda_rev_y1:string="";
 int_y1:number;
 dep_amo_y1:number;
 other_inc_y1:number;
 pro_bef_tax_y1:string="";
 pbt_rev_y1:string="";
 taxes_y1:string="";
 pat_y1:string="";
 pat_rev_y1:string="";
 
 con_net_rev_y2:number;
 ebitda_y2:number;
 ebitda_rev_y2:string="";
 int_y2:number;
 dep_amo_y2:number;
 other_inc_y2:number;
 pro_bef_tax_y2:string="";
 pbt_rev_y2:string="";
 taxes_y2:string="";
 pat_y2:string="";
 pat_rev_y2:string="";	
 
 con_net_rev_y3:number;
 ebitda_y3:number;
 ebitda_rev_y3:string="";
 int_y3:number;
 dep_amo_y3:number;
 other_inc_y3:number;
 pro_bef_tax_y3:string="";
 pbt_rev_y3:string="";
 taxes_y3:string="";
 pat_y3:string="";
 pat_rev_y3:string="";
 
 con_net_rev_y4:number;
 ebitda_y4:number;
 ebitda_rev_y4:string="";
 int_y4:number;
 dep_amo_y4:number;
 other_inc_y4:number;
 pro_bef_tax_y4:string="";
 pbt_rev_y4:string="";
 taxes_y4:string="";
 pat_y4:string="";
 pat_rev_y4:string="";
 
 con_net_rev_y5:number;
 ebitda_y5:number;
 ebitda_rev_y5:string="";
 int_y5:number;
 dep_amo_y5:number;
 other_inc_y5:number;
 pro_bef_tax_y5:string="";
 pbt_rev_y5:string="";
 taxes_y5:string="";
 pat_y5:string="";
 pat_rev_y5:string="";
 


 info_month:number;
 info_year:number;
 no_of_months: number;
 con_net_rev_ytd:number;
 ebitda_ytd:number;
 ebitda_rev_ytd:string="";
 int_ytd:number;
 dep_amo_ytd:number;
 other_inc_ytd:number;
 pro_bef_tax_ytd:string="";
 pbt_rev_ytd:string="";
 taxes_ytd:string="";
 pat_ytd:string="";
 pat_rev_ytd:string="";


 comp_file_1:string="";
 comp_file_2:string="";

 

 inventory:string="";
 receivables:string="";
 int_cov_ratio:number;

 free_cash_flow_y1:string="";
 free_cash_flow_y2:string="";
 free_cash_flow_months:string="";

 revenue_cgar:number;

 debt_equity_ratio:string="";

 total_debt_y1:number;
 equity_y1:string="";
 net_worth_y1:number;
 de_ratio_y1:number;

 total_debt_y2:number;
 equity_y2:string="";
 net_worth_y2:number;
 de_ratio_y2:number;

 total_debt_y3:number;
 equity_y3:string="";
 net_worth_y3:number;
 de_ratio_y3:number;

 total_debt_y4:number;
 equity_y4:string="";
 net_worth_y4:number;
 de_ratio_y4:number;

 total_debt_y5:number;
 equity_y5:string="";
 net_worth_y5:number;
 de_ratio_y5:number;

 debt_leverage: any = false;
 ebitdaRevPer:number;

 //Page 2 Variables

 raw_materials_year:string="";

 raw_materials_y1:number;
 raw_materials_y2:number;
 raw_materials_y3:number;
 raw_materials_y4:number;
 raw_materials_y5:number;

 top_customer1_name: string="";
 top_customer2_name: string="";
 top_customer3_name: string="";
 top_customer4_name: string="";
 top_customer5_name: string="";

 top_customer1_name_per: string="";
 top_customer2_name_per: string="";
 top_customer3_name_per: string="";
 top_customer4_name_per: string="";
 top_customer5_name_per: string="";

 top_customer1_y1:number;
 top_customer2_y1:number;
 top_customer3_y1:number;
 top_customer4_y1:number;
 top_customer5_y1:number;

 top_customer1_y2:number;
 top_customer2_y2:number;
 top_customer3_y2:number;
 top_customer4_y2:number;
 top_customer5_y2:number;

 top_customer1_m1:number;
 top_customer2_m1:number;
 top_customer3_m1:number;
 top_customer4_m1:number;
 top_customer5_m1:number;

 top_customer1_rev_m1:string="";
 top_customer2_rev_m1:string="";
 top_customer3_rev_m1:string="";
 top_customer4_rev_m1:string="";
 top_customer5_rev_m1:string="";

 top_customer1_rev_y1:number=0;
 top_customer2_rev_y1:number=0;
 top_customer3_rev_y1:number=0;
 top_customer4_rev_y1:number=0;
 top_customer5_rev_y1:number=0;

 top_customer1_rev_y2:number=0;
 top_customer2_rev_y2:number=0;
 top_customer3_rev_y2:number=0;
 top_customer4_rev_y2:number=0;
 top_customer5_rev_y2:number=0;

 top_customer_total_y1: number = 0;
 top_customer_total_y2: number = 0;

 top_customer_conc_y1: number = 0;
 top_customer_conc_y2: number = 0;

 top_customer_stick_y1: number = 0;
 top_customer_stick_y2: number = 0;

 top_supplier1_name: string="";
 top_supplier2_name: string="";
 top_supplier3_name: string="";
 top_supplier4_name: string="";
 top_supplier5_name: string="";

 top_supplier1_name_per: string="";
 top_supplier2_name_per: string="";
 top_supplier3_name_per: string="";
 top_supplier4_name_per: string="";
 top_supplier5_name_per: string="";

 top_supplier1_y1:number;
 top_supplier2_y1:number;
 top_supplier3_y1:number;
 top_supplier4_y1:number;
 top_supplier5_y1:number;

 top_supplier1_y2:number;
 top_supplier2_y2:number;
 top_supplier3_y2:number;
 top_supplier4_y2:number;
 top_supplier5_y2:number;

 top_supplier1_m1:number;
 top_supplier2_m1:number;
 top_supplier3_m1:number;
 top_supplier4_m1:number;
 top_supplier5_m1:number;

 top_supplier1_rev_m1:number=0;
 top_supplier2_rev_m1:number=0;
 top_supplier3_rev_m1:number=0;
 top_supplier4_rev_m1:number=0;
 top_supplier5_rev_m1:number=0;

 top_supplier1_rev_y1:number=0;
 top_supplier2_rev_y1:number=0;
 top_supplier3_rev_y1:number=0;
 top_supplier4_rev_y1:number=0;
 top_supplier5_rev_y1:number=0;

 top_supplier1_rev_y2:number=0;
 top_supplier2_rev_y2:number=0;
 top_supplier3_rev_y2:number=0;
 top_supplier4_rev_y2:number=0;
 top_supplier5_rev_y2:number=0;

 top_supplier_total_y1: number=0;
 top_supplier_total_y2: number=0;

 top_supplier_conc_y1: number;
 top_supplier_conc_y2: number;

 business_file_1:string="";
 business_file_2:string="";

 potential_supplier_risk: boolean = false;
 alternate_suppliers: boolean = false;
 competitive_pricing_industry: string = "";
 risk_of_disruption: boolean = false;
 potential_regulatory_risk: boolean = false;
 net_currency_risk: number;
 market_size: number;

 expert_ref_name1: string="";
 expert_ref_company1: string = "";
 expert_ref_relevance1: string = "";
 expert_ref_feedback1: string = "";

 expert_ref_name2: string="";
 expert_ref_company2: string = "";
 expert_ref_relevance2: string = "";
 expert_ref_feedback2: string = "";

 expert_ref_name3: string="";
 expert_ref_company3: string = "";
 expert_ref_relevance3: string = "";
 expert_ref_feedback3: string = "";

 expert_ref_name4: string="";
 expert_ref_company4: string = "";
 expert_ref_relevance4: string = "";
 expert_ref_feedback4: string = "";

 expert_ref_name5: string="";
 expert_ref_company5: string = "";
 expert_ref_relevance5: string = "";
 expert_ref_feedback5: string = "";

 business_model: boolean = false;
 business_model_desc: string = "";

 enterprise_val_year : string = "";
 
 enterprise_val_y1: number;
 enterprise_val_y2: number;
 enterprise_val_y3: number;
 enterprise_val_y4: number;
 enterprise_val_y5: number;

 enter_ebitda_y1 : number;
 enter_ebitda_y2 : number;
 enter_ebitda_y3 : number;
 enter_ebitda_y4 : number;
 enter_ebitda_y5 : number;

 ebitda_mult_y1 : number;
 ebitda_mult_y2 : number;
 ebitda_mult_y3 : number;
 ebitda_mult_y4 : number;
 ebitda_mult_y5 : number;

 ebitda_debt_y1 : number;
 ebitda_debt_y2 : number;
 ebitda_debt_y3 : number;
 ebitda_debt_y4 : number;
 ebitda_debt_y5 : number;

 ebitda_cash_y1 : number;
 ebitda_cash_y2 : number;
 ebitda_cash_y3 : number;
 ebitda_cash_y4 : number;
 ebitda_cash_y5 : number;

 ebitda_equity_y1 : number;
 ebitda_equity_y2 : number;
 ebitda_equity_y3 : number;
 ebitda_equity_y4 : number;
 ebitda_equity_y5 : number;

 capital_investment : number;
 capital_investment_desc : string = "";

 capital_equity : number;
 captial_equity_desc : string = "";

 irr_val : number;
 irr_desc : string = "";

 exit_month: number;
 exit_year : number;
 exit_desc : string = "";

 cash_on_cash : string = "";
 cash_on_cash_desc : string = "";

 drag_rights: boolean = false;
 drag_rights_desc : string = "";

 liquid_pref : boolean = false;
 liquid_pref_val : number;
 liquid_pref_desc : string = "";

 unique_rights : boolean = false;
 unique_rights_desc : string = "";

 strategic_buyers : boolean = false;
 strategic_buyers_desc : string = "";
 sub:string = "";
 id: number = 0;
 companyId: number = 0;
 dealId: number = 0;
 promoId: number = 0;
 promoScore: number = 0;
 promId: string = "";

 promoId1: number = 0;
 promoId2: number = 0;
 promoId3: number = 0;

 promoter_det:string = "";
 promoter_det2:string = "";
 promoter_det3:string = "";

 fin_info_arr: any = [];
 fin_year_todate: any = [];
 fin_info_files: any = [];
 free_cashflow_arr : any = [];
 debt_equity_arr: any = [];


 raw_material_arr: any = [];
 top_5_customer_arr: any = [];
 top_5_supplier_arr: any = [];
 fin_bus_files: any = [];
 expert_ref_arr: any = [];


 finInfo_finArr: any = [];
 busInfo_finarr: any = [];
 invInfo_finarr: any = [];
 proInfo_finarr: any = [];

 companyEn:any = [];


 finInfo_stArr: any = [];
 busInfo_stArr: any = [];
 invInfo_stArr: any = [];
 proInfo_stArr: any = [];
 displayName: string;
 compName: string = "";
 deaDt: string = "";

 dealTeam: any = [];

 proShow1: boolean = true;
 proShow2: boolean = false;
 proShow3: boolean = false;

 fileShow1: boolean = true;
 fileShow2: boolean = false;
 fileShow3: boolean = false;
 fileShow4: boolean = false;
 fileShow5: boolean = false;
 fileShow6: boolean = false;
 fileShow7: boolean = false;
 fileShow8: boolean = false;
 fileShow9: boolean = false;
 fileShow10: boolean = false;

 file2Show1: boolean = true;
 file2Show2: boolean = false;
 file2Show3: boolean = false;
 file2Show4: boolean = false;
 file2Show5: boolean = false;
 file2Show6: boolean = false;
 file2Show7: boolean = false;
 file2Show8: boolean = false;
 file2Show9: boolean = false;
 file2Show10: boolean = false;

 fileList1: boolean = false;
 fileList2: boolean = false;
 fileList3: boolean = false;
 fileList4: boolean = false;
 fileList5: boolean = false;
 fileList6: boolean = false;
 fileList7: boolean = false;
 fileList8: boolean = false;
 fileList9: boolean = false;
 fileList10: boolean = false;

 fileUplo1: boolean = false;
 fileUplo2: boolean = false;
 fileUplo3: boolean = false;
 fileUplo4: boolean = false;
 fileUplo5: boolean = false;
 fileUplo6: boolean = false;
 fileUplo7: boolean = false;
 fileUplo8: boolean = false;
 fileUplo9: boolean = false;
 fileUplo10: boolean = false;

 file2Uplo1: boolean = false;
 file2Uplo2: boolean = false;
 file2Uplo3: boolean = false;
 file2Uplo4: boolean = false;
 file2Uplo5: boolean = false;
 file2Uplo6: boolean = false;
 file2Uplo7: boolean = false;
 file2Uplo8: boolean = false;
 file2Uplo9: boolean = false;
 file2Uplo10: boolean = false;

 uploadedFiles2: any = [];
 uploadedFiles: any = [];
 uploadedFiles1: any = [];

 promoterArr: any = [];

 promoterData: any = [];

 @ViewChild('filewrapper', { static: false }) filewrapper: ElementRef;
 constructor(
  	private appservices: AppServices,
  	private renderer: Renderer2,
  	private route: ActivatedRoute,
  	private router: Router,
  	private domService: DomService, private authenticationService: AuthenticationService
 ){ 
 	
  }

 ngOnInit(){
	
 	let user = localStorage.getItem('currentUser');
  	this.displayName = user && JSON.parse(user).userName;
    let dealDate = localStorage.getItem('dealDate').split("-");
    let company = localStorage.getItem('selectedCompany');
    this.compName = localStorage.getItem('selectedCompany');
    this.deaDt = localStorage.getItem('dealDate');

    this.financialInfo = new FinancialInfo(dealDate[0]);

    this.financial_data_year = "FY Mar " + dealDate[0];
    let finyr = parseInt(dealDate[0]);
    this.fin_dt_yr_0 = finyr;
    this.fin_dt_yr_1 = finyr - 2;
    this.fin_dt_yr_2 = finyr - 1;
    this.fin_dt_yr_3 = finyr + 1;
    this.fin_dt_yr_4 = finyr + 2;

	this.updateEbitda();


  this.debt_equity_ratio = this.financial_data_year;
  this.enterprise_val_year = this.financial_data_year;
  	this.route.params.subscribe(params => {
	   	this.id = +params['id']; // (+) converts string 'id' to a number
	   	this.companyId = +params['cmpId'];
	   	this.dealId = +params['dealId'];
	   	// In a real app: dispatch action to load the details here.
	   	if(this.id == 1) {
	   		this.page_1 = 'active';
	   		this.page_2 = '';
	   		this.page_3 = '';
	   		this.page_4 = '';
	   	}
	   	else if(this.id == 2) {
	   		this.page_1 = '';
	   		this.page_2 = 'active';
	   		this.page_3 = '';
	   		this.page_4 = '';
	   }
	   else if(this.id == 3) {
	   		this.page_1 = '';
	   		this.page_2 = '';
	   		this.page_3 = 'active';
	   		this.page_4 = '';
	   }
	   else if(this.id == 4) {
	   		this.page_1 = '';
	   		this.page_2 = '';
	   		this.page_3 = '';
	   		this.page_4 = 'active';

	   		/*this.promId = localStorage.getItem("promoterid").toString();
		   	if(this.promId != '') {
		   		console.log(this.promoterArr);
		   		if(this.promId == '0'){		   			
					this.promoterArr[0].promo_score = parseInt(localStorage.getItem("promoterScore"));
		   		}
		   		else if(this.promId == '1') {
		   			this.promoterArr[1].promo_score = parseInt(localStorage.getItem("promoterScore"));
		   		}
		   		else if(this.promId == '2') {
		   			this.promoterArr[2].promo_score = parseInt(localStorage.getItem("promoterScore"));
		   		}
		   	}*/
	   }
	});

	this.appservices.getCompanyEntry(this.companyId, this.dealId, (res, err) => {
		if(res.response.data.length > 0){
			this.companyEn = JSON.parse(res.response.data[0].JSON);
			console.log(this.companyEn.rev_data);
			console.log(this.companyEn);
			this.finInfo_stArr = this.companyEn.rev_data[0].cat_data[0];
			this.busInfo_stArr = this.companyEn.rev_data[1].cat_data[0];
			this.invInfo_stArr = this.companyEn.rev_data[2].cat_data[0];
			this.proInfo_stArr = this.companyEn.rev_data[3].cat_data[0];
			if (this.companyEn.pro_data.length > 0) {
				this.promoterData = this.companyEn.pro_data;
			}
			this.updateFinancialInfo(this.finInfo_stArr);
			this.updateBusinessInfo(this.busInfo_stArr);
			this.updateInvestmentInfo(this.invInfo_stArr);
			this.updatePromoterInfo(this.proInfo_stArr);

			this.updateEbitda();
		}
	});

	this.appservices.getCompany(this.companyId, (res, err) => {
		
		var dealDta = res.response.data[0].dealTeam;
		for(var k = 0; k< dealDta.length; k++){
			this.dealTeam.push({'id': k, 'name':dealDta[k].name, 'designation': dealDta[k].designation});
		}
	});
 }

updateEbitda(){
	this.enter_ebitda_y1 = this.ebitda_y1;
	this.enter_ebitda_y2 = this.ebitda_y2;
	this.enter_ebitda_y3 = this.ebitda_y3;
	this.enter_ebitda_y4 = this.ebitda_y4;
	this.enter_ebitda_y5 = this.ebitda_y5;

	this.ebitda_debt_y1 = this.dep_amo_y1;
	this.ebitda_debt_y2 = this.dep_amo_y2;
	this.ebitda_debt_y3 = this.dep_amo_y3;
	this.ebitda_debt_y4 = this.dep_amo_y4;
	this.ebitda_debt_y5 = this.dep_amo_y5;
}

updateFinancialInfo(arr) {
 	//this.fin_dt_yr_1 = arr.financial_data_year;

 	this.con_net_rev_y1 = arr.financial_data[0].data.consolidated_net_revenue;
 	this.ebitda_y1 = arr.financial_data[0].data.ebitda;
 	this.ebitda_rev_y1 = arr.financial_data[0].data.ebitda_to_rev;
 	this.int_y1 = arr.financial_data[0].data.interest;
 	this.dep_amo_y1 = arr.financial_data[0].data.depreciation;
 	this.other_inc_y1 = arr.financial_data[0].data.other_income;
 	this.pro_bef_tax_y1 = arr.financial_data[0].data.profit_before_tax;
 	this.pbt_rev_y1 = arr.financial_data[0].data.pbt_to_revenue;
 	this.taxes_y1 = arr.financial_data[0].data.taxes;
 	this.pat_y1 = arr.financial_data[0].data.profit_after_tax;
 	this.pat_rev_y1 = arr.financial_data[0].data.pat_to_revenue;

 	this.con_net_rev_y2 = arr.financial_data[1].data.consolidated_net_revenue;
 	this.ebitda_y2 = arr.financial_data[1].data.ebitda;
 	this.ebitda_rev_y2 = arr.financial_data[1].data.ebitda_to_rev;
 	this.int_y2 = arr.financial_data[1].data.interest;
 	this.dep_amo_y2 = arr.financial_data[1].data.depreciation;
 	this.other_inc_y2 = arr.financial_data[1].data.other_income;
 	this.pro_bef_tax_y2 = arr.financial_data[1].data.profit_before_tax;
 	this.pbt_rev_y2 = arr.financial_data[1].data.pbt_to_revenue;
 	this.taxes_y2 = arr.financial_data[1].data.taxes;
 	this.pat_y2 = arr.financial_data[1].data.profit_after_tax;
 	this.pat_rev_y2 = arr.financial_data[1].data.pat_to_revenue;

 	this.con_net_rev_y3 = arr.financial_data[2].data.consolidated_net_revenue;
 	this.ebitda_y3 = arr.financial_data[2].data.ebitda;
 	this.ebitda_rev_y3 = arr.financial_data[2].data.ebitda_to_rev;
 	this.int_y3 = arr.financial_data[2].data.interest;
 	this.dep_amo_y3 = arr.financial_data[2].data.depreciation;
 	this.other_inc_y3 = arr.financial_data[2].data.other_income;
 	this.pro_bef_tax_y3 = arr.financial_data[2].data.profit_before_tax;
 	this.pbt_rev_y3 = arr.financial_data[2].data.pbt_to_revenue;
 	this.taxes_y3 = arr.financial_data[2].data.taxes;
 	this.pat_y3 = arr.financial_data[2].data.profit_after_tax;
 	this.pat_rev_y3 = arr.financial_data[2].data.pat_to_revenue;

 	this.con_net_rev_y4 = arr.financial_data[3].data.consolidated_net_revenue;
 	this.ebitda_y4 = arr.financial_data[3].data.ebitda;
 	this.ebitda_rev_y4 = arr.financial_data[3].data.ebitda_to_rev;
 	this.int_y4 = arr.financial_data[3].data.interest;
 	this.dep_amo_y4 = arr.financial_data[3].data.depreciation;
 	this.other_inc_y4 = arr.financial_data[3].data.other_income;
 	this.pro_bef_tax_y4 = arr.financial_data[3].data.profit_before_tax;
 	this.pbt_rev_y4 = arr.financial_data[3].data.pbt_to_revenue;
 	this.taxes_y4 = arr.financial_data[3].data.taxes;
 	this.pat_y4 = arr.financial_data[3].data.profit_after_tax;
 	this.pat_rev_y4 = arr.financial_data[3].data.pat_to_revenue;

 	this.con_net_rev_y5 = arr.financial_data[4].data.consolidated_net_revenue;
 	this.ebitda_y5 = arr.financial_data[4].data.ebitda;
 	this.ebitda_rev_y5 = arr.financial_data[4].data.ebitda_to_rev;
 	this.int_y5 = arr.financial_data[4].data.interest;
 	this.dep_amo_y5 = arr.financial_data[4].data.depreciation;
 	this.other_inc_y5 = arr.financial_data[4].data.other_income;
 	this.pro_bef_tax_y5 = arr.financial_data[4].data.profit_before_tax;
 	this.pbt_rev_y5 = arr.financial_data[4].data.pbt_to_revenue;
 	this.taxes_y5 = arr.financial_data[4].data.taxes;
 	this.pat_y5 = arr.financial_data[4].data.profit_after_tax;
 	this.pat_rev_y5 = arr.financial_data[4].data.pat_to_revenue;

 	this.info_month = arr.fin_info_year_to_date[0].period_end_month;
 	this.info_year = arr.fin_info_year_to_date[0].period_end_year;
 	this.no_of_months = arr.fin_info_year_to_date[0].period_months;

 	this.con_net_rev_ytd = arr.fin_info_year_to_date[0].data.consolidated_net_revenue;
 	this.ebitda_ytd = arr.fin_info_year_to_date[0].data.ebitda;
 	this.ebitda_rev_ytd = arr.fin_info_year_to_date[0].data.ebitda_to_rev;
 	this.int_ytd = arr.fin_info_year_to_date[0].data.interest;
 	this.dep_amo_ytd = arr.fin_info_year_to_date[0].data.depreciation;
 	this.other_inc_ytd = arr.fin_info_year_to_date[0].data.other_income;
 	this.pro_bef_tax_ytd = arr.fin_info_year_to_date[0].data.profit_before_tax;
 	this.pbt_rev_ytd = arr.fin_info_year_to_date[0].data.pbt_to_revenue;
 	this.taxes_ytd = arr.fin_info_year_to_date[0].data.taxes;
 	this.pat_ytd = arr.fin_info_year_to_date[0].data.profit_after_tax;
 	this.pat_rev_ytd = arr.fin_info_year_to_date[0].data.pat_to_revenue;

	this.financial_data_year = arr.financial_data_year;
 	this.inventory = arr.inventory_count;
 	this.receivables = arr.receivables_count;
 	this.int_cov_ratio = arr.interest_coverage_ratio;

 	this.free_cash_flow_y1 = arr.free_cash_flow[0].cash_flow;
 	this.free_cash_flow_y2 = arr.free_cash_flow[1].cash_flow
 	this.free_cash_flow_months = arr.free_cash_flow[2].cash_flow

 	this.total_debt_y1 = arr.debit_equity_ratio[0].data.total_debt;
 	this.equity_y1 = arr.debit_equity_ratio[0].data.equity;
 	this.net_worth_y1 = arr.debit_equity_ratio[0].data.net_worth;
 	this.de_ratio_y1 = arr.debit_equity_ratio[0].data.de_ratio;

 	this.total_debt_y2 = arr.debit_equity_ratio[1].data.total_debt;
 	this.equity_y2 = arr.debit_equity_ratio[1].data.equity;
 	this.net_worth_y2 = arr.debit_equity_ratio[1].data.net_worth;
 	this.de_ratio_y2 = arr.debit_equity_ratio[1].data.de_ratio;

 	this.total_debt_y3 = arr.debit_equity_ratio[2].data.total_debt;
 	this.equity_y3 = arr.debit_equity_ratio[2].data.equity;
 	this.net_worth_y3 = arr.debit_equity_ratio[2].data.net_worth;
 	this.de_ratio_y3 = arr.debit_equity_ratio[2].data.de_ratio;

 	this.total_debt_y4 = arr.debit_equity_ratio[3].data.total_debt;
 	this.equity_y4 = arr.debit_equity_ratio[3].data.equity;
 	this.net_worth_y4 = arr.debit_equity_ratio[3].data.net_worth;
 	this.de_ratio_y4 = arr.debit_equity_ratio[3].data.de_ratio;

 	this.total_debt_y5 = arr.debit_equity_ratio[4].data.total_debt;
 	this.equity_y5 = arr.debit_equity_ratio[4].data.equity;
 	this.net_worth_y5 = arr.debit_equity_ratio[4].data.net_worth;
 	this.de_ratio_y5 = arr.debit_equity_ratio[4].data.de_ratio;
 	
 	this.revenue_cgar = arr.revenue_cagr;
 	this.debt_leverage = arr.leverage_structured
 	/*if(arr.leverage_structured == "true"){
 		this.debt_leverage = true;
 	}
 	else {
 		this.debt_leverage = false;
 	}*/
 	if(arr.files.length > 0) {
 		for(var i=0; i<arr.files.length; i++){
 			this.uploadedFiles[i] = arr.files[i];
			var leng = this.uploadedFiles.length;
			if( leng == 1) {
				this.fileUplo1 = true;
			}
 			if(i == 1) {
 				this.fileShow2 = true;
				this.fileUplo2 = true;
 			}
 			else if(i == 2) {
 				this.fileShow3 = true;
				this.fileUplo3 = true;
 			}
 			else if(i == 3) {
 				this.fileShow4 = true;
				this.fileUplo4 = true;
 			}
 			else if(i == 4) {
 				this.fileShow5 = true;
				this.fileUplo5 = true;
 			}
 			else if(i == 5) {
 				this.fileShow6 = true;
				this.fileUplo6 = true;
 			}
 			else if(i == 6) {
 				this.fileShow7 = true;
				this.fileUplo7 = true;
 			}
 			else if(i == 7) {
 				this.fileShow8 = true;
				this.fileUplo8 = true;
 			}
 			else if(i == 8) {
 				this.fileShow9 = true;
				this.fileUplo9 = true;
 			}
 			else if(i == 9) {
 				this.fileShow10 = true;
				this.fileUplo10 = true;
 			}
 		}
 	}
 	else {
 		this.uploadedFiles = [];
 	}
	this.updateEbitda();
	console.log(this.uploadedFiles)
}

updateBusinessInfo(arr) {
	this.raw_materials_y1 = arr.raw_material_costs[0].costs;
	this.raw_materials_y2 = arr.raw_material_costs[1].costs;
	this.raw_materials_y3 = arr.raw_material_costs[2].costs;
	this.raw_materials_y4 = arr.raw_material_costs[3].costs;
	this.raw_materials_y5 = arr.raw_material_costs[4].costs;


	this.top_customer1_name = arr.top_5_customers[0].data[0].customer_name;

	this.top_customer1_y1 = arr.top_5_customers[0].data[0].cost_data[0].revenue;
	this.top_customer1_y2 = arr.top_5_customers[0].data[0].cost_data[1].revenue;
	this.top_customer1_m1 = arr.top_5_customers[0].data[0].cost_data[2].revenue;

	this.top_customer1_rev_y1 = arr.top_5_customers[0].data[0].per_data[0].revenue;
	this.top_customer1_rev_y2 = arr.top_5_customers[0].data[0].per_data[1].revenue;
	this.top_customer1_rev_m1 = arr.top_5_customers[0].data[0].per_data[2].revenue;

	this.top_customer2_name = arr.top_5_customers[0].data[1].customer_name;

	this.top_customer2_y1 = arr.top_5_customers[0].data[1].cost_data[0].revenue;
	this.top_customer2_y2 = arr.top_5_customers[0].data[1].cost_data[1].revenue;
	this.top_customer2_m1 = arr.top_5_customers[0].data[1].cost_data[2].revenue;
	
	this.top_customer2_rev_y1 = arr.top_5_customers[0].data[1].per_data[0].revenue;
	this.top_customer2_rev_y2 = arr.top_5_customers[0].data[1].per_data[1].revenue;
	this.top_customer2_rev_m1 = arr.top_5_customers[0].data[1].per_data[2].revenue;

	this.top_customer3_name = arr.top_5_customers[0].data[2].customer_name;

	this.top_customer3_y1 = arr.top_5_customers[0].data[2].cost_data[0].revenue;
	this.top_customer3_y2 = arr.top_5_customers[0].data[2].cost_data[1].revenue;
	this.top_customer3_m1 = arr.top_5_customers[0].data[2].cost_data[2].revenue;
	
	this.top_customer3_rev_y1 = arr.top_5_customers[0].data[2].per_data[0].revenue;
	this.top_customer3_rev_y2 = arr.top_5_customers[0].data[2].per_data[1].revenue;
	this.top_customer3_rev_m1 = arr.top_5_customers[0].data[2].per_data[2].revenue;

	this.top_customer4_name = arr.top_5_customers[0].data[3].customer_name;

	this.top_customer4_y1 = arr.top_5_customers[0].data[3].cost_data[0].revenue;
	this.top_customer4_y2 = arr.top_5_customers[0].data[3].cost_data[1].revenue;
	this.top_customer4_m1 = arr.top_5_customers[0].data[3].cost_data[2].revenue;
	
	this.top_customer4_rev_y1 = arr.top_5_customers[0].data[3].per_data[0].revenue;
	this.top_customer4_rev_y2 = arr.top_5_customers[0].data[3].per_data[1].revenue;
	this.top_customer4_rev_m1 = arr.top_5_customers[0].data[3].per_data[2].revenue;

	this.top_customer5_name = arr.top_5_customers[0].data[4].customer_name;

	this.top_customer5_y1 = arr.top_5_customers[0].data[4].cost_data[0].revenue;
	this.top_customer5_y2 = arr.top_5_customers[0].data[4].cost_data[1].revenue;
	this.top_customer5_m1 = arr.top_5_customers[0].data[4].cost_data[2].revenue;
	
	this.top_customer5_rev_y1 = arr.top_5_customers[0].data[4].per_data[0].revenue;
	this.top_customer5_rev_y2 = arr.top_5_customers[0].data[4].per_data[1].revenue;
	this.top_customer5_rev_m1 = arr.top_5_customers[0].data[4].per_data[2].revenue;

	this.top_customer_total_y1 = arr.top_5_customers[0].total_y1;
	this.top_customer_total_y2 = arr.top_5_customers[0].total_y2;

	this.top_customer_conc_y1 = arr.top_5_customers[0].concentration;

	this.top_customer_stick_y1 = arr.top_5_customers[0].stickness;

	this.top_customer1_name_per = arr.top_5_customers[0].data[0].customer_name + " in %";
	this.top_customer2_name_per = arr.top_5_customers[0].data[1].customer_name + " in %";
	this.top_customer3_name_per = arr.top_5_customers[0].data[2].customer_name + " in %";
	this.top_customer4_name_per = arr.top_5_customers[0].data[3].customer_name + " in %";
	this.top_customer5_name_per = arr.top_5_customers[0].data[4].customer_name + " in %";

	this.top_supplier1_name = arr.top_5_suppliers[0].data[0].supplier_name;
	this.top_supplier1_y1 = arr.top_5_suppliers[0].data[0].cost_data[0].revenue;
	this.top_supplier1_y2 = arr.top_5_suppliers[0].data[0].cost_data[1].revenue;
	this.top_supplier1_m1 = arr.top_5_suppliers[0].data[0].cost_data[2].revenue;

	this.top_supplier1_rev_y1 = arr.top_5_suppliers[0].data[0].per_data[0].revenue;
	this.top_supplier1_rev_y2 = arr.top_5_suppliers[0].data[0].per_data[1].revenue;
	this.top_supplier1_rev_m1 = arr.top_5_suppliers[0].data[0].per_data[2].revenue;

	this.top_supplier2_name = arr.top_5_suppliers[0].data[1].supplier_name;

	this.top_supplier2_y1 = arr.top_5_suppliers[0].data[1].cost_data[0].revenue;
	this.top_supplier2_y2 = arr.top_5_suppliers[0].data[1].cost_data[1].revenue;
	this.top_supplier2_m1 = arr.top_5_suppliers[0].data[1].cost_data[2].revenue;
	
	this.top_supplier2_rev_y1 = arr.top_5_suppliers[0].data[1].per_data[0].revenue;
	this.top_supplier2_rev_y2 = arr.top_5_suppliers[0].data[1].per_data[1].revenue;
	this.top_supplier2_rev_m1 = arr.top_5_suppliers[0].data[1].per_data[2].revenue;

	this.top_supplier3_name = arr.top_5_suppliers[0].data[2].supplier_name;

	this.top_supplier3_y1 = arr.top_5_suppliers[0].data[2].cost_data[0].revenue;
	this.top_supplier3_y2 = arr.top_5_suppliers[0].data[2].cost_data[1].revenue;
	this.top_supplier3_m1 = arr.top_5_suppliers[0].data[2].cost_data[2].revenue;
	
	this.top_supplier3_rev_y1 = arr.top_5_suppliers[0].data[2].per_data[0].revenue;
	this.top_supplier3_rev_y2 = arr.top_5_suppliers[0].data[2].per_data[1].revenue;
	this.top_supplier3_rev_m1 = arr.top_5_suppliers[0].data[2].per_data[2].revenue;

	this.top_supplier4_name = arr.top_5_suppliers[0].data[3].supplier_name;

	this.top_supplier4_y1 = arr.top_5_suppliers[0].data[3].cost_data[0].revenue;
	this.top_supplier4_y2 = arr.top_5_suppliers[0].data[3].cost_data[1].revenue;
	this.top_supplier4_m1 = arr.top_5_suppliers[0].data[3].cost_data[2].revenue;
	
	this.top_supplier4_rev_y1 = arr.top_5_suppliers[0].data[3].per_data[0].revenue;
	this.top_supplier4_rev_y2 = arr.top_5_suppliers[0].data[3].per_data[1].revenue;
	this.top_supplier4_rev_m1 = arr.top_5_suppliers[0].data[3].per_data[2].revenue;

	this.top_supplier5_name = arr.top_5_suppliers[0].data[4].supplier_name;

	this.top_supplier5_y1 = arr.top_5_suppliers[0].data[4].cost_data[0].revenue;
	this.top_supplier5_y2 = arr.top_5_suppliers[0].data[4].cost_data[1].revenue;
	this.top_supplier5_m1 = arr.top_5_suppliers[0].data[4].cost_data[2].revenue;
	
	this.top_supplier5_rev_y1 = arr.top_5_suppliers[0].data[4].per_data[0].revenue;
	this.top_supplier5_rev_y2 = arr.top_5_suppliers[0].data[4].per_data[1].revenue;
	this.top_supplier5_rev_m1 = arr.top_5_suppliers[0].data[4].per_data[2].revenue;

	this.top_supplier_total_y1 = arr.top_5_suppliers[0].total_y1;
	this.top_supplier_total_y2 = arr.top_5_suppliers[0].total_y2;

	this.top_supplier_conc_y1 = arr.top_5_suppliers[0].concentration;

	this.top_supplier1_name_per = arr.top_5_suppliers[0].data[0].supplier_name + " in %";
	this.top_supplier2_name_per = arr.top_5_suppliers[0].data[1].supplier_name + " in %";
	this.top_supplier3_name_per = arr.top_5_suppliers[0].data[2].supplier_name + " in %";
	this.top_supplier4_name_per = arr.top_5_suppliers[0].data[3].supplier_name + " in %";
	this.top_supplier5_name_per = arr.top_5_suppliers[0].data[4].supplier_name + " in %";

	this.potential_supplier_risk = arr.potential_risk_high_price;
	this.alternate_suppliers = arr.alternative_suppliers_available;
	this.competitive_pricing_industry = arr.competitive_price_impact
	this.risk_of_disruption = arr.risk_of_disruption;
	this.net_currency_risk = arr.net_currency_risk
	this.potential_regulatory_risk = arr.potential_regulatory_risk;
	this.market_size = arr.market_size;

	this.expert_ref_name1 = arr.reference_with_experts[0].expert_name;
	this.expert_ref_company1 = arr.reference_with_experts[0].company;
	this.expert_ref_relevance1 = arr.reference_with_experts[0].relevance;
	this.expert_ref_feedback1 = arr.reference_with_experts[0].feedback;

	this.expert_ref_name2 = arr.reference_with_experts[1].expert_name;
	this.expert_ref_company2 = arr.reference_with_experts[1].company;
	this.expert_ref_relevance2 = arr.reference_with_experts[1].relevance;
	this.expert_ref_feedback2 = arr.reference_with_experts[1].feedback;

	this.expert_ref_name3 = arr.reference_with_experts[2].expert_name;
	this.expert_ref_company3 = arr.reference_with_experts[2].company;
	this.expert_ref_relevance3 = arr.reference_with_experts[2].relevance;
	this.expert_ref_feedback3 = arr.reference_with_experts[2].feedback;

	this.expert_ref_name4 = arr.reference_with_experts[3].expert_name;
	this.expert_ref_company4 = arr.reference_with_experts[3].company;
	this.expert_ref_relevance4 = arr.reference_with_experts[3].relevance;
	this.expert_ref_feedback4 = arr.reference_with_experts[3].feedback;

	this.expert_ref_name5 = arr.reference_with_experts[4].expert_name;
	this.expert_ref_company5 = arr.reference_with_experts[4].company;
	this.expert_ref_relevance5 = arr.reference_with_experts[4].relevance;
	this.expert_ref_feedback5 = arr.reference_with_experts[4].feedback;

	if(arr.files.length > 0) {
 		for(var i=0; i<arr.files.length; i++){
 			this.uploadedFiles1[i] = arr.files[i];
			if(this.uploadedFiles1.length == 1) {
				this.file2Uplo1 = true;
			}
 			if(i == 1) {
 				this.file2Show2 = true;
				this.file2Uplo2 = true;
 			}
 			else if(i == 2) {
 				this.file2Show3 = true;
				 this.file2Uplo3 = true;
 			}
 			else if(i == 3) {
 				this.file2Show4 = true;
				 this.file2Uplo4 = true;
 			}
 			else if(i == 4) {
 				this.file2Show5 = true;
				 this.file2Uplo5 = true;
 			}
 			else if(i == 5) {
 				this.file2Show6 = true;
				 this.file2Uplo6 = true;
 			}
 			else if(i == 6) {
 				this.file2Show7 = true;
				 this.file2Uplo7 = true;
 			}
 			else if(i == 7) {
 				this.file2Show8 = true;
				 this.file2Uplo8 = true;
 			}
 			else if(i == 8) {
 				this.file2Show9 = true;
				 this.file2Uplo9 = true;
 			}
 			else if(i == 9) {
 				this.file2Show10 = true;
				 this.file2Uplo10 = true;
 			}
 		}
 	}
 	else {
 		this.uploadedFiles1 = [];
 	}
}

updateInvestmentInfo(arr) {

	this.business_model = arr.business_model;
	this.business_model_desc = arr.business_desc;
	this.capital_investment = arr.stake_capital_invest;
	this.capital_equity = arr.stake_capital_equity;
	this.irr_val = arr.internal_rate_return;
	this.exit_month = arr.exit_month;
	this.exit_year = arr.exit_year;
	this.cash_on_cash = arr.cash_on_cash_multiple;
	this.drag_rights = arr.drag_rights;
	this.liquid_pref = arr.liquidation_preference;
	this.unique_rights = arr.unique_rights_negotiated;
	this.strategic_buyers = arr.intern_strategic_buyers;
	this.enterprise_val_year = arr.enterpise_val_year;

	this.enterprise_val_y1 = arr.enterprise_valuation[0].enterprise_valuation;
	this.enter_ebitda_y1 = arr.enterprise_valuation[0].ebitda;
	this.ebitda_mult_y1 = arr.enterprise_valuation[0].ebitda_multiple;
	this.ebitda_debt_y1 = arr.enterprise_valuation[0].debt;
	this.ebitda_cash_y1 = arr.enterprise_valuation[0].cash;
	this.ebitda_equity_y1 = arr.enterprise_valuation[0].equity_valuation;

	this.enterprise_val_y2 = arr.enterprise_valuation[1].enterprise_valuation;
	this.enter_ebitda_y2 = arr.enterprise_valuation[1].ebitda;
	this.ebitda_mult_y2 = arr.enterprise_valuation[1].ebitda_multiple;
	this.ebitda_debt_y2 = arr.enterprise_valuation[1].debt;
	this.ebitda_cash_y2 = arr.enterprise_valuation[1].cash;
	this.ebitda_equity_y2 = arr.enterprise_valuation[1].equity_valuation;

	this.enterprise_val_y3 = arr.enterprise_valuation[2].enterprise_valuation;
	this.enter_ebitda_y3 = arr.enterprise_valuation[2].ebitda;
	this.ebitda_mult_y3 = arr.enterprise_valuation[2].ebitda_multiple;
	this.ebitda_debt_y3 = arr.enterprise_valuation[2].debt;
	this.ebitda_cash_y3 = arr.enterprise_valuation[2].cash;
	this.ebitda_equity_y3 = arr.enterprise_valuation[2].equity_valuation;

	this.enterprise_val_y4 = arr.enterprise_valuation[3].enterprise_valuation;
	this.enter_ebitda_y4 = arr.enterprise_valuation[3].ebitda;
	this.ebitda_mult_y4 = arr.enterprise_valuation[3].ebitda_multiple;
	this.ebitda_debt_y4 = arr.enterprise_valuation[3].debt;
	this.ebitda_cash_y4 = arr.enterprise_valuation[3].cash;
	this.ebitda_equity_y4 = arr.enterprise_valuation[3].equity_valuation;

	this.enterprise_val_y5 = arr.enterprise_valuation[4].enterprise_valuation;
	this.enter_ebitda_y5 = arr.enterprise_valuation[4].ebitda;
	this.ebitda_mult_y5 = arr.enterprise_valuation[4].ebitda_multiple;
	this.ebitda_debt_y5 = arr.enterprise_valuation[4].debt;
	this.ebitda_cash_y5 = arr.enterprise_valuation[4].cash;
	this.ebitda_equity_y5 = arr.enterprise_valuation[4].equity_valuation;

}

updatePromoterInfo(arr) {
	console.log(arr);
	var promIntData =  {
		id: 0,
		promo_id: this.promoId1,
		promo_score: 0,
	};
	
	if(this.promId != '') {
		if(arr.promoter_data.length > 0) {
			for(var i=0; i<arr.promoter_data.length; i++ ){
				console.log(arr.promoter_data[i]);
				this.promoterArr[i] = arr.promoter_data[i];
				if(i == 0){
					this.promoId1 = arr.promoter_data[i].promo_id;
					this.proShow1 = true;
				}
				else if(i == 1) {
					this.promoId2 = arr.promoter_data[i].promo_id;
					this.proShow2 = true;
				}
				else if(i == 2) {
					this.promoId3 = parseInt(this.promId);
					this.proShow3 = true;
				}
			}
   		}
   		if(this.promId == '0'){
   			console.log(parseInt(localStorage.getItem("promoterScore")));
   			//this.promoId1 = parseInt(this.promId);
   			
			this.promoterArr[0].promo_score = parseInt(localStorage.getItem("promoterScore"));
			//this.promoterArr.push(promIntData);
   		}
   		else if(this.promId == '1') {
   			//this.promoId2 = parseInt(this.promId);
   			this.promoterArr[1].promo_score = parseInt(localStorage.getItem("promoterScore"));
   		}
   		else if(this.promId == '2') {
   			//this.promoId3 = parseInt(this.promId);
   			this.promoterArr.promo_score = parseInt(localStorage.getItem("promoterScore"));
   		}
   	}
   	else {
   		if(arr.promoter_data.length <= 0) {
			this.promoterArr.push(promIntData);
		}
		else {
			for(var i=0; i<arr.promoter_data.length; i++ ){
				this.promoterArr[i] = arr.promoter_data[i];
				if(i == 0){
					this.promoId1 = arr.promoter_data[i].promo_id;
					this.proShow1 = true;
				}
				else if(i == 1) {
					this.promoId2 = arr.promoter_data[i].promo_id;
					this.proShow2 = true;
				}
				else if(i == 2) {
					this.promoId3 =  arr.promoter_data[i].promo_id;
					this.proShow3 = true;
				}
			}
		}
   	}
}

updateData(){
	this.assignFinancialInfo();
	this.assignBusInfo();
	this.assignInvInfo();
	this.assignProInfo();
}

changeCusName(event, cou){
	if(cou == 1) {
		this.top_customer1_name_per = this.top_customer1_name + " in %";
	}
	else if(cou == 2) {
		this.top_customer2_name_per = this.top_customer2_name + " in %";
	}
	else if(cou == 3) {
		this.top_customer3_name_per = this.top_customer3_name + " in %";
	}
	else if(cou == 4) {
		this.top_customer4_name_per = this.top_customer4_name + " in %";
	}
	else if(cou == 5) {
		this.top_customer5_name_per = this.top_customer5_name + " in %";
	}
}

changeSupName(event, cou){
	if(cou == 1) {
		this.top_supplier1_name_per = this.top_supplier1_name + " in %";
	}
	else if(cou == 2) {
		this.top_supplier2_name_per = this.top_supplier2_name + " in %";
	}
	else if(cou == 3) {
		this.top_supplier3_name_per = this.top_supplier3_name + " in %";
	}
	else if(cou == 4) {
		this.top_supplier4_name_per = this.top_supplier4_name + " in %";
	}
	else if(cou == 5) {
		this.top_supplier5_name_per = this.top_supplier5_name + " in %";
	}
}

onRevChange(event, year) {
	console.log(event);
  	var ebitda_rev_per = 0;
  	if(year == 'y1') {
  		ebitda_rev_per = ((this.ebitda_y1 / this.con_net_rev_y1)*100);
  		this.ebitda_rev_y1 = (Math.round(ebitda_rev_per * 100) / 100) + '';
		this.calcPBT(event, 'y1');
  	}
  	else if(year == 'y2') {
  		ebitda_rev_per = ((this.ebitda_y2 / this.con_net_rev_y2)*100);
  		this.ebitda_rev_y2  = (Math.round(ebitda_rev_per * 100) / 100) + '';
		this.calcPBT(event, 'y2');
  	}
  	else if(year == 'y3') {
  		ebitda_rev_per = ((this.ebitda_y3 / this.con_net_rev_y3)*100);
  		this.ebitda_rev_y3 = (Math.round(ebitda_rev_per * 100) / 100) + '';

  		this.revenue_cgar = ((this.con_net_rev_y3/this.con_net_rev_y1)^(1/2));
		this.calcPBT(event, 'y3');
  	}
  	else if(year == 'y4') {
  		ebitda_rev_per = ((this.ebitda_y4 / this.con_net_rev_y4)*100);
  		this.ebitda_rev_y4 = (Math.round(ebitda_rev_per * 100) / 100) + '';
		this.calcPBT(event, 'y4');
  	}
  	else if(year == 'y5') {
  		ebitda_rev_per = ((this.ebitda_y5 / this.con_net_rev_y5)*100);
  		this.ebitda_rev_y5 = (Math.round(ebitda_rev_per * 100) / 100) + '';
		this.calcPBT(event, 'y5');
  	}
  	else if(year == 'y6') {
  		ebitda_rev_per = ((this.ebitda_ytd / this.con_net_rev_ytd)*100);
  		this.ebitda_rev_ytd = (Math.round(ebitda_rev_per * 100) / 100) + '';
		this.calcPBT(event, 'y6');
  	}
}

calcPBT(event, year) {
  	var pbt_val = 0;
  	var pbt_per = 0;
  	if(year == 'y1') {
  		if(typeof(this.ebitda_y1) == 'string'){
  			this.ebitda_y1 = parseInt(this.ebitda_y1);
  		}
  		if(typeof(this.other_inc_y1) == 'string'){
  			this.other_inc_y1 = parseInt(this.other_inc_y1);
  		}
  		if(typeof(this.int_y1) == 'string'){
  			this.int_y1 = parseInt(this.int_y1);
  		}
  		if(typeof(this.dep_amo_y1) == 'string'){
  			this.dep_amo_y1 = parseInt(this.dep_amo_y1);
  		}
  		pbt_val = (this.ebitda_y1 + this.other_inc_y1 - this.int_y1 - this.dep_amo_y1);
  		pbt_per = ((pbt_val/this.con_net_rev_y1)*100);
  		this.pro_bef_tax_y1 = pbt_val.toString();
  		this.pbt_rev_y1 = (Math.round(pbt_per * 100) / 100) + '';
		this.calcPAT(event, 'y1');
  	}
  	else if(year == 'y2') {
  		if(typeof(this.ebitda_y2) == 'string'){
  			this.ebitda_y2 = parseInt(this.ebitda_y2);
  		}
  		if(typeof(this.other_inc_y2) == 'string'){
  			this.other_inc_y2 = parseInt(this.other_inc_y2);
  		}
  		if(typeof(this.int_y2) == 'string'){
  			this.int_y2 = parseInt(this.int_y2);
  		}
  		if(typeof(this.dep_amo_y2) == 'string'){
  			this.dep_amo_y2 = parseInt(this.dep_amo_y2);
  		}
  		pbt_val = (this.ebitda_y2 + this.other_inc_y2 - this.int_y2 - this.dep_amo_y2);
  		pbt_per = ((pbt_val/this.con_net_rev_y2)*100);
  		this.pro_bef_tax_y2 = pbt_val.toString();
  		this.pbt_rev_y2 = (Math.round(pbt_per * 100) / 100) + '';
		  this.calcPAT(event, 'y2');
  	}
  	else if(year == 'y3') {
  		if(typeof(this.ebitda_y3) == 'string'){
  			this.ebitda_y3 = parseInt(this.ebitda_y3);
  		}
  		if(typeof(this.other_inc_y3) == 'string'){
  			this.other_inc_y3 = parseInt(this.other_inc_y3);
  		}
  		if(typeof(this.int_y3) == 'string'){
  			this.int_y3 = parseInt(this.int_y3);
  		}
  		if(typeof(this.dep_amo_y3) == 'string'){
  			this.dep_amo_y3 = parseInt(this.dep_amo_y3);
  		}
  		pbt_val = (this.ebitda_y3 + this.other_inc_y3 - this.int_y3 - this.dep_amo_y3);
  		pbt_per = ((pbt_val/this.con_net_rev_y3)*100);
  		this.pro_bef_tax_y3 = pbt_val.toString();
  		this.pbt_rev_y3 = (Math.round(pbt_per * 100) / 100) + '';
  		this.int_cov_ratio = ((parseInt(this.pro_bef_tax_y3) + this.int_y3)/this.int_y3);
		this.calcPAT(event, 'y3');
  	}
  	else if(year == 'y4') {
  		if(typeof(this.ebitda_y4) == 'string'){
  			this.ebitda_y4 = parseInt(this.ebitda_y4);
  		}
  		if(typeof(this.other_inc_y4) == 'string'){
  			this.other_inc_y4 = parseInt(this.other_inc_y4);
  		}
  		if(typeof(this.int_y4) == 'string'){
  			this.int_y4 = parseInt(this.int_y4);
  		}
  		if(typeof(this.dep_amo_y4) == 'string'){
  			this.dep_amo_y4 = parseInt(this.dep_amo_y4);
  		}
  		pbt_val = (this.ebitda_y4 + this.other_inc_y4 - this.int_y4 - this.dep_amo_y4);
  		pbt_per = ((pbt_val/this.con_net_rev_y4)*100);
  		this.pro_bef_tax_y4 = pbt_val.toString();
  		this.pbt_rev_y4 = (Math.round(pbt_per * 100) / 100) + '';
		this.calcPAT(event, 'y4');
  	}
  	else if(year == 'y5') {
  		if(typeof(this.ebitda_y5) == 'string'){
  			this.ebitda_y5 = parseInt(this.ebitda_y5);
  		}
  		if(typeof(this.other_inc_y5) == 'string'){
  			this.other_inc_y5 = parseInt(this.other_inc_y5);
  		}
  		if(typeof(this.int_y5) == 'string'){
  			this.int_y5 = parseInt(this.int_y5);
  		}
  		if(typeof(this.dep_amo_y5) == 'string'){
  			this.dep_amo_y5 = parseInt(this.dep_amo_y5);
  		}
  		pbt_val = (this.ebitda_y5 + this.other_inc_y5 - this.int_y5 - this.dep_amo_y5);
  		pbt_per = ((pbt_val/this.con_net_rev_y5)*100);
  		this.pro_bef_tax_y5 = pbt_val.toString();
  		this.pbt_rev_y5 = (Math.round(pbt_per * 100) / 100) + '';
		this.calcPAT(event, 'y5');
  	}
  	else if(year == 'y6') {
  		if(typeof(this.ebitda_ytd) == 'string'){
  			this.ebitda_ytd = parseInt(this.ebitda_ytd);
  		}
  		if(typeof(this.other_inc_ytd) == 'string'){
  			this.other_inc_ytd = parseInt(this.other_inc_ytd);
  		}
  		if(typeof(this.int_ytd) == 'string'){
  			this.int_ytd = parseInt(this.int_ytd);
  		}
  		if(typeof(this.dep_amo_ytd) == 'string'){
  			this.dep_amo_ytd = parseInt(this.dep_amo_ytd);
  		}
  		pbt_val = (this.ebitda_ytd + this.other_inc_ytd - this.int_ytd - this.dep_amo_ytd);
  		pbt_per = ((pbt_val/this.con_net_rev_ytd)*100);
  		this.pro_bef_tax_ytd = pbt_val.toString();
  		this.pbt_rev_ytd = (Math.round(pbt_per * 100) / 100) + '';
  		this.calcPAT(event, 'y6');
  	}
}


calcPAT(event, year) {
  	var pat_val = 0;
  	var pat_per = 0;
  	if(year == 'y1') {
  		pat_val = (parseInt(this.pro_bef_tax_y1) - parseInt(this.taxes_y1));
  		pat_per = ((pat_val/this.con_net_rev_y1)*100);
  		this.pat_y1 = pat_val.toString();
  		this.pat_rev_y1 = (Math.round(pat_per * 100) / 100) + '';
  	}
  	else if(year == 'y2') {
  		pat_val = (parseInt(this.pro_bef_tax_y2) - parseInt(this.taxes_y2));
  		pat_per = ((pat_val/this.con_net_rev_y2)*100);
  		this.pat_y2 = pat_val.toString();
  		this.pat_rev_y2 = (Math.round(pat_per * 100) / 100) + '';
  	}
  	else if(year == 'y3') {
  		pat_val = (parseInt(this.pro_bef_tax_y3) - parseInt(this.taxes_y3));
  		pat_per = ((pat_val/this.con_net_rev_y3)*100);
  		this.pat_y3 = pat_val.toString();
  		this.pat_rev_y3 = (Math.round(pat_per * 100) / 100) + '';
  	}
  	else if(year == 'y4') {
  		pat_val = (parseInt(this.pro_bef_tax_y4) - parseInt(this.taxes_y4));
  		pat_per = ((pat_val/this.con_net_rev_y4)*100);
  		this.pat_y4 = pat_val.toString();
  		this.pat_rev_y4 = (Math.round(pat_per * 100) / 100) + '';
  	}
  	else if(year == 'y5') {
  		pat_val = (parseInt(this.pro_bef_tax_y5) - parseInt(this.taxes_y5));
  		pat_per = ((pat_val/this.con_net_rev_y5)*100);
  		this.pat_y5 = pat_val.toString();
  		this.pat_rev_y5 = (Math.round(pat_per * 100) / 100) + '';
  	}
  	else if(year == 'y6') {
  		pat_val = (parseInt(this.pro_bef_tax_ytd) - parseInt(this.taxes_ytd));
  		pat_per = ((pat_val/this.con_net_rev_ytd)*100);
  		this.pat_ytd = pat_val.toString();
  		this.pat_rev_ytd = (Math.round(pat_per * 100) / 100) + '';
  	}
}

fileUploadHandler(event, count, filename) {

	const formData = new FormData();
	formData.append('strFileInput', event.target.files[0], event.target.files[0].name);
	formData.append('strFileName', filename);
	
	this.appservices.uploadFiles(formData, (res, err) => {
		/*if(count == 1) {
			this.comp_file_1 = res.response.fileUploadURL;
		}
		else if(count == 2) {
			this.comp_file_2 = res.response.fileUploadURL;
		}
		else if(count == 3) {
			this.business_file_1 = res.response.fileUploadURL;
		}
		else if(count == 4) {
			this.business_file_2 = res.response.fileUploadURL;
		}*/
		console.log(res);
	});
}

calcDER(event, year) {
  	if(year == 'y1') {
  		this.de_ratio_y1 = ((this.total_debt_y1 / this.net_worth_y1));
  	}
  	else if(year == 'y2') {
  		this.de_ratio_y2 = ((this.total_debt_y2 / this.net_worth_y2));
  	}
  	else if(year == 'y3') {
  		this.de_ratio_y3 = ((this.total_debt_y3 / this.net_worth_y3));
  	}
  	else if(year == 'y4') {
  		this.de_ratio_y4 = ((this.total_debt_y4 / this.net_worth_y4));
  	}
  	else if(year == 'y5') {
  		this.de_ratio_y5 = ((this.total_debt_y5 / this.net_worth_y5));
  	}
}

addFinancialInfo() {
	this.page_1 = "";
	this.page_2 = "active";
	this.scrollToTop();
	console.log(this.page_1, this.page_2);
	
	console.log(this.finInfo_finArr);
	this.updateData();
	this.updateCompanyEntry();
}

assignFinancialInfo(){
	this.fin_info_arr = [
		{
			"year_coun": 1,
		    "year":this.fin_dt_yr_1,
		    "data":{
			  "consolidated_net_revenue":this.con_net_rev_y1,
			  "ebitda":this.ebitda_y1,
			  "ebitda_to_rev":this.ebitda_rev_y1,
			  "interest":this.int_y1,
			  "depreciation":this.dep_amo_y1,
			  "other_income":this.other_inc_y1,
			  "profit_before_tax":this.pro_bef_tax_y1,
			  "pbt_to_revenue":this.pbt_rev_y1,
			  "taxes":this.taxes_y1,
			  "profit_after_tax":this.pat_y1,
			  "pat_to_revenue":this.pat_rev_y1
		    }
		},
		{
			"year_coun": 2,
		    "year":this.fin_dt_yr_2,
		    "data":{
			  "consolidated_net_revenue":this.con_net_rev_y2,
			  "ebitda":this.ebitda_y2,
			  "ebitda_to_rev":this.ebitda_rev_y2,
			  "interest":this.int_y2,
			  "depreciation":this.dep_amo_y2,
			  "other_income":this.other_inc_y2,
			  "profit_before_tax":this.pro_bef_tax_y2,
			  "pbt_to_revenue":this.pbt_rev_y2,
			  "taxes":this.taxes_y2,
			  "profit_after_tax":this.pat_y2,
			  "pat_to_revenue":this.pat_rev_y2
		    }
		},
		{
			"year_coun": 3,
		    "year":this.fin_dt_yr_0,
		    "data":{
			  "consolidated_net_revenue":this.con_net_rev_y3,
			  "ebitda":this.ebitda_y3,
			  "ebitda_to_rev":this.ebitda_rev_y3,
			  "interest":this.int_y3,
			  "depreciation":this.dep_amo_y3,
			  "other_income":this.other_inc_y3,
			  "profit_before_tax":this.pro_bef_tax_y3,
			  "pbt_to_revenue":this.pbt_rev_y3,
			  "taxes":this.taxes_y3,
			  "profit_after_tax":this.pat_y3,
			  "pat_to_revenue":this.pat_rev_y3
		    }
		},
		{
			"year_coun": 4,
		    "year":this.fin_dt_yr_3,
		    "data":{
			  "consolidated_net_revenue":this.con_net_rev_y4,
			  "ebitda":this.ebitda_y4,
			  "ebitda_to_rev":this.ebitda_rev_y4,
			  "interest":this.int_y4,
			  "depreciation":this.dep_amo_y4,
			  "other_income":this.other_inc_y4,
			  "profit_before_tax":this.pro_bef_tax_y4,
			  "pbt_to_revenue":this.pbt_rev_y4,
			  "taxes":this.taxes_y4,
			  "profit_after_tax":this.pat_y4,
			  "pat_to_revenue":this.pat_rev_y4
		    }
		},
		{
			"year_coun": 5,
		    "year":this.fin_dt_yr_4,
		    "data":{
			  "consolidated_net_revenue":this.con_net_rev_y5,
			  "ebitda":this.ebitda_y5,
			  "ebitda_to_rev":this.ebitda_rev_y5,
			  "interest":this.int_y5,
			  "depreciation":this.dep_amo_y5,
			  "other_income":this.other_inc_y5,
			  "profit_before_tax":this.pro_bef_tax_y5,
			  "pbt_to_revenue":this.pbt_rev_y5,
			  "taxes":this.taxes_y5,
			  "profit_after_tax":this.pat_y5,
			  "pat_to_revenue":this.pat_rev_y5
		    }
		}
	];

	this.fin_year_todate = [
		{
			"period_end_month":this.info_month,
			"period_end_year":this.info_year,
			"period_months":this.no_of_months,
			"data":{
			  	"consolidated_net_revenue":this.con_net_rev_ytd,
			  	"ebitda":this.ebitda_ytd,
			  	"ebitda_to_rev":this.ebitda_rev_ytd,
			  	"interest":this.int_ytd,
			  	"depreciation":this.dep_amo_ytd,
			  	"other_income":this.other_inc_ytd,
			  	"profit_before_tax":this.pro_bef_tax_ytd,
			  	"pbt_to_revenue":this.pbt_rev_ytd,
			  	"taxes":this.taxes_ytd,
			  	"profit_after_tax":this.pat_ytd,
			  	"pat_to_revenue":this.pat_rev_ytd
		    }
		}
	];

	this.free_cashflow_arr = [
		{
			"period_end_month":this.fin_dt_yr_2,
			"cash_flow":this.free_cash_flow_y1
		},
		{
			"period_end_month":this.fin_dt_yr_0,
			"cash_flow":this.free_cash_flow_y2
		},
		{
			"period_months":"10 Months",
			"cash_flow":this.free_cash_flow_months
		}
	];

	this.debt_equity_arr = [
		{
			"year_coun": 1,
		    "year":this.fin_dt_yr_1,
		    "data":{
			    "total_debt":this.total_debt_y1,
			    "equity":this.equity_y1,
			    "net_worth":this.net_worth_y1,
			    "de_ratio":this.de_ratio_y1
			}
		},
		{
		    "year_coun": 2,
		    "year":this.fin_dt_yr_2,
		    "data":{
			    "total_debt":this.total_debt_y2,
			    "equity":this.equity_y2,
			    "net_worth":this.net_worth_y2,
			    "de_ratio":this.de_ratio_y2
			}
		},
		{
		    "year_coun": 3,
		    "year":this.fin_dt_yr_0,
		    "data":{
			    "total_debt":this.total_debt_y3,
			    "equity":this.equity_y3,
			    "net_worth":this.net_worth_y3,
			    "de_ratio":this.de_ratio_y3
			}
		},
		{
		    "year_coun": 4,
		    "year":this.fin_dt_yr_3,
		    "data":{
			    "total_debt":this.total_debt_y4,
			    "equity":this.equity_y4,
			    "net_worth":this.net_worth_y4,
			    "de_ratio":this.de_ratio_y4
			}
		},
		{
		    "year_coun": 5,
		    "year":this.fin_dt_yr_4,
		    "data":{
			    "total_debt":this.total_debt_y5,
			    "equity":this.equity_y5,
			    "net_worth":this.net_worth_y5,
			    "de_ratio":this.de_ratio_y5
			}
		}
	];

	this.finInfo_finArr = [
      	{
			"page_name" : "finanical_information",
			"financial_data_year" : this.financial_data_year,
			"financial_data":this.fin_info_arr,
			"fin_info_year_to_date":this.fin_year_todate,
			"files":this.uploadedFiles,
			"inventory_count":this.inventory,
			"receivables_count":this.receivables,
			"interest_coverage_ratio":this.int_cov_ratio,
			"free_cash_flow":this.free_cashflow_arr,
			"revenue_cagr":this.revenue_cgar,
			"debit_equity_ratio":this.debt_equity_arr,
			"leverage_structured":this.debt_leverage
	  	}
	];

}


selectValue(newValue : any) {
	var counter = this.fin_info_files.length;
	if(counter > 0) {
		this.fin_info_files[counter + 1] = newValue; 
	}
	else {
		this.fin_info_files[counter] = newValue;
	}
	var tempDt = {
		version: newValue.version,
		fileURL: newValue.fileURL
	}
	this.uploadedFiles.push(tempDt);
	this.checkFileUpload(newValue.version)
}

selectValue2(newValue : any) {
	
	var tempDt = {
		version: newValue.version,
		fileURL: newValue.fileURL
	}
	this.uploadedFiles1.push(tempDt);
	console.log(this.uploadedFiles1);
	this.checkFileUpload(newValue.version);
}



addUploadControl_old() {  		
	this.fileversion = this.fileversion + 1;
	if(this.fileversion <=10){
		var ver = {'version': this.fileversion.toString()};
		var fun = {'(onSelectValue)': "selectValue($event)"};
		var upIn = this.domService.createComponent(FileuploadComponent, ver, fun);
		this.domService.attachComponent(upIn, this.filewrapper.nativeElement)
	}
	else {
	console.log("Can't upload files");
	}
}

scrollToTop() {
    // let scrollToTop = window.setInterval(() => {
    //     let pos = window.pageYOffset;
    //     if (pos > 0) {
    //         window.scrollTo(0, pos - 20); // how far to scroll on each step
    //     } else {
    //         window.clearInterval(scrollToTop);
    //     }
	// }, .1);
	window.scrollTo(0, 0); // how far to scroll on each step
}

onCusRevChange(event, cus, year) {  		
	var cus_rev_per = 0;
	if(year == 'y1'){
		if(cus == 1) {
  		cus_rev_per = ((this.top_customer1_y1 / this.con_net_rev_y2)*100);
	  		this.top_customer1_rev_y1 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 2) {
	  		cus_rev_per = ((this.top_customer2_y1 / this.con_net_rev_y2)*100);
	  		this.top_customer2_rev_y1 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 3) {
	  		cus_rev_per = ((this.top_customer3_y1 / this.con_net_rev_y2)*100);
	  		this.top_customer3_rev_y1 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 4) {
	  		cus_rev_per = ((this.top_customer4_y1 / this.con_net_rev_y2)*100);
	  		this.top_customer4_rev_y1 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 5) {
	  		cus_rev_per = ((this.top_customer5_y1 / this.con_net_rev_y2)*100);
	  		this.top_customer5_rev_y1 = (Math.round(cus_rev_per * 100) / 100);
	  	}
  		this.top_customer_total_y1 = (this.top_customer1_rev_y1 + this.top_customer2_rev_y1+ this.top_customer3_rev_y1 + this.top_customer4_rev_y1 + this.top_customer5_rev_y1);
	}
	else if(year == 'y2') {
		if(cus == 1) {
  		cus_rev_per = ((this.top_customer1_y2 / this.con_net_rev_y3)*100);
  		this.top_customer1_rev_y2 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 2) {
	  		cus_rev_per = ((this.top_customer2_y2 / this.con_net_rev_y3)*100);
	  		this.top_customer2_rev_y2 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 3) {
	  		cus_rev_per = ((this.top_customer3_y2 / this.con_net_rev_y3)*100);
	  		this.top_customer3_rev_y2 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 4) {
	  		cus_rev_per = ((this.top_customer4_y2 / this.con_net_rev_y3)*100);
	  		this.top_customer4_rev_y2 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	else if(cus == 5) {
	  		cus_rev_per = ((this.top_customer5_y2 / this.con_net_rev_y3)*100);
	  		this.top_customer5_rev_y2 = (Math.round(cus_rev_per * 100) / 100);
	  	}
	  	this.top_customer_total_y2 = (this.top_customer1_rev_y2 + this.top_customer2_rev_y2 + this.top_customer3_rev_y2 + this.top_customer4_rev_y2 + this.top_customer5_rev_y2);
	}
	else if(year == 'm1') {
		if(cus == 1) {
	  		cus_rev_per = ((this.top_customer1_m1 / this.con_net_rev_ytd)*100);
	  		this.top_customer1_rev_m1 = (Math.round(cus_rev_per * 100) / 100) + '';
	  	}
	  	else if(cus == 2) {
	  		cus_rev_per = ((this.top_customer2_m1 / this.con_net_rev_ytd)*100);
	  		this.top_customer2_rev_m1 = (Math.round(cus_rev_per * 100) / 100) + '';
	  	}
	  	else if(cus == 3) {
	  		cus_rev_per = ((this.top_customer3_m1 / this.con_net_rev_ytd)*100);
	  		this.top_customer3_rev_m1 = (Math.round(cus_rev_per * 100) / 100) + '';
	  	}
	  	else if(cus == 4) {
	  		cus_rev_per = ((this.top_customer4_m1 / this.con_net_rev_ytd)*100);
	  		this.top_customer4_rev_m1 = (Math.round(cus_rev_per * 100) / 100) + '';
	  	}
	  	else if(cus == 5) {
	  		cus_rev_per = ((this.top_customer5_m1 / this.con_net_rev_ytd)*100);
	  		this.top_customer5_rev_m1 = (Math.round(cus_rev_per * 100) / 100) + '';
	  	}
	}
	this.top_customer_conc_y1 = ((this.top_customer_total_y1 + this.top_customer_total_y2) / 2);
	var cus1_data;
	if(typeof(this.top_customer1_y1) == 'string') {
		cus1_data = parseFloat(this.top_customer1_y1).toFixed(1);
	}
	this.top_customer_stick_y1 = ((this.top_customer1_rev_y1 + this.top_customer1_rev_y2 + this.top_customer2_rev_y1 + this.top_customer2_rev_y2)/2);
	
}

onSupRevChange(event, cus, year) {
	var sup_rev_per = 0;
	if(year == 'y1'){
		if(cus == 1) {
	  		sup_rev_per = ((this.top_supplier1_y1 / this.raw_materials_y2)*100);
	  		this.top_supplier1_rev_y1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 2) {
	  		sup_rev_per = ((this.top_supplier2_y1 / this.raw_materials_y2)*100);
	  		this.top_supplier2_rev_y1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 3) {
	  		sup_rev_per = ((this.top_supplier3_y1 / this.raw_materials_y2)*100);
	  		this.top_supplier3_rev_y1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 4) {
	  		sup_rev_per = ((this.top_supplier4_y1 / this.raw_materials_y2)*100);
	  		this.top_supplier4_rev_y1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 5) {
	  		sup_rev_per = ((this.top_supplier5_y1 / this.raw_materials_y2)*100);
	  		this.top_supplier5_rev_y1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	this.top_supplier_total_y1 = (this.top_supplier1_rev_y1 + this.top_supplier2_rev_y1 + this.top_supplier3_rev_y1 + this.top_supplier4_rev_y1 + this.top_supplier5_rev_y1);
	}
	else if(year == 'y2') {
		if(cus == 1) {
	  		sup_rev_per = ((this.top_supplier1_y2 / this.raw_materials_y3)*100);
	  		this.top_supplier1_rev_y2 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 2) {
	  		sup_rev_per = ((this.top_supplier2_y2 / this.raw_materials_y3)*100);
	  		this.top_supplier2_rev_y2 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 3) {
	  		sup_rev_per = ((this.top_supplier3_y2 / this.raw_materials_y3)*100);
	  		this.top_supplier3_rev_y2 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 4) {
	  		sup_rev_per = ((this.top_supplier4_y2 / this.raw_materials_y3)*100);
	  		this.top_supplier4_rev_y2 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 5) {
	  		sup_rev_per = ((this.top_supplier5_y2 / this.raw_materials_y3)*100);
	  		this.top_supplier5_rev_y2 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	this.top_supplier_total_y2 = (this.top_supplier1_rev_y2 + this.top_supplier2_rev_y2 + this.top_supplier3_rev_y2 + this.top_supplier4_rev_y2 + this.top_supplier5_rev_y2);
	}
	else if(year == 'm1') {
		if(cus == 1) {
	  		sup_rev_per = ((this.top_supplier1_m1 / this.raw_materials_y4)*100);
	  		this.top_supplier1_rev_m1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 2) {
	  		sup_rev_per = ((this.top_supplier2_m1 / this.raw_materials_y4)*100);
	  		this.top_supplier2_rev_m1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 3) {
	  		sup_rev_per = ((this.top_supplier3_m1 / this.raw_materials_y4)*100);
	  		this.top_supplier3_rev_m1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 4) {
	  		sup_rev_per = ((this.top_supplier4_m1 / this.raw_materials_y4)*100);
	  		this.top_supplier4_rev_m1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	  	else if(cus == 5) {
	  		sup_rev_per = ((this.top_supplier5_m1 / this.raw_materials_y4)*100);
	  		this.top_supplier5_rev_m1 = (Math.round(sup_rev_per * 100) / 100);
	  	}
	}
	this.top_supplier_conc_y1 = ((this.top_supplier_total_y1 + this.top_supplier_total_y2) / 2);
}

addBusinessInfo() {
	this.page_2 = "";
	this.page_3 = "active";
	this.scrollToTop();
	console.log(this.page_2, this.page_3);
	this.updateData();
	console.log(this.finInfo_finArr);
	this.updateCompanyEntry();
}

assignBusInfo(){
	this.raw_material_arr = [
		{
			"year_coun": 1,
			"year":this.fin_dt_yr_1,
            "costs":this.raw_materials_y1
		},
		{
			"year_coun": 2,
			"year":this.fin_dt_yr_2,
            "costs":this.raw_materials_y2
		},
		{
			"year_coun": 3,
			"year":this.fin_dt_yr_0,
            "costs":this.raw_materials_y3
		},
		{
			"year_coun": 4,
			"year":this.fin_dt_yr_3,
            "costs":this.raw_materials_y4
		},
		{
			"year_coun": 5,
			"year":this.fin_dt_yr_4,
            "costs":this.raw_materials_y5
		}
	];

	var customer_data = [
		{
			"customer_name":this.top_customer1_name,
           	"customer_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer1_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer1_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_customer1_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer1_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer1_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_customer1_rev_m1,
              	}
           	]
		},
		{
			"customer_name":this.top_customer2_name,
           	"customer_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer2_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer2_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_customer2_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer2_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer2_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_customer2_rev_m1,
              	}
           	]
		},
		{
			"customer_name":this.top_customer3_name,
           	"customer_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer3_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer3_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_customer3_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer3_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer3_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_customer3_rev_m1,
              	}
           	]
		},
		{
			"customer_name":this.top_customer4_name,
           	"customer_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer4_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer4_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_customer4_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer4_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer4_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_customer4_rev_m1,
              	}
           	]
		},
		{
			"customer_name":this.top_customer5_name,
           	"customer_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer5_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer5_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_customer5_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_customer5_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_customer5_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_customer5_rev_m1,
              	}
           	]
		}			
	];
	this.top_5_customer_arr = [
		{
			"total_y1":this.top_customer_total_y1,
			"total_y2":this.top_customer_total_y2,
			"concentration": this.top_customer_conc_y1,
			"stickness":this.top_customer_stick_y1,
			"data": customer_data
		}
	];
	var supplier_data = [
		{
			"supplier_name":this.top_supplier1_name,
           	"supplier_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier1_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier1_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_supplier1_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier1_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier1_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_supplier1_rev_m1,
              	}
           	]
		},
		{
			"supplier_name":this.top_supplier2_name,
           	"supplier_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier2_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier2_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_supplier2_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier2_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier2_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_supplier2_rev_m1,
              	}
           	]
		},
		{
			"supplier_name":this.top_supplier3_name,
           	"supplier_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier3_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier3_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_supplier3_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier3_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier3_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_supplier3_rev_m1,
              	}
           	]
		},
		{
			"supplier_name":this.top_supplier4_name,
           	"supplier_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier4_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier4_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_supplier4_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier4_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier4_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_supplier4_rev_m1,
              	}
           	]
		},
		{
			"supplier_name":this.top_supplier5_name,
           	"supplier_rank":1,
           	"cost_data":[
              	{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier5_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier5_y2,
              	},
              	{
              		"year_count":3,
                 	"year":"1 Month",
                 	"revenue": this.top_supplier5_m1,
              	}
           	],
           	"per_data":[
           		{
              		"year_count":1,
                 	"year":this.fin_dt_yr_2,
                 	"revenue": this.top_supplier5_rev_y1,
              	},
              	{
              		"year_count":2,
                 	"year":this.fin_dt_yr_0,
                 	"revenue": this.top_supplier5_rev_y2,
              	},
              	{
              		"year_count":3,
                 	"year": "1 Month",
                 	"revenue": this.top_supplier5_rev_m1,
              	}
           	]
		}			
	];
	this.top_5_supplier_arr = [
		{
			"total_y1":this.top_supplier_total_y1,
			"total_y2":this.top_supplier_total_y2,
			"concentration": this.top_supplier_conc_y1,
			"data": supplier_data
		}
	];

	this.expert_ref_arr = [
		{
			"expert_name": this.expert_ref_name1,
           	"company": this.expert_ref_company1,
           	"relevance": this.expert_ref_relevance1,
           	"feedback": this.expert_ref_feedback1
		},
		{
			"expert_name": this.expert_ref_name2,
           	"company": this.expert_ref_company2,
           	"relevance": this.expert_ref_relevance2,
           	"feedback": this.expert_ref_feedback2
		},
		{
			"expert_name": this.expert_ref_name3,
           	"company": this.expert_ref_company3,
           	"relevance": this.expert_ref_relevance3,
           	"feedback": this.expert_ref_feedback3
		},
		{
			"expert_name": this.expert_ref_name4,
           	"company": this.expert_ref_company4,
           	"relevance": this.expert_ref_relevance4,
           	"feedback": this.expert_ref_feedback4
		},
		{
			"expert_name": this.expert_ref_name5,
           	"company": this.expert_ref_company5,
           	"relevance": this.expert_ref_relevance5,
           	"feedback": this.expert_ref_feedback5
		}
	];

	this.busInfo_finarr = [
      	{
			"page_name" : "business_info",
	        "raw_material_costs": this.raw_material_arr,
	        "top_5_customers": this.top_5_customer_arr,		 
			"top_5_suppliers": this.top_5_supplier_arr,
	        "files":this.uploadedFiles1,
	        "potential_risk_high_price": this.potential_supplier_risk,
	        "alternative_suppliers_available": this.alternate_suppliers,
	        "competitive_price_impact": this.competitive_pricing_industry,
	        "risk_of_disruption": this.risk_of_disruption,
	        "net_currency_risk": this.net_currency_risk,
	        "potential_regulatory_risk": this.potential_regulatory_risk,
	        "market_size": this.market_size,
	        "reference_with_experts":this.expert_ref_arr
      	}
		];
}

onEVChange(event, year) {
	var ev_rev_per = 0;
		if(year == 'y1'){
			ev_rev_per = ((this.enterprise_val_y1 / this.enter_ebitda_y1));
	  	this.ebitda_mult_y1 = (Math.round(ev_rev_per * 100) / 100);
		}
		else if(year == 'y2'){
			ev_rev_per = ((this.enterprise_val_y2 / this.enter_ebitda_y2));
	  	this.ebitda_mult_y2 = (Math.round(ev_rev_per * 100) / 100);
		}
		else if(year == 'y3'){
			ev_rev_per = ((this.enterprise_val_y3 / this.enter_ebitda_y3));
	  	this.ebitda_mult_y3 = (Math.round(ev_rev_per * 100) / 100);
		}
		else if(year == 'y4'){
			ev_rev_per = ((this.enterprise_val_y4 / this.enter_ebitda_y4));
	  	this.ebitda_mult_y4 = (Math.round(ev_rev_per * 100) / 100);
		}
		else if(year == 'y5'){
			ev_rev_per = ((this.enterprise_val_y5 / this.enter_ebitda_y5));
	  	this.ebitda_mult_y5 = (Math.round(ev_rev_per * 100) / 100);
		}
}

onEVMChange(event, year) {
	var evm_rev_per = 0;
		if(year == 'y1'){
			if(typeof(this.enterprise_val_y1) == 'string'){
				this.enterprise_val_y1 = parseInt(this.enterprise_val_y1);
			}
			if(typeof(this.ebitda_debt_y1) == 'string'){
				this.ebitda_debt_y1 = parseInt(this.ebitda_debt_y1);
			}
			if(typeof(this.ebitda_cash_y1) == 'string'){
				this.ebitda_cash_y1 = parseInt(this.ebitda_cash_y1);
			}
			evm_rev_per = ((this.enterprise_val_y1 - this.ebitda_debt_y1 + this.ebitda_cash_y1));
	  	this.ebitda_equity_y1 = (Math.round(evm_rev_per * 100) / 100);
		}
		else if(year == 'y2'){
			if(typeof(this.enterprise_val_y2) == 'string'){
				this.enterprise_val_y2 = parseInt(this.enterprise_val_y2);
			}
			if(typeof(this.ebitda_debt_y2) == 'string'){
				this.ebitda_debt_y2 = parseInt(this.ebitda_debt_y2);
			}
			if(typeof(this.ebitda_cash_y2) == 'string'){
				this.ebitda_cash_y2 = parseInt(this.ebitda_cash_y2);
			}
			evm_rev_per = ((this.enterprise_val_y2 - this.ebitda_debt_y2 + this.ebitda_cash_y2));
	  	this.ebitda_equity_y2 = (Math.round(evm_rev_per * 100) / 100);
		}
		else if(year == 'y3'){
		if(typeof(this.enterprise_val_y3) == 'string'){
				this.enterprise_val_y3 = parseInt(this.enterprise_val_y3);
			}
			if(typeof(this.ebitda_debt_y3) == 'string'){
				this.ebitda_debt_y3 = parseInt(this.ebitda_debt_y3);
			}
			if(typeof(this.ebitda_cash_y3) == 'string'){
				this.ebitda_cash_y3 = parseInt(this.ebitda_cash_y3);
			}
			evm_rev_per = ((this.enterprise_val_y3 - this.ebitda_debt_y3 + this.ebitda_cash_y3));
	  	this.ebitda_equity_y3 = (Math.round(evm_rev_per * 100) / 100);
		}
		else if(year == 'y4'){
			if(typeof(this.enterprise_val_y4) == 'string'){
				this.enterprise_val_y4 = parseInt(this.enterprise_val_y4);
			}
			if(typeof(this.ebitda_debt_y4) == 'string'){
				this.ebitda_debt_y4 = parseInt(this.ebitda_debt_y4);
			}
			if(typeof(this.ebitda_cash_y4) == 'string'){
				this.ebitda_cash_y4 = parseInt(this.ebitda_cash_y4);
			}
			evm_rev_per = ((this.enterprise_val_y4 - this.ebitda_debt_y4 + this.ebitda_cash_y4));
	  	this.ebitda_equity_y4 = (Math.round(evm_rev_per * 100) / 100);
		}
		else if(year == 'y5'){
		if(typeof(this.enterprise_val_y5) == 'string'){
				this.enterprise_val_y5 = parseInt(this.enterprise_val_y5);
			}
			if(typeof(this.ebitda_debt_y5) == 'string'){
				this.ebitda_debt_y5 = parseInt(this.ebitda_debt_y5);
			}
			if(typeof(this.ebitda_cash_y5) == 'string'){
				this.ebitda_cash_y5 = parseInt(this.ebitda_cash_y5);
			}
			evm_rev_per = ((this.enterprise_val_y5 - this.ebitda_debt_y5 + this.ebitda_cash_y5));
	  	this.ebitda_equity_y5 = (Math.round(evm_rev_per * 100) / 100);
		}
}

addInvestmentInfo() {
	this.page_3 = "";
	this.page_4 = "active";
	this.scrollToTop();
	console.log(this.page_3, this.page_4);
	
	this.updateData();
	this.updateCompanyEntry();
}

assignInvInfo() {
	var enterprise_valuation = [
		{
			"year_count":1,
			"year":this.fin_dt_yr_1,
			"enterprise_valuation": this.enterprise_val_y1,
			"ebitda": this.enter_ebitda_y1,
			"ebitda_multiple": this.ebitda_mult_y1,
			"debt": this.ebitda_debt_y1,
			"cash": this.ebitda_cash_y1,
			"equity_valuation": this.ebitda_equity_y1
		},
		{
			"year_count":2,
			"year":this.fin_dt_yr_2,
			"enterprise_valuation": this.enterprise_val_y2,
			"ebitda": this.enter_ebitda_y2,
			"ebitda_multiple": this.ebitda_mult_y2,
			"debt": this.ebitda_debt_y2,
			"cash": this.ebitda_cash_y2,
			"equity_valuation": this.ebitda_equity_y2
		},
		{
			"year_count":3,
			"year":this.fin_dt_yr_0,
			"enterprise_valuation": this.enterprise_val_y3,
			"ebitda": this.enter_ebitda_y3,
			"ebitda_multiple": this.ebitda_mult_y3,
			"debt": this.ebitda_debt_y3,
			"cash": this.ebitda_cash_y3,
			"equity_valuation": this.ebitda_equity_y3
		},
		{
			"year_count":4,
			"year":this.fin_dt_yr_3,
			"enterprise_valuation": this.enterprise_val_y4,
			"ebitda": this.enter_ebitda_y4,
			"ebitda_multiple": this.ebitda_mult_y4,
			"debt": this.ebitda_debt_y4,
			"cash": this.ebitda_cash_y4,
			"equity_valuation": this.ebitda_equity_y4
		},
		{
			"year_count":5,
			"year":this.fin_dt_yr_4,
			"enterprise_valuation": this.enterprise_val_y5,
			"ebitda": this.enter_ebitda_y5,
			"ebitda_multiple": this.ebitda_mult_y5,
			"debt": this.ebitda_debt_y5,
			"cash": this.ebitda_cash_y5,
			"equity_valuation": this.ebitda_equity_y5
		}
	];

	this.invInfo_finarr = [
		{
			"page_name" : "investment_info",
			"business_model":this.business_model,
			"business_desc": this.business_model_desc,
			"enterpise_val_year": this.enterprise_val_year,
			"enterprise_valuation":enterprise_valuation,
			"stake_capital_invest":this.capital_investment,
			"stake_capital_equity":this.capital_equity,
			"internal_rate_return":this.irr_val,
			"exit_month": this.exit_month,
			"exit_year": this.exit_year,
			"cash_on_cash_multiple": this.cash_on_cash,
			"drag_rights":this.drag_rights,
			"liquidation_preference": this.liquid_pref,
			"unique_rights_negotiated": this.unique_rights,
			"intern_strategic_buyers": this.strategic_buyers
		}
	];
}

changePromoter(event, ver){
	console.log(event);	
	
	//this.promoId = event;

	/*var promIntData = {
		"id": ver,
		"promo_id": event,
		"promo_score": ""
	};*/

	//this.proInfo_finarr.push(promIntData);
	this.updateData();
	this.updateCompanyEntry();
}

goToPromoter(cou){	
	console.log(['/promoter', this.companyId, this.dealId]);
	this.updateData();
	this.updateCompanyEntry();
  	this.router.navigate( ['/promoter', this.companyId, this.dealId, cou]);
}

goTodashboard() {
	this.router.navigate( ['/dashboard']);
}

goToUser() {	
	this.updateData();
	this.updateCompanyEntry();
	this.router.navigate( ['/userentry', "1", this.companyId, this.dealId]);
}

assignProInfo() {
	console.log(this.promoterArr);
}

goToPage(cou) {
    if(cou == 1) {
        this.page_1 = 'active';
        this.page_2 = '';
        this.page_3 = '';
        this.page_4 = '';
    }
    else if(cou == 2) {
        this.page_1 = '';
        this.page_2 = 'active';
        this.page_3 = '';
        this.page_4 = '';
    }
    else if(cou == 3) {
        this.page_1 = '';
        this.page_2 = '';
        this.page_3 = 'active';
        this.page_4 = '';
    }
    else if(cou == 4) {
        this.page_1 = '';
        this.page_2 = '';
        this.page_3 = '';
        this.page_4 = 'active';
    }
}

updateCompanyEntry(){
	var payload={
	    "intCmpId":this.companyId,
	    "intDealId": this.dealId,
	    "intRuleId": 1,
	    "intEntryType": 1,
	    "json":  {
	        "id": 1,
	        "rev_id": 1,
	        "rev_model": "General",
	        "rev_data": [
	            {
	                "cat_id": 1,
	                "cat_name": "FINANCIAL INFORMATION",
	                "cat_data": this.finInfo_finArr
	            },
	            {
	                "cat_id": 2,
	                "cat_name": "BUSINESS",
	                "cat_data": this.busInfo_finarr
	            },
	            {
	                "cat_id": 3,
	                "cat_name": "INVESTMENT",
	                "cat_data": this.invInfo_finarr
	            },
	            {
	                "cat_id": 4,
	                "cat_name": "PROMOTERS",
	                "cat_data": [
	                    {
	                        "page_name" : "promoter_info",
	                        "promoter_data" : this.promoterArr
	                    }
	                ]
	            }
	        ],
			"pro_data": this.promoterData,
	    },
	    "deviceInfo": {
	        "deviceType": "Desktop",
	        "browser": "crome"
	    }
	};
	console.log(payload);
	this.appservices.uploadCompanyEntry(payload, (res, err) => {
	console.log(res);
	});
}

deletefiles(index) {
	this.uploadedFiles.splice(index, 1);
	//delete this.uploadedFiles[index];
	var len = this.uploadedFiles.length;
	if(len == 9) {
		this.fileShow10 = false;
		this.fileUplo9 = true;
	}
	else if(len == 8) {
		this.fileShow9 = false;
		this.fileUplo8 = true;
	}
	else if(len == 7) {
		this.fileShow8 = false;
		this.fileUplo7 = true;
	}
	else if(len == 6) {
		this.fileShow7 = false;
		this.fileUplo6 = true;
	}
	else if(len == 5) {
		this.fileShow6 = false;
		this.fileUplo5 = true;
	}
	else if(len == 4) {
		this.fileShow5 = false;
		this.fileUplo4 = true;
	}
	else if(len == 3) {
		this.fileShow4 = false;
		this.fileUplo3 = true;
	}
	else if(len == 2) {
		this.fileShow3 = false;
		this.fileUplo2 = true;
	}
	else if(len == 1) {
		this.fileShow2 = false;
		this.fileUplo1 = true;
	}
	console.log(this.uploadedFiles);
}

deletefiles1(index) {
	this.uploadedFiles1.splice(index, 1);
	//delete this.uploadedFiles[index];
	var len = this.uploadedFiles1.length;
	console.log(len);
	if(len == 9) {
		this.file2Show10 = false;
	}
	else if(len == 8) {
		this.file2Show9 = false;
	}
	else if(len == 7) {
		this.file2Show8 = false;
	}
	else if(len == 6) {
		this.file2Show7 = false;
	}
	else if(len == 5) {
		this.file2Show6 = false;
	}
	else if(len == 4) {
		this.file2Show5 = false;
	}
	else if(len == 3) {
		this.file2Show4 = false;
	}
	else if(len == 2) {
		this.file2Show3 = false;
	}
	else if(len == 1) {
		this.file2Show2 = false;
	}
	console.log(this.uploadedFiles);
}

checkFileUpload(cou) {
	if(cou == 1) {
		this.fileUplo1 = true;
	}
	else if(cou == 2) {
		this.fileUplo2 = true;
	}
	else if(cou == 3) {
		this.fileUplo3 = true;
	}
	else if(cou == 4) {
		this.fileUplo4 = true;
	}
	else if(cou == 5) {
		this.fileUplo5 = true;
	}
	else if(cou == 6) {
		this.fileUplo6 = true;
	}
	else if(cou == 7) {
		this.fileUplo7 = true;
	}
	else if(cou == 8) {
		this.fileUplo8 = true;
	}
	else if(cou == 9) {
		this.fileUplo9 = true;
	}
	else if(cou == 10) {
		this.fileUplo10 = true;
	}
	else if(cou == 11) {
		this.file2Uplo1 = true;
	}
	else if(cou == 12) {
		this.file2Uplo2 = true;
	}
	else if(cou == 13) {
		this.file2Uplo3 = true;
	}
	else if(cou == 14) {
		this.file2Uplo4 = true;
	}
	else if(cou == 15) {
		this.file2Uplo5 = true;
	}
	else if(cou == 16) {
		this.file2Uplo6 = true;
	}
	else if(cou == 17) {
		this.file2Uplo7 = true;
	}
	else if(cou == 18) {
		this.file2Uplo8 = true;
	}
	else if(cou == 19) {
		this.file2Uplo9 = true;
	}
	else if(cou == 20) {
		this.file2Uplo10 = true;
	}
}

addUploadControl(cou) {
	if(cou == 1) {
		this.fileShow2 = true;
		this.fileUplo2 = false;
	}
	else if(cou == 2) {
		this.fileShow3 = true;
		this.fileUplo3 = false;
	}
	else if(cou == 3) {
		this.fileShow4 = true;
		this.fileUplo4 = false;
	}
	else if(cou == 4) {
		this.fileShow5 = true;
		this.fileUplo5 = false;
	}
	else if(cou == 5) {
		this.fileShow6 = true;
		this.fileUplo6 = false;
	}
	else if(cou == 6) {
		this.fileShow7 = true;
		this.fileUplo7 = false;
	}
	else if(cou == 7) {
		this.fileShow8 = true;
		this.fileUplo8 = false;
	}
	else if(cou == 8) {
		this.fileShow9 = true;
		this.fileUplo9 = false;
	}
	else if(cou == 9) {
		this.fileShow10 = true;
		this.fileUplo10 = false;
	}
	else if(cou == 10) {
		this.fileUplo10 = true;
	}
	else if(cou == 11) {
		this.file2Show2 = true;
	}
	else if(cou == 12) {
		this.file2Show3 = true;
	}
	else if(cou == 13) {
		this.file2Show4 = true;
	}
	else if(cou == 14) {
		this.file2Show5 = true;
	}
	else if(cou == 15) {
		this.file2Show6 = true;
	}
	else if(cou == 16) {
		this.file2Show7 = true;
	}
	else if(cou == 17) {
		this.file2Show8 = true;
	}
	else if(cou == 18) {
		this.file2Show9 = true;
	}
	else if(cou == 19) {
		this.file2Show10 = true;
	}
	else if(cou == 20) {
		this.file2Uplo10 = true;
	}
}

addPromoComp(cou) {
	console.log(cou, "Promoter Comp");

	var promIntData = {
		"id": cou,
		"promo_id": 0,
		"promo_score": ""
	};

	if(cou == 1) {
		this.proShow2 = true;
		promIntData.promo_id = this.promoId2;
		this.promoterArr.push(promIntData);
	}
	else if(cou == 2) {
		this.proShow3 = true;
		promIntData.promo_id = this.promoId3;
		this.promoterArr.push(promIntData);
	}
	this.updateData();
	this.updateCompanyEntry();
}

remPromoComp(cou) {
	if(cou == 1) {
		this.proShow1 = false;		
	}
	else if(cou == 2) {
		this.proShow2 = false;
		this.promoterArr.splice(1, 1);
	}
	else if(cou == 3) {
		this.proShow3 = false;
		this.promoterArr.splice(2, 1);
	}
	console.log(this.promoterArr);
}

logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}

}
