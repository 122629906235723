<div class="login-container">
    <div class="column desc-column">
        <label class="desc-text">Your Business Valuation Tools</label>
    </div>
    <div class="column input-column">
        <div class="logo">
            <img src="assets/zastra-logo-color.svg" height="80px" alt="stakeboat-logo" />
        </div>
        <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <label class="title">{{isSignUp ? "Sign Up" : "Sign In"}}</label>
            <div *ngIf="isSignUp" class="form-group">
                <input type="text" placeholder="Nameee" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <input type="text" placeholder="Email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email is invalid</div>
                </div>
            </div>
            <div class="form-group">
                <input type="password" placeholder="Password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
            </div>
            <div *ngIf="isSignUp" class="form-group">
                <input type="password" placeholder="Repeat your password" formControlName="confirmPassword"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">Please retype your password</div>
                </div>
            </div>
            <div *ngIf="!isSignUp" class="links">
                <label class="checkbox checkbox-outline flex-shrink-0 mr-4">
                    <input type="checkbox" value="rememberMe" id="remember-me" formControlName="remember"
                        (change)="rememberMe = !rememberMe" />
                    <span></span>
                    <label class="pl-2 cursor-pointer user-select-none ml-1 mb-0" for="remember-me">Remember Me</label>
                </label>
                <div class="forgot-password cursor-pointer" (click)="forgotPassword(content)">Forgot Password?</div>
            </div>
            <div class="action-btn">
                <button [disabled]="loading" class="btn btn-primary login-btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{isSignUp ? "Register" : "Login"}}
                </button>
                <!-- <button type="button" [disabled]="loading" class="btn" (click)="toggleForm()">
                    {{isSignUp ? "Login" : "Sign Up"}}
                </button> -->
            </div>
            <div *ngIf="isSignUp" class="agreement">
                By signin up, you agree to our <br />
                <span class="hyperlink">Terms and Conditions</span> & <span class="hyperlink">Privacy Policy</span>
            </div>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            <div *ngIf="successMsg" class="alert alert-success mt-3 mb-0">{{successMsg}}</div>
        </form>
        <div class="copyright"> © 2020</div>
    </div>
</div>
<ng-template #forgotPasswordDialog>
    <h2 matDialogTitle>Forgot Password!</h2>
    <mat-dialog-content>
        <form #forgotPasswordForm="ngForm">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="text" id="email" class="form-control" [(ngModel)]="forgotRequestEmail"
                    name="forgotRequestEmailName" #forgotRequestEmailName="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required [ngClass]="{ 'is-invalid': fpSubmitted && forgotRequestEmailName.errors }">
                <div class="invalid-feedback">
                    <div>Please enter valid Email address</div>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" class="btn btn-primary-outline" (click)="c('Close click')">Close</button>
        <button (click)="fpSubmitted = true; triggerForgotPassword(!forgotRequestEmailName.invalid)"
            class="btn btn-primary">Request Password</button>
    </mat-dialog-actions>
</ng-template>