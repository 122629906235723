import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { WebConfig } from "./$";
import { Options } from "selenium-webdriver/opera";
import { StateManagement } from "./stateManager";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class HttpServices {
  public baseUrl: string = "https://gpapp-poc.kinitous.in/";

  // serviceUrls:
  url_login = "/adminLogin";
  url_goodPracticesAndIncidents = "/admin/goodPracticesAndIncidents";


  constructor(private http: HttpClient,
    private stateManagement: StateManagement) {
    const webConfig = new WebConfig();
    this.baseUrl = webConfig.api_base_url;
  }
  status_success: number = 200;

  getHttpHeaders(): HttpHeaders {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    let token = currentUser.token;

    // const authToken = JSON.parse(sessionStorage.getItem("currentUser"))
    //   .authToken;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });

    return headers;
  }

  getHttpPostHeaders(): HttpHeaders {
    const headers = new HttpHeaders({      
      "mimeType": "multipart/form-data",
      'TOKEN' : '1'
    });
    return headers;
  }

  getHttpPostTokHeaders(): HttpHeaders {
    const headers = new HttpHeaders({  
      'TOKEN' : '1'
    });
    return headers;
  }

  getHttpCorsHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Origin": '*',
    });

    return headers;
  }

  getHttpOptionsWithHeaders(): any {
    const options = {
      observe: "response",
      response:'jsonp',
      headers: this.getHttpHeaders()
    };

    return options;
  }

  getHttpGet(url: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(url, {
      observe: "response",

      //headers: this.getHttpHeaders()
    });
  }

  getHttpGetContent(url: string): Observable<HttpResponse<any>> {
    return this.http.get(url, {
      observe: "response",
      responseType:"text",
      headers: this.getHttpCorsHeaders()
    });
  }



  getHttpGet_Auth(url: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(url, {
      observe: "response",
      headers: this.getHttpHeaders()
    });
  }

  getHttpPost_Auth(url: string, body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(url, body, {
      observe: "response",
      headers: this.getHttpHeaders()
    });
  }

  getHttpPost_Token(url: string, body: any): Observable<HttpResponse<any>> {
  console.log(this.getHttpPostTokHeaders);
    return this.http.post<any>(url, body, {
      observe: "response",
      headers: this.getHttpPostTokHeaders()
    });
  }


  getHttpPost(url: string, body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(url, body, {
      observe: "response",
      //headers: this.getHttpHeaders()
    });
  }

  getHttpFilePost(url: string, body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(url, body, {      
      observe: "response",
      headers: this.getHttpPostHeaders()
    });
  }

  getHttpGetData(url: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(url, {
      observe: "response",
      headers: this.getHttpPostTokHeaders()
    });
  }

  login(username: string, password: string, cb: Function) {
    let body = {
      userid: username,
      password: password
    };

    const httpOptions = {
      headers: new HttpHeaders({
        "client-id": "134adjfbebu134b"
      })
    };

    this.http
      .post(this.baseUrl + this.url_login, body, {
        observe: "response",
        headers: httpOptions.headers
      })
      .subscribe(resp => {
        if (resp) {
          if (resp.status == 200) {
            if (cb) cb(resp.body, null);
          }
        }
      });
  }



}
