import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppServices } from '../common/appServices';
import { DataService } from '@app/_services/data.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/_services';

import { IPromoterInfo, PromoterInfo } from '../companyentry/models/promoter-info';

@Component({
  selector: 'app-promoter',
  templateUrl: './promoter.component.html',
  styleUrls: ['./promoter.component.scss']
})
export class PromoterComponent implements OnInit {
	qualification: string="";
	qual_score: number = 0;
	ageinyears:number;
	age_score: number = 0;
	relexperience: number = 0;
	exp_score: number = 0;
	exitagreement: string="";
	exit_score: number = 0;
	professional: string="";
	prof_score: number = 0;
	confidence: number;
	conf_score: number = 0;
	promotershare: number = 0;
	promo_score: number = 0;
	abilityscale: number = 0;
	scale_score: number = 0;
	abilitywork: number = 0;
	work_score: number = 0;
	attitude: number = 0;
	attit_score: number = 0;
	cibil: number = 0;
	cibil_score: number = 0;
	exitcapability: string="";
	exit_cap:number=0;
	promoterData:any;
	promoterScore:number=0;
	companyId: number = 0;
 	dealId: number = 0;
 	promId: number = 0;

 	risk_appetite: number = 0;
 	risk_score: number = 0;

 	ref_check_comp: number = 0;
 	check_comp_score: number = 0;

 	ref_check_bankers: number = 0;
 	check_bank_score: number = 0;

 	ref_check_past: number = 0;
 	check_past_score: number = 0;

 	attitude_governance: number = 0;
 	att_gov_score: number = 0;

 	compName: string = "";
 	deaDt: string = "";
 	displayName: string;
	promoData: IPromoterInfo;

	revData: any = [];

	finInfo_finArr: any = [];
	busInfo_finarr: any = [];
	invInfo_finarr: any = [];
	proInfo_finarr: any = [];
	promoterArr: any = [];
  constructor(
  	private appservices: AppServices, 
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private dataService: DataService,
    private toastr: ToastrService,
  	private route: ActivatedRoute,  	
    private router: Router, private authenticationService: AuthenticationService
    ) {
  	
   }

  ngOnInit(): void {
  	let user = localStorage.getItem('currentUser');
  	console.log(user);
  	this.displayName = user && JSON.parse(user).userName;
  	this.compName = localStorage.getItem('selectedCompany');
    this.deaDt = localStorage.getItem('dealDate');
  	this.route.params.subscribe(params => {	   
	   this.companyId = +params['cmpId'];
	   this.dealId = +params['dealId'];
	   this.promId = +params['promId'];
	});
	this.promoData = new PromoterInfo;	
  	this.dataService.getData().subscribe((data: any[])=>{		 
      	this.promoterData = JSON.parse(JSON.stringify(data[0]['rev_data'][4]['cat_data']));
      	console.log(this.promoterData['par_max_value']);
    });

	this.appservices.getCompanyEntry(this.companyId, this.dealId, (res, err) => {
		if(res.response.data.length > 0){
			console.log(res);
			var jsonData = JSON.parse(res.response.data[0].JSON);
			this.revData = jsonData.rev_data;
			console.log(this.revData);
			this.finInfo_finArr = this.revData[0].cat_data;
			this.busInfo_finarr = this.revData[1].cat_data;
			this.invInfo_finarr = this.revData[2].cat_data;
			this.proInfo_finarr = this.revData[3].cat_data;
			console.log(this.proInfo_finarr[0].promoter_data.length);
			for(var k=0; k<this.proInfo_finarr[0].promoter_data.length; k++) {
				this.promoterArr[k] = this.proInfo_finarr[0].promoter_data[k];
				console.log(this.promoterArr);
			}
			
			if(jsonData.hasOwnProperty('pro_data')	) {
				var promoDetails = jsonData.pro_data;
				console.log(promoDetails)
				if(promoDetails.length > 0) {
					this.promoData = promoDetails[this.promId];
					console.log(this.promoData);
					this.calculatePromoterScore();
				}
				
			}
			
		}
	});
  }

  updateCompanyEntry(){
	let prData= [];
	prData[this.promId] = this.promoData;
	this.promoterArr[this.promId].promo_id = this.promId;
	this.promoterArr[this.promId].promo_score = this.promoterScore;
	var payload={
	    "intCmpId":this.companyId,
	    "intDealId": this.dealId,
	    "intRuleId": 1,
	    "intEntryType": 1,
	    "json":  {
	        "id": 1,
	        "rev_id": 1,
	        "rev_model": "General",
	        "rev_data": [
	            {
	                "cat_id": 1,
	                "cat_name": "FINANCIAL INFORMATION",
	                "cat_data": this.finInfo_finArr
	            },
	            {
	                "cat_id": 2,
	                "cat_name": "BUSINESS",
	                "cat_data": this.busInfo_finarr
	            },
	            {
	                "cat_id": 3,
	                "cat_name": "INVESTMENT",
	                "cat_data": this.invInfo_finarr
	            },
	            {
	                "cat_id": 4,
	                "cat_name": "PROMOTERS",
	                "cat_data": [
	                    {
	                        "page_name" : "promoter_info",
	                        "promoter_data" : this.promoterArr
	                    }
	                ]
	            }
	        ],
			"pro_data": prData,
	    },
	    "deviceInfo": {
	        "deviceType": "Desktop",
	        "browser": "crome"
	    }
	};
	console.log(payload);
	this.appservices.uploadCompanyEntry(payload, (res, err) => {
	console.log(res);
	this.goToCompany();
	});
}

  addCompanyInfo() {

  }

  goToCompany(){
      localStorage.setItem('promoterid', this.promId.toString());
  	  localStorage.setItem('promoterScore', this.promoterScore.toString());
      this.router.navigate( ['/companyentry', 4, this.companyId, this.dealId]);
  }

	onValueChange(ind) {
	  	if(ind == 1) {
	  		if(this.promoData.qualification == 1) {
	  			this.qual_score = 5;
	  		}
	  		else if(this.promoData.qualification == 2) {
	  			this.qual_score = 7;
	  		}
	  		else if(this.promoData.qualification == 3) {
	  			this.qual_score = 10;
	  		}
	  		else {
	  			this.qual_score = 0;
	  		}
	  		//this.promoterScore = this.promoterScore + this.qual_score;
	  	}
	  	else if(ind == 2) {
	  		if(this.promoData.age_in_years <= 48 ){
	  			this.age_score = 10;
	  		}
	  		else if(this.promoData.age_in_years > 48) {
	  			this.age_score = -10;
	  		}
	  		else {
	  			this.age_score = 0;
	  		}
	  		//this.promoterScore = this.promoterScore + this.age_score;
	  	}
	  	else if(ind == 3) {
	  		if(this.promoData.relevant_experience <= 10 ){
	  			this.exp_score = this.promoData.relevant_experience;
	  		}  		
	  		else {
	  			this.exp_score = 0;
	  		}
	  		if(typeof(this.exp_score) == "string"){
	  			this.exp_score = parseInt(this.exp_score);
	  		}
	  	}
	  	else if(ind == 4) {
	  		if(this.promoData.exit_capability == 1 ){
	  			this.exit_cap = 5;
	  		}
	  		else if(this.promoData.exit_capability == 2) {
	  			this.exit_cap = 0;
	  		}
	  		else {
	  			this.exit_cap = 0;
	  		}
	  		//this.promoterScore = this.promoterScore + this.exit_cap;
	  	}
	  	else if(ind == 5) {
	  		if(this.promoData.exit_agreement == 1 ){
	  			this.exit_score = 4;
	  		}
	  		else if(this.promoData.exit_agreement == 2) {
	  			this.exit_score = 6.5;
	  		}
	  		else if(this.promoData.exit_agreement == 3) {
	  			this.exit_score = 20;
	  		}
	  		else {
	  			this.exit_score = 0;
	  		}
	  		//this.promoterScore = this.promoterScore + this.exit_score;
	  	}
	  	else if(ind == 6) {
	  		if(this.promoData.prof_deal_breaker == 1 ){
	  			this.prof_score = 4;
	  		}
	  		else if(this.promoData.prof_deal_breaker == 2) {
	  			this.prof_score = 7;
	  		}
	  		else if(this.promoData.prof_deal_breaker == 3) {
	  			this.prof_score = 10;
	  		}
	  		else {
	  			this.prof_score = 0;
	  		}
	  		//this.promoterScore = this.promoterScore + this.prof_score;
	  	}
	  	else if(ind == 7) {
	  		if(this.promoData.confidence <= 10 ){
	  			this.conf_score = this.promoData.confidence;
	  		}
			else if(this.promoData.confidence > 10){
				this.conf_score = 10;
			}
	  		else {
	  			this.conf_score = 0;
	  		}
	  		if(typeof(this.conf_score) == "string"){
	  			this.conf_score = parseInt(this.conf_score);
	  		}
	  		//this.promoterScore = this.promoterScore + this.conf_score;  		
	  	}
	  	else if(ind == 8) {
	  		if(this.promoData.prom_shareholding <= 20 ){
	  			this.promo_score = 12;
	  		}
	  		else if(this.promoData.prom_shareholding >= 20 && this.promoData.prom_shareholding <50) {
	  			this.promo_score = 20;
	  		}
	  		else if(this.promoData.prom_shareholding >= 50) {
	  			this.promo_score = 8;
	  		}	
	  		else {
	  			this.promo_score = 0;
	  		}
	  		//this.promoterScore = this.promoterScore + this.promo_score; 
	  	}
	  	else if(ind == 9) {
	  		if(this.promoData.ability_to_scale <= 10 ){
	  			this.scale_score = this.promoData.ability_to_scale;
	  		}  		
	  		else {
	  			this.scale_score = 0;
	  		}
	  		if(typeof(this.scale_score) == "string"){
	  			this.scale_score = parseInt(this.scale_score);
	  		}
	  		//this.promoterScore = this.promoterScore + this.scale_score;
	  	}
	  	else if(ind == 10) {
	  		if(this.promoData.ability_to_work <= 10 ){
	  			this.work_score = this.promoData.ability_to_work;
	  		}  		
	  		else {
	  			this.work_score = 0;
	  		}
	  		if(typeof(this.work_score) == "string"){
	  			this.work_score = parseInt(this.work_score);
	  		}
	  		//this.promoterScore = this.promoterScore + this.work_score;
	  	}
	  	else if(ind == 11) {
	  		if(this.promoData.attitude_towards <= 10 ){
	  			this.attit_score = this.promoData.attitude_towards;
	  		}  		
	  		else {
	  			this.attit_score = 0;
	  		}
	  		if(typeof(this.attit_score) == "string"){
	  			this.attit_score = parseInt(this.attit_score);
	  		}
	  		//this.promoterScore = this.promoterScore + this.attit_score;
	  	}
	  	else if(ind == 12) {
	  		if(this.promoData.cibil_score <700 ){
	  			this.cibil_score = -10;
	  		}
	  		else if(this.promoData.cibil_score >= 700 && this.promoData.cibil_score <750) {
	  			this.cibil_score = 0;
	  		} 		
	  		else if(this.promoData.cibil_score >=750 ){
	  			this.cibil_score = 10;
	  		}
	  		//this.promoterScore = this.promoterScore + this.cibil_score;
	  	}

	  	else if(ind == 13) {
	  		if(this.promoData.risk_appetite <= 10 ){
	  			this.risk_score = this.promoData.risk_appetite;
	  		}  		
	  		else {
	  			this.risk_score = 0;
	  		}
	  		if(typeof(this.risk_score) == "string"){
	  			this.risk_score = parseInt(this.risk_score);
	  		}
	  	}
	  	else if(ind == 14) {
	  		if(this.promoData.reference_competitors <= 10 ){
	  			this.check_comp_score = this.promoData.reference_competitors;
	  		}  		
	  		else {
	  			this.check_comp_score = 0;
	  		}
	  		if(typeof(this.check_comp_score) == "string"){
	  			this.check_comp_score = parseInt(this.check_comp_score);
	  		}
	  	}
	  	else if(ind == 15) {
	  		if(this.promoData.reference_bankers <= 10 ){
	  			this.check_bank_score = this.promoData.reference_bankers;
	  		}  		
	  		else {
	  			this.check_bank_score = 0;
	  		}
	  		if(typeof(this.check_bank_score) == "string"){
	  			this.check_bank_score = parseInt(this.check_bank_score);
	  		}
	  	}
	  	else if(ind == 16) {
	  		if(this.promoData.reference_past_employees <= 10 ){
	  			this.check_past_score = this.promoData.reference_past_employees;
	  		}  		
	  		else {
	  			this.check_past_score = 0;
	  		}
	  		if(typeof(this.check_past_score) == "string"){
	  			this.check_past_score = parseInt(this.check_past_score);
	  		}
	  	}
	  	else if(ind == 17) {
	  		if(this.promoData.attitude_governance <= 10 ){
	  			this.att_gov_score = this.promoData.attitude_governance;
	  		}  		
	  		else {
	  			this.att_gov_score = 0;
	  		}
	  		if(typeof(this.att_gov_score) == "string"){
	  			this.att_gov_score = parseInt(this.att_gov_score);
	  		}
	  	}

	  	
	  	this.promoterScore = this.qual_score + this.age_score + this.exp_score + this.exit_cap + this.exit_score + this.prof_score + this.conf_score + this.promo_score + this.scale_score + this.work_score + this.attit_score + this.cibil_score + this.risk_score + this.check_comp_score + this.check_bank_score  + this.check_past_score  + this.att_gov_score;
 
	}

	calculatePromoterScore() {
		if(this.promoData.qualification != 0) {
			if(this.promoData.qualification == 1) {
				this.qual_score = 5;
			}
			else if(this.promoData.qualification == 2) {
				this.qual_score = 7;
			}
			else if(this.promoData.qualification == 3) {
				this.qual_score = 10;
			}
			else {
				this.qual_score = 0;
			}
			//this.promoterScore = this.promoterScore + this.qual_score;
		}
		if(this.promoData.age_in_years != 0) {
			if(this.promoData.age_in_years <= 48 ){
				this.age_score = 10;
			}
			else if(this.promoData.age_in_years > 48) {
				this.age_score = -10;
			}
			else {
				this.age_score = 0;
			}
			//this.promoterScore = this.promoterScore + this.age_score;
		}
		if(this.promoData.relevant_experience != 0) {
			if(this.promoData.relevant_experience <= 10 ){
				this.exp_score = this.promoData.relevant_experience;
			}  		
			else {
				this.exp_score = 0;
			}
			if(typeof(this.exp_score) == "string"){
				this.exp_score = parseInt(this.exp_score);
			}
		}
		if(this.promoData.exit_capability != 0) {
			if(this.promoData.exit_capability == 1 ){
				this.exit_cap = 5;
			}
			else if(this.promoData.exit_capability == 2) {
				this.exit_cap = 0;
			}
			else {
				this.exit_cap = 0;
			}
			//this.promoterScore = this.promoterScore + this.exit_cap;
		}
		if(this.promoData.exit_agreement != 0) {
			if(this.promoData.exit_agreement == 1 ){
				this.exit_score = 4;
			}
			else if(this.promoData.exit_agreement == 2) {
				this.exit_score = 6.5;
			}
			else if(this.promoData.exit_agreement == 3) {
				this.exit_score = 20;
			}
			else {
				this.exit_score = 0;
			}
			//this.promoterScore = this.promoterScore + this.exit_score;
		}
		if(this.promoData.prof_deal_breaker != 0) {
			if(this.promoData.prof_deal_breaker == 1 ){
				this.prof_score = 4;
			}
			else if(this.promoData.prof_deal_breaker == 2) {
				this.prof_score = 7;
			}
			else if(this.promoData.prof_deal_breaker == 3) {
				this.prof_score = 10;
			}
			else {
				this.prof_score = 0;
			}
			//this.promoterScore = this.promoterScore + this.prof_score;
		}
		if(this.promoData.confidence != 0) {
			if(this.promoData.confidence <= 10 ){
				this.conf_score = this.promoData.confidence;
			}
		  else if(this.promoData.confidence > 10){
			  this.conf_score = 10;
		  }
			else {
				this.conf_score = 0;
			}
			if(typeof(this.conf_score) == "string"){
				this.conf_score = parseInt(this.conf_score);
			}
			//this.promoterScore = this.promoterScore + this.conf_score;  		
		}
		if(this.promoData.prom_shareholding != 0) {
			if(this.promoData.prom_shareholding <= 20 ){
				this.promo_score = 12;
			}
			else if(this.promoData.prom_shareholding >= 20 && this.promoData.prom_shareholding <50) {
				this.promo_score = 20;
			}
			else if(this.promoData.prom_shareholding >= 50) {
				this.promo_score = 8;
			}	
			else {
				this.promo_score = 0;
			}
			//this.promoterScore = this.promoterScore + this.promo_score; 
		}
		if(this.promoData.ability_to_scale != 0) {
			if(this.promoData.ability_to_scale <= 10 ){
				this.scale_score = this.promoData.ability_to_scale;
			}  		
			else {
				this.scale_score = 0;
			}
			if(typeof(this.scale_score) == "string"){
				this.scale_score = parseInt(this.scale_score);
			}
			//this.promoterScore = this.promoterScore + this.scale_score;
		}
		if(this.promoData.ability_to_work != 0) {
			if(this.promoData.ability_to_work <= 10 ){
				this.work_score = this.promoData.ability_to_work;
			}  		
			else {
				this.work_score = 0;
			}
			if(typeof(this.work_score) == "string"){
				this.work_score = parseInt(this.work_score);
			}
			//this.promoterScore = this.promoterScore + this.work_score;
		}
		if(this.promoData.attitude_towards != 0) {
			if(this.promoData.attitude_towards <= 10 ){
				this.attit_score = this.promoData.attitude_towards;
			}  		
			else {
				this.attit_score = 0;
			}
			if(typeof(this.attit_score) == "string"){
				this.attit_score = parseInt(this.attit_score);
			}
			//this.promoterScore = this.promoterScore + this.attit_score;
		}
		if(this.promoData.cibil_score != 0) {
			if(this.promoData.cibil_score <700 ){
				this.cibil_score = -10;
			}
			else if(this.promoData.cibil_score >= 700 && this.promoData.cibil_score <750) {
				this.cibil_score = 0;
			} 		
			else if(this.promoData.cibil_score >=750 ){
				this.cibil_score = 10;
			}
			//this.promoterScore = this.promoterScore + this.cibil_score;
		}

		if(this.promoData.risk_appetite != 0) {
			if(this.promoData.risk_appetite <= 10 ){
				this.risk_score = this.promoData.risk_appetite;
			}  		
			else {
				this.risk_score = 0;
			}
			if(typeof(this.risk_score) == "string"){
				this.risk_score = parseInt(this.risk_score);
			}
		}
		if(this.promoData.reference_competitors != 0) {
			if(this.promoData.reference_competitors <= 10 ){
				this.check_comp_score = this.promoData.reference_competitors;
			}  		
			else {
				this.check_comp_score = 0;
			}
			if(typeof(this.check_comp_score) == "string"){
				this.check_comp_score = parseInt(this.check_comp_score);
			}
		}
		if(this.promoData.reference_bankers != 0) {
			if(this.promoData.reference_bankers <= 10 ){
				this.check_bank_score = this.promoData.reference_bankers;
			}  		
			else {
				this.check_bank_score = 0;
			}
			if(typeof(this.check_bank_score) == "string"){
				this.check_bank_score = parseInt(this.check_bank_score);
			}
		}
		if(this.promoData.reference_past_employees != 0) {
			if(this.promoData.reference_past_employees <= 10 ){
				this.check_past_score = this.promoData.reference_past_employees;
			}  		
			else {
				this.check_past_score = 0;
			}
			if(typeof(this.check_past_score) == "string"){
				this.check_past_score = parseInt(this.check_past_score);
			}
		}
		if(this.promoData.attitude_governance != 0) {
			if(this.promoData.attitude_governance <= 10 ){
				this.att_gov_score = this.promoData.attitude_governance;
			}  		
			else {
				this.att_gov_score = 0;
			}
			if(typeof(this.att_gov_score) == "string"){
				this.att_gov_score = parseInt(this.att_gov_score);
			}
		}

		
		this.promoterScore = this.qual_score + this.age_score + this.exp_score + this.exit_cap + this.exit_score + this.prof_score + this.conf_score + this.promo_score + this.scale_score + this.work_score + this.attit_score + this.cibil_score + this.risk_score + this.check_comp_score + this.check_bank_score  + this.check_past_score  + this.att_gov_score;

	}

	logout() {
	    this.authenticationService.logout();
	    this.router.navigate(['/login']);
	}


}

