<div class="app-component-container">
    <!-- nav -->
    <!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
        <div class="navbar-nav">
            <a class="nav-item nav-link" routerLink="/">Home</a>
            <a class="nav-item nav-link" (click)="logout()">Logout</a>
        </div>
    </nav> -->

    <!-- main app container -->
    <div class="main-container">
        <router-outlet></router-outlet>
    </div>

    <!-- credits -->
    <!-- <div class="text-center mt-4">

        <p>
            <a href="#" target="_top">kinitous.in</a>
        </p>
    </div> -->
</div>