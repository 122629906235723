<div class="container">
	<div class="row">
		<div class="col-md-12">
			<div class="brand-header">
			    <img src="assets/zastra-logo-color.svg" height="50px" alt="stakeboat-logo" />
			    <div class="d-flex align-items-center">
			        <div class="name mr-4">{{displayName}}</div>
			        <button style="padding: 4px 12px;" class="btn btn-outline-primary center-btn-icon" (click)="logout()">
			            <mat-icon class="center-mat-icon">power_settings_new</mat-icon>
			            Logout
			        </button>
			    </div>
			</div>
		</div>
	</div>
</div>
<div class="header-img-wrapper">
	<div class="container">
		<div class="header-img-desc">
			<p class="header-img-txt">Company: <span class="bolder-text">{{compName}}</span></p>
			<p class="header-img-txt-desc">Date of Deal: <span class="bolder-text">{{deaDt}}</span></p>
		</div>
		<div class="header-output">
			<div class="row">
				<div class="col-md-4">
					<div class="form-group">
						<div class="score_desc">
							<span class="score_txt">{{maxScoree}}</span><br />
							<span class="score_sub">Max Score</span>
						</div>
			        </div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<div class="score_desc">
							<span class="score_txt">{{totScore}}</span><br />
							<span class="score_sub">Total Score</span>
						</div>
			        </div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<div class="score_desc">
							<span class="score_txt">{{netScore}}</span><br />
							<span class="score_sub">Net Score</span>
						</div>
			        </div>
				</div>
			</div>
		</div>
	</div>	
</div>
<div class="container pad-50 pad-bot-50">
	<div class="row">
		<div class="col-md-12">
			<div class="kbt-breadcrumb">
				<div class="kbt-breadcrumb-item" [class.selected]="page_1 == 'active'" (click)="goToPage(1);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Financial Information</span>
					</div>
				</div>

				<div class="kbt-breadcrumb-item" [class.selected]="page_2 == 'active'" (click)="goToPage(2);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Investment</span>
					</div>
				</div>

				<div class="kbt-breadcrumb-item" [class.selected]="page_3 == 'active'" (click)="goToPage(3);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Business</span>
					</div>
				</div>

				<div class="kbt-breadcrumb-item" [class.selected]="page_4 == 'active'" (click)="goToPage(4);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Industry</span>
					</div>
				</div>
				<div class="kbt-breadcrumb-item" [class.selected]="page_5 == 'active'" (click)="goToPage(5);">
					<span class="arrow"></span>
					<div class="bread-crumb-text hide-mobile">
						<span class="bc-text">Promoter</span>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="col-md-12">
			<div class="bread-crumb">
				<div class="breadcrumb-wrapper" [class.active]="page_1 == 'active'" (click)="goToPage(1);">
					<div class="bread-crumb-text">
						<span class="bc-text">Financial Information</span>
					</div>
				</div>
				<div class="breadcrumb-wrapper2" [class.active]="page_2 == 'active'" (click)="goToPage(2);">
					<div class="bread-crumb-text">
						<span class="bc-text">Investment</span>
					</div>
				</div>
				<div class="breadcrumb-wrapper3" [class.active]="page_3 == 'active'" (click)="goToPage(3);">
					<div class="bread-crumb-text">
						<span class="bc-text">Business</span>
					</div>
				</div>
				<div class="breadcrumb-wrapper4" [class.active]="page_4 == 'active'" (click)="goToPage(4);">
					<div class="bread-crumb-text">
						<span class="bc-text">Industry</span>
					</div>
				</div>

				<div class="breadcrumb-wrapper5" [class.active]="page_5 == 'active'" (click)="goToPage(5);">
					<div class="bread-crumb-text">
						<span class="bc-text">Promoter</span>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</div>
<div class="container pad-bot-50">
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-12">

					<div class="forms">
						<form  novalidate>
							<div *ngIf="page_1 === 'active'">
								<ng-container *ngFor="let item of filData; let i = index;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group row">
											    <label class="col-lg-12 col-form-label ">
											    	<span class="tbl-pro-num">{{i + 1}}. <span class="tbl-pro-txt">{{item.par_name}}</span></span> 
												</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group row">
												<div class="col-lg-12">
												    <div *ngIf="item.rule_type == 'rule_1' || item.rule_type == 'rule_2' || item.rule_type == 'rule_6'">
											          <input currencyMask [options]="inputCommonConfig" type="text" class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(0)]" (ngModelChange)="onPerChange(0, i + cumulativeLength(0), $event, item.rules, item.par_id)">										   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_3' && item.par_id!=7">
											         <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(0)]" (ngModelChange)="onPerChange(0, i + cumulativeLength(0), $event, item.rules, item.par_id)">
											            	<option value="Yes">Yes</option>
											            	<option value="No">No</option>
											            </select>									   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_3' && item.par_id==7">
											           <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(0)]"  (ngModelChange)="onPerChange(0, i + cumulativeLength(0), $event, item.rules, item.par_id)">
											            	<option value="Yes">Yes</option>
											            	<option value="No">No</option>
											            </select>
											           <label class="center-block"  *ngIf="firstFields[i +  cumulativeLength(0)] == 'Yes'" style="padding-left: 10px;">LP IRR(%):
											            <input type="text" name="lpIRR" class="form-control"  [(ngModel)]="lpIRR" (ngModelChange)="onPerChange(0, i + cumulativeLength(0), $event, item.rules, item.par_id)">
											           </label>
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_7'">
											          <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(0)]" (ngModelChange)="onPerChange(0, i + cumulativeLength(0), $event, item.rules, item.par_id)">
											            	<option value="500">Less than {{item.rules[0]['option_1']}}00</option>
											            	<option value="2000">{{item.rules[0]['option_1']}}00 to {{item.rules[1]['option_2']}}00 </option>
											            	<option value="5600">Greater than {{item.rules[2]['option_1']}}00 </option>
											            </select>									   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_4'">
											          <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(0)]" (ngModelChange)="onPerChange(0, i + cumulativeLength(0), $event, item.rules, item.par_id)">
											            	<option value="High">High</option>
											            	<option value="Low">Low</option>
											            </select>								   	
										      		</div>
									      		</div>
										    </div>
										</div>
										<div class="col-md-2"></div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">

													<span>Score </span><span class="pro_score">{{secondFields[i + cumulativeLength(0)]}}</span>
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">
													<span>Max Score </span><span class="pro_score">{{item.par_max_value}}</span>
												</div>
											</div>
										</div>
									</div>
									
								</ng-container>
								<div class="row pad-50 pad-bot-50">
									<div class="col-md-6">

									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Net: <span class="catscore">{{totScoreFields[0]}}%</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="totscore0" [(ngModel)]="totScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Total: <span class="catscore">{{netScoreFields[0]}}</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="netscore0" [(ngModel)]="netScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									
									
								</div>
								<div class="container pad-bot-50">
									<div class="form_footer_wrapper">
										<button class="btn btn-primary btn-pad-20" (click)="goToPage(2)">Save</button>
										<button class="btn btn-primary" (click)="goToCompany()">Cancel</button>
									</div>
								</div>
							</div>
							<div *ngIf="page_2 === 'active'">
								<ng-container *ngFor="let item of invData; let i = index;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group row">
											    <label class="col-lg-12 col-form-label ">
											    	<span class="tbl-pro-num">{{i + 1}}. <span class="tbl-pro-txt">{{item.par_name}}</span></span> 
												</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group row">
												<div class="col-lg-12">
											    <!-- <select class="form-control" id="exampleSelect1" [(ngModel)]="finData" name="finData" (ngModelChange)="onValueChange(1)">
											    	<option>Select</option>
											    	<option value="1">Below Average</option>
											    	<option value="2">Average</option>
											    	<option value="3">Good</option>
											    </select> -->
												    <div *ngIf="item.rule_type == 'rule_1' || item.rule_type == 'rule_2' || item.rule_type == 'rule_6'">
											          <input currencyMask [options]="inputCommonConfig" type="text" class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(1)]" (ngModelChange)="onPerChange(1, i + cumulativeLength(1), $event, item.rules, item.par_id)">										   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_3' && item.par_id!=7">
											         <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(1)]" (ngModelChange)="onPerChange(1, i + cumulativeLength(1), $event, item.rules, item.par_id)">
											            	<option value="Yes">Yes</option>
											            	<option value="No">No</option>
											            </select>									   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_3' && item.par_id==7">
											           <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(1)]"  (ngModelChange)="onPerChange(1, i + cumulativeLength(1), $event, item.rules, item.par_id)">
											            	<option value="Yes">Yes</option>
											            	<option value="No">No</option>
											            </select>
											           <label class="center-block"  *ngIf="firstFields[i +  cumulativeLength(1)] == 'Yes'" style="padding-left: 10px;">LP IRR(%):
											            <input type="text" name="lpIRR" class="form-control"  [(ngModel)]="lpIRR" (ngModelChange)="onPerChange(1, i + cumulativeLength(1), $event, item.rules, item.par_id)">
											           </label>
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_7'">
											          <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(1)]" (ngModelChange)="onPerChange(1, i + cumulativeLength(1), $event, item.rules, item.par_id)">
											            	<option value="500">Less than {{item.rules[0]['option_1']}}00</option>
											            	<option value="2000">{{item.rules[0]['option_1']}}00 to {{item.rules[1]['option_2']}}00 </option>
											            	<option value="5600">Greater than {{item.rules[2]['option_1']}}00 </option>
											            </select>									   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_4'">
											          <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i +  cumulativeLength(1)]" (ngModelChange)="onPerChange(1, i + cumulativeLength(1), $event, item.rules, item.par_id)">
											            	<option value="High">High</option>
											            	<option value="Low">Low</option>
											            </select>								   	
										      		</div>
									      		</div>
										    </div>
										</div>
										<div class="col-md-2"></div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">

													<span>Score </span><span class="pro_score">{{secondFields[i + cumulativeLength(1)]}}</span>
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">
													<span>Max Score </span><span class="pro_score">{{item.par_max_value}}</span>
												</div>
											</div>
										</div>
									</div>
									
								</ng-container>
								<div class="row pad-50 pad-bot-50">
									<div class="col-md-6">

									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Net: <span class="catscore">{{totScoreFields[1]}}%</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="totscore0" [(ngModel)]="totScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Total: <span class="catscore">{{netScoreFields[1]}}</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="netscore0" [(ngModel)]="netScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									
									
								</div>
								<div class="container pad-bot-50">
									<div class="form_footer_wrapper">
										<button class="btn btn-primary btn-pad-20" (click)="goToPage(3)">Save</button>
										<button class="btn btn-primary" (click)="goToCompany()">Cancel</button>
									</div>
								</div>
							</div>
							<div *ngIf="page_3 === 'active'">
								<ng-container *ngFor="let item of busData; let i = index;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group row">
											    <label class="col-lg-12 col-form-label ">
											    	<span class="tbl-pro-num">{{i + 1}}. <span class="tbl-pro-txt">{{item.par_name}}</span></span> 
												</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group row">
												<div class="col-lg-12">
											    <!-- <select class="form-control" id="exampleSelect1" [(ngModel)]="finData" name="finData" (ngModelChange)="onValueChange(1)">
											    	<option>Select</option>
											    	<option value="1">Below Average</option>
											    	<option value="2">Average</option>
											    	<option value="3">Good</option>
											    </select> -->
												    <div *ngIf="item.rule_type == 'rule_1' || item.rule_type == 'rule_2' || item.rule_type == 'rule_6'">
											          <input  currencyMask [options]="inputCommonConfig" type="text" class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i + cumulativeLength(2)]" (ngModelChange)="onPerChange(2, i + cumulativeLength(2), $event, item.rules, item.par_id)">										   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_3' && item.par_id!=7">
											         <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i + cumulativeLength(2)]" (ngModelChange)="onPerChange(2, i + cumulativeLength(2), $event, item.rules, item.par_id)">
											            	<option value="Yes">Yes</option>
											            	<option value="No">No</option>
											            </select>									   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_3' && item.par_id==7">
											           <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i + cumulativeLength(2)]"  (ngModelChange)="onPerChange(2, i + cumulativeLength(2), $event, item.rules, item.par_id)">
											            	<option value="Yes">Yes</option>
											            	<option value="No">No</option>
											            </select>
											           <label class="center-block"  *ngIf="firstFields[i + cumulativeLength(2)] == 'Yes'" style="padding-left: 10px;">LP IRR(%):
											            <input type="text" name="lpIRR" class="form-control"  [(ngModel)]="lpIRR" (ngModelChange)="onPerChange(2, i + cumulativeLength(2), $event, item.rules, item.par_id)">
											           </label>
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_7'">
											          <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i + cumulativeLength(2)]" (ngModelChange)="onPerChange(2, i + cumulativeLength(2), $event, item.rules, item.par_id)">
											            	<option value="500">Less than {{item.rules[0]['option_1']}}00</option>
											            	<option value="2000">{{item.rules[0]['option_1']}}00 to {{item.rules[1]['option_2']}}00 </option>
											            	<option value="5600">Greater than {{item.rules[2]['option_1']}}00 </option>
											            </select>									   	
										      		</div>
										      		<div *ngIf="item.rule_type == 'rule_4'">
											          <select class="form-control" name="netRevenue{{i}}" [(ngModel)]="firstFields[i + cumulativeLength(2)]" (ngModelChange)="onPerChange(2, i + cumulativeLength(2), $event, item.rules, item.par_id)">
											            	<option value="High">High</option>
											            	<option value="Low">Low</option>
											            </select>								   	
										      		</div>
									      		</div>
										    </div>
										</div>
										<div class="col-md-3"></div>
										<div class="col-md-1">
											<div class="form-group row">
												<div class="promoter-lbl-btn">

													<span>Score </span><span class="pro_score">{{secondFields[i + cumulativeLength(2)]}}</span>
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">
													<span>Max Score </span><span class="pro_score">{{item.par_max_value}}</span>
												</div>
											</div>
										</div>
									</div>
									
								</ng-container>
								<div class="row pad-50 pad-bot-50">
									<div class="col-md-6">

									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Net: <span class="catscore">{{totScoreFields[2]}}%</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="totscore0" [(ngModel)]="totScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Total: <span class="catscore">{{netScoreFields[2]}}</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="netscore0" [(ngModel)]="netScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									
									
								</div>
								<div class="container pad-bot-50">
									<div class="form_footer_wrapper">
										<button class="btn btn-primary btn-pad-20" (click)="goToPage(4)">Save</button>
										<button class="btn btn-primary" (click)="goToCompany()">Cancel</button>
									</div>
								</div>
							</div>
							<div *ngIf="page_4 === 'active'">
								<ng-container *ngFor="let item of indData; let i = index;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group row">
											    <label class="col-lg-12 col-form-label ">
											    	<span class="tbl-pro-num">{{i + 1}}. <span class="tbl-pro-txt">{{item.par_name}}</span></span> 
												</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group row">
												<div class="col-lg-12">
											    	<select class="form-control" name="netRevenue{{i + cumulativeLength(3)}}" [(ngModel)]="firstFields[i + cumulativeLength(3)]" (ngModelChange)="onPerChange(3, i + cumulativeLength(3), $event, item.rules, item.par_id)">
										            	<option value="Healthcare">Healthcare</option>
										            	<option value="Business to Business Technology">Business to Business Technology</option>
										            	<option value="Manufacturing">Manufacturing</option>
										            	<option value="Financial Services">Financial Services</option>
										            	<option value=" Business to Business Services"> Business to Business Services</option>
										            	<option value="Logistics">Logistics</option>
										            	<option value="FMCG">FMCG</option>
										            	<option value="Pharma">Pharma</option>
										            	<option value="Infastructure">Infastructure</option>
										            	<option value="Consumer Internet">Consumer Internet</option>
										            	<option value="Real Estate">Real Estate</option>
										            	<option value="Trading">Trading</option>
										            	<option value="Food Retail">Food Retail</option>
										            	<option value="Restaurants">Restaurants</option>
										            </select>
									      		</div>
										    </div>
										</div>
										<div class="col-md-3"></div>
										<div class="col-md-1">
											<div class="form-group row">
												<div class="promoter-lbl-btn">

													<span>Score </span><span class="pro_score">{{secondFields[i + cumulativeLength(3)]}}</span>
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">
													<span>Max Score </span><span class="pro_score">{{item.par_max_value}}</span>
												</div>
											</div>
										</div>
									</div>
									
								</ng-container>
								<div class="row pad-50 pad-bot-50">
									<div class="col-md-6">

									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Net: <span class="catscore">{{totScoreFields[3]}}%</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="totscore0" [(ngModel)]="totScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									<div class="col-md-3">
										<label class="center-block cat-det">Category Total: <span class="catscore">{{netScoreFields[3]}}</span>
											<!-- <div class="input-group mb-3">
												  <input type="text" class="form-control" name="netscore0" [(ngModel)]="netScoreFields[0]" disabled="disabled">
											</div> -->
										</label>
									</div>
									
									
								</div>
								<div class="container pad-bot-50">
									<div class="form_footer_wrapper">
										<button class="btn btn-primary btn-pad-20" (click)="goToPage(5)">Next</button>
										<button class="btn btn-primary" (click)="goToCompany()">Cancel</button>
									</div>
								</div>
							</div>
							<div *ngIf="page_5 === 'active'">
								<ng-container *ngFor="let item of proData; let i = index;">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group row">
											    <label class="col-lg-12 col-form-label ">
											    	<span class="tbl-pro-num">{{i + 1}}. <span class="tbl-pro-txt">{{item.par_name}}</span></span> 
												</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
										    <select class="custom-select form-control" name="promoter_det" [(ngModel)]="promoter_det" >
										     	<option  >Promoter Name</option>
							    				<option *ngFor="let deal of dealTeam" [value]="deal.id">{{deal.name}}</option>
										    </select>
										   </div>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												
												<div class="promoter-lbl-btn" style="margin-top: -20px;" *ngIf="promoScore">
													<span> </span><span class="pro_score">{{promoScore}}</span>
												</div>
												
											</div>
										</div>
										<!-- <div class="col-md-2">
											<div class="form-group">
												<button class="form-control btn btn-primary"> <i class="flaticon-plus icon-lg"></i> Add</button>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<i class="icon-2x text-light-50 flaticon-delete"></i>
											</div>
										</div> -->
									</div>
									<!-- <div class="row">
										<div class="col-md-6">
											<div class="form-group row">
												<div class="col-lg-12">
											    													    
									      		</div>
										    </div>
										</div>
										<div class="col-md-3"></div>
										<div class="col-md-1">
											<div class="form-group row">
												<div class="promoter-lbl-btn">
													<span>Score </span><span class="pro_score">{{secondFields[i + cumulativeLength(4)]}}</span>
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group row">
												<div class="promoter-lbl-btn">
													<span>Max Score </span><span class="pro_score">{{item.par_max_value}}</span>
												</div>
											</div>
										</div>
									</div> -->
									
								</ng-container>
								<div class="container pad-bot-50">
									<div class="form_footer_wrapper">
										<button class="btn btn-primary btn-pad-20" (click)="goToCompany()" >Save</button>
										<button class="btn btn-primary" (click)="goToCompany()" >Cancel</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>	
</div>