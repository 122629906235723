import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { HttpClient } from "@angular/common/http";
import { saveAs } from 'file-saver';
import { DataService } from '@app/_services/data.service';

import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    loading = false;
    users: User[];
    isAdmin : boolean = false;
    angForm: FormGroup;

    finData: any = [];
    valData: any = [];
    finsco : number = 0;
    netScore : string = '';
    maxScoree : number = 0;
    totScore : number = 0;
    products: any;
    firstFields: any = [];
    secondFields: any = [];
    thirdFields: any = [];
    totScoreFields:any =[];
    netScoreFields:any = [];
    rules: any = [];
    subRules: any = [];
    rev_model: string = '';
    cat_name: string = '';
    par_name: string = '';
    finsConv:number = 0;
    lpIRR: number = 0;
    sublpIRR: number = 0;
    firstSubFields: any = [];
    secondSubFields: any = [];
    thirdSubFields: any = [];

    finSubsco : number = 0;
    netSubScore : string = '';
    maxSubScoree : number = 0;
    totSubScore : number = 0;
    finsSubConv:number = 0;

    totSubScoreFields:any =[];
    netSubScoreFields:any = [];
    
    fin_1_max_score: string = '';
    fin_1_option_1: string = '';
    fin_1_option_2: string = '';
    fin_1_option_3: string = '';

    fin_1_par_1_gen_opt_val: string = '';
    fin_1_par_2_gen_opt_val: string = '';
    fin_1_par_3_gen_opt_val: string = '';

    fin_1_par_1_opt_bus_val:string = '';
    fin_1_par_2_opt_bus_val:string = '';   
    fin_1_par_3_opt_bus_val:string = '';


    fin_2_max_score: string = '';
    fin_2_option_1: string = '';
    fin_2_option_2: string = '';
    fin_2_option_3: string = '';

    fin_2_par_1_gen_opt_val: string = '';
    fin_2_par_2_gen_opt_val: string = '';
    fin_2_par_3_gen_opt_val: string = '';

    fin_2_par_1_opt_bus_val:string = '';
    fin_2_par_2_opt_bus_val:string = '';   
    fin_2_par_3_opt_bus_val:string = '';


    fin_3_max_score: string = '';
    fin_3_option_1: string = '';
    fin_3_option_2: string = '';
    fin_3_option_3: string = '';

    fin_3_par_1_gen_opt_val: string = '';
    fin_3_par_2_gen_opt_val: string = '';
    fin_3_par_3_gen_opt_val: string = '';

    fin_3_par_1_opt_bus_val:string = '';
    fin_3_par_2_opt_bus_val:string = '';   
    fin_3_par_3_opt_bus_val:string = '';

    fin_4_max_score: string = '';
    fin_4_option_1: string = '';
    fin_4_option_2: string = '';
    fin_4_option_3: string = '';

    fin_4_par_1_gen_opt_val: string = '';
    fin_4_par_2_gen_opt_val: string = '';
    fin_4_par_3_gen_opt_val: string = '';

    fin_4_par_1_opt_bus_val:string = '';
    fin_4_par_2_opt_bus_val:string = '';   
    fin_4_par_3_opt_bus_val:string = '';


    fin_5_max_score: string = '';
    fin_5_option_1: string = '';
    fin_5_option_2: string = '';
    fin_5_option_3: string = '';

    fin_5_par_1_gen_opt_val: string = '';
    fin_5_par_2_gen_opt_val: string = '';
    fin_5_par_3_gen_opt_val: string = '';

    fin_5_par_1_opt_bus_val:string = '';
    fin_5_par_2_opt_bus_val:string = '';   
    fin_5_par_3_opt_bus_val:string = '';


    fin_6_max_score: string = '';
    fin_6_option_1: string = '';
    fin_6_option_2: string = '';
    fin_6_option_3: string = '';

    fin_6_par_1_gen_opt_val: string = '';
    fin_6_par_2_gen_opt_val: string = '';
    fin_6_par_3_gen_opt_val: string = '';

    fin_6_par_1_opt_bus_val:string = '';
    fin_6_par_2_opt_bus_val:string = '';   
    fin_6_par_3_opt_bus_val:string = '';

    fin_7_max_score: string = '';
    fin_7_option_1: string = '';
    fin_7_option_2: string = '';
    fin_7_option_3: string = '';

    fin_7_par_1_gen_opt_val: string = '';
    fin_7_par_2_gen_opt_val: string = '';
    fin_7_par_3_gen_opt_val: string = '';

    fin_7_par_1_opt_bus_val:string = '';
    fin_7_par_2_opt_bus_val:string = '';   
    fin_7_par_3_opt_bus_val:string = '';


    fin_8_max_score: string = '';
    fin_8_option_1: string = '';
    fin_8_option_2: string = '';
    fin_8_option_3: string = '';

    fin_8_par_1_gen_opt_val: string = '';
    fin_8_par_2_gen_opt_val: string = '';
    fin_8_par_3_gen_opt_val: string = '';

    fin_8_par_1_opt_bus_val:string = '';
    fin_8_par_2_opt_bus_val:string = '';   
    fin_8_par_3_opt_bus_val:string = '';


    fin_9_max_score: string = '';
    fin_9_option_1: string = '';
    fin_9_option_2: string = '';
    fin_9_option_3: string = '';

    fin_9_par_1_gen_opt_val: string = '';
    fin_9_par_2_gen_opt_val: string = '';
    fin_9_par_3_gen_opt_val: string = '';

    fin_9_par_1_opt_bus_val:string = '';
    fin_9_par_2_opt_bus_val:string = '';   
    fin_9_par_3_opt_bus_val:string = '';
    
    
    fin_10_max_score: string = '';
    fin_10_option_1: string = '';
    fin_10_option_2: string = '';
    fin_10_option_3: string = '';

    fin_10_par_1_gen_opt_val: string = '';
    fin_10_par_2_gen_opt_val: string = '';
    fin_10_par_3_gen_opt_val: string = '';

    fin_10_par_1_opt_bus_val:string = '';
    fin_10_par_2_opt_bus_val:string = '';
    fin_10_par_3_opt_bus_val:string = '';

    fin_tot_score: number = 0;
    fin_net_score: number = 0;


    fin_1_sub_max_score: string = '';
    fin_1_sub_option_1: string = '';
    fin_1_sub_option_2: string = '';
    fin_1_sub_option_3: string = '';

    fin_2_sub_max_score: string = '';
    fin_2_sub_option_1: string = '';
    fin_2_sub_option_2: string = '';
    fin_2_sub_option_3: string = '';

    fin_3_sub_max_score: string = '';
    fin_3_sub_option_1: string = '';
    fin_3_sub_option_2: string = '';
    fin_3_sub_option_3: string = '';

    fin_4_sub_max_score: string = '';
    fin_4_sub_option_1: string = '';
    fin_4_sub_option_2: string = '';
    fin_4_sub_option_3: string = '';

    fin_5_sub_max_score: string = '';
    fin_5_sub_option_1: string = '';
    fin_5_sub_option_2: string = '';
    fin_5_sub_option_3: string = '';

    fin_6_sub_max_score: string = '';
    fin_6_sub_option_1: string = '';
    fin_6_sub_option_2: string = '';
    fin_6_sub_option_3: string = '';

    fin_7_sub_max_score: string = '';
    fin_7_sub_option_1: string = '';
    fin_7_sub_option_2: string = '';
    fin_7_sub_option_3: string = '';

    fin_8_sub_max_score: string = '';
    fin_8_sub_option_1: string = '';
    fin_8_sub_option_2: string = '';
    fin_8_sub_option_3: string = '';

    fin_9_sub_max_score: string = '';
    fin_9_sub_option_1: string = '';
    fin_9_sub_option_2: string = '';
    fin_9_sub_option_3: string = '';

    fin_10_sub_max_score: string = '';
    fin_10_sub_option_1: string = '';
    fin_10_sub_option_2: string = '';
    fin_10_sub_option_3: string = '';


    inv_1_max_score: string = '';
    inv_1_option_1: string = '';
    inv_1_option_2: string = '';
    inv_1_option_3: string = '';

    inv_1_par_1_gen_opt_val: string = '';
    inv_1_par_2_gen_opt_val: string = '';
    inv_1_par_3_gen_opt_val: string = '';

    inv_1_par_1_opt_bus_val:string = '';
    inv_1_par_2_opt_bus_val:string = '';   
    inv_1_par_3_opt_bus_val:string = '';


    inv_2_max_score: string = '';
    inv_2_option_1: string = '';
    inv_2_option_2: string = '';
    inv_2_option_3: string = '';

    inv_2_par_1_gen_opt_val: string = '';
    inv_2_par_2_gen_opt_val: string = '';
    inv_2_par_3_gen_opt_val: string = '';

    inv_2_par_1_opt_bus_val:string = '';
    inv_2_par_2_opt_bus_val:string = '';   
    inv_2_par_3_opt_bus_val:string = '';


    inv_3_max_score: string = '';
    inv_3_option_1: string = '';
    inv_3_option_2: string = '';
    inv_3_option_3: string = '';

    inv_3_par_1_gen_opt_val: string = '';
    inv_3_par_2_gen_opt_val: string = '';
    inv_3_par_3_gen_opt_val: string = '';

    inv_3_par_1_opt_bus_val:string = '';
    inv_3_par_2_opt_bus_val:string = '';   
    inv_3_par_3_opt_bus_val:string = '';

    inv_4_max_score: string = '';
    inv_4_option_1: string = '';
    inv_4_option_2: string = '';
    inv_4_option_3: string = '';

    inv_4_par_1_gen_opt_val: string = '';
    inv_4_par_2_gen_opt_val: string = '';
    inv_4_par_3_gen_opt_val: string = '';

    inv_4_par_1_opt_bus_val:string = '';
    inv_4_par_2_opt_bus_val:string = '';   
    inv_4_par_3_opt_bus_val:string = '';


    inv_5_max_score: string = '';
    inv_5_option_1: string = '';
    inv_5_option_2: string = '';
    inv_5_option_3: string = '';

    inv_5_par_1_gen_opt_val: string = '';
    inv_5_par_2_gen_opt_val: string = '';
    inv_5_par_3_gen_opt_val: string = '';

    inv_5_par_1_opt_bus_val:string = '';
    inv_5_par_2_opt_bus_val:string = '';   
    inv_5_par_3_opt_bus_val:string = '';


    inv_6_max_score: string = '';
    inv_6_option_1: string = '';
    inv_6_option_2: string = '';
    inv_6_option_3: string = '';

    inv_6_par_1_gen_opt_val: string = '';
    inv_6_par_2_gen_opt_val: string = '';
    inv_6_par_3_gen_opt_val: string = '';

    inv_6_par_1_opt_bus_val:string = '';
    inv_6_par_2_opt_bus_val:string = '';   
    inv_6_par_3_opt_bus_val:string = '';

    inv_7_max_score: string = '';
    inv_7_option_1: string = '';
    inv_7_option_2: string = '';
    inv_7_option_3: string = '';

    inv_7_par_1_gen_opt_val: string = '';
    inv_7_par_2_gen_opt_val: string = '';
    inv_7_par_3_gen_opt_val: string = '';

    inv_7_par_1_opt_bus_val:string = '';
    inv_7_par_2_opt_bus_val:string = '';   
    inv_7_par_3_opt_bus_val:string = '';


    inv_8_max_score: string = '';
    inv_8_option_1: string = '';
    inv_8_option_2: string = '';
    inv_8_option_3: string = '';

    inv_8_par_1_gen_opt_val: string = '';
    inv_8_par_2_gen_opt_val: string = '';
    inv_8_par_3_gen_opt_val: string = '';

    inv_8_par_1_opt_bus_val:string = '';
    inv_8_par_2_opt_bus_val:string = '';   
    inv_8_par_3_opt_bus_val:string = '';


    inv_9_max_score: string = '';
    inv_9_option_1: string = '';
    inv_9_option_2: string = '';
    inv_9_option_3: string = '';

    inv_9_par_1_gen_opt_val: string = '';
    inv_9_par_2_gen_opt_val: string = '';
    inv_9_par_3_gen_opt_val: string = '';

    inv_9_par_1_opt_bus_val:string = '';
    inv_9_par_2_opt_bus_val:string = '';   
    inv_9_par_3_opt_bus_val:string = '';

    inv_tot_score: number = 0;
    inv_net_score: number = 0;


    inv_1_sub_max_score: string = '';
    inv_1_sub_option_1: string = '';
    inv_1_sub_option_2: string = '';
    inv_1_sub_option_3: string = '';

    inv_2_sub_max_score: string = '';
    inv_2_sub_option_1: string = '';
    inv_2_sub_option_2: string = '';
    inv_2_sub_option_3: string = '';

    inv_3_sub_max_score: string = '';
    inv_3_sub_option_1: string = '';
    inv_3_sub_option_2: string = '';
    inv_3_sub_option_3: string = '';


    inv_4_sub_max_score: string = '';
    inv_4_sub_option_1: string = '';
    inv_4_sub_option_2: string = '';
    inv_4_sub_option_3: string = '';

    inv_5_sub_max_score: string = '';
    inv_5_sub_option_1: string = '';
    inv_5_sub_option_2: string = '';
    inv_5_sub_option_3: string = '';

    inv_6_sub_max_score: string = '';
    inv_6_sub_option_1: string = '';
    inv_6_sub_option_2: string = '';
    inv_6_sub_option_3: string = '';

    inv_7_sub_max_score: string = '';
    inv_7_sub_option_1: string = '';
    inv_7_sub_option_2: string = '';
    inv_7_sub_option_3: string = '';

    inv_8_sub_max_score: string = '';
    inv_8_sub_option_1: string = '';
    inv_8_sub_option_2: string = '';
    inv_8_sub_option_3: string = '';

    inv_9_sub_max_score: string = '';
    inv_9_sub_option_1: string = '';
    inv_9_sub_option_2: string = '';
    inv_9_sub_option_3: string = '';

    bus_1_max_score: string = '';
    bus_1_option_1: string = '';
    bus_1_option_2: string = '';
    bus_1_option_3: string = '';

    bus_1_par_1_gen_opt_val: string = '';
    bus_1_par_2_gen_opt_val: string = '';
    bus_1_par_3_gen_opt_val: string = '';

    bus_1_par_1_opt_bus_val:string = '';
    bus_1_par_2_opt_bus_val:string = '';   
    bus_1_par_3_opt_bus_val:string = '';


    bus_2_max_score: string = '';
    bus_2_option_1: string = '';
    bus_2_option_2: string = '';
    bus_2_option_3: string = '';

    bus_2_par_1_gen_opt_val: string = '';
    bus_2_par_2_gen_opt_val: string = '';
    bus_2_par_3_gen_opt_val: string = '';

    bus_2_par_1_opt_bus_val:string = '';
    bus_2_par_2_opt_bus_val:string = '';   
    bus_2_par_3_opt_bus_val:string = '';


    bus_3_max_score: string = '';
    bus_3_option_1: string = '';
    bus_3_option_2: string = '';
    bus_3_option_3: string = '';

    bus_3_par_1_gen_opt_val: string = '';
    bus_3_par_2_gen_opt_val: string = '';
    bus_3_par_3_gen_opt_val: string = '';

    bus_3_par_1_opt_bus_val:string = '';
    bus_3_par_2_opt_bus_val:string = '';   
    bus_3_par_3_opt_bus_val:string = '';

    bus_4_max_score: string = '';
    bus_4_option_1: string = '';
    bus_4_option_2: string = '';
    bus_4_option_3: string = '';

    bus_4_par_1_gen_opt_val: string = '';
    bus_4_par_2_gen_opt_val: string = '';
    bus_4_par_3_gen_opt_val: string = '';

    bus_4_par_1_opt_bus_val:string = '';
    bus_4_par_2_opt_bus_val:string = '';   
    bus_4_par_3_opt_bus_val:string = '';


    bus_5_max_score: string = '';
    bus_5_option_1: string = '';
    bus_5_option_2: string = '';
    bus_5_option_3: string = '';

    bus_5_par_1_gen_opt_val: string = '';
    bus_5_par_2_gen_opt_val: string = '';
    bus_5_par_3_gen_opt_val: string = '';

    bus_5_par_1_opt_bus_val:string = '';
    bus_5_par_2_opt_bus_val:string = '';   
    bus_5_par_3_opt_bus_val:string = '';


    bus_6_max_score: string = '';
    bus_6_option_1: string = '';
    bus_6_option_2: string = '';
    bus_6_option_3: string = '';

    bus_6_par_1_gen_opt_val: string = '';
    bus_6_par_2_gen_opt_val: string = '';
    bus_6_par_3_gen_opt_val: string = '';

    bus_6_par_1_opt_bus_val:string = '';
    bus_6_par_2_opt_bus_val:string = '';   
    bus_6_par_3_opt_bus_val:string = '';

    bus_7_max_score: string = '';
    bus_7_option_1: string = '';
    bus_7_option_2: string = '';
    bus_7_option_3: string = '';

    bus_7_par_1_gen_opt_val: string = '';
    bus_7_par_2_gen_opt_val: string = '';
    bus_7_par_3_gen_opt_val: string = '';

    bus_7_par_1_opt_bus_val:string = '';
    bus_7_par_2_opt_bus_val:string = '';   
    bus_7_par_3_opt_bus_val:string = '';


    bus_8_max_score: string = '';
    bus_8_option_1: string = '';
    bus_8_option_2: string = '';
    bus_8_option_3: string = '';

    bus_8_par_1_gen_opt_val: string = '';
    bus_8_par_2_gen_opt_val: string = '';
    bus_8_par_3_gen_opt_val: string = '';

    bus_8_par_1_opt_bus_val:string = '';
    bus_8_par_2_opt_bus_val:string = '';   
    bus_8_par_3_opt_bus_val:string = '';


    bus_9_max_score: string = '';
    bus_9_option_1: string = '';
    bus_9_option_2: string = '';
    bus_9_option_3: string = '';

    bus_9_par_1_gen_opt_val: string = '';
    bus_9_par_2_gen_opt_val: string = '';
    bus_9_par_3_gen_opt_val: string = '';

    bus_9_par_1_opt_bus_val:string = '';
    bus_9_par_2_opt_bus_val:string = '';   
    bus_9_par_3_opt_bus_val:string = '';
    
    
    bus_10_max_score: string = '';
    bus_10_option_1: string = '';
    bus_10_option_2: string = '';
    bus_10_option_3: string = '';

    bus_10_par_1_gen_opt_val: string = '';
    bus_10_par_2_gen_opt_val: string = '';
    bus_10_par_3_gen_opt_val: string = '';

    bus_10_par_1_opt_bus_val:string = '';
    bus_10_par_2_opt_bus_val:string = '';
    bus_10_par_3_opt_bus_val:string = '';
    
    bus_11_max_score: string = '';
    bus_11_option_1: string = '';
    bus_11_option_2: string = '';
    bus_11_option_3: string = '';

    bus_11_par_1_gen_opt_val: string = '';
    bus_11_par_2_gen_opt_val: string = '';
    bus_11_par_3_gen_opt_val: string = '';

    bus_11_par_1_opt_bus_val:string = '';
    bus_11_par_2_opt_bus_val:string = '';
    bus_11_par_3_opt_bus_val:string = '';

    bus_12_max_score: string = '';
    bus_12_option_1: string = '';
    bus_12_option_2: string = '';
    bus_12_option_3: string = '';

    bus_12_par_1_gen_opt_val: string = '';
    bus_12_par_2_gen_opt_val: string = '';
    bus_12_par_3_gen_opt_val: string = '';

    bus_12_par_1_opt_bus_val:string = '';
    bus_12_par_2_opt_bus_val:string = '';   
    bus_12_par_3_opt_bus_val:string = '';
    
    
    bus_13_max_score: string = '';
    bus_13_option_1: string = '';
    bus_13_option_2: string = '';
    bus_13_option_3: string = '';

    bus_13_par_1_gen_opt_val: string = '';
    bus_13_par_2_gen_opt_val: string = '';
    bus_13_par_3_gen_opt_val: string = '';

    bus_13_par_1_opt_bus_val:string = '';
    bus_13_par_2_opt_bus_val:string = '';
    bus_13_par_3_opt_bus_val:string = '';

    bus_tot_score: number = 0;
    bus_net_score: number = 0;

    bus_1_sub_max_score: string = '';
    bus_1_sub_option_1: string = '';
    bus_1_sub_option_2: string = '';
    bus_1_sub_option_3: string = '';

    bus_2_sub_max_score: string = '';
    bus_2_sub_option_1: string = '';
    bus_2_sub_option_2: string = '';
    bus_2_sub_option_3: string = '';

    bus_3_sub_max_score: string = '';
    bus_3_sub_option_1: string = '';
    bus_3_sub_option_2: string = '';
    bus_3_sub_option_3: string = '';


    bus_4_sub_max_score: string = '';
    bus_4_sub_option_1: string = '';
    bus_4_sub_option_2: string = '';
    bus_4_sub_option_3: string = '';

    bus_5_sub_max_score: string = '';
    bus_5_sub_option_1: string = '';
    bus_5_sub_option_2: string = '';
    bus_5_sub_option_3: string = '';

    bus_6_sub_max_score: string = '';
    bus_6_sub_option_1: string = '';
    bus_6_sub_option_2: string = '';
    bus_6_sub_option_3: string = '';

    bus_7_sub_max_score: string = '';
    bus_7_sub_option_1: string = '';
    bus_7_sub_option_2: string = '';
    bus_7_sub_option_3: string = '';

    bus_8_sub_max_score: string = '';
    bus_8_sub_option_1: string = '';
    bus_8_sub_option_2: string = '';
    bus_8_sub_option_3: string = '';

    bus_9_sub_max_score: string = '';
    bus_9_sub_option_1: string = '';
    bus_9_sub_option_2: string = '';
    bus_9_sub_option_3: string = '';

    bus_10_sub_max_score: string = '';
    bus_10_sub_option_1: string = '';
    bus_10_sub_option_2: string = '';
    bus_10_sub_option_3: string = '';

    bus_11_sub_max_score: string = '';
    bus_11_sub_option_1: string = '';
    bus_11_sub_option_2: string = '';
    bus_11_sub_option_3: string = '';

    bus_12_sub_max_score: string = '';
    bus_12_sub_option_1: string = '';
    bus_12_sub_option_2: string = '';
    bus_12_sub_option_3: string = '';


    ind_1_max_score: string = '';
    ind_1_option_1: string = '';
    ind_1_option_2: string = '';
    ind_1_option_3: string = '';

    ind_1_par_1_gen_opt_val: string = '';
    ind_1_par_2_gen_opt_val: string = '';
    ind_1_par_3_gen_opt_val: string = '';

    ind_1_par_1_opt_bus_val:string = '';
    ind_1_par_2_opt_bus_val:string = '';
    ind_1_par_3_opt_bus_val:string = '';

    ind_tot_score: number = 0;
    ind_net_score: number = 0;

    ind_1_sub_max_score: string = '';
    ind_1_sub_option_1: string = '';
    ind_1_sub_option_2: string = '';
    ind_1_sub_option_3: string = '';    
    
    pro_1_max_score: string = '';
    pro_1_option_1: string = '';
    pro_1_option_2: string = '';
    pro_1_option_3: string = '';

    pro_1_par_1_gen_opt_val: string = '';
    pro_1_par_2_gen_opt_val: string = '';
    pro_1_par_3_gen_opt_val: string = '';

    pro_1_par_1_opt_bus_val:string = '';
    pro_1_par_2_opt_bus_val:string = '';
    pro_1_par_3_opt_bus_val:string = '';

    pro_tot_score: number = 0;
    pro_net_score: number = 0;

    pro_1_sub_max_score: string = '';
    pro_1_sub_option_1: string = '';
    pro_1_sub_option_2: string = '';
    pro_1_sub_option_3: string = '';

    categoryData: any = [];

    genData : any = [];
    subData : any = [];

    constructor(
    private userService: UserService, 
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private dataService: DataService,
    private toastr: ToastrService
    ) { 

    }

    /*createForm() {
        
    }*/

    ngOnInit() {

        this.loading = true;
        var curUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(curUser.username);
        if(curUser.username == 'admin'){
            this.loading = false;
            this.isAdmin = true;
            //this.createForm();
            this.dataService.getData().subscribe((data: any[])=>{              
              this.finData = data;
              console.log(this.finData);


                this.fin_1_max_score = this.finData[0]['rev_data'][0]['cat_data'][0]['par_max_value'];
                this.fin_1_option_1 = this.finData[0]['rev_data'][0]['cat_data'][0]['rules'][0]['option_1'];
                this.fin_1_option_2 = this.finData[0]['rev_data'][0]['cat_data'][0]['rules'][1]['option_2'];
                this.fin_1_option_3 = '';

                this.fin_1_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][0]['rules'][0]['output'];
                this.fin_1_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][0]['rules'][1]['output'];
                this.fin_1_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][0]['rules'][2]['output'];

                this.fin_1_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][0]['rules'][0]['output'];
                this.fin_1_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][0]['rules'][1]['output'];    
                this.fin_1_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][0]['rules'][2]['output'];


                this.fin_2_max_score = this.finData[0]['rev_data'][0]['cat_data'][1]['par_max_value'];
                this.fin_2_option_1 = this.finData[0]['rev_data'][0]['cat_data'][1]['rules'][0]['option_1'];
                this.fin_2_option_2 = this.finData[0]['rev_data'][0]['cat_data'][1]['rules'][1]['option_2'];
                this.fin_2_option_3 = '';

                this.fin_2_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][1]['rules'][0]['output'];
                this.fin_2_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][1]['rules'][1]['output'];
                this.fin_2_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][1]['rules'][2]['output'];

                this.fin_2_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][1]['rules'][0]['output'];
                this.fin_2_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][1]['rules'][1]['output'];   
                this.fin_2_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][1]['rules'][2]['output'];
                
                
                this.fin_3_max_score = this.finData[0]['rev_data'][0]['cat_data'][2]['par_max_value'];
                this.fin_3_option_1 = this.finData[0]['rev_data'][0]['cat_data'][2]['rules'][0]['option_1'];
                this.fin_3_option_2 = this.finData[0]['rev_data'][0]['cat_data'][2]['rules'][1]['option_2'];
                this.fin_3_option_3 = '';

                this.fin_3_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][2]['rules'][0]['output'];
                this.fin_3_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][2]['rules'][1]['output'];
                this.fin_3_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][2]['rules'][2]['output'];

                this.fin_3_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][2]['rules'][0]['output'];
                this.fin_3_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][2]['rules'][1]['output'];   
                this.fin_3_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][2]['rules'][2]['output'];

                this.fin_4_max_score = this.finData[0]['rev_data'][0]['cat_data'][3]['par_max_value'];
                this.fin_4_option_1 = this.finData[0]['rev_data'][0]['cat_data'][3]['rules'][0]['option_1'];
                this.fin_4_option_2 = "";
                this.fin_4_option_3 = '';

                this.fin_4_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][3]['rules'][0]['output'];
                this.fin_4_par_2_gen_opt_val = "";
                this.fin_4_par_3_gen_opt_val = "";

                this.fin_4_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][3]['rules'][0]['output'];
                this.fin_4_par_2_opt_bus_val = '';    
                this.fin_4_par_3_opt_bus_val = '';


                this.fin_5_max_score = this.finData[0]['rev_data'][0]['cat_data'][4]['par_max_value'];
                this.fin_5_option_1 = this.finData[0]['rev_data'][0]['cat_data'][4]['rules'][0]['option_1'];
                this.fin_5_option_2 = this.finData[0]['rev_data'][0]['cat_data'][4]['rules'][1]['option_2'];
                this.fin_5_option_3 = '';

                this.fin_5_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][4]['rules'][0]['output'];
                this.fin_5_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][4]['rules'][1]['output'];
                this.fin_5_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][4]['rules'][2]['output'];

                this.fin_5_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][4]['rules'][0]['output'];
                this.fin_5_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][4]['rules'][1]['output']; 
                this.fin_5_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][4]['rules'][2]['output'];


                this.fin_6_max_score = this.finData[0]['rev_data'][0]['cat_data'][5]['par_max_value'];
                this.fin_6_option_1 = this.finData[0]['rev_data'][0]['cat_data'][5]['rules'][0]['option_1'];
                this.fin_6_option_2 = this.finData[0]['rev_data'][0]['cat_data'][5]['rules'][1]['option_2'];
                this.fin_6_option_3 = '';

                this.fin_6_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][5]['rules'][0]['output'];
                this.fin_6_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][5]['rules'][1]['output'];
                this.fin_6_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][5]['rules'][2]['output'];

                this.fin_6_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][5]['rules'][0]['output'];
                this.fin_6_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][5]['rules'][1]['output'];   
                this.fin_6_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][5]['rules'][2]['output'];


                this.fin_7_max_score = this.finData[0]['rev_data'][0]['cat_data'][6]['par_max_value'];
                this.fin_7_option_1 = this.finData[0]['rev_data'][0]['cat_data'][6]['rules'][0]['option_1'];
                this.fin_7_option_2 = this.finData[0]['rev_data'][0]['cat_data'][6]['rules'][1]['option_2'];
                this.fin_7_option_3 = '';

                this.fin_7_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][6]['rules'][0]['output'];
                this.fin_7_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][6]['rules'][1]['output'];
                this.fin_7_par_3_gen_opt_val = "";

                this.fin_7_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][6]['rules'][0]['output'];
                this.fin_7_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][6]['rules'][1]['output'];   
                this.fin_7_par_3_opt_bus_val = '';


                this.fin_8_max_score = this.finData[0]['rev_data'][0]['cat_data'][7]['par_max_value'];
                this.fin_8_option_1 = this.finData[0]['rev_data'][0]['cat_data'][7]['rules'][0]['option_1'];
                this.fin_8_option_2 = this.finData[0]['rev_data'][0]['cat_data'][7]['rules'][1]['option_2'];
                this.fin_8_option_3 = '';

                this.fin_8_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][7]['rules'][0]['output'];
                this.fin_8_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][7]['rules'][1]['output'];
                this.fin_8_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][7]['rules'][2]['output'];

                this.fin_8_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][7]['rules'][0]['output'];
                this.fin_8_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][7]['rules'][1]['output'];    
                this.fin_8_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][7]['rules'][2]['output'];


                this.fin_9_max_score = this.finData[0]['rev_data'][0]['cat_data'][8]['par_max_value'];
                this.fin_9_option_1 = this.finData[0]['rev_data'][0]['cat_data'][8]['rules'][0]['option_1'];
                this.fin_9_option_2 = this.finData[0]['rev_data'][0]['cat_data'][8]['rules'][1]['option_2'];
                this.fin_9_option_3 = '';

                this.fin_9_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][8]['rules'][0]['output'];
                this.fin_9_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][8]['rules'][1]['output'];
                this.fin_9_par_3_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][8]['rules'][2]['output'];

                this.fin_9_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][8]['rules'][0]['output'];
                this.fin_9_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][8]['rules'][1]['output']; 
                this.fin_9_par_3_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][8]['rules'][2]['output'];

                this.fin_10_max_score = this.finData[0]['rev_data'][0]['cat_data'][9]['par_max_value'];
                this.fin_10_option_1 = this.finData[0]['rev_data'][0]['cat_data'][9]['rules'][0]['option_1'];
                this.fin_10_option_2 = this.finData[0]['rev_data'][0]['cat_data'][9]['rules'][1]['option_2'];
                this.fin_10_option_3 = '';

                this.fin_10_par_1_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][9]['rules'][0]['output'];
                this.fin_10_par_2_gen_opt_val = this.finData[0]['rev_data'][0]['cat_data'][9]['rules'][1]['output'];
                this.fin_10_par_3_gen_opt_val = "";

                this.fin_10_par_1_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][9]['rules'][0]['output'];
                this.fin_10_par_2_opt_bus_val = this.finData[1]['rev_data'][0]['cat_data'][9]['rules'][1]['output'];  
                this.fin_10_par_3_opt_bus_val = '';

                this.fin_1_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][0]['par_max_value'];
                this.fin_1_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][0]['rules'][0]['option_1'];
                this.fin_1_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][0]['rules'][1]['option_2'];
                this.fin_1_sub_option_3 = '';
                
                this.fin_2_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][1]['par_max_value'];
                this.fin_2_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][1]['rules'][0]['option_1'];
                this.fin_2_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][1]['rules'][1]['option_2'];
                this.fin_2_sub_option_3 =  this.finData[1]['rev_data'][0]['cat_data'][1]['rules'][2]['option_1'];;
                
                this.fin_3_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][2]['par_max_value'];
                this.fin_3_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][2]['rules'][0]['option_1'];
                this.fin_3_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][2]['rules'][1]['option_2'];
                this.fin_3_sub_option_3 = this.finData[1]['rev_data'][0]['cat_data'][2]['rules'][2]['option_1'];
                
                this.fin_4_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][3]['par_max_value'];
                this.fin_4_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][3]['rules'][0]['option_1'];
                this.fin_4_sub_option_2 = "";
                this.fin_4_sub_option_3 = '';
                
                this.fin_5_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][4]['par_max_value'];
                this.fin_5_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][4]['rules'][0]['option_1'];
                this.fin_5_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][4]['rules'][1]['option_2'];
                this.fin_5_sub_option_3 = this.finData[1]['rev_data'][0]['cat_data'][4]['rules'][2]['option_1'];
                
                this.fin_6_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][5]['par_max_value'];
                this.fin_6_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][5]['rules'][0]['option_1'];
                this.fin_6_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][5]['rules'][1]['option_2'];
                this.fin_6_sub_option_3 = this.finData[1]['rev_data'][0]['cat_data'][5]['rules'][2]['option_1'];
                
                this.fin_7_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][6]['par_max_value'];
                this.fin_7_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][6]['rules'][0]['option_1'];
                this.fin_7_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][6]['rules'][1]['option_2'];
                this.fin_7_sub_option_3 = '';
                
                this.fin_8_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][7]['par_max_value'];
                this.fin_8_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][7]['rules'][0]['option_1'];
                this.fin_8_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][7]['rules'][1]['option_2'];
                this.fin_8_sub_option_3 = '';
                
                this.fin_9_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][8]['par_max_value'];
                this.fin_9_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][8]['rules'][0]['option_1'];
                this.fin_9_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][8]['rules'][1]['option_2'];
                this.fin_9_sub_option_3 = '';
                
                this.fin_10_sub_max_score = this.finData[1]['rev_data'][0]['cat_data'][9]['par_max_value'];
                this.fin_10_sub_option_1 = this.finData[1]['rev_data'][0]['cat_data'][9]['rules'][0]['option_1'];
                this.fin_10_sub_option_2 = this.finData[1]['rev_data'][0]['cat_data'][9]['rules'][1]['option_2'];
                this.fin_10_sub_option_3 = '';


                this.inv_1_max_score = this.finData[0]['rev_data'][1]['cat_data'][0]['par_max_value'];
                this.inv_1_option_1 = this.finData[0]['rev_data'][1]['cat_data'][0]['rules'][0]['option_1'];
                this.inv_1_option_2 = this.finData[0]['rev_data'][1]['cat_data'][0]['rules'][1]['option_2'];
                this.inv_1_option_3 = '';

                this.inv_1_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][0]['rules'][0]['output'];
                this.inv_1_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][0]['rules'][1]['output'];
                this.inv_1_par_3_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][0]['rules'][2]['output'];

                this.inv_1_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][0]['rules'][0]['output'];
                this.inv_1_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][0]['rules'][1]['output'];
                this.inv_1_par_3_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][0]['rules'][2]['output'];


                this.inv_2_max_score = this.finData[0]['rev_data'][1]['cat_data'][1]['par_max_value'];
                this.inv_2_option_1 = this.finData[0]['rev_data'][1]['cat_data'][1]['rules'][0]['option_1'];
                this.inv_2_option_2 = this.finData[0]['rev_data'][1]['cat_data'][1]['rules'][1]['option_2'];
                this.inv_2_option_3 = '';

                this.inv_2_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][1]['rules'][0]['output'];
                this.inv_2_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][1]['rules'][1]['output'];
                this.inv_2_par_3_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][1]['rules'][2]['output'];

                this.inv_2_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][1]['rules'][0]['output'];
                this.inv_2_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][1]['rules'][1]['output'];   
                this.inv_2_par_3_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][1]['rules'][2]['output'];


                this.inv_3_max_score = this.finData[0]['rev_data'][1]['cat_data'][2]['par_max_value'];
                this.inv_3_option_1 = this.finData[0]['rev_data'][1]['cat_data'][2]['rules'][0]['option_1'];
                this.inv_3_option_2 = this.finData[0]['rev_data'][1]['cat_data'][2]['rules'][1]['option_2'];
                this.inv_3_option_3 = '';

                this.inv_3_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][2]['rules'][0]['output'];
                this.inv_3_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][2]['rules'][1]['output'];
                this.inv_3_par_3_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][2]['rules'][2]['output'];

                this.inv_3_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][2]['rules'][0]['output'];
                this.inv_3_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][2]['rules'][1]['output'];  
                this.inv_3_par_3_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][2]['rules'][2]['output'];

                this.inv_4_max_score = this.finData[0]['rev_data'][1]['cat_data'][3]['par_max_value'];
                this.inv_4_option_1 = this.finData[0]['rev_data'][1]['cat_data'][3]['rules'][0]['option_1'];
                this.inv_4_option_2 = this.finData[0]['rev_data'][1]['cat_data'][3]['rules'][1]['option_2'];
                this.inv_4_option_3 = '';

                this.inv_4_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][3]['rules'][0]['output'];
                this.inv_4_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][3]['rules'][1]['output'];
                this.inv_4_par_3_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][3]['rules'][2]['output'];

                this.inv_4_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][3]['rules'][0]['output'];
                this.inv_4_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][3]['rules'][1]['output'];    
                this.inv_4_par_3_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][3]['rules'][2]['output'];


                this.inv_5_max_score = this.finData[0]['rev_data'][1]['cat_data'][4]['par_max_value'];
                this.inv_5_option_1 = this.finData[0]['rev_data'][1]['cat_data'][4]['rules'][0]['option_1'];
                this.inv_5_option_2 = this.finData[0]['rev_data'][1]['cat_data'][4]['rules'][1]['option_2'];
                this.inv_5_option_3 = '';

                this.inv_5_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][4]['rules'][0]['output'];
                this.inv_5_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][4]['rules'][1]['output'];
                this.inv_5_par_3_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][4]['rules'][2]['output'];

                this.inv_5_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][4]['rules'][0]['output'];
                this.inv_5_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][4]['rules'][1]['output'];    
                this.inv_5_par_3_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][4]['rules'][2]['output'];


                this.inv_6_max_score = this.finData[0]['rev_data'][1]['cat_data'][5]['par_max_value'];
                this.inv_6_option_1 = this.finData[0]['rev_data'][1]['cat_data'][5]['rules'][0]['option_1'];
                this.inv_6_option_2 = this.finData[0]['rev_data'][1]['cat_data'][5]['rules'][1]['option_2'];
                this.inv_6_option_3 = '';

                this.inv_6_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][5]['rules'][0]['output'];
                this.inv_6_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][5]['rules'][1]['output'];
                this.inv_6_par_3_gen_opt_val = "";

                this.inv_6_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][5]['rules'][0]['output'];
                this.inv_6_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][5]['rules'][1]['output']; 
                this.inv_6_par_3_opt_bus_val = '';


                this.inv_7_max_score = this.finData[0]['rev_data'][1]['cat_data'][6]['par_max_value'];
                this.inv_7_option_1 = this.finData[0]['rev_data'][1]['cat_data'][6]['rules'][0]['option_1'];
                this.inv_7_option_2 = this.finData[0]['rev_data'][1]['cat_data'][6]['rules'][1]['option_1'];
                this.inv_7_option_3 = '';

                this.inv_7_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][6]['rules'][0]['output'];
                this.inv_7_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][6]['rules'][1]['output'];
                this.inv_7_par_3_gen_opt_val = '';

                this.inv_7_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][6]['rules'][0]['output'];
                this.inv_7_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][6]['rules'][1]['output'];   
                this.inv_7_par_3_opt_bus_val = '';


                this.inv_8_max_score = this.finData[0]['rev_data'][1]['cat_data'][7]['par_max_value'];
                this.inv_8_option_1 = this.finData[0]['rev_data'][1]['cat_data'][7]['rules'][0]['option_1'];
                this.inv_8_option_2 = this.finData[0]['rev_data'][1]['cat_data'][7]['rules'][1]['option_2'];
                this.inv_8_option_3 = '';

                this.inv_8_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][7]['rules'][0]['output'];
                this.inv_8_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][7]['rules'][1]['output'];
                this.inv_8_par_3_gen_opt_val = '';

                this.inv_8_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][7]['rules'][0]['output'];
                this.inv_8_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][7]['rules'][1]['output'];
                this.inv_8_par_3_opt_bus_val = '';


                this.inv_9_max_score = this.finData[0]['rev_data'][1]['cat_data'][8]['par_max_value'];
                this.inv_9_option_1 = this.finData[0]['rev_data'][1]['cat_data'][8]['rules'][0]['option_1'];
                this.inv_9_option_2 = this.finData[0]['rev_data'][1]['cat_data'][8]['rules'][1]['option_2'];
                this.inv_9_option_3 = '';

                this.inv_9_par_1_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][8]['rules'][0]['output'];
                this.inv_9_par_2_gen_opt_val = this.finData[0]['rev_data'][1]['cat_data'][8]['rules'][1]['output'];
                this.inv_9_par_3_gen_opt_val = '';

                this.inv_9_par_1_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][8]['rules'][0]['output'];
                this.inv_9_par_2_opt_bus_val = this.finData[1]['rev_data'][1]['cat_data'][8]['rules'][1]['output'];    
                this.inv_9_par_3_opt_bus_val = '';


                this.inv_1_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][0]['par_max_value'];
                this.inv_1_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][0]['rules'][0]['option_1'];
                this.inv_1_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][0]['rules'][1]['option_2'];
                this.inv_1_sub_option_3 = this.finData[1]['rev_data'][1]['cat_data'][0]['rules'][2]['option_1'];


                this.inv_2_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][1]['par_max_value'];
                this.inv_2_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][1]['rules'][0]['option_1'];
                this.inv_2_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][1]['rules'][1]['option_2'];
                this.inv_2_sub_option_3 = this.finData[1]['rev_data'][1]['cat_data'][1]['rules'][2]['option_1'];


                this.inv_3_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][2]['par_max_value'];
                this.inv_3_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][2]['rules'][0]['option_1'];
                this.inv_3_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][2]['rules'][1]['option_2'];
                this.inv_3_sub_option_3 = '';

                this.inv_4_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][3]['par_max_value'];
                this.inv_4_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][3]['rules'][0]['option_1'];
                this.inv_4_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][3]['rules'][1]['option_2'];
                this.inv_4_sub_option_3 = '';



                this.inv_5_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][4]['par_max_value'];
                this.inv_5_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][4]['rules'][0]['option_1'];;
                this.inv_5_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][4]['rules'][1]['option_2'];;
                this.inv_5_sub_option_3 = '';



                this.inv_6_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][5]['par_max_value'];
                this.inv_6_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][5]['rules'][0]['option_1'];;
                this.inv_6_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][5]['rules'][1]['option_2'];;
                this.inv_6_sub_option_3 = '';



                this.inv_7_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][6]['par_max_value'];
                this.inv_7_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][6]['rules'][0]['option_1'];
                this.inv_7_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][6]['rules'][1]['option_1'];
                this.inv_7_sub_option_3 = '';



                this.inv_8_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][7]['par_max_value'];
                this.inv_8_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][7]['rules'][0]['option_1'];
                this.inv_8_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][7]['rules'][1]['option_2'];
                this.inv_8_sub_option_3 = '';


                this.inv_9_sub_max_score = this.finData[1]['rev_data'][1]['cat_data'][8]['par_max_value'];
                this.inv_9_sub_option_1 = this.finData[1]['rev_data'][1]['cat_data'][8]['rules'][0]['option_1'];
                this.inv_9_sub_option_2 = this.finData[1]['rev_data'][1]['cat_data'][8]['rules'][1]['option_2'];
                this.inv_9_sub_option_3 = '';

                this.bus_1_max_score = this.finData[0]['rev_data'][2]['cat_data'][0]['par_max_value'];
                this.bus_1_option_1 = this.finData[0]['rev_data'][2]['cat_data'][0]['rules'][0]['option_1'];
                this.bus_1_option_2 = this.finData[0]['rev_data'][2]['cat_data'][0]['rules'][1]['option_1'];
                this.bus_1_option_3 = '';

                this.bus_1_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][0]['rules'][0]['output'];
                this.bus_1_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][0]['rules'][1]['output'];
                this.bus_1_par_3_gen_opt_val = '';

                this.bus_1_par_1_opt_bus_val = '';
                this.bus_1_par_2_opt_bus_val = '';    
                this.bus_1_par_3_opt_bus_val = '';


                this.bus_2_max_score = this.finData[0]['rev_data'][2]['cat_data'][1]['par_max_value'];
                this.bus_2_option_1 = this.finData[0]['rev_data'][2]['cat_data'][1]['rules'][0]['option_1'];
                this.bus_2_option_2 = this.finData[0]['rev_data'][2]['cat_data'][1]['rules'][1]['option_2'];
                this.bus_2_option_3 = '';

                this.bus_2_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][1]['rules'][0]['output'];
                this.bus_2_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][1]['rules'][1]['output'];
                this.bus_2_par_3_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][1]['rules'][2]['output'];

                this.bus_2_par_1_opt_bus_val = '';
                this.bus_2_par_2_opt_bus_val = '';    
                this.bus_2_par_3_opt_bus_val = '';
                
                
                this.bus_3_max_score = this.finData[0]['rev_data'][2]['cat_data'][2]['par_max_value'];
                this.bus_3_option_1 = this.finData[0]['rev_data'][2]['cat_data'][2]['rules'][0]['option_1'];
                this.bus_3_option_2 = this.finData[0]['rev_data'][2]['cat_data'][2]['rules'][1]['option_1'];
                this.bus_3_option_3 = '';

                this.bus_3_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][2]['rules'][0]['output'];
                this.bus_3_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][2]['rules'][1]['output'];
                this.bus_3_par_3_gen_opt_val = '';

                this.bus_3_par_1_opt_bus_val = '';
                this.bus_3_par_2_opt_bus_val = '';    
                this.bus_3_par_3_opt_bus_val = '';

                this.bus_4_max_score = this.finData[0]['rev_data'][2]['cat_data'][3]['par_max_value'];
                this.bus_4_option_1 = this.finData[0]['rev_data'][2]['cat_data'][3]['rules'][0]['option_1'];
                this.bus_4_option_2 = this.finData[0]['rev_data'][2]['cat_data'][3]['rules'][1]['option_1'];
                this.bus_4_option_3 = '';

                this.bus_4_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][3]['rules'][0]['output'];
                this.bus_4_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][3]['rules'][1]['output'];
                this.bus_4_par_3_gen_opt_val = "";

                this.bus_4_par_1_opt_bus_val = '';
                this.bus_4_par_2_opt_bus_val = '';    
                this.bus_4_par_3_opt_bus_val = '';


                this.bus_5_max_score = this.finData[0]['rev_data'][2]['cat_data'][4]['par_max_value'];
                this.bus_5_option_1 = this.finData[0]['rev_data'][2]['cat_data'][4]['rules'][0]['option_1'];
                this.bus_5_option_2 = this.finData[0]['rev_data'][2]['cat_data'][4]['rules'][1]['option_1'];
                this.bus_5_option_3 = '';

                this.bus_5_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][4]['rules'][0]['output'];
                this.bus_5_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][4]['rules'][1]['output'];
                this.bus_5_par_3_gen_opt_val = '';

                this.bus_5_par_1_opt_bus_val = '';
                this.bus_5_par_2_opt_bus_val = '';    
                this.bus_5_par_3_opt_bus_val = '';


                this.bus_6_max_score = this.finData[0]['rev_data'][2]['cat_data'][5]['par_max_value'];
                this.bus_6_option_1 = this.finData[0]['rev_data'][2]['cat_data'][5]['rules'][0]['option_1'];
                this.bus_6_option_2 = this.finData[0]['rev_data'][2]['cat_data'][5]['rules'][1]['option_1'];
                this.bus_6_option_3 = '';

                this.bus_6_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][5]['rules'][0]['output'];
                this.bus_6_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][5]['rules'][1]['output'];
                this.bus_6_par_3_gen_opt_val = '';

                this.bus_6_par_1_opt_bus_val = '';
                this.bus_6_par_2_opt_bus_val = '';    
                this.bus_6_par_3_opt_bus_val = '';


                this.bus_7_max_score = this.finData[0]['rev_data'][2]['cat_data'][6]['par_max_value'];
                this.bus_7_option_1 = this.finData[0]['rev_data'][2]['cat_data'][6]['rules'][0]['option_1'];
                this.bus_7_option_2 = this.finData[0]['rev_data'][2]['cat_data'][6]['rules'][1]['option_2'];
                this.bus_7_option_3 = '';

                this.bus_7_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][6]['rules'][0]['output'];
                this.bus_7_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][6]['rules'][1]['output'];
                this.bus_7_par_3_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][6]['rules'][2]['output'];

                this.bus_7_par_1_opt_bus_val = '';
                this.bus_7_par_2_opt_bus_val = '';    
                this.bus_7_par_3_opt_bus_val = '';


                this.bus_8_max_score = this.finData[0]['rev_data'][2]['cat_data'][7]['par_max_value'];
                this.bus_8_option_1 = this.finData[0]['rev_data'][2]['cat_data'][7]['rules'][0]['option_1'];
                this.bus_8_option_2 = this.finData[0]['rev_data'][2]['cat_data'][7]['rules'][1]['option_1'];
                this.bus_8_option_3 = '';

                this.bus_8_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][7]['rules'][0]['output'];
                this.bus_8_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][7]['rules'][1]['output'];
                this.bus_8_par_3_gen_opt_val = "";

                this.bus_8_par_1_opt_bus_val = '';
                this.bus_8_par_2_opt_bus_val = '';    
                this.bus_8_par_3_opt_bus_val = '';


                this.bus_9_max_score = this.finData[0]['rev_data'][2]['cat_data'][8]['par_max_value'];
                this.bus_9_option_1 = this.finData[0]['rev_data'][2]['cat_data'][8]['rules'][0]['option_1'];
                this.bus_9_option_2 = this.finData[0]['rev_data'][2]['cat_data'][8]['rules'][1]['option_1'];
                this.bus_9_option_3 = '';

                this.bus_9_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][8]['rules'][0]['output'];
                this.bus_9_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][8]['rules'][1]['output'];
                this.bus_9_par_3_gen_opt_val = '';

                this.bus_9_par_1_opt_bus_val = '';
                this.bus_9_par_2_opt_bus_val = '';    
                this.bus_9_par_3_opt_bus_val = '';

                this.bus_10_max_score = this.finData[0]['rev_data'][2]['cat_data'][9]['par_max_value'];
                this.bus_10_option_1 = this.finData[0]['rev_data'][2]['cat_data'][9]['rules'][0]['option_1'];
                this.bus_10_option_2 = this.finData[0]['rev_data'][2]['cat_data'][9]['rules'][1]['option_1'];
                this.bus_10_option_3 = '';

                this.bus_10_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][9]['rules'][0]['output'];
                this.bus_10_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][9]['rules'][1]['output'];
                this.bus_10_par_3_gen_opt_val = "";

                this.bus_10_par_1_opt_bus_val = '';
                this.bus_10_par_2_opt_bus_val = '';    
                this.bus_10_par_3_opt_bus_val = '';

                this.bus_11_max_score = this.finData[0]['rev_data'][2]['cat_data'][10]['par_max_value'];
                this.bus_11_option_1 = this.finData[0]['rev_data'][2]['cat_data'][10]['rules'][0]['option_1'];
                this.bus_11_option_2 = this.finData[0]['rev_data'][2]['cat_data'][10]['rules'][1]['option_2'];
                this.bus_11_option_3 = this.finData[0]['rev_data'][2]['cat_data'][10]['rules'][2]['option_1'];

                this.bus_11_par_1_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][10]['rules'][0]['output'];
                this.bus_11_par_2_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][10]['rules'][1]['output'];
                this.bus_11_par_3_gen_opt_val = this.finData[0]['rev_data'][2]['cat_data'][10]['rules'][2]['output'];

                this.bus_11_par_1_opt_bus_val = '';
                this.bus_11_par_2_opt_bus_val = '';    
                this.bus_11_par_3_opt_bus_val = '';


                
                
                this.bus_1_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][0]['par_max_value'];
                this.bus_1_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][0]['rules'][0]['option_1'];
                this.bus_1_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][0]['rules'][1]['option_1'];
                this.bus_1_sub_option_3 = '';

                this.bus_1_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][0]['rules'][0]['output'];
                this.bus_1_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][0]['rules'][1]['output'];
                this.bus_1_par_3_opt_bus_val = '';



                this.bus_2_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][1]['par_max_value'];
                this.bus_2_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][1]['rules'][0]['option_1'];
                this.bus_2_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][1]['rules'][1]['option_2'];
                this.bus_2_sub_option_3 = '';

                this.bus_2_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][1]['rules'][0]['output'];
                this.bus_2_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][1]['rules'][1]['output'];
                this.bus_2_par_3_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][1]['rules'][2]['output'];

 
                
                this.bus_3_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][2]['par_max_value'];
                this.bus_3_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][2]['rules'][0]['option_1'];
                this.bus_3_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][2]['rules'][1]['option_1'];
                this.bus_3_sub_option_3 = '';

                this.bus_3_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][2]['rules'][0]['output'];
                this.bus_3_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][2]['rules'][1]['output'];
                this.bus_3_par_3_opt_bus_val = '';

  

                this.bus_4_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][3]['par_max_value'];
                this.bus_4_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][3]['rules'][0]['option_1'];
                this.bus_4_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][3]['rules'][1]['option_1'];
                this.bus_4_sub_option_3 = '';

                this.bus_4_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][3]['rules'][0]['output'];
                this.bus_4_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][3]['rules'][1]['output'];
                this.bus_4_par_3_opt_bus_val = "";


                this.bus_5_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][4]['par_max_value'];
                this.bus_5_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][4]['rules'][0]['option_1'];
                this.bus_5_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][4]['rules'][1]['option_1'];
                this.bus_5_sub_option_3 = '';

                this.bus_5_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][4]['rules'][0]['output'];
                this.bus_5_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][4]['rules'][1]['output'];
                this.bus_5_par_3_opt_bus_val = '';

   
                this.bus_6_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][5]['par_max_value'];
                this.bus_6_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][5]['rules'][0]['option_1'];
                this.bus_6_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][5]['rules'][1]['option_1'];
                this.bus_6_sub_option_3 = '';

                this.bus_6_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][5]['rules'][0]['output'];
                this.bus_6_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][5]['rules'][1]['output'];
                this.bus_6_par_3_opt_bus_val = '';

    

                this.bus_7_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][6]['par_max_value'];
                this.bus_7_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][6]['rules'][0]['option_1'];
                this.bus_7_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][6]['rules'][1]['option_2'];
                this.bus_7_sub_option_3 = '';

                this.bus_7_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][6]['rules'][0]['output'];
                this.bus_7_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][6]['rules'][1]['output'];
                this.bus_7_par_3_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][6]['rules'][2]['output'];


                this.bus_8_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][7]['par_max_value'];
                this.bus_8_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][7]['rules'][0]['option_1'];
                this.bus_8_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][7]['rules'][1]['option_1'];
                this.bus_8_sub_option_3 = '';

                this.bus_8_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][7]['rules'][0]['output'];
                this.bus_8_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][7]['rules'][1]['output'];
                this.bus_8_par_3_opt_bus_val = "";


                this.bus_9_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][8]['par_max_value'];
                this.bus_9_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][8]['rules'][0]['option_1'];
                this.bus_9_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][8]['rules'][1]['option_1'];
                this.bus_9_sub_option_3 = '';

                this.bus_9_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][8]['rules'][0]['output'];
                this.bus_9_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][8]['rules'][1]['output'];
                this.bus_9_par_3_opt_bus_val = '';

                this.bus_10_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][9]['par_max_value'];
                this.bus_10_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][9]['rules'][0]['option_1'];
                this.bus_10_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][9]['rules'][1]['option_1'];
                this.bus_10_sub_option_3 = '';

                this.bus_10_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][9]['rules'][0]['output'];
                this.bus_10_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][9]['rules'][1]['output'];
                this.bus_10_par_3_opt_bus_val = "";

                this.bus_11_sub_max_score = this.finData[1]['rev_data'][2]['cat_data'][10]['par_max_value'];
                this.bus_11_sub_option_1 = this.finData[1]['rev_data'][2]['cat_data'][10]['rules'][0]['option_1'];
                this.bus_11_sub_option_2 = this.finData[1]['rev_data'][2]['cat_data'][10]['rules'][1]['option_2'];
                this.bus_11_sub_option_3 = this.finData[1]['rev_data'][2]['cat_data'][10]['rules'][2]['option_1'];

                this.bus_11_par_1_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][10]['rules'][0]['output'];
                this.bus_11_par_2_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][10]['rules'][1]['output'];
                this.bus_11_par_3_opt_bus_val = this.finData[1]['rev_data'][2]['cat_data'][10]['rules'][2]['output'];


                

                this.ind_1_max_score = this.finData[0]['rev_data'][3]['cat_data'][0]['par_max_value'];
                this.ind_1_option_1 = this.finData[0]['rev_data'][3]['cat_data'][0]['rules'][0]['option_1'];
                this.ind_1_option_2 = this.finData[0]['rev_data'][3]['cat_data'][0]['rules'][1]['option_1'];
                this.ind_1_option_3 = '';

                this.ind_1_par_1_gen_opt_val = this.finData[0]['rev_data'][3]['cat_data'][0]['rules'][0]['output'];
                this.ind_1_par_2_gen_opt_val = this.finData[0]['rev_data'][3]['cat_data'][0]['rules'][1]['output'];
                this.ind_1_par_3_gen_opt_val = '-NA-';

                this.ind_1_par_1_opt_bus_val = '';
                this.ind_1_par_2_opt_bus_val = '';    
                this.ind_1_par_3_opt_bus_val = '';
                
                this.pro_1_max_score = this.finData[0]['rev_data'][4]['cat_data'][0]['par_max_value'];
                this.pro_1_option_1 = this.finData[0]['rev_data'][4]['cat_data'][0]['rules'][0]['option_1'];
                this.pro_1_option_2 = '-NA-';
                this.pro_1_option_3 = '-NA-';

                this.pro_1_par_1_gen_opt_val = this.finData[0]['rev_data'][4]['cat_data'][0]['rules'][0]['output'];
                this.pro_1_par_2_gen_opt_val = '-NA-';
                this.pro_1_par_3_gen_opt_val = '-NA-';

                this.pro_1_par_1_opt_bus_val = '';
                this.pro_1_par_2_opt_bus_val = '';    
                this.pro_1_par_3_opt_bus_val = '';

                this.ind_1_sub_max_score = this.finData[1]['rev_data'][3]['cat_data'][0]['par_max_value'];
                this.ind_1_sub_option_1 = this.finData[1]['rev_data'][3]['cat_data'][0]['rules'][0]['option_1'];
                this.ind_1_sub_option_2 = this.finData[1]['rev_data'][3]['cat_data'][0]['rules'][1]['option_2'];
                this.ind_1_sub_option_3 = '';

                this.ind_1_par_1_opt_bus_val = this.finData[1]['rev_data'][3]['cat_data'][0]['rules'][0]['output'];
                this.ind_1_par_2_opt_bus_val = this.finData[1]['rev_data'][3]['cat_data'][0]['rules'][1]['output'];
                this.ind_1_par_3_opt_bus_val = '-NA-';
                
                this.pro_1_sub_max_score = this.finData[1]['rev_data'][4]['cat_data'][0]['par_max_value'];
                this.pro_1_sub_option_1 = this.finData[1]['rev_data'][4]['cat_data'][0]['rules'][0]['option_1'];
                this.pro_1_sub_option_2 = '-NA-';
                this.pro_1_sub_option_3 = '-NA-';

                this.pro_1_par_1_opt_bus_val = this.finData[1]['rev_data'][4]['cat_data'][0]['rules'][0]['output'];
                this.pro_1_par_2_opt_bus_val = '-NA-';
                this.pro_1_par_3_opt_bus_val = '-NA-';

            });

        }
        else {
            this.isAdmin = false;
            this.dataService.getData().subscribe((data: any[])=>{
              console.log(data);
              this.toastr.success('Data loaded successfully!!!');
              this.products = data;
              this.genData = [data[0]];
              this.subData = [data[1]];

              this.categoryData = data[0].rev_data;
                console.log(this.genData);
                console.log(this.subData);
                var fieCou = 0;

                for(var l=0; l<this.products[0]['rev_data'].length; l++) {
                    for(var k=0; k<this.products[0]['rev_data'][l]['cat_data'].length; k++) {
                        this.firstFields[fieCou] = '';
                        this.secondFields[fieCou] = '';
                        this.thirdFields[fieCou] = parseInt(this.products[0]['rev_data'][l]['cat_data'][k]['par_max_value']);
                        this.rules[fieCou] = this.products[0]['rev_data'][l]['cat_data'][k]['rules'];
                        fieCou++;
                        this.maxScoree += parseInt(this.products[0]['rev_data'][l]['cat_data'][k]['par_max_value']);
                    }
                }

                var cou=0;
                for(var ss=0; ss<this.products[1]['rev_data'].length;ss++) {
                    for(var kk=0; kk<this.products[1]['rev_data'][ss]['cat_data'].length; kk++) {
                        this.firstSubFields[cou] = '';
                        this.secondSubFields[cou] = '';
                        this.thirdSubFields[cou] = this.products[1]['rev_data'][ss]['cat_data'][kk]['par_max_value'];
                        this.subRules[cou] = this.products[1]['rev_data'][ss]['cat_data'][kk]['rules'];
                        cou++;
                        this.maxSubScoree += parseInt(this.products[1]['rev_data'][ss]['cat_data'][kk]['par_max_value']);
                    }
                }
                
                //console.log( this.firstFields, "FirstFields");
                //console.log( this.secondFields, "secondFields");
                //console.log( this.thirdFields, "thirdFields");
              //this.createForm(this.products.length);
            })
            
            this.userService.getAll().pipe(first()).subscribe(users => {
                this.loading = false;
                console.log(users);
                this.users = users;
            });
        }
       
    }

    cumulativeLength(firArr) {
        //console.log(firArr);
        if(firArr == 1) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length;
            return acc;
        }
        else if(firArr == 2) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length +  this.products[0].rev_data[firArr-2].cat_data.length;
            return acc;
        }
        else if(firArr == 3) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length +  this.products[0].rev_data[firArr-2].cat_data.length +  this.products[0].rev_data[firArr-3].cat_data.length;
            return acc;
        }
        else if(firArr == 4) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length +  this.products[0].rev_data[firArr-2].cat_data.length +  this.products[0].rev_data[firArr-3].cat_data.length +  this.products[0].rev_data[firArr-4].cat_data.length;
            return acc;
        }
        else {
            return 0;
        }
        
    }

    cumulativeSubLength(firArr) {
        //console.log(firArr);
        if(firArr == 1) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length;
            return acc;
        }
        else if(firArr == 2) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length +  this.products[1].rev_data[firArr-2].cat_data.length;
            return acc;
        }
        else if(firArr == 3) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length +  this.products[1].rev_data[firArr-2].cat_data.length +  this.products[1].rev_data[firArr-3].cat_data.length;
            return acc;
        }
        else if(firArr == 4) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length +  this.products[1].rev_data[firArr-2].cat_data.length +  this.products[1].rev_data[firArr-3].cat_data.length +  this.products[1].rev_data[firArr-4].cat_data.length;
            return acc;
        }
        else {
            return 0;
        }
        
    }

    onPerChange(catid, cou, $event, rules, parid) {
        var curValue = this.firstFields[cou];
        var rule = rules;
        console.log(cou);
        console.log(parid);
        /*console.log(catid);
        */
        console.log(rule);
        if(rule[0]["option_1"] !== 'undefined'){
            var rule_0_option_1 = parseFloat(rule[0]['option_1']);
        }
        
        if(rule[0]["output"]  !== 'undefined'){
            var rule_0_output = rule[0]['output'];
        }

        if(rule.length > 1) {
            if(rule[1]["option_1"] !== 'undefined'){
                var rule_1_option_1 = parseFloat(rule[1]['option_1']);
            }
            if(rule[1]["option_2"]  !== 'undefined'){
                var rule_1_option_2 = parseFloat(rule[1]['option_2']);
            }
            if(rule[1]["output"]  !== 'undefined'){
                var rule_1_output = rule[1]['output'];
            }
        }
        if(rule.length > 2) {
            if(rule[2]["option_1"]  !== 'undefined'){
                var rule_2_option_1 = parseInt(rule[2]['option_1']);
            }
            if(rule[2]["output"]  !== 'undefined'){
                var rule_2_output = rule[2]['output'];
            }

        }

                console.log(rule_0_option_1, "rule_0_option_1");
                console.log(rule_1_option_1, "rule_1_option_1");
                console.log(rule_1_option_2, "rule_1_option_2");
                console.log(rule_2_option_1, "rule_2_option_1");
                console.log(rule_0_output, "rule_0_output");
                console.log(rule_1_output, "rule_1_output");
                console.log(rule_2_output, "rule_2_output");
        if(catid == 0) {

            if(rule.length==3){
                if(parid == 6) {
                    if(rule_0_option_1 > curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "First1 this" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second1 thi"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third1 - -"  )
                    }
                }      
                else if(parid == 8) {
                    if(curValue < rule_0_option_1) {
                        this.secondFields[cou] = 'NO DEAL';
                        console.log(curValue, this.secondFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third1"  )
                    }
                }
                else if(parid == 9) {
                    if(curValue <= rule_0_option_1) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "1.9 cond 1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                        var calVal = ((rule_1_output*(curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1))).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "1.9 cond 2"  )

                    }
                    else if(rule_1_option_1 <= curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "1.9 cond 3   "  )
                    }
                }
                else if(parid == 5) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "1.5 con 1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "1.5 con 2"  )

                    }
                    else if(rule_2_option_1 <= curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "1.5 con 3"  )
                    }
                }
                else {
                    if(parid == 2) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                        }
                        this.firstFields[cou] = '';
                    }
                    if(parid == 3) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                            this.firstFields[cou] = '';
                        }
                        if(curValue > parseFloat(this.firstFields[cou-1])) {                           
                            this.toastr.error('Value cannot be more than EBITDA margin past financial year (%).');
                            this.firstFields[cou] = '';
                        }
                    }
                    if(rule_0_option_1 >= curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third1"  )
                    }
                }
                
            }
            else if(parid == 4 || parid == 7) {
                if(curValue >= rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = 0;   
                }
            }
            else if(rule.length == 2 && parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
        }
        else if (catid == 1) {
            if(parid == 4) {
                if(rule_0_option_1 > curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First12" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                     console.log(curValue, this.secondFields[cou], "Second12"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third12"  )
                }
            }
            else if(parid ==1 || parid ==2) {
                if(rule_0_option_1 >= curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                     var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                    console.log(curValue, this.secondFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third22"  )
                }
            }
            else if(parid ==5) {
                if(rule_0_option_1 > curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                    console.log(curValue, this.secondFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third22"  )
                }
            }
            else if(parid == 6 || parid == 8) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondFields[cou] = rule_0_output;
                }
                else {
                    this.secondFields[cou] = rule_1_output;
                }
            }
            else if(parid == 9) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondFields[cou] = rule_0_output;
                }
                else {
                    this.secondFields[cou] = rule_1_output;
                }
            }
            else if(parid ==7) {
                if(curValue == 'No') {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else {
                    var refVal = this.lpIRR;
                    if(refVal < rule_1_option_1) {
                        var calVal = (((refVal / rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                    }
                    else if (refVal > rule_1_option_1) {
                        this.secondFields[cou] = rule_1_output;
                    }
                }
            }
            else if(parid ==17) {
                if(curValue == 'No') {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue / rule_1_option_2)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                    console.log(curValue, this.secondFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third22"  )
                }
            }
            else {
                if(rule.length == 3) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "First22" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second22"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third22"  )
                    }
                }
                else if(rule.length == 2 && parid == 8) {
                    if(curValue == rule[0]['option_1']) {
                        this.secondFields[cou] = rule_0_output;                    
                    }
                    else if(curValue == rule[1]['option_1']) {
                        this.secondFields[cou] = rule_1_output;   
                    }
                }
                else if(rule.length == 2) {
                    if(curValue >= rule_0_option_1) {
                        this.secondFields[cou] = rule_0_output;                    
                    }
                    else if(curValue < rule_0_option_1) {
                        this.secondFields[cou] = 0;   
                    }
                }
                
            }
            
        }
        else if(catid == 2) {
            if(parid == 1) {
                if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 2) {
                if(rule_0_option_1 >= curValue) {
                    this.secondFields[cou] = rule_0_output;
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                    var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                }
            }

            else if(parid == 3 ) {
                if(curValue <= rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 4) {
                if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 9) {
                if(curValue <= rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 5 || parid == 6 || parid == 8 || parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 7) {
                if((curValue/100) < rule_0_option_1) {                    
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if((curValue/100) >= rule_1_option_1 && (curValue/100) < rule_1_option_2) {
                
                    this.secondFields[cou] = rule_1_output;   
                }
                else if((curValue/100) >= rule_2_option_1) {
                
                    this.secondFields[cou] = rule_2_output;   
                }
            }
            else if(parid == 11){
                if(rule_0_option_1 > curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First1" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                     console.log(curValue, this.secondFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third1"  )
                }
            }
            else if(parid == 12) {
                if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else {
                if(rule_0_option_1 >= curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First1" )
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                     console.log(curValue, this.secondFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third1"  )
                }
            }
        }
        else if(catid == 3) {
            if(parid == 1) {
                if(curValue == 'Healthcare' || curValue == 'Business to Business Technology' || curValue == 'Manufacturing' || curValue == 'Financial Services' || curValue == 'Business to Business Services' || curValue == 'Logistics' || curValue == 'FMCG' || curValue == 'Pharma') {                    
                    this.secondFields[cou] = 0;                    
                }
                else if(curValue == 'Infastructure' || curValue == 'Consumer Internet' || curValue == 'Real Estate' || curValue == 'Trading' || curValue == 'Food Retail' || curValue == 'Restaurants') {                
                    this.secondFields[cou] = 'NO DEAL';   
                }
            }
        }
        else if(catid == 4) {
            if(parid == 1) {
                if(curValue <= 150) {
                    this.secondFields[cou] = curValue/10;
                }
                else{
                    this.secondFields[cou] = 15;
                }
                
            }
        }
        console.log( this.secondFields, "secondFields");
        this.calculateScore(catid, parid);

    }

    calculateScore(ca_id, pa_id) {
        console.log("CA ID : ", ca_id);
        console.log("PA ID : ", pa_id);
        var fou = false;
        let finsss:number = 0;
        this.totScore = 0;
        let finssco:number = 0;
        for(var k=0; k<this.secondFields.length; k++) {
            if(this.secondFields[k] != 'NO DEAL') {
                if(this.secondFields[k] != '') {
                    let cuVa:number =  parseFloat(this.secondFields[k]);        
                    this.totScore = Math.round((this.totScore + cuVa) *100)/100;
                    this.finsco = ((this.totScore /this.maxScoree)*100); 
                    //finsss = (parseFloat(Math.round(this.finsco*10^2)/10^2));
                    
                    finssco = (Math.round(this.finsco * 100) / 100);                   
                    this.finsConv = parseFloat(finssco.toString());
                    finsss = (this.finsConv);
                }                               
            }
            else {
               fou = true;
            }
        }
        let toSc1:number = 0;
        let maSc1:number = 0;
        let neSc1:number = 0;

        let toSc2:number = 0;
        let maSc2:number = 0;
        let neSc2:number = 0;

        let toSc3:number = 0;
        let maSc3:number = 0;
        let neSc3:number = 0;

        let toSc4:number = 0;
        let maSc4:number = 0;
        let neSc4:number = 0;

        let toSc5:number = 0;
        let maSc5:number = 0;
        let neSc5:number = 0;

        if(ca_id == 0) {            
            for(var ll=0; ll<10; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc1 = toSc1 + (Math.round(this.secondFields[ll] * 100) / 100);                    
                }
                maSc1 = maSc1 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            console.log(maSc1, "maSc1");
            this.totScoreFields[ca_id] = Math.round(toSc1 * 100) / 100;
            neSc1 = ((this.totScoreFields[ca_id] /maSc1)*100);
            console.log(neSc1, "neSc1");
            //this.netScoreFields[ca_id] = Math.round(neSc1 * 100) / 100;
            this.netScoreFields[ca_id] = maSc1;
        }
        else if(ca_id == 1) {
             for(var ll=10; ll<19; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc2 = toSc2 + (Math.round(this.secondFields[ll] * 100) / 100);
                }
                    maSc2 = maSc2 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totScoreFields[ca_id] = Math.round(toSc2 * 100) / 100;
            neSc2 = ((this.totScoreFields[ca_id] /maSc2)*100);
            //this.netScoreFields[ca_id] = Math.round(neSc2 * 100) / 100;
            this.netScoreFields[ca_id] = maSc2;
        }
        else if(ca_id == 2) {
             for(var ll=19; ll<30; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc3 = toSc3 + (Math.round(this.secondFields[ll] * 100) / 100);
                }
                    maSc3 = maSc3 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totScoreFields[ca_id] = Math.round(toSc3 * 100) / 100;
            neSc3 = ((this.totScoreFields[ca_id] /maSc3)*100);
            //this.netScoreFields[ca_id] = Math.round(neSc3 * 100) / 100;
            this.netScoreFields[ca_id] = maSc3;
        }
        else if(ca_id == 3) {
             for(var ll=30; ll<31; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc4 = toSc4 + (Math.round(this.secondFields[ll] * 100) / 100);
                } 
            }
            this.totScoreFields[ca_id] = Math.round(toSc4 * 100) / 100;
            neSc4 = ((this.totScoreFields[ca_id]));
            //this.netScoreFields[ca_id] = Math.round(neSc4 * 100) / 100;
            this.netScoreFields[ca_id] = maSc4;
        }
        else if(ca_id == 4) {
             for(var ll=31; ll<32; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc5 = toSc5 + (Math.round(this.secondFields[ll] * 100) / 100);
                }
                    maSc5 = maSc5 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totScoreFields[ca_id] = Math.round(toSc5 * 100) / 100;
            neSc5 = ((this.totScoreFields[ca_id] /maSc5)*100);
            //this.netScoreFields[ca_id] = Math.round(neSc5 * 100) / 100;
            this.netScoreFields[ca_id] = maSc5;
        }

        if(fou == true) {
             this.netScore = 'NO DEAL';
        }
        else {
            this.netScore = (finsss).toString();
        }

        console.log(this.totScoreFields, "totScoreFields");
        console.log(this.netScoreFields, "netScoreFields");
        console.log(this.thirdFields, "thirdFields");
        console.log(this.secondFields, "secondFields");
    }

    onPerSubChange(catid, cou, $event, rules, parid) {
        var curValue = this.firstSubFields[cou];
        var rule = rules;
        console.log(cou);
        console.log(parid);
        /*console.log(catid);
        */
        console.log(rule);
        if(rule[0]["option_1"] !== 'undefined'){
            var rule_0_option_1 = parseFloat(rule[0]['option_1']);
        }
        
        if(rule[0]["output"]  !== 'undefined'){
            var rule_0_output = rule[0]['output'];
        }

        if(rule.length > 1) {
            if(rule[1]["option_1"] !== 'undefined'){
                var rule_1_option_1 = parseFloat(rule[1]['option_1']);
            }
            if(rule[1]["option_2"]  !== 'undefined'){
                var rule_1_option_2 = parseFloat(rule[1]['option_2']);
            }
            if(rule[1]["output"]  !== 'undefined'){
                var rule_1_output = rule[1]['output'];
            }
        }
        if(rule.length > 2) {
            if(rule[2]["option_1"]  !== 'undefined'){
                var rule_2_option_1 = parseInt(rule[2]['option_1']);
            }
            if(rule[2]["output"]  !== 'undefined'){
                var rule_2_output = rule[2]['output'];
            }

        }

                console.log(rule_0_option_1, "rule_0_option_1");
                console.log(rule_1_option_1, "rule_1_option_1");
                console.log(rule_1_option_2, "rule_1_option_2");
                console.log(rule_2_option_1, "rule_2_option_1");
                console.log(rule_0_output, "rule_0_output");
                console.log(rule_1_output, "rule_1_output");
                console.log(rule_2_output, "rule_2_output");
        if(catid == 0) {

            if(rule.length==3){
                if(parid == 6) {
                    if(rule_0_option_1 > curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "First1 this" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second1 thi"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third1 - -"  )
                    }
                }      
                else if(parid == 8) {
                    if(curValue < rule_0_option_1) {
                        this.secondSubFields[cou] = 'NO DEAL';
                        console.log(curValue, this.secondSubFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third1"  )
                    }
                }
                else if(parid == 9) {
                    if(curValue <= rule_0_option_1) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "1.9 cond 1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                        var calVal = ((rule_1_output*(curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1))).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "1.9 cond 2"  )

                    }
                    else if(rule_1_option_1 <= curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "1.9 cond 3   "  )
                    }
                }
                else if(parid == 5) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "1.5 con 1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "1.5 con 2"  )

                    }
                    else if(rule_2_option_1 <= curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "1.5 con 3"  )
                    }
                }
                else {
                    if(parid == 2) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                        }

                    }
                    if(parid == 3) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                            this.firstSubFields[cou] = '';
                        }
                        if(curValue > parseFloat(this.firstSubFields[cou-1])) {                           
                            this.toastr.error('Value cannot be more than EBITDA margin past financial year (%).');
                            this.firstSubFields[cou] = '';
                        }
                    }
                    if(rule_0_option_1 >= curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third1"  )
                    }
                }
                
            }
            else if(parid == 4 || parid == 7) {
                if(curValue >= rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = 0;   
                }
            }
            else if(rule.length == 2 && parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
        }
        else if (catid == 1) {
            if(parid == 4) {
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First12" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                     console.log(curValue, this.secondSubFields[cou], "Second12"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third12"  )
                }
            }
            else if(parid ==1 || parid ==2) {
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                     var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                    console.log(curValue, this.secondSubFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third22"  )
                }
            }
            else if(parid ==5) {
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                    console.log(curValue, this.secondSubFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third22"  )
                }
            }
            else if(parid == 6 || parid == 8) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;
                }
            }
            else if(parid == 9) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;
                }
            }
            else if(parid ==7) {                
                 if(curValue == 'No') {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First22" )
                }
                else {
                    var refVal = this.sublpIRR;
                    console.log(refVal, "refVal");
                    console.log(rule_1_option_1, "rule_1_option_1");
                    if(refVal < rule_1_option_1) {
                        var calVal = (((refVal / rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                    }
                    else if (refVal > rule_1_option_1) {
                        this.secondSubFields[cou] = rule_1_output;
                    }
                }
            }
            else {
                if(rule.length == 3) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "First22" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second22"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third22"  )
                    }
                }
                else if(rule.length == 2 && parid == 8) {
                    if(curValue == rule[0]['option_1']) {
                        this.secondSubFields[cou] = rule_0_output;                    
                    }
                    else if(curValue == rule[1]['option_1']) {
                        this.secondSubFields[cou] = rule_1_output;   
                    }
                }
                else if(rule.length == 2) {
                    if(curValue >= rule_0_option_1) {
                        this.secondSubFields[cou] = rule_0_output;                    
                    }
                    else if(curValue < rule_0_option_1) {
                        this.secondSubFields[cou] = 0;   
                    }
                }
                
            }
            
        }
        else if(catid == 2) {
            if(parid == 1) {
                if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 2) {
                if(rule_0_option_1 >= curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                    var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                }
            }

            else if(parid == 3 ) {
                if(curValue <= rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 4) {
                if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 9) {
                if(curValue <= rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 5 || parid == 6 || parid == 8 || parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 7) {
                if((curValue/100) < rule_0_option_1) {
                    
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if((curValue/100) >= rule_1_option_1 && (curValue/100) < rule_1_option_2) {
                
                    this.secondSubFields[cou] = rule_1_output;   
                }
                else if((curValue/100) >= rule_2_option_1) {
                
                    this.secondSubFields[cou] = rule_2_output;   
                }
            }
            else if(parid == 11){
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First1" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                     console.log(curValue, this.secondSubFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third1"  )
                }
            }
            else if(parid == 12) {
                if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else {
                if(rule_0_option_1 >= curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First1" )
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                     console.log(curValue, this.secondSubFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third1"  )
                }
            }
        }
        else if(catid == 3) {
            if(parid == 1) {
                if(curValue == 'Healthcare' || curValue == 'Business to Business Technology' || curValue == 'Manufacturing' || curValue == 'Financial Services' || curValue == 'Business to Business Services' || curValue == 'Logistics' || curValue == 'FMCG' || curValue == 'Pharma') {                    
                    this.secondSubFields[cou] = 0;                    
                }
                else if(curValue == 'Infastructure' || curValue == 'Consumer Internet' || curValue == 'Real Estate' || curValue == 'Trading' || curValue == 'Food Retail' || curValue == 'Restaurants') {                
                    this.secondSubFields[cou] = 'NO DEAL';   
                }
            }
        }
        else if(catid == 4) {
            if(parid == 1) {
                if(curValue <= 150) {
                    this.secondSubFields[cou] = curValue/10;
                }
                else{
                    this.secondSubFields[cou] = 15;
                }
                
            }
        }
        console.log( this.secondSubFields, "secondSubFields");
        this.calculateSubScore(catid, parid);

    }

    calculateSubScore(ca_id, pa_id) {
        console.log("CA ID : ", ca_id);
        console.log("PA ID : ", pa_id);
        var fou = false;
        let finsuss:number = 0;
        this.totSubScore = 0;
        let finssco:number = 0;
        for(var k=0; k<this.secondSubFields.length; k++) {
            if(this.secondSubFields[k] != 'NO DEAL') {
                if(this.secondSubFields[k] != '') {
                    let cuVa:number =  parseFloat(this.secondSubFields[k]);        
                    this.totSubScore = Math.round((this.totSubScore + cuVa) *100)/100;
                    this.finSubsco = ((this.totSubScore /this.maxScoree)*100);
                    
                    finssco = (Math.round(this.finSubsco * 100) / 100);                   
                    this.finsSubConv = parseFloat(finssco.toString());
                    finsuss = (this.finsSubConv);
                }                               
            }
            else {
               fou = true;
            }
        }
        let toSc1:number = 0;
        let maSc1:number = 0;
        let neSc1:number = 0;

        let toSc2:number = 0;
        let maSc2:number = 0;
        let neSc2:number = 0;

        let toSc3:number = 0;
        let maSc3:number = 0;
        let neSc3:number = 0;

        let toSc4:number = 0;
        let maSc4:number = 0;
        let neSc4:number = 0;

        let toSc5:number = 0;
        let maSc5:number = 0;
        let neSc5:number = 0;

        if(ca_id == 0) {            
            for(var ll=0; ll<pa_id; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc1 = toSc1 + (Math.round(this.secondSubFields[ll] * 100) / 100);                    
                }
                maSc1 = maSc1 + (Math.round(this.thirdFields[ll] * 100) / 100);              
            }

            this.totSubScoreFields[ca_id] = Math.round(toSc1 * 100) / 100;
            neSc1 = ((this.totSubScoreFields[ca_id] /maSc1)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc1 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc1;
        }
        else if(ca_id == 1) {
             for(var ll=10; ll<19; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc2 = toSc2 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                    
                }
                maSc2 = maSc2 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc2 * 100) / 100;
            neSc2 = ((this.totSubScoreFields[ca_id] /maSc2)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc2 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc2;
        }
        else if(ca_id == 2) {
             for(var ll=19; ll<30; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc3 = toSc3 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                    
                } 
                maSc3 = maSc3 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc3 * 100) / 100;
            neSc3 = ((this.totSubScoreFields[ca_id] /maSc3)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc3 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc3;
        }
        else if(ca_id == 3) {
             for(var ll=30; ll<31; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc4 = toSc4 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                } 
                maSc4 = maSc4 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc4 * 100) / 100;
            neSc4 = ((this.totSubScoreFields[ca_id]));
            //this.netSubScoreFields[ca_id] = Math.round(neSc4 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc4;
        }
        else if(ca_id == 4) {
             for(var ll=31; ll<32; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc5 = toSc5 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                    
                } 
                maSc5 = maSc5 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc5 * 100) / 100;
            neSc5 = ((this.totSubScoreFields[ca_id] /maSc5)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc5 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc5;
        }
        if(fou == true) {
             this.netSubScore = 'NO DEAL';
        }
        else {
            this.netSubScore = (finsuss).toString();
        }
    }

    addData() {
        var payload = [
            {
                "id": 1,
                "rev_id": 1,
                "rev_model": "General",
                "rev_data":[ 
                    {
                        "cat_id": 1,
                        "cat_name": "FINANCIAL INFORMATION",
                        "cat_data": [
                            {
                                "par_id":1,
                                "par_name": "Net revenue (past financial year) (INR million)",
                                "par_max_value": this.fin_1_max_score,
                                "par_val_format": "Rs.",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_1_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_1_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_1_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_1_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_1_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_1_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_1_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":2,
                                "par_name": "EBITDA margin past financial year (%)",
                                "par_max_value": this.fin_2_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_2_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_2_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_2_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_2_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_2_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_2_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_2_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":3,
                                "par_name": "PAT margin (past financial year) (%)",
                                "par_max_value": this.fin_3_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_3_option_1,
                                    "parameter_1": "less than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_3_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_3_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_3_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_3_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_3_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_3_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":4,
                                "par_name": "Inventory days - based on past financial year",
                                "par_max_value": this.fin_4_max_score,
                                "par_val_format": "d",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.fin_4_option_1,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_4_par_1_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":5,
                                "par_name": "Receivables days - based on past financial year",
                                "par_max_value": this.fin_5_max_score,
                                "par_val_format": "d",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_5_option_1,
                                    "parameter_1": "less than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_5_par_1_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_5_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_5_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "-sliding",
                                    "output" : this.fin_5_par_2_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_5_option_2,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_5_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":6,
                                "par_name": "Interest coverage ratio {EBIT/Interest Expenses}",
                                "par_max_value": this.fin_6_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_6",
                                "rules": [{
                                    "option_1": this.fin_6_option_1,
                                    "parameter_1": "less than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_6_par_1_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_6_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_6_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "-sliding",
                                    "output" : this.fin_6_par_2_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_6_option_2,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_6_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":7,
                                "par_name": "Free Cash Flow from Operations (INR  million)",
                                "par_max_value": this.fin_7_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.fin_7_option_1,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_7_par_1_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_7_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_7_par_2_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":8,
                                "par_name": "Revenue CAGR (past 2 years) (%)",
                                "par_max_value": this.fin_8_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_8_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_8_par_1_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_8_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_8_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_8_par_2_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_8_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_8_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":9,
                                "par_name": "Debt / Equity ratio",
                                "par_max_value": this.fin_9_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_9_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_9_par_1_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_9_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_9_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_9_par_2_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_9_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_9_par_3_gen_opt_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":10,
                                "par_name": "Is debt leverage structured in the transaction ?",
                                "par_max_value": this.fin_10_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.fin_10_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_10_par_1_gen_opt_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_10_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_10_par_2_gen_opt_val,
                                    "format" : "%"
                                }]                

                            }
                        ]
                    },
                    {
                        "cat_id": 2,
                        "cat_name": "INVESTMENT",
                        "cat_data": 
                        [
                            {
                                "par_id":1,
                                "par_name": "Valuation multiple ((Enterprise Value / EBITDA) for General) / {(Enterprise Value /Revenue) for Subscription}  - past financial year (INR  million)",
                                "par_max_value": this.inv_1_max_score,
                                "par_val_format": "x",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_1_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_1_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_1_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_1_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_1_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_1_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_1_par_3_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":2,
                                "par_name": "Valuation multiple ((Enterprise Value / EBITDA) for General) / {(Enterprise Value /Revenue) for Subscription}  - projected financial year (INR  million)",
                                "par_max_value": this.inv_2_max_score,
                                "par_val_format": "x",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_2_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_2_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_2_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_2_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_2_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_2_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_2_par_3_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":3,
                                "par_name": "Stakeboat Capital holding (%)",
                                "par_max_value": this.inv_3_max_score,
                                "par_val_format": "x",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_3_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_3_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_3_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_3_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_3_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_3_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_3_par_3_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":4,
                                "par_name": "Internal Rate of Return (IRR) (%)",
                                "par_max_value": this.inv_4_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_4_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_4_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_4_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_4_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_4_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_4_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_4_par_3_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":5,
                                "par_name": "Cash on Cash (CoC) multiple (times)",
                                "par_max_value": this.inv_5_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_5_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_5_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_5_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_5_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_5_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_5_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_5_par_3_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":6,
                                "par_name": "Exit rights",
                                "par_max_value": this.inv_6_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_6_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_6_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_6_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_6_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":7,
                                "par_name": "Liquidation Preference (If No LP, enter NO ; if Yes, then enter IRR%)",
                                "par_max_value": this.inv_7_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_7_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_7_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_7_option_2,
                                    "parameter_1": "greater than",                                
                                    "output_type": "sliding",
                                    "output" : this.inv_7_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":8,
                                "par_name": "Unique Rights negotiated",
                                "par_max_value": this.inv_8_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_8_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_8_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_8_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_8_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":9,
                                "par_name": "Are there international Strategic buyers ?  List 3 International Companies in the same space / List 2 transactions happened in the same space",
                                "par_max_value": this.inv_9_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_9_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_9_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_9_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_9_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            }
                        ]
                    }, 
                    {
                        "cat_id": 3,
                        "cat_name": "BUSINESS",
                        "cat_data": 
                        [
                            {
                                "par_id":1,
                                "par_name": "Customer Stickiness (%)",
                                "par_max_value": this.bus_1_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_1_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_1_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_1_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_1_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":2,
                                "par_name": "Customer concentration - Market share of top 2 customers (%)",
                                "par_max_value": this.bus_2_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.bus_2_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_2_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_2_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.bus_2_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.bus_2_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_2_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_2_par_3_gen_opt_val,
                                    "format" : "%"
                                }]            
                            },
                            {
                                "par_id":3,
                                "par_name": "% of revenue contributed by govt. customers (both directly and indirectly) (%)",
                                "par_max_value": this.bus_3_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_3_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_3_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_3_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_3_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":4,
                                "par_name": "Supplier concentration (of the top 3 suppliers) (%)",
                                "par_max_value": this.bus_4_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_4_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_4_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_4_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_4_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":5,
                                "par_name": "Potential risk of suppliers increasing the prices? ",
                                "par_max_value": this.bus_5_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_4",
                                "rules": [{
                                    "option_1": this.bus_5_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_5_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_5_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_5_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":6,
                                "par_name": "Is the business driven by operations or external factors like location, tax benefits, govt. grants, discounted pricing, subsidy? (Make sure that the impact is high if not then its can be relaxed)",
                                "par_max_value": this.bus_6_max_score,
                                "par_val_format": "%",                            
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.bus_6_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_6_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_6_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_6_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":7,
                                "par_name": "Is the pricing in the industry very competitive and can it impact the business? Herfindhal (HHL )Index - use the values and link it to pricing point",
                                "par_max_value": this.bus_7_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_7",
                                "rules": [{
                                    "option_1": this.bus_7_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_7_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_7_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.bus_7_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.bus_7_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_7_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_7_par_3_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":8,
                                "par_name": "Risk of disruption / technology",
                                "par_max_value": this.bus_8_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.bus_8_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_8_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_8_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_8_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":9,
                                "par_name": "Net currency risk (%)",
                                "par_max_value": this.bus_9_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_9_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_9_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_9_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_9_par_2_gen_opt_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":10,
                                "par_name": "Potential regulatory risk",
                                "par_max_value": this.bus_10_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.bus_10_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_10_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_10_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_10_par_2_gen_opt_val,
                                    "format" : "%"
                                }]         
                            },
                            {
                                "par_id":11,
                                "par_name": "Market Size (INR  million)",
                                "par_max_value": this.bus_11_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.bus_11_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_11_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_11_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.bus_11_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.bus_11_par_2_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_11_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_11_par_3_gen_opt_val,
                                    "format" : "%"
                                }]           
                            }
                        ]
                    }, 
                    {
                        "cat_id": 4,
                        "cat_name": "INDUSTRY",
                        "cat_data": [
                            {
                                "par_id":1,
                                "par_name": "Sector",
                                "par_max_value": this.ind_1_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_5",
                                "rules": [{
                                    "option_1": this.ind_1_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.ind_1_par_1_gen_opt_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.ind_1_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.ind_1_par_2_gen_opt_val,
                                    "format" : "%"
                                }]         
                            }
                        
                        ]
                    }, 
                    {
                        "cat_id": 5,
                        "cat_name": "PROMOTERS",
                        "cat_data": [
                            {
                                "par_id":1,
                                "par_name": "Management team (out of total score of 200)",
                                "par_max_value": this.pro_1_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_6",
                                "rules": [{
                                    "option_1": this.pro_1_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.pro_1_par_1_gen_opt_val,
                                    "format" : "%"
                                }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 2,
                "rev_id": 2,
                "rev_model": "Subscription",
                "rev_data":[ 
                    {
                        "cat_id": 1,
                        "cat_name": "FINANCIAL INFORMATION",
                        "cat_data": [
                            {
                                "par_id":1,
                                "par_name": "Net revenue (past financial year) (INR million)",
                                "par_max_value": this.fin_1_sub_max_score,
                                "par_val_format": "Rs.",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_1_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_1_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_1_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_1_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_1_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_1_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_1_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":2,
                                "par_name": "EBITDA margin past financial year (%)",
                                "par_max_value": this.fin_2_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_2_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_2_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_2_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_2_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_2_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_2_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_2_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":3,
                                "par_name": "PAT margin (past financial year) (%)",
                                "par_max_value": this.fin_3_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_3_sub_option_1,
                                    "parameter_1": "less than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_3_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_3_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_3_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_3_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.fin_3_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_3_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":4,
                                "par_name": "Inventory days - based on past financial year",
                                "par_max_value": this.fin_4_sub_max_score,
                                "par_val_format": "d",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.fin_4_sub_option_1,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_4_par_1_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":5,
                                "par_name": "Receivables days - based on past financial year",
                                "par_max_value": this.fin_5_sub_max_score,
                                "par_val_format": "d",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_5_sub_option_1,
                                    "parameter_1": "less than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_5_par_1_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_5_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_5_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "-sliding",
                                    "output" : this.fin_5_par_2_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_5_sub_option_2,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_5_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":6,
                                "par_name": "Interest coverage ratio {EBIT/Interest Expenses}",
                                "par_max_value": this.fin_6_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_6",
                                "rules": [{
                                    "option_1": this.fin_6_sub_option_1,
                                    "parameter_1": "less than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_6_par_1_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_6_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_6_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "-sliding",
                                    "output" : this.fin_6_par_2_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_6_sub_option_2,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_6_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":7,
                                "par_name": "Free Cash Flow from Operations (INR  million)",
                                "par_max_value": this.fin_7_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.fin_7_sub_option_1,
                                    "parameter_1": "greater than equal to",
                                    "output_type": "Fixed",
                                    "output" : this.fin_7_par_1_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_7_sub_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_7_par_2_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":8,
                                "par_name": "Revenue CAGR (past 2 years) (%)",
                                "par_max_value": this.fin_8_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_8_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_8_par_1_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_8_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_8_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_8_par_2_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_8_sub_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_8_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":9,
                                "par_name": "Debt / Equity ratio",
                                "par_max_value": this.fin_9_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.fin_9_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_9_par_1_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_9_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.fin_9_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.fin_9_par_2_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_9_sub_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_9_par_3_opt_bus_val,
                                    "format" : "%"
                                }]                

                            },
                            {
                                "par_id":10,
                                "par_name": "Is debt leverage structured in the transaction ?",
                                "par_max_value": this.fin_10_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.fin_10_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_10_par_1_opt_bus_val,
                                    "format" : "%"
                                },
                                {
                                    "option_1": this.fin_10_sub_option_2,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.fin_10_par_2_opt_bus_val,
                                    "format" : "%"
                                }]                

                            }
                        ]
                    },
                    {
                        "cat_id": 2,
                        "cat_name": "INVESTMENT",
                        "cat_data": 
                        [
                            {
                                "par_id":1,
                                "par_name": "Valuation multiple ((Enterprise Value / EBITDA) for General) / {(Enterprise Value /Revenue) for Subscription}  - past financial year (INR  million)",
                                "par_max_value": this.inv_1_sub_max_score,
                                "par_val_format": "x",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_1_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_1_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_1_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_1_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_1_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_1_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_1_par_3_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":2,
                                "par_name": "Valuation multiple ((Enterprise Value / EBITDA) for General) / {(Enterprise Value /Revenue) for Subscription}  - projected financial year (INR  million)",
                                "par_max_value": this.inv_2_sub_max_score,
                                "par_val_format": "x",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_2_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_2_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_2_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_2_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_2_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_2_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_2_par_3_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":3,
                                "par_name": "Stakeboat Capital holding (%)",
                                "par_max_value": this.inv_3_sub_max_score,
                                "par_val_format": "x",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_3_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_3_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_3_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_3_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_3_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_3_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_3_par_3_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":4,
                                "par_name": "Internal Rate of Return (IRR) (%)",
                                "par_max_value": this.inv_4_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_4_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_4_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_4_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_4_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_4_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_4_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_4_par_3_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":5,
                                "par_name": "Cash on Cash (CoC) multiple (times)",
                                "par_max_value": this.inv_5_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.inv_5_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_5_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_5_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.inv_5_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.inv_5_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_5_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.inv_5_par_3_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":6,
                                "par_name": "Exit rights",
                                "par_max_value": this.inv_6_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_6_sub_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_6_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_6_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_6_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":7,
                                "par_name": "Liquidation Preference (If No LP, enter NO ; if Yes, then enter IRR%)",
                                "par_max_value": this.inv_7_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_7_sub_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_7_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_7_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_7_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":8,
                                "par_name": "Unique Rights negotiated",
                                "par_max_value": this.inv_8_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_8_sub_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_8_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_8_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_8_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":9,
                                "par_name": "Are there international Strategic buyers ?  List 3 International Companies in the same space / List 2 transactions happened in the same space",
                                "par_max_value": this.inv_9_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.inv_9_sub_option_1,
                                    "parameter_1": "equal to",
                                    "output_type": "Fixed",
                                    "output" : this.inv_9_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.inv_9_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.inv_9_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            }
                        ]
                    }, 
                    {
                        "cat_id": 3,
                        "cat_name": "BUSINESS",
                        "cat_data": 
                        [
                            {
                                "par_id":1,
                                "par_name": "Customer Stickiness (%)",
                                "par_max_value": this.bus_1_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_1_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_1_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_1_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_1_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":2,
                                "par_name": "Customer concentration - Market share of top 2 customers (%)",
                                "par_max_value": this.bus_2_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.bus_2_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_2_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_2_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.bus_2_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.bus_2_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_2_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_2_par_3_opt_bus_val,
                                    "format" : "%"
                                }]            
                            },
                            {
                                "par_id":3,
                                "par_name": "% of revenue contributed by govt. customers (both directly and indirectly) (%)",
                                "par_max_value": this.bus_3_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_3_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_3_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_3_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_3_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":4,
                                "par_name": "Supplier concentration (of the top 3 suppliers) (%)",
                                "par_max_value": this.bus_4_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_4_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_4_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_4_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_4_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":5,
                                "par_name": "Potential risk of suppliers increasing the prices? ",
                                "par_max_value": this.bus_5_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_4",
                                "rules": [{
                                    "option_1": this.bus_5_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_5_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_5_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_5_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":6,
                                "par_name": "Is the business driven by operations or external factors like location, tax benefits, govt. grants, discounted pricing, subsidy? (Make sure that the impact is high if not then its can be relaxed)",
                                "par_max_value": this.bus_6_sub_max_score,
                                "par_val_format": "%",                            
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.bus_6_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_6_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_6_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_6_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":7,
                                "par_name": "Is the pricing in the industry very competitive and can it impact the business? Herfindhal (HHL )Index - use the values and link it to pricing point",
                                "par_max_value": this.bus_7_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_7",
                                "rules": [{
                                    "option_1": this.bus_7_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_7_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_7_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.bus_7_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.bus_7_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_7_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_7_par_3_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":8,
                                "par_name": "Risk of disruption / technology",
                                "par_max_value": this.bus_8_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.bus_8_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_8_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_8_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_8_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":9,
                                "par_name": "Net currency risk (%)",
                                "par_max_value": this.bus_9_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_2",
                                "rules": [{
                                    "option_1": this.bus_9_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_9_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_9_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_9_par_2_opt_bus_val,
                                    "format" : "%"
                                }]             
                            },
                            {
                                "par_id":10,
                                "par_name": "Potential regulatory risk",
                                "par_max_value": this.bus_10_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_3",
                                "rules": [{
                                    "option_1": this.bus_10_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_10_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_10_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "sliding",
                                    "output" : this.bus_10_par_2_opt_bus_val,
                                    "format" : "%"
                                }]         
                            },
                            {
                                "par_id":11,
                                "par_name": "Market Size (INR  million)",
                                "par_max_value": this.bus_11_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_1",
                                "rules": [{
                                    "option_1": this.bus_11_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_11_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_11_sub_option_1,
                                    "parameter_1": "greater than",
                                    "option_2": this.bus_11_sub_option_2,
                                    "parameter_2": "less than",
                                    "output_type": "sliding",
                                    "output" : this.bus_11_par_2_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.bus_11_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.bus_11_par_3_opt_bus_val,
                                    "format" : "%"
                                }]           
                            }
                        ]
                    }, 
                    {
                        "cat_id": 4,
                        "cat_name": "INDUSTRY",
                        "cat_data": [
                            {
                                "par_id":1,
                                "par_name": "Sector",
                                "par_max_value": this.ind_1_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_5",
                                "rules": [{
                                    "option_1": this.ind_1_sub_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.ind_1_par_1_opt_bus_val,
                                    "format" : "%"
                                }, 
                                {
                                    "option_1": this.ind_1_sub_option_2,
                                    "parameter_1": "greater than",
                                    "output_type": "Fixed",
                                    "output" : this.ind_1_par_2_opt_bus_val,
                                    "format" : "%"
                                }]         
                            }
                        
                        ]
                    }, 
                    {
                        "cat_id": 5,
                        "cat_name": "PROMOTERS",
                        "cat_data": [
                            {
                                "par_id":1,
                                "par_name": "Management team (out of total score of 200)",
                                "par_max_value": this.pro_1_sub_max_score,
                                "par_val_format": "%",
                                "rule_type": "rule_6",
                                "rules": [{
                                    "option_1": this.pro_1_option_1,
                                    "parameter_1": "less than",
                                    "output_type": "Fixed",
                                    "output" : this.pro_1_par_1_opt_bus_val,
                                    "format" : "%"
                                }
                                ]
                            }
                        ]
                    }
                ]
            }
        ];

        this.dataService.updateParam(payload).subscribe((data: any[])=>{
            console.log(data);
            this.toastr.success('Data updated successfully!!!');
        });
    }
}