<div class="addbusiness-container">
    <app-header></app-header>
    <div class="body-content">
        <div class="title font-size-h1">Let's Get Started</div>
        <div class="msg">We'll need some information first.</div>
        <div class="input-container pl-0 pl-sm-0 d-flex flex-column justify-content-center">
            <div class="company form-group position-relative">
                <div class="input-label">Choose Company</div>
                <div class="field-row w-100 d-inline-block">
                    <div ngbDropdown display="dynamic"  placement="bottom-left" class="dropdown-container">
                        <button class="w-100 btn btn-outline-primary custom-field" id="dropdownBasic2"
                            ngbDropdownToggle>{{company && company.cmpName || "Choose Company"}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <button *ngFor="let elem of companyList" (click)="onChangeCompany(elem)"
                                class="dropdown-item">{{elem.cmpName}}</button>
                            <button (click)="showAddCompany=true; company = null; deal = null" class="dropdown-item">Add New</button>
                        </div>
                    </div>
                </div>
                <span *ngIf="showAddCompany" class="ml-0 ml-md-4 mt-4 mt-md-0 d-block d-md-inline add-btn-container">
                    <button class="btn btn-outline-primary add-btn" (click)="addCompany()">Add Company</button>
                </span>
            </div>
            <div class="deal form-group position-relative">
                <div class="input-label">Choose Deal</div>
                <div class="field-row w-100 d-inline-block">
                    <div ngbDropdown placement="bottom-left" class="dropdown-container" container="body">
                        <button [disabled]="!company" class="w-100 btn btn-outline-primary custom-field" id="deal"
                            ngbDropdownToggle>{{deal && deal.dateofDeal || "Choose Deal"}}</button>
                        <div ngbDropdownMenu aria-labelledby="deal">
                            <button *ngFor="let elem of dealList" (click)="onChangeDeal(elem)"
                                class="dropdown-item">{{elem.dateofDeal}}</button>
                            <button (click)="showAddDeal=true; deal = null" class="dropdown-item">Add New</button>
                        </div>
                    </div>

                </div>
                <span *ngIf="showAddDeal"  class="ml-0 ml-md-4 mt-4 mt-md-0 d-block d-md-inline add-btn-container">
                    <button class="btn btn-outline-primary add-btn" (click)="addDeal()">Add Deal</button>
                </span>
            </div>
            <button (click)="goToCompany();" class="continue-btn btn btn-primary" [disabled]="!company || !deal">
                Continue
            </button>
        </div>
    </div>
</div>