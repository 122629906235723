import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { HttpClient } from "@angular/common/http";
import { saveAs } from 'file-saver';
import { DataService } from '@app/_services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { AppServices } from '../common/appServices';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-userentry',
  templateUrl: './userentry.component.html',
  styleUrls: ['./userentry.component.scss']
})
export class UserentryComponent implements OnInit {
    inputCommonConfig = {prefix: ''}
	loading = false;
    users: User[];
    isAdmin : boolean = false;
    angForm: FormGroup;
    displayName: string;
    finData: any = [];
    valData: any = [];
    finsco : number = 0;
    netScore : string = '0';
    maxScoree : number = 0;
    totScore : number = 0;
    products: any;
    firstFields: any = [];
    secondFields: any = [];
    thirdFields: any = [];
    totScoreFields:any =[];
    netScoreFields:any = [];
    rules: any = [];
    subRules: any = [];
    rev_model: string = '';
    cat_name: string = '';
    par_name: string = '';
    finsConv:number = 0;
    lpIRR: number = 0;
    sublpIRR: number = 0;
    firstSubFields: any = [];
    secondSubFields: any = [];
    thirdSubFields: any = [];

    finSubsco : number = 0;
    netSubScore : string = '';
    maxSubScoree : number = 0;
    totSubScore : number = 0;
    finsSubConv:number = 0;

    totSubScoreFields:any =[];
    netSubScoreFields:any = [];

    categoryData: any = [];

    genData : any = [];
    subData : any = [];

    filData:any=[];
    invData:any=[];
    busData:any=[];
    indData:any=[];
    proData:any=[];

    sub:string = "";
 	id: number = 0;

    page_1: string = "active";
	page_2: string = "";
	page_3: string = "";
	page_4: string = "";
	page_5: string = "";
    
    companyEn: any = [];
    finInfo_stArr: any = [];
    busInfo_stArr: any = [];
    invInfo_stArr: any = [];
    indInfo_stArr: any = [];

    finDt_arr : any = [];
    indDt_arr : any = [];
    busDt_arr : any = [];
    invDt_arr : any = [];
    proDt_arr : any = [];

	companyId: number = 0;
 	dealId: number = 0;

    promoter_det: string = '';
    promoScore: number = 0;

    dealTeam: any = [];
    compName: string = "";
    deaDt: string = "";

    promoData: any = [];

  constructor(
  	private userService: UserService, 
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private dataService: DataService,
    private toastr: ToastrService,    
  	private route: ActivatedRoute,
    private appservices: AppServices,
    private router: Router, private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
      let user = localStorage.getItem('currentUser');
      console.log(user);
      this.displayName = user && JSON.parse(user).userName;
      this.compName = localStorage.getItem('selectedCompany');
      this.deaDt = localStorage.getItem('dealDate');
      this.route.params.subscribe(params => {
    	   this.id = +params['id']; // (+) converts string 'id' to a number
    	   console.log(this.id);
    	   this.companyId = +params['cmpId'];
    	   this.dealId = +params['dealId'];
    	   // In a real app: dispatch action to load the details here.
    	   if(this.id == 1) {
    	   	this.page_1 = 'active';
    	   	this.page_2 = '';
    	   	this.page_3 = '';
    	   	this.page_4 = '';
    	   	this.page_5 = '';
    	   }
    	   else if(this.id == 2) {
    	   	this.page_1 = '';
    	   	this.page_2 = 'active';
    	   	this.page_3 = '';
    	   	this.page_4 = '';
    	   	this.page_5 = '';
    	   }
    	   else if(this.id == 3) {
    	   	this.page_1 = '';
    	   	this.page_2 = '';
    	   	this.page_3 = 'active';
    	   	this.page_4 = '';
    	   	this.page_5 = '';
    	   }
    	   else if(this.id == 4) {
    	   	this.page_1 = '';
    	   	this.page_2 = '';
    	   	this.page_3 = '';
    	   	this.page_4 = 'active';
    	   	this.page_5 = '';
    	   }
    	   else if(this.id == 5) {
    	   	this.page_1 = '';
    	   	this.page_2 = '';
    	   	this.page_3 = '';
    	   	this.page_4 = '';
    	   	this.page_5 = 'active';
    	   }
    	});
      	this.dataService.getData().subscribe((data: any[])=>{
          console.log(data);
          //this.toastr.success('Data loaded successfully!!!');
          this.products = data;
          this.genData = [data[0]];
          this.subData = [data[1]];

          this.categoryData = data[0].rev_data;
            console.log(this.genData);
            console.log(this.subData);
            var fieCou = 0;

            for(var l=0; l<this.products[0]['rev_data'].length; l++) {
                this.filData = this.products[0]['rev_data'][0]['cat_data'];
                this.invData = this.products[0]['rev_data'][1]['cat_data'];
                this.busData = this.products[0]['rev_data'][2]['cat_data'];
                this.indData = this.products[0]['rev_data'][3]['cat_data'];
                this.proData = this.products[0]['rev_data'][4]['cat_data'];
                    for(var k=0; k<this.products[0]['rev_data'][l]['cat_data'].length; k++) {
                        this.firstFields[fieCou] = '';
                        this.secondFields[fieCou] = '';
                        this.thirdFields[fieCou] = parseInt(this.products[0]['rev_data'][l]['cat_data'][k]['par_max_value']);
                        this.rules[fieCou] = this.products[0]['rev_data'][l]['cat_data'][k]['rules'];
                        fieCou++;
                        this.maxScoree += parseInt(this.products[0]['rev_data'][l]['cat_data'][k]['par_max_value']);
                    }
            }

            var cou=0;
            for(var ss=0; ss<this.products[1]['rev_data'].length;ss++) {
                for(var kk=0; kk<this.products[1]['rev_data'][ss]['cat_data'].length; kk++) {
                    this.firstSubFields[cou] = '';
                    this.secondSubFields[cou] = '';
                    this.thirdSubFields[cou] = this.products[1]['rev_data'][ss]['cat_data'][kk]['par_max_value'];
                    this.subRules[cou] = this.products[1]['rev_data'][ss]['cat_data'][kk]['rules'];
                    cou++;
                    this.maxSubScoree += parseInt(this.products[1]['rev_data'][ss]['cat_data'][kk]['par_max_value']);
                }
            }
            
            //console.log( this.firstFields, "FirstFields");
            //console.log( this.secondFields, "secondFields");
            //console.log( this.thirdFields, "thirdFields");
          //this.createForm(this.products.length);
        })
        
        /*this.userService.getAll().pipe(first()).subscribe(users => {
            this.loading = false;
            console.log(users);
            this.users = users;
        });*/

        this.appservices.getCompanyEntry(this.companyId, this.dealId, (res, err) => {            
            var promoterDet = JSON.parse(res.response.data[0].JSON);
            console.log(promoterDet);
            //this.promoter_det = promoterDet.rev_data[3].cat_data[0].promoter_data[0].promo_id;
            //this.promoScore = promoterDet.rev_data[3].cat_data[0].promoter_data[0].promoScore;
            this.promoData = promoterDet.rev_data[3].cat_data[0].promoter_data;
            console.log(this.promoData);
            if(this.promoData.length > 0) {
                this.promoter_det = this.promoData[0].promo_id;
                var proScore = this.promoScore;
                for(var i=0; i<this.promoData.length; i++) {
                    proScore = proScore + this.promoData[i].promo_score;
                }
                this.promoScore = (proScore)/this.promoData.length;
            }
            if(this.promoScore > 0){
                this.secondFields[31] = (this.promoScore/10);
                this.calculateScore(4, 1);
            }
        });
        this.appservices.getUserEntry(this.companyId, this.dealId, (res, err) => {
        
            if(res.response.data.length > 0){
                this.companyEn = JSON.parse(res.response.data[0].JSON);
                console.log(this.companyEn.promoter_data);
                if(this.companyEn != null) {
                    this.finInfo_stArr = this.companyEn.promoter_data[0].cat_data;
                    this.invInfo_stArr = this.companyEn.promoter_data[1].cat_data;
                    this.busInfo_stArr = this.companyEn.promoter_data[2].cat_data;
                    this.indInfo_stArr = this.companyEn.promoter_data[3].cat_data;
                    this.lpIRR = this.companyEn.lprr;
                    if(this.finInfo_stArr.length > 0 ){
                        for(var i=0; i<=9; i++){
                            this.firstFields[i] = this.finInfo_stArr[i].value;
                            this.secondFields[i] = this.finInfo_stArr[i].score;
                        }
                    }

                    if(this.invInfo_stArr.length > 0 ){
                        for(var i=10; i<=18; i++){
                            this.firstFields[i] = this.invInfo_stArr[i-10].value;
                            this.secondFields[i] = this.invInfo_stArr[i-10].score;
                        }
                    }

                    if(this.busInfo_stArr.length > 0 ){
                        for(var i=19; i<=29; i++){
                            this.firstFields[i] = this.busInfo_stArr[i-19].value;
                            this.secondFields[i] = this.busInfo_stArr[i-19].score;
                        }
                    }
                    if(this.indInfo_stArr.length > 0 ){
                        this.firstFields[30] = this.indInfo_stArr[0].value;
                        this.secondFields[30] = this.indInfo_stArr[0].score;
                    }

                    this.totScore = this.companyEn.promoter_total_score;
                    this.netScore = this.companyEn.promoter_net_score;
                }
                
                //this.maxScoree = this.companyEn.promoter_max_score;
            }
            
            
        });

        

        this.appservices.getCompany(this.companyId, (res, err) => {
            var dealDta = res.response.data[0].dealTeam;
            console.log(res.response.data[0].dealTeam);
            for(var k = 0; k< dealDta.length; k++){
                this.dealTeam.push({'id': k, 'name':dealDta[k].name, 'designation': dealDta[k].designation});
            }
            console.log(this.dealTeam);
        });
  }
goToPage(cou) {
    if(cou == 1) {
        this.page_1 = 'active';
        this.page_2 = '';
        this.page_3 = '';
        this.page_4 = '';
        this.page_5 = '';        
    }
    else if(cou == 2) {
        this.page_1 = '';
        this.page_2 = 'active';
        this.page_3 = '';
        this.page_4 = '';
        this.page_5 = '';
        this.finDt_arr = [];
        for(var i=0; i <=9; i++) {
            this.finDt_arr.push({'value': this.firstFields[i], 'score': this.secondFields[i]});
        }
        this.updatePromoterPage();
    }
    else if(cou == 3) {
        this.page_1 = '';
        this.page_2 = '';
        this.page_3 = 'active';
        this.page_4 = '';
        this.page_5 = '';
        this.indDt_arr = [];
        for(var i=10; i <=18; i++) {
            this.indDt_arr.push({'value': this.firstFields[i], 'score': this.secondFields[i]});
        }

        this.updatePromoterPage();
    }
    else if(cou == 4) {
        this.page_1 = '';
        this.page_2 = '';
        this.page_3 = '';
        this.page_4 = 'active';
        this.page_5 = '';

        this.busDt_arr = [];
        for(var i=19; i <=29; i++) {
            this.busDt_arr.push({'value': this.firstFields[i], 'score': this.secondFields[i]});
        }
        this.updatePromoterPage();
    }
    else if(cou == 5) {
        this.page_1 = '';
        this.page_2 = '';
        this.page_3 = '';
        this.page_4 = '';
        this.page_5 = 'active';

        this.invDt_arr = [];
        this.invDt_arr.push({'value': this.firstFields[30], 'score': this.secondFields[30]});
        
        this.updatePromoterPage();
    }
}
cumulativeLength(firArr) {
        //console.log(firArr);
        if(firArr == 1) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length;
            return acc;
        }
        else if(firArr == 2) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length +  this.products[0].rev_data[firArr-2].cat_data.length;
            return acc;
        }
        else if(firArr == 3) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length +  this.products[0].rev_data[firArr-2].cat_data.length +  this.products[0].rev_data[firArr-3].cat_data.length;
            return acc;
        }
        else if(firArr == 4) {
            let acc = firArr;
            acc = this.products[0].rev_data[firArr-1].cat_data.length +  this.products[0].rev_data[firArr-2].cat_data.length +  this.products[0].rev_data[firArr-3].cat_data.length +  this.products[0].rev_data[firArr-4].cat_data.length;
            return acc;
        }
        else {
            return 0;
        }
        
}

    cumulativeSubLength(firArr) {
        //console.log(firArr);
        if(firArr == 1) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length;
            return acc;
        }
        else if(firArr == 2) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length +  this.products[1].rev_data[firArr-2].cat_data.length;
            return acc;
        }
        else if(firArr == 3) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length +  this.products[1].rev_data[firArr-2].cat_data.length +  this.products[1].rev_data[firArr-3].cat_data.length;
            return acc;
        }
        else if(firArr == 4) {
            let acc = firArr;
            acc = this.products[1].rev_data[firArr-1].cat_data.length +  this.products[1].rev_data[firArr-2].cat_data.length +  this.products[1].rev_data[firArr-3].cat_data.length +  this.products[1].rev_data[firArr-4].cat_data.length;
            return acc;
        }
        else {
            return 0;
        }
        
    }
    onValueChange(cou) {

    }
    onPerChange(catid, cou, $event, rules, parid) {
        var curValue = this.firstFields[cou];
        var rule = rules;
        console.log(cou);
        console.log(parid);
         console.log(this.firstFields);
        /*console.log(catid);
        */
        console.log(rule);
        if(rule[0]["option_1"] !== 'undefined'){
            var rule_0_option_1 = parseFloat(rule[0]['option_1']);
        }
        
        if(rule[0]["output"]  !== 'undefined'){
            var rule_0_output = rule[0]['output'];
        }

        if(rule.length > 1) {
            if(rule[1]["option_1"] !== 'undefined'){
                var rule_1_option_1 = parseFloat(rule[1]['option_1']);
            }
            if(rule[1]["option_2"]  !== 'undefined'){
                var rule_1_option_2 = parseFloat(rule[1]['option_2']);
            }
            if(rule[1]["output"]  !== 'undefined'){
                var rule_1_output = rule[1]['output'];
            }
        }
        if(rule.length > 2) {
            if(rule[2]["option_1"]  !== 'undefined'){
                var rule_2_option_1 = parseInt(rule[2]['option_1']);
            }
            if(rule[2]["output"]  !== 'undefined'){
                var rule_2_output = rule[2]['output'];
            }

        }

        if(catid == 0) {

            if(rule.length==3){
                if(parid == 6) {
                    if(rule_0_option_1 > curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "First1 this" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second1 thi"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third1 - -"  )
                    }
                }      
                else if(parid == 8) {
                    if(curValue < rule_0_option_1) {
                        this.secondFields[cou] = 'NO DEAL';
                        console.log(curValue, this.secondFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third1"  )
                    }
                }
                else if(parid == 9) {
                    if(curValue <= rule_0_option_1) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "1.9 cond 1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                        var calVal = ((rule_1_output*(curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1))).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "1.9 cond 2"  )

                    }
                    else if(rule_1_option_1 <= curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "1.9 cond 3   "  )
                    }
                }
                else if(parid == 5) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "1.5 con 1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "1.5 con 2"  )

                    }
                    else if(rule_2_option_1 <= curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "1.5 con 3"  )
                    }
                }
                else {
                    if(parid == 2) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                            this.firstFields[cou] = '';
                        }
                        
                    }
                    if(parid == 3) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                            this.firstFields[cou] = '';
                        }
                        if(curValue > parseFloat(this.firstFields[cou-1])) {                           
                            this.toastr.error('Value cannot be more than EBITDA margin past financial year (%).');
                            this.firstFields[cou] = '';
                        }
                    }
                    if(rule_0_option_1 >= curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                        console.log(curValue, this.secondFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third1"  )
                    }
                }
                
            }
            else if(parid == 4 || parid == 7) {
                if(curValue >= rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = 0;   
                }
            }
            else if(rule.length == 2 && parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
        }
        else if (catid == 1) {
            if(parid == 4) {
                if(rule_0_option_1 > curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First12" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                     console.log(curValue, this.secondFields[cou], "Second12"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third12"  )
                }
            }
            else if(parid ==1 || parid ==2) {
                if(rule_0_option_1 >= curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                     var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                    console.log(curValue, this.secondFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third22"  )
                }
            }
            else if(parid ==5) {
                if(rule_0_option_1 > curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                    console.log(curValue, this.secondFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third22"  )
                }
            }
            else if(parid == 6 || parid == 8) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondFields[cou] = rule_0_output;
                }
                else {
                    this.secondFields[cou] = rule_1_output;
                }
            }
            else if(parid == 9) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondFields[cou] = rule_0_output;
                }
                else {
                    this.secondFields[cou] = rule_1_output;
                }
            }
            else if(parid ==7) {
                if(curValue == 'No') {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else {
                    var refVal = this.lpIRR;
                    if(refVal < rule_1_option_1) {
                        var calVal = (((refVal / rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                    }
                    else if (refVal > rule_1_option_1) {
                        this.secondFields[cou] = rule_1_output;
                    }
                }
            }
            else if(parid ==17) {
                if(curValue == 'No') {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue / rule_1_option_2)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                    console.log(curValue, this.secondFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third22"  )
                }
            }
            else {
                if(rule.length == 3) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondFields[cou] = rule_0_output;
                        console.log(curValue, this.secondFields[cou], "First22" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondFields[cou] = calVal;
                         console.log(curValue, this.secondFields[cou], "Second22"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondFields[cou] = rule_2_output;
                        console.log(curValue, this.secondFields[cou], "Third22"  )
                    }
                }
                else if(rule.length == 2 && parid == 8) {
                    if(curValue == rule[0]['option_1']) {
                        this.secondFields[cou] = rule_0_output;                    
                    }
                    else if(curValue == rule[1]['option_1']) {
                        this.secondFields[cou] = rule_1_output;   
                    }
                }
                else if(rule.length == 2) {
                    if(curValue >= rule_0_option_1) {
                        this.secondFields[cou] = rule_0_output;                    
                    }
                    else if(curValue < rule_0_option_1) {
                        this.secondFields[cou] = 0;   
                    }
                }
                
            }
            
        }
        else if(catid == 2) {
            if(parid == 1) {
                if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 2) {
                if(rule_0_option_1 >= curValue) {
                    this.secondFields[cou] = rule_0_output;
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                    var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondFields[cou] = rule_2_output;
                }
            }

            else if(parid == 3 ) {
                if(curValue <= rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 4) {
                if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 9) {
                if(curValue <= rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 5 || parid == 6 || parid == 8 || parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 7) {
                if((curValue/100) < rule_0_option_1) {                    
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if((curValue/100) >= rule_1_option_1 && (curValue/100) < rule_1_option_2) {
                
                    this.secondFields[cou] = rule_1_output;   
                }
                else if((curValue/100) >= rule_2_option_1) {
                
                    this.secondFields[cou] = rule_2_output;   
                }
            }
            else if(parid == 11){
                if(rule_0_option_1 > curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First1" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                     console.log(curValue, this.secondFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third1"  )
                }
            }
            else if(parid == 12) {
                if(curValue < rule_0_option_1) {
                    this.secondFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondFields[cou] = rule_1_output;   
                }
            }
            else {
                if(rule_0_option_1 >= curValue) {
                    this.secondFields[cou] = rule_0_output;
                    console.log(curValue, this.secondFields[cou], "First1" )
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondFields[cou] = calVal;
                     console.log(curValue, this.secondFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondFields[cou] = rule_2_output;
                    console.log(curValue, this.secondFields[cou], "Third1"  )
                }
            }
        }
        else if(catid == 3) {
            if(parid == 1) {
                if(curValue == 'Healthcare' || curValue == 'Business to Business Technology' || curValue == 'Manufacturing' || curValue == 'Financial Services' || curValue == 'Business to Business Services' || curValue == 'Logistics' || curValue == 'FMCG' || curValue == 'Pharma') {                    
                    this.secondFields[cou] = 0;                    
                }
                else if(curValue == 'Infastructure' || curValue == 'Consumer Internet' || curValue == 'Real Estate' || curValue == 'Trading' || curValue == 'Food Retail' || curValue == 'Restaurants') {                
                    this.secondFields[cou] = 'NO DEAL';   
                }
            }
        }
        else if(catid == 4) {
            if(parid == 1) {
                if(curValue <= 150) {
                    this.secondFields[cou] = curValue/10;
                }
                else{
                    this.secondFields[cou] = 15;
                }
                
            }
        }
        console.log( this.secondFields, "secondFields");
        console.log( this.firstFields, "firstFields");
        this.calculateScore(catid, parid);

    }

    calculateScore(ca_id, pa_id) {
        console.log("CA ID : ", ca_id);
        console.log("PA ID : ", pa_id);
        var fou = false;
        let finsss:number = 0;
        this.totScore = 0;
        let finssco:number = 0;
        for(var k=0; k<this.secondFields.length; k++) {
            if(this.secondFields[k] != 'NO DEAL') {
                if(this.secondFields[k] != '') {
                    let cuVa:number =  parseFloat(this.secondFields[k]);        
                    this.totScore = Math.round((this.totScore + cuVa) *100)/100;
                    this.finsco = ((this.totScore /this.maxScoree)*100); 
                    //finsss = (parseFloat(Math.round(this.finsco*10^2)/10^2));
                    
                    finssco = (Math.round(this.finsco * 100) / 100);                   
                    this.finsConv = parseFloat(finssco.toString());
                    finsss = (this.finsConv);
                }                               
            }
            else {
               fou = true;
            }
        }
        let toSc1:number = 0;
        let maSc1:number = 0;
        let neSc1:number = 0;

        let toSc2:number = 0;
        let maSc2:number = 0;
        let neSc2:number = 0;

        let toSc3:number = 0;
        let maSc3:number = 0;
        let neSc3:number = 0;

        let toSc4:number = 0;
        let maSc4:number = 0;
        let neSc4:number = 0;

        let toSc5:number = 0;
        let maSc5:number = 0;
        let neSc5:number = 0;

        if(ca_id == 0) {            
            for(var ll=0; ll<10; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc1 = toSc1 + (Math.round(this.secondFields[ll] * 100) / 100);                    
                }
                maSc1 = maSc1 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            console.log(maSc1, "maSc1");
            this.totScoreFields[ca_id] = Math.round(toSc1 * 100) / 100;
            neSc1 = ((this.totScoreFields[ca_id] /maSc1)*100);
            console.log(neSc1, "neSc1");
            //this.netScoreFields[ca_id] = Math.round(neSc1 * 100) / 100;
            this.netScoreFields[ca_id] = maSc1;
        }
        else if(ca_id == 1) {
             for(var ll=10; ll<19; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc2 = toSc2 + (Math.round(this.secondFields[ll] * 100) / 100);
                }
                    maSc2 = maSc2 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totScoreFields[ca_id] = Math.round(toSc2 * 100) / 100;
            neSc2 = ((this.totScoreFields[ca_id] /maSc2)*100);
            //this.netScoreFields[ca_id] = Math.round(neSc2 * 100) / 100;
            this.netScoreFields[ca_id] = maSc2;
        }
        else if(ca_id == 2) {
             for(var ll=19; ll<30; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc3 = toSc3 + (Math.round(this.secondFields[ll] * 100) / 100);
                }
                    maSc3 = maSc3 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totScoreFields[ca_id] = Math.round(toSc3 * 100) / 100;
            neSc3 = ((this.totScoreFields[ca_id] /maSc3)*100);
            //this.netScoreFields[ca_id] = Math.round(neSc3 * 100) / 100;
            this.netScoreFields[ca_id] = maSc3;
        }
        else if(ca_id == 3) {
             for(var ll=30; ll<31; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc4 = toSc4 + (Math.round(this.secondFields[ll] * 100) / 100);
                } 
            }
            this.totScoreFields[ca_id] = Math.round(toSc4 * 100) / 100;
            neSc4 = ((this.totScoreFields[ca_id]));
            //this.netScoreFields[ca_id] = Math.round(neSc4 * 100) / 100;
            this.netScoreFields[ca_id] = maSc4;
        }
        else if(ca_id == 4) {
             for(var ll=31; ll<32; ll++) {
                if(this.secondFields[ll] != ''){
                    toSc5 = toSc5 + (Math.round(this.secondFields[ll] * 100) / 100);
                }
                    maSc5 = maSc5 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totScoreFields[ca_id] = Math.round(toSc5 * 100) / 100;
            neSc5 = ((this.totScoreFields[ca_id] /maSc5)*100);
            //this.netScoreFields[ca_id] = Math.round(neSc5 * 100) / 100;
            this.netScoreFields[ca_id] = maSc5;
        }


        if(fou == true) {
             this.netScore = 'NO DEAL';
        }
        else {
            this.netScore = (finsss).toString();
        }

        //console.log(this.totScoreFields, "totScoreFields");
        //console.log(this.netScoreFields, "netScoreFields");
        //console.log(this.thirdFields, "thirdFields");
        //console.log(this.secondFields, "secondFields");
    }

    onPerSubChange(catid, cou, $event, rules, parid) {
        var curValue = this.firstSubFields[cou];
        var rule = rules;
        console.log(cou);
        console.log(parid);
        /*console.log(catid);
        */
        console.log(rule);
        if(rule[0]["option_1"] !== 'undefined'){
            var rule_0_option_1 = parseFloat(rule[0]['option_1']);
        }
        
        if(rule[0]["output"]  !== 'undefined'){
            var rule_0_output = rule[0]['output'];
        }

        if(rule.length > 1) {
            if(rule[1]["option_1"] !== 'undefined'){
                var rule_1_option_1 = parseFloat(rule[1]['option_1']);
            }
            if(rule[1]["option_2"]  !== 'undefined'){
                var rule_1_option_2 = parseFloat(rule[1]['option_2']);
            }
            if(rule[1]["output"]  !== 'undefined'){
                var rule_1_output = rule[1]['output'];
            }
        }
        if(rule.length > 2) {
            if(rule[2]["option_1"]  !== 'undefined'){
                var rule_2_option_1 = parseInt(rule[2]['option_1']);
            }
            if(rule[2]["output"]  !== 'undefined'){
                var rule_2_output = rule[2]['output'];
            }

        }

                console.log(rule_0_option_1, "rule_0_option_1");
                console.log(rule_1_option_1, "rule_1_option_1");
                console.log(rule_1_option_2, "rule_1_option_2");
                console.log(rule_2_option_1, "rule_2_option_1");
                console.log(rule_0_output, "rule_0_output");
                console.log(rule_1_output, "rule_1_output");
                console.log(rule_2_output, "rule_2_output");
        if(catid == 0) {

            if(rule.length==3){
                if(parid == 6) {
                    if(rule_0_option_1 > curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "First1 this" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second1 thi"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third1 - -"  )
                    }
                }      
                else if(parid == 8) {
                    if(curValue < rule_0_option_1) {
                        this.secondSubFields[cou] = 'NO DEAL';
                        console.log(curValue, this.secondSubFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third1"  )
                    }
                }
                else if(parid == 9) {
                    if(curValue <= rule_0_option_1) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "1.9 cond 1" )
                    }
                    else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                        var calVal = ((rule_1_output*(curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1))).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "1.9 cond 2"  )

                    }
                    else if(rule_1_option_1 <= curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "1.9 cond 3   "  )
                    }
                }
                else if(parid == 5) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "1.5 con 1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "1.5 con 2"  )

                    }
                    else if(rule_2_option_1 <= curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "1.5 con 3"  )
                    }
                }
                else {
                    if(parid == 2) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                        }

                    }
                    if(parid == 3) {
                        if(curValue > 100) {
                            this.toastr.error('Maximum value cannot be more than 100.');
                            this.firstSubFields[cou] = '';
                        }
                        if(curValue > parseFloat(this.firstSubFields[cou-1])) {                           
                            this.toastr.error('Value cannot be more than EBITDA margin past financial year (%).');
                            this.firstSubFields[cou] = '';
                        }
                    }
                    if(rule_0_option_1 >= curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "First1" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second1"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third1"  )
                    }
                }
                
            }
            else if(parid == 4 || parid == 7) {
                if(curValue >= rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = 0;   
                }
            }
            else if(rule.length == 2 && parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
        }
        else if (catid == 1) {
            if(parid == 4) {
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First12" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                     console.log(curValue, this.secondSubFields[cou], "Second12"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third12"  )
                }
            }
            else if(parid ==1 || parid ==2) {
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                     var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                    console.log(curValue, this.secondSubFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third22"  )
                }
            }
            else if(parid ==5) {
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First22" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 > curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                    console.log(curValue, this.secondSubFields[cou], "Second22"  )

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third22"  )
                }
            }
            else if(parid == 6 || parid == 8) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;
                }
            }
            else if(parid == 9) {
                if(rule[0]['option_1'] == curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;
                }
            }
            else if(parid ==7) {                
                 if(curValue == 'No') {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First22" )
                }
                else {
                    var refVal = this.sublpIRR;
                    console.log(refVal, "refVal");
                    console.log(rule_1_option_1, "rule_1_option_1");
                    if(refVal < rule_1_option_1) {
                        var calVal = (((refVal / rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                    }
                    else if (refVal > rule_1_option_1) {
                        this.secondSubFields[cou] = rule_1_output;
                    }
                }
            }
            else {
                if(rule.length == 3) {
                    if(rule_0_option_1 >= curValue) {
                        this.secondSubFields[cou] = rule_0_output;
                        console.log(curValue, this.secondSubFields[cou], "First22" )
                    }
                    else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                        var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                        this.secondSubFields[cou] = calVal;
                         console.log(curValue, this.secondSubFields[cou], "Second22"  )

                    }
                    else if(rule_2_option_1 < curValue) {
                        this.secondSubFields[cou] = rule_2_output;
                        console.log(curValue, this.secondSubFields[cou], "Third22"  )
                    }
                }
                else if(rule.length == 2 && parid == 8) {
                    if(curValue == rule[0]['option_1']) {
                        this.secondSubFields[cou] = rule_0_output;                    
                    }
                    else if(curValue == rule[1]['option_1']) {
                        this.secondSubFields[cou] = rule_1_output;   
                    }
                }
                else if(rule.length == 2) {
                    if(curValue >= rule_0_option_1) {
                        this.secondSubFields[cou] = rule_0_output;                    
                    }
                    else if(curValue < rule_0_option_1) {
                        this.secondSubFields[cou] = 0;   
                    }
                }
                
            }
            
        }
        else if(catid == 2) {
            if(parid == 1) {
                if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 2) {
                if(rule_0_option_1 >= curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 > curValue){
                    var calVal = (((rule_1_option_2 - curValue)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;

                }
                else if(rule_2_option_1 <= curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                }
            }

            else if(parid == 3 ) {
                if(curValue <= rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 4) {
                if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 9) {
                if(curValue <= rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue > rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 5 || parid == 6 || parid == 8 || parid == 10) {
                if(curValue == rule[0]['option_1']) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else if(parid == 7) {
                if((curValue/100) < rule_0_option_1) {
                    
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if((curValue/100) >= rule_1_option_1 && (curValue/100) < rule_1_option_2) {
                
                    this.secondSubFields[cou] = rule_1_output;   
                }
                else if((curValue/100) >= rule_2_option_1) {
                
                    this.secondSubFields[cou] = rule_2_output;   
                }
            }
            else if(parid == 11){
                if(rule_0_option_1 > curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First1" )
                }
                else if(rule_1_option_1 <= curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                     console.log(curValue, this.secondSubFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third1"  )
                }
            }
            else if(parid == 12) {
                if(curValue < rule_0_option_1) {
                    this.secondSubFields[cou] = rule_0_output;                    
                }
                else if(curValue >= rule_1_option_1) {
                    this.secondSubFields[cou] = rule_1_output;   
                }
            }
            else {
                if(rule_0_option_1 >= curValue) {
                    this.secondSubFields[cou] = rule_0_output;
                    console.log(curValue, this.secondSubFields[cou], "First1" )
                }
                else if(rule_1_option_1 < curValue && rule_1_option_2 >= curValue){
                    var calVal = (((curValue - rule_1_option_1)/(rule_1_option_2 - rule_1_option_1)*rule_1_output)).toFixed(2);
                    this.secondSubFields[cou] = calVal;
                     console.log(curValue, this.secondSubFields[cou], "Second1"  )

                }
                else if(rule_2_option_1 < curValue) {
                    this.secondSubFields[cou] = rule_2_output;
                    console.log(curValue, this.secondSubFields[cou], "Third1"  )
                }
            }
        }
        else if(catid == 3) {
            if(parid == 1) {
                if(curValue == 'Healthcare' || curValue == 'Business to Business Technology' || curValue == 'Manufacturing' || curValue == 'Financial Services' || curValue == 'Business to Business Services' || curValue == 'Logistics' || curValue == 'FMCG' || curValue == 'Pharma') {                    
                    this.secondSubFields[cou] = 0;                    
                }
                else if(curValue == 'Infastructure' || curValue == 'Consumer Internet' || curValue == 'Real Estate' || curValue == 'Trading' || curValue == 'Food Retail' || curValue == 'Restaurants') {                
                    this.secondSubFields[cou] = 'NO DEAL';   
                }
            }
        }
        else if(catid == 4) {
            if(parid == 1) {
                if(curValue <= 150) {
                    this.secondSubFields[cou] = curValue/10;
                }
                else{
                    this.secondSubFields[cou] = 15;
                }
                
            }
        }
        console.log( this.secondSubFields, "secondSubFields");
        this.calculateSubScore(catid, parid);

    }

    calculateSubScore(ca_id, pa_id) {
        console.log("CA ID : ", ca_id);
        console.log("PA ID : ", pa_id);
        var fou = false;
        let finsuss:number = 0;
        this.totSubScore = 0;
        let finssco:number = 0;
        for(var k=0; k<this.secondSubFields.length; k++) {
            if(this.secondSubFields[k] != 'NO DEAL') {
                if(this.secondSubFields[k] != '') {
                    let cuVa:number =  parseFloat(this.secondSubFields[k]);        
                    this.totSubScore = Math.round((this.totSubScore + cuVa) *100)/100;
                    this.finSubsco = ((this.totSubScore /this.maxScoree)*100);
                    
                    finssco = (Math.round(this.finSubsco * 100) / 100);                   
                    this.finsSubConv = parseFloat(finssco.toString());
                    finsuss = (this.finsSubConv);
                }                               
            }
            else {
               fou = true;
            }
        }
        let toSc1:number = 0;
        let maSc1:number = 0;
        let neSc1:number = 0;

        let toSc2:number = 0;
        let maSc2:number = 0;
        let neSc2:number = 0;

        let toSc3:number = 0;
        let maSc3:number = 0;
        let neSc3:number = 0;

        let toSc4:number = 0;
        let maSc4:number = 0;
        let neSc4:number = 0;

        let toSc5:number = 0;
        let maSc5:number = 0;
        let neSc5:number = 0;

        if(ca_id == 0) {            
            for(var ll=0; ll<pa_id; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc1 = toSc1 + (Math.round(this.secondSubFields[ll] * 100) / 100);                    
                }
                maSc1 = maSc1 + (Math.round(this.thirdFields[ll] * 100) / 100);              
            }

            this.totSubScoreFields[ca_id] = Math.round(toSc1 * 100) / 100;
            neSc1 = ((this.totSubScoreFields[ca_id] /maSc1)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc1 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc1;
        }
        else if(ca_id == 1) {
             for(var ll=10; ll<19; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc2 = toSc2 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                    
                }
                maSc2 = maSc2 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc2 * 100) / 100;
            neSc2 = ((this.totSubScoreFields[ca_id] /maSc2)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc2 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc2;
        }
        else if(ca_id == 2) {
             for(var ll=19; ll<30; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc3 = toSc3 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                    
                } 
                maSc3 = maSc3 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc3 * 100) / 100;
            neSc3 = ((this.totSubScoreFields[ca_id] /maSc3)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc3 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc3;
        }
        else if(ca_id == 3) {
             for(var ll=30; ll<31; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc4 = toSc4 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                } 
                maSc4 = maSc4 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc4 * 100) / 100;
            neSc4 = ((this.totSubScoreFields[ca_id]));
            //this.netSubScoreFields[ca_id] = Math.round(neSc4 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc4;
        }
        else if(ca_id == 4) {
             for(var ll=31; ll<32; ll++) {
                if(this.secondSubFields[ll] != ''){
                    toSc5 = toSc5 + (Math.round(this.secondSubFields[ll] * 100) / 100);
                    
                } 
                maSc5 = maSc5 + (Math.round(this.thirdFields[ll] * 100) / 100);
            }
            this.totSubScoreFields[ca_id] = Math.round(toSc5 * 100) / 100;
            neSc5 = ((this.totSubScoreFields[ca_id] /maSc5)*100);
            //this.netSubScoreFields[ca_id] = Math.round(maSc5 * 100) / 100;
            this.netSubScoreFields[ca_id] = maSc5;
        }
        if(fou == true) {
             this.netSubScore = 'NO DEAL';
        }
        else {
            this.netSubScore = (finsuss).toString();
        }
    }

updatePromoterPage(){
    var payload={
        "intCmpId":this.companyId,
        "intDealId": this.dealId,
        "intRuleId": 1,
        "intEntryType": 2,
        "json":  {
            "id": 1,
            "rev_id": 1,
            "rev_model": "General",
            "promoter_total_score": this.totScore,
            "promoter_net_score": this.netScore,
            "promoter_max_score": this.maxScoree,
            "lprr": this.lpIRR,
            "promoter_data": [
                {
                    "cat_id": 1,
                    "cat_name": "FINANCIAL INFORMATION",
                    "cat_data": this.finDt_arr
                },
                {
                    "cat_id": 2,
                    "cat_name": "INVESTMENT",
                    "cat_data": this.indDt_arr
                },
                {
                    "cat_id": 3,
                    "cat_name": "BUSINESS",
                    "cat_data": this.busDt_arr
                },
                {
                    "cat_id": 4,
                    "cat_name": "INDUSTRY",
                    "cat_data": this.invDt_arr
                },
                {
                    "cat_id": 5,
                    "cat_name": "PROMOTERS",
                    "cat_data": this.proDt_arr
                }
            ]
        },
        "deviceInfo": {
            "deviceType": "Desktop",
            "browser": "crome"
        }
    };
    console.log(payload);
    this.appservices.uploadCompanyEntry(payload, (res, err) => {
    console.log(res);
    });
}

goToCompany() {
    this.updatePromoterPage();
    this.router.navigate( ['/companyentry', 1, this.companyId, this.dealId]);
}

logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}

}
