import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-financial',
  templateUrl: './company-financial.component.html',
  styleUrls: ['./company-financial.component.less']
})
export class CompanyFinancialComponent implements OnInit {
  isAdmin: boolean;
  constructor() { }

  ngOnInit() {
  }

}
