import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './header/header.component';
import { FileuploadComponent } from './fileupload/fileupload.component';

@NgModule({
 imports:      [ CommonModule, MaterialModule, FormsModule ],
 declarations: [ HeaderComponent, FileuploadComponent ],
 exports:      [ CommonModule, MaterialModule, HeaderComponent, FileuploadComponent ]
})
export class SharedModule { }