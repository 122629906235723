
<div class="dashboard-container">
  <app-header></app-header>
  <!-- <div class="brand-header">
    <img src="assets/zastra-logo-color.svg" height="50px" alt="stakeboat-logo" />
    <div class="name">Jones Ferdinand</div>
  </div> -->
  
  <div class="list-card">
    <div class="title-row">
      <h3 class="title d-flex align-items-center">Companies
        <span class="pl-4 d-flex align-items-center">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
        </span>
      </h3>
      <div class="action d-flex align-items-center">
        <div ngbDropdown class="sort d-flex {{sortBy ? 'text-primary active' : ''}}" #sortDropdown="ngbDropdown">
          <button id="sortBy" class=" d-flex cursor-pointer align-items-center sortByToggle btn" [disabled]="dataSource.data.length == 0" ngbDropdownToggle>
            <mat-icon>sort</mat-icon>
            <span>Sort</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="sortByToggle">
            <button *ngFor="let s of sortByOptions | keyvalue" (click)="onSortChange(s.key)"
              class="dropdown-item">{{s.value}}</button>
          </div>
        </div>
        <div ngbDropdown class="filter d-flex {{filterByType ? 'text-primary active' : ''}}">
          <button id="filterBy" class="d-flex cursor-pointer align-items-center filterByToggle btn" [disabled]="dataSource.data.length == 0" ngbDropdownToggle>
            <mat-icon>filter_alt</mat-icon>
            <span>Filter</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="filterByToggle">
            <button *ngFor="let f of filterByOptions | keyvalue" (click)="onFilterChange('type', f.key)"
              class="dropdown-item">{{f.value}}</button>
          </div>
        </div>
        <button class="btn btn-primary ml-2" (click)="addBusiness()">Add Business</button>
      </div>
    </div>
    <div class="sort-filter-row">
      <div *ngIf="sortBy" class="sortvalue d-flex flex-column position-relative" (click)="sortDropdown.open()">
        <mat-icon class="closeIcon close-icon" (click)="onSortChange('')">close</mat-icon>
        <div class="input-label">Sort By</div>
        <div class="mt-auto mb-auto">{{sortByOptions[sortBy]}}</div>
      </div>
      <div *ngIf="filterByType" class="filtervalue position-relative">
        <mat-icon class="closeIcon close-icon" (click)="onFilterRemove()">close</mat-icon>
        <mat-icon class="position-absolute cursor-pointer" style="bottom: 4px;right: 0;"
          (click)="filterByValue && getAllCompanies(true)">arrow_forward</mat-icon>
        <div class="input-label">Filter by {{filterByOptions[filterByType]}}</div>
        <input type="text" placeholder="" #filterValueRef="ngModel" [(ngModel)]="filterByValue"
          class="form-control filterinput" (keyup.enter)="filterByValue && getAllCompanies(true)"
          [ngClass]="{ 'is-invalid': filterValueRef.dirty && filterValueRef.errors }" />
        <div *ngIf="filterValueRef.dirty && filterValueRef.errors" class="invalid-feedback">
          <div *ngIf="filterValueRef.errors.required">Name is required</div>
        </div>
      </div>
    </div>
    <mat-table *ngIf="dataSource.data.length > 0" #table [dataSource]="dataSource">
      <!-- Position Column -->
      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef class="d-flex justify-content-start"> Company Name </mat-header-cell>
        <mat-cell *matCellDef="let element" class="d-flex justify-content-start"> {{element.companyName}} </mat-cell>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="dateofDeal">
        <mat-header-cell *matHeaderCellDef> Date of Deal </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateofDeal}} </mat-cell>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="sector">
        <mat-header-cell *matHeaderCellDef> Sector </mat-header-cell>
        <mat-cell *matCellDef="let element"> 
          {{sectorLabel && sectorLabel[element.sector]}}
        </mat-cell>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="geoRegion">
        <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.geoRegion}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="strStatus">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.strStatus}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          <div ngbDropdown container="body" class="rowMenuContainer d-flex">
            <button id="rowMenu" class="center-btn-icon" mat-icon-button ngbDropdownToggle>
              <mat-icon class="center-mat-icon" aria-label="options">more_vert</mat-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="rowMenu">
              <button (click)="onRowMenuClick('edit', element)" class="dropdown-item">Edit</button>
              <!-- <button (click)="onRowMenuClick('delete', element)" class="dropdown-item">Delete</button> -->
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator *ngIf="dataSource.data.length > 0" [length]="dataLength" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" (page)="onPaginatorChange($event)">
    </mat-paginator>
    <div *ngIf="dataSource.data.length == 0" class="empty-list">
      <img src="assets/company-list-empty.svg" height="100px" alt="stakeboat-logo" />
      <span class="mt-2">No Companies Evaluated</span>
    </div>
  </div>
</div>