import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServices } from '@app/common/appServices';
import { DashboardService } from '@app/_services';

function getMonth(i) {
  let objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(i - 1);
  let locale = "en-us";
  let month = objDate.toLocaleString(locale, { month: "short" });
  return month;
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  public companyForm;
  public submitted;
  public fileUploadErr: boolean;
  monthList = Array(12).fill(0).map((v, i) => getMonth(i + 1));
  public yearList = Array(200).fill(0).map((v, i) => new Date().getFullYear() - i);
  // remove sample data - kbtcheck
  stakeboardOwners = ['Owner 1', 'Owner 2'];
  sampleDropdownList = [
    'Lorem',
    'Vestib',
    'Nulla',
    'Suspen',
    'Suspe',
    'Pellen',
  ];
  regionDropdown = [
    'North',
    'South',
    'East',
    'West',
  ];
  geoData = {
    North: [
      'Uttar Pradesh',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Uttarakhand',
      'Bihar',
      'Punjab',
      'Haryana',
      'Chandigarh',
      'Delhi',
      'Ladakh',
    ],
    South: [
      'Tamil Nadu',
      'Karnataka',
      'Kerala',
      'Andhra Pradesh',
      'Telangana',
      'Puducherry',
      'Andaman and Nicobar',
      'Lakshadweep',
    ],
    East: [
      'Arunachal Pradesh',
      'Assam',
      'West Bengal',
      'Jharkhand',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Sikkim ',
      'Tripura',
      'Odisha',
    ],
    West: [
      'Maharashtra',
      'Gujarat',
      'Rajasthan',
      'Goa',
      'Daman and Diu',
    ],
    Central: [
      'Madhya Pradesh',
      'Chhattisgarh',
    ]
  }
  stateDropdown = [
    'Tamilnadu',
    'Andrapradesh',
    'Kerala',
    'Telangana',
  ];
  ownerDropdown = [
    'Owner 1',
    'Owner 2',
    'Owner 3',
    'Owner 4',
  ];
  designationDropdown = [
    'Designation 1',
    'Designation 2',
    'Designation 3',
    'Designation 4',
  ];
  sectorDropdown: any = [];
  subSectorDropdown: any = [];
  displayName: string;
  companyId: string;
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    private appservices: AppServices,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.route.params.subscribe(params => {
      this.companyId = params['cmpId'];
      this.getCompanyDetails(params['cmpId']);
    })
    this.getSector();
  }

  fileUploadHandler(event, controlName) {
    let file = event.target.files[0];
    let origFileName = event.target.files[0].name;
    let fileName = controlName == 'msme' ? 'MsmeCertificate' : 'StartUpCertificate';
    const formData = new FormData();
    formData.append('strFileInput', file, origFileName);
    formData.append('strFileName', fileName);
    formData.append('strUploadFolder', 'company');
    this.appservices.uploadFiles(formData, (res, err) => {
      event.target.value = '';
      console.log(res.response);
      let fileUrl = res.response.path;
      let fileControl = this.companyForm.get(`${controlName}.file`);
      fileControl.setValue(fileUrl);
    });
  }

  deleteFile(controlName) {
    let control = this.companyForm.get(`${controlName}.file`);
    control.setValue('');
  }

  getCompanyDetails(cmpId) {
    if (cmpId != 'new') {
      this.dashboardService.getCompanyById(cmpId).subscribe((res: any) => {
        if (res.intStatus == 200) {
          let d = res.response.data[0];
          this.prepareForm(d);
          this.getSubSector(d.sector);
          // this.companyForm.setValue({
          //     name: d.companyName,
          //     dateOfEntry: {
          //         date,
          //         month,
          //         year,
          //     },
          //     dealTeam: d.dealTeam,
          //     type: d.companyType,
          //     incorporationNumber: d.companyNumber,
          //     msme: {
          //       isMsme: d.MSME,
          //       file: d.MSMEFile,
          //     },
          //     startup: {
          //       isStartup: d.startUp,
          //       file: d.startUpFile,
          //     },
          //     nameOfFounders: d.founderInfo || [],
          //     keyManagementTeam: [
          //       {
          //         "owner": "",
          //         "designation": ""
          //       }
          //     ],
          //     boardOfDirectors: d.bodir || [],
          //     geography: {
          //       region: d.geoRegion,
          //       state: d.geoState,
          //     },
          //     sector: d.sector,
          //     subSector: d.subSector,
          //     businessDescription: d.businessDesc,
          // });
        } else {
          this.showError('Error fetching company data');
        }
      });
    } else {
      this.prepareForm();
    }
  }

  prepareForm(d?) {
    let isNew = !d;
    let [year = '', month = '', date = ''] = d ? d.dateOfEntry.split('-') : [];
    year = +year;
    month = +month;
    date = +date;
    this.companyForm = this.formBuilder.group({
      name: [isNew ? '' : d.companyName, Validators.required],
      dateOfEntry: this.formBuilder.group({
        date: [isNew ? '' : date, Validators.required],
        month: [isNew ? '' : month, Validators.required],
        year: [isNew ? '' : year, Validators.required],
      }),
      dealTeam: this.formBuilder.array(
        isNew ? [this.createDealTeam()] :
          d.dealTeam.map(dt => this.createDealTeam(dt))
      ),
      type: [isNew ? '' : d.companyType, Validators.required],
      incorporationNumber: [isNew ? '' : d.companyNumber, Validators.required],
      msme: this.formBuilder.group({
        isMsme: [isNew ? 'n' : d.MSME, Validators.required],
        file: [isNew ? '' : d.MSMEFile],
      }),
      startup: this.formBuilder.group({
        isStartup: [isNew ? 'n' : d.startUp, Validators.required],
        file: [isNew ? '' : d.startUpFile],
      }),
      nameOfFounders: this.formBuilder.array(
        isNew ? [this.createNameOfFounder()] :
          d.founderInfo.map(fi => this.createNameOfFounder(fi))
      ),
      keyManagementTeam: this.formBuilder.array(
        isNew ? [this.createKeyManagementTeam()] :
          d.keyManagementTeam.map(km => this.createKeyManagementTeam(km))
      ),
      boardOfDirectors: this.formBuilder.array(
        isNew ? [this.createBoardOfDirector()] :
          d.bodir.map(bd => this.createBoardOfDirector(bd))
      ),
      geography: this.formBuilder.group({
        region: [isNew ? '' : d.geoRegion, Validators.required],
        state: [isNew ? '' : d.geoState, Validators.required],
      }),
      sector: [isNew ? '' : d.sector, Validators.required],
      subSector: [isNew ? '' : d.subSector, Validators.required],
      businessDescription: [isNew ? '' : d.businessDesc, Validators.required],
    });
  }

  getSector() {
    this.dashboardService.getSector().subscribe((res: any) => {
      if (res.intStatus == 200) {
        let data = res.response.data;
        this.sectorDropdown = data;
      } else {
        this.showError(res.strMessage || 'Failed to fetch Sector values')
      }
    })
  }

  getSubSector(sectorId?) {
    let sector = this.companyForm.get('sector') as FormControl;

    this.dashboardService.getSubSector(sectorId || sector.value).subscribe((res: any) => {
      if (res.intStatus == 200) {
        let data = res.response.data[0];
        this.subSectorDropdown = data.subsector;
      } else {
        this.showError(res.strMessage || 'Failed to fetch Sector values')
      }
    })
  }

  addNameOfFounder() {
    this.nameOfFounders.push(this.createNameOfFounder());
  }

  addBoardOfDirector() {
    this.boardOfDirectors.push(this.createBoardOfDirector());
  }

  addDealTeam() {
    if (this.dealTeam.length < 4)
      this.dealTeam.push(this.createDealTeam());
    else
      this.showError('Only 4 deals can be created');
  }

  addKeyManagementTeam() {
    this.keyManagementTeam.push(this.createKeyManagementTeam());
  }

  onSubmit() {
    this.submitted = true;
    let d = this.companyForm.value;
    if (this.companyForm.invalid || (d.msme.isMsme == 'y' && !d.msme.file) || (d.startup.isStartup == 'y' && !d.startup.file)) {
      if ((d.msme.isMsme == 'y' && !d.msme.file) || (d.startup.isStartup == 'y' && !d.startup.file)) this.fileUploadErr = true;
      return;
    }

    console.log("kbt: CompanyComponent -> onSubmit -> this.companyForm.value", this.companyForm.value);
    this.saveCompany();
  }

  saveCompany() {
    let d = this.companyForm.value;
    let date = d.dateOfEntry;
    date.date = date.date && date.date < 10 ? '0' + date.date : date.date;
    date.month = date.month && date.month < 10 ? '0' + date.month : date.month;
    let postData = {
      strCompanyName: d.name,
      strDateOfEntry: `${date.year}-${date.month}-${date.date}`,
      strDealTeam: d.dealTeam,
      strCompanyType: d.type,
      strCompanyNumber: d.incorporationNumber,
      strMSME: d.msme.isMsme,
      strMSMEFile: d.msme.file || null,
      strstartUp: d.startup.isStartup,
      strstartUpFile: d.startup.file || null,
      founderInfo: d.nameOfFounders,
      keyManagementTeam: d.keyManagementTeam,
      bodir: d.boardOfDirectors,
      strGeoRegion: d.geography.region,
      strGeoState: d.geography.state,
      strSector: d.sector,
      strSubSector: d.subSector,
      strBusinessDesc: d.businessDescription,
    }
    this.dashboardService.addCompany(postData, this.companyId).subscribe((res: any) => {
      if (res.intStatus == 200) {
        this.showSuccess(res.strMessage);
        let resData = res.response.data && res.response.data[0];
        localStorage.setItem('selectedCompanyId', resData.id);
        localStorage.setItem('selectedCompanyName', resData.companyName);
        let dealId = localStorage.getItem('selectedDealId');
        this.router.navigate(['deals', dealId]);
      } else {
        this.showError(res && res.strMessage || 'Some error occured');
      }
      console.log("kbt ~ file: company.component.ts ~ line 125 ~ CompanyComponent ~ this.dashboardService.addCompany ~ res", res);
    })
  }

  deleteField(arrayName, i) {
    let ctrl = this.companyForm.get(arrayName) as FormArray;
    ctrl.removeAt(i);
  }

  createNameOfFounder(d?) {
    return this.formBuilder.group({
      name: [d ? d.name : '', Validators.required],
      designation: [d ? d.designation : '', Validators.required]
    });
  }

  createDealTeam(d?) {
    return this.formBuilder.group({
      name: [d ? d.name : ''],
      designation: [d ? d.designation : '']
    });
  }

  createKeyManagementTeam(d?) {
    return this.formBuilder.group({
      owner: [d ? d.owner : '', Validators.required],
      designation: [d ? d.designation : '', Validators.required]
    });
  }

  createBoardOfDirector(d?) {
    return this.formBuilder.group({
      name: [d ? d.name : '', Validators.required],
      promotor: [d ? d.promotor : '', Validators.required],
      dinNumber: [d ? d.dinNumber : '', Validators.required]
    });
  }

  makeSubSectorEmpty() {
    let ss = this.companyForm.get('subSector') as FormControl;
    ss.setValue("");
  }

  makeStateEmpty() {
    let geoState = this.companyForm.get('geography').controls.state as FormControl;
    geoState.setValue("");
  }

  showError(err) {
    this.snackBar.open(err, '', {
      duration: 2000,
      panelClass: ['alert', 'show', 'alert-danger'],
    });
  }

  showSuccess(msg) {
    this.snackBar.open(msg, '', {
      duration: 2000,
      panelClass: ['alert', 'show', 'alert-success'],
    });
  }

  errorCheck(controlName, isGroup?) {
    if (!this.companyForm) return false;
    let elem = this.companyForm.get(controlName);
    if (controlName == 'sector ') {
      console.log('elem.invalid', elem.invalid);
    }
    return elem.invalid && (isGroup ? this.submitted : (this.submitted || elem.touched || elem.dirty));
  }

  errorShow(controlName, isGroup?) {
    if (!this.companyForm) return false;
    let elem = this.companyForm.get(controlName);
    if (controlName == 'sector ') {
      console.log('elem.invalid', elem.invalid);
    }
    if (elem.invalid && (isGroup ? this.submitted : (this.submitted || elem.touched || elem.dirty))) {
      if (elem.errors && elem.errors.required)
        return 'This field is required';
      else
        return 'Please provide valid input';
    }
  }

  public get name() {
    return this.companyForm.get('name');
  }

  public get type() {
    return this.companyForm.get('type');
  }

  public get incorporationNumber() {
    return this.companyForm.get('incorporationNumber');
  }

  public get msme() {
    return this.companyForm.get('msme');
  }

  public get startup() {
    return this.companyForm.get('startup');
  }

  public get geography() {
    return this.companyForm.get('geography');
  }

  public get sector() {
    return this.companyForm.get('sector');
  }

  public get subSector() {
    return this.companyForm.get('subSector');
  }

  public get businessDescription() {
    return this.companyForm.get('businessDescription');
  }

  public get dealTeam() {
    return this.companyForm.get('dealTeam');
  }

  public get keyManagementTeam() {
    return this.companyForm.get('keyManagementTeam');
  }

  public get nameOfFounders() {
    return this.companyForm.get('nameOfFounders');
  }

  public get boardOfDirectors() {
    return this.companyForm.get('boardOfDirectors');
  }

  public get dateOfEntry() {
    return this.companyForm.get('dateOfEntry');
  }

  public get dateList() {
    let selYear = this.dateOfEntry.controls.year.value;
    let selMonth = this.dateOfEntry.controls.month.value;
    console.log("kbt: CompanyComponent -> getdateList -> selYear && selMonth", this.companyForm.controls.msme.controls.isMsme.value);
    if (selYear && selMonth) {
      const dayCount = this.getDaysInMonth(selYear, selMonth);
      return Array(dayCount).fill(0).map((v, i) => i + 1)
    } else {
      return Array(31).fill(0).map((v, i) => i + 1);
    }
  }

  public getDaysInMonth(year: number, month: number) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

}
