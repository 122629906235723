import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject } from "rxjs";

import * as _ from 'lodash';

@Injectable({ providedIn: "root" })
export class StateManagement {
  public userProfile: BehaviorSubject<any>;
  public userProfile$: Observable<any>;

  public userSiteData: any;
  public adminData: any;

  public resultLogs: any;

  public dataLoaded: BehaviorSubject<any>;
  public dataLoaded$: Observable<any>;

  public reLoadSite: BehaviorSubject<any>;
  public reLoadSite$: Observable<any>;

  public userProfileData: any;

  public requestTypes: any;


  public promoterDetails: any;
  constructor() {
    this.init();



  }

  init() {
    this.userProfile = new BehaviorSubject(null);
    this.userProfile$ = this.userProfile.asObservable();

    this.dataLoaded = new BehaviorSubject(null);
    this.dataLoaded$ = this.dataLoaded.asObservable();

    this.reLoadSite = new BehaviorSubject(null);
    this.reLoadSite$ = this.reLoadSite.asObservable();


    this.adminData = null;
    this.resultLogs = null;
  }

  clearAll() {
    this.userProfile = null;
    this.userProfile$ = null;

    this.dataLoaded = null;
    this.dataLoaded$ = null;

    this.reLoadSite = null;
    this.reLoadSite$ = null;

    this.userProfileData = null;
    this.adminData = null;

    this.resultLogs = null;


  }

  getRequestType(id) {
    let type = _.filter(this.requestTypes, { uid: id })[0];
    return type;
  }

}
