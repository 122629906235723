import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DataService {
private REST_API_SERVER = "https://stakeboat-dev.kinitous.in/api/account";

  constructor(private httpClient: HttpClient) { }

  public getData(){
    return this.httpClient.get(`${environment.apiUrl}` + '/account/getJSON');
  }

  public updateParam(payload) {
    const headers = { 'content-type': 'application/json'}  
    const body= {
		"json":payload
	};
    return this.httpClient.post(`${environment.apiUrl}` + '/account/updatejson', body,{'headers':headers})
  }
}
